import moment from 'moment'

export type Consultation = {
  date: string
  patientsCount: number
}

export type Month = {
  data: Consultation[]
  total: number
}

export type Consultations = {
  [key: string]: Month
}

export const formatConsultations = (data: any): Consultations => {
  return data
    .filter((item: any) => item.patientsCount > 0)
    .reduce((acc: any, item: any) => {
    const key = moment(item.dateReference).format("MMMM YYYY")
    if (acc[key]) {
      acc[key].data.push({
        date: moment(item.dateReference).format("MM/DD"),
        patientsCount: item.patientsCount || 0,
      })
      acc[key].total += item.patientsCount || 0
    } else {
      acc[key] = {
        data: [{
          date: moment(item.dateReference).format("MM/DD"),
          patientsCount: item.patientsCount || 0,
        }],
        total: item.patientsCount
      }
    }
    return acc
  }, {})
}
