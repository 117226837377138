import { createTheme } from '@material-ui/core'
import red from '@material-ui/core/colors/red'
import blue from '@material-ui/core/colors/blue'
import cyan from '@material-ui/core/colors/cyan'
import teal from '@material-ui/core/colors/teal'
import grey from '@material-ui/core/colors/grey'
import orange from '@material-ui/core/colors/orange'
import green from '@material-ui/core/colors/green'

declare module '@material-ui/core/styles/createTheme' {
	interface Theme {
		sidebarWidth: number
		sidebarMobileHeight: number
		brand: string
		footerHeight: number
		mobileTopBarHeight: number
		mobileFooterHeight: number
		sidebarMobilePadding: number
		participantBorderWidth: number
	}

	// allow configuration using `createMuiTheme`
	interface ThemeOptions {
		sidebarWidth?: number
		sidebarMobileHeight?: number
		brand: string
		footerHeight: number
		mobileTopBarHeight: number
		mobileFooterHeight: number
		sidebarMobilePadding: number
		participantBorderWidth: number
	}
}

export default createTheme({
	typography: {
		fontFamily: 'Inter, sans-serif',
	},
	palette: {
		primary: {
			main: '#2E6EC1',
			light: '#FFFFFF',
			dark: '#2E6EC1',
		},
		warning: {
			main: '#F95252',
		},
		secondary: {
			main: '#FE6B8B',
			light: '#CCCCCC',
		},
		text: {
			primary: '#31353D',
		},
		background: {
			default: '#DCEBFF',
			paper: '#FFFFFF',
		},
	},
	brand: '#E22525',
	footerHeight: 72,
	mobileFooterHeight: 56,
	sidebarWidth: 355,
	sidebarMobileHeight: 90,
	sidebarMobilePadding: 8,
	participantBorderWidth: 2,
	mobileTopBarHeight: 52,
})

export const Colors = {
	red,
	blue,
	cyan,
	teal,
	grey,
	orange,
	green,
}
