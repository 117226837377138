import React from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter, Route } from 'react-router-dom'
import { Auth0Provider } from '@auth0/auth0-react'
import { ClientRouter } from 'router/ClientRouter'
import { LocalizationProvider } from '@material-ui/pickers'
import { ThemeProvider, CssBaseline } from '@material-ui/core'
import { QueryParamProvider } from 'use-query-params'
import DateFnsAdapter from '@date-io/date-fns'

import { auth0ProviderOptions } from 'utils/auth0/authProviderOptions'
import { store } from 'store'
import Theme from 'utils/theme'

function App() {
	return (
		<Provider store={store}>
			<ThemeProvider theme={Theme}>
				<CssBaseline />
				<Auth0Provider {...auth0ProviderOptions}>
					<BrowserRouter>
						<LocalizationProvider dateAdapter={DateFnsAdapter as any}>
							<QueryParamProvider ReactRouterRoute={Route}>
								<ClientRouter />
							</QueryParamProvider>
						</LocalizationProvider>
					</BrowserRouter>
				</Auth0Provider>
			</ThemeProvider>
		</Provider>
	)
}

export default App
