import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { Redirect } from 'react-router-dom'

import RouterWrapper, { RouterWrapperProps } from './RouterWrapper'
import { defaultProtectedUrl } from 'router/ClientRouter'

const GuestRoute = <LP extends {}>(props: RouterWrapperProps<LP>): React.ReactElement => {
	const { isAuthenticated } = useAuth0()

	if (isAuthenticated) {
		return <Redirect to={defaultProtectedUrl} />
	}

	return <RouterWrapper {...props} />
}

export default GuestRoute
