import { IconButton, makeStyles, Avatar } from '@material-ui/core'
import { PersonalInfo } from 'pages/Profile/redux/model'
import EditIcon from '@material-ui/icons/Edit'
import { useTranslation } from 'react-i18next'
import { format } from 'date-fns'
import React, { FC, useCallback, useMemo } from 'react'
import { Colors } from 'utils/theme'

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		alignItems: 'center',
		backgroundColor: theme.palette.background.default,
		borderRadius: '10px',
		padding: theme.spacing(2),
		marginBottom: theme.spacing(3),
		position: 'relative',
	},
	logo: {
		width: '50px',
		height: '50px',
		borderRadius: '50%',
		backgroundColor: theme.palette.primary.main,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		color: theme.palette.background.paper,
	},
	univer: {
		marginLeft: theme.spacing(2),
	},
	univerTitle: {
		fontWeight: 600,
		fontSize: '18px',
		marginBottom: theme.spacing(1),
	},
	faculty: {
		fontSize: '12px',
	},
	ml10: {
		marginLeft: theme.spacing(10),
	},
	title: {
		fontSize: '12px',
		color: Colors.grey[700],
		marginBottom: theme.spacing(1),
	},
	description: {
		fontWeight: 500,
		fontSize: '12px',
	},
	edit: {
		position: 'absolute',
		right: 0,
		top: 0,
		cursor: 'pointer',
	},
}))

type PersonalInfoBannerProps = {
	item: PersonalInfo
	editItem: (itemId?: string) => void
}

export const PersonalInfoBanner: FC<PersonalInfoBannerProps> = ({
	item: { birthDate, city, country, firstName, lastName, middleName, gender, phone, email, avatar },
	editItem,
}) => {
	const classes = useStyles()
	const { t } = useTranslation(['translation', 'gender', 'countries', 'cities'])

	const clickHandler = useCallback(() => {
		editItem()
	}, [editItem])

	const date = useMemo(() => format(new Date(birthDate as Date), 'dd/MM/yyyy'), [birthDate])
	const genderOptions = t('gender:GENDER') as any[]
	const countriesOptions = t('countries:COUNTRIES') as any[]
	const citiesOptions = t('cities:CITIES') as any[]
	const genderLocalized = useMemo(() => genderOptions.find(item => item.value === gender).label, [
		gender,
		genderOptions,
	])
	const countryLocalized = useMemo(
		() => countriesOptions.find(item => item.value === country).label,
		[countriesOptions, country]
	)
	const cityLocalized = useMemo(() => citiesOptions.find(item => item.id === city).name, [
		citiesOptions,
		city,
	])

	return (
		<div className={classes.root}>
			<div className={classes.logo}>{avatar && <Avatar src={avatar} />}</div>
			<div className={classes.univer}>
				<div className={classes.univerTitle}>{`${lastName} ${firstName} ${middleName}`}</div>
				<div className={classes.faculty}>{`${countryLocalized}, ${cityLocalized}`}</div>
			</div>
			<div className={classes.ml10}>
				<div className={classes.title}>{t('LEOMED_ADMIN_PROFILE_PAGE_DOB_LABEL')}</div>
				<div className={classes.description}>{date}</div>
			</div>
			<div className={classes.ml10}>
				<div className={classes.title}>{t('LEOMED_ADMIN_PROFILE_PAGE_GENDER_LABEL')}</div>
				<div className={classes.description}>{genderLocalized}</div>
			</div>
			<div className={classes.ml10}>
				<div className={classes.title}>{t('LEOMED_ADMIN_PROFILE_PAGE_PHONE_LABEL')}</div>
				<div className={classes.description}>{phone}</div>
			</div>
			<div className={classes.ml10}>
				<div className={classes.title}>{t('LEOMED_ADMIN_PROFILE_PAGE_EMAIL_LABEL')}</div>
				<div className={classes.description}>{email}</div>
			</div>
			<div className={classes.edit}>
				<IconButton onClick={clickHandler}>
					<EditIcon color="primary" />
				</IconButton>
			</div>
		</div>
	)
}
