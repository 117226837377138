import { authActions } from 'pages/Auth/redux/actions'
import { officeActions } from 'pages/Office/redux/actions'
import { createReducer, ActionType } from 'typesafe-actions'

export type State = {
	token: string
	session: any
}

export const initialState: State = {
	token: '',
	session: null,
}

export default createReducer<State, ActionType<typeof authActions | typeof officeActions>>(
	initialState
)
	.handleAction(authActions.storeToken, (state, { payload: { token } }) => ({
		...state,
		token,
	}))
	.handleAction(authActions.getSession.success, (state, { payload: { session } }) => ({
		...state,
		session,
	}))
	.handleAction(officeActions.sendReport.success, state => ({
		...state,
		session: null,
	}))
	.handleAction(authActions.updateSession.success, (state, { payload: { session } }) => ({
		...state,
		session: {
			...state.session,
			data: {
				...state.session.data,
				liveConsultation: {
					...state.session.data.liveConsultation,
					...session.liveConsultation,
				},
				state: {
					...state.session.data.state,
					...session.state,
				},
			},
		},
	}))
