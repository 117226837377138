import { createReducer, ActionType } from 'typesafe-actions'
import { alertsActions, AlertType, NotificationType } from './actions'

export type State = {
	alerts: AlertType[]
	socketNotifications: NotificationType[]
}

export const initialState = {
	alerts: [],
	socketNotifications: [],
}

export const alertsReducer = createReducer<State, ActionType<typeof alertsActions>>(
	initialState
)
	.handleAction(alertsActions.popupAlert, (state, { payload: { message, severity, color } }) => ({
		...state,
		alerts: [
			...state.alerts,
			{ message, severity, color },
		]
	}))
  .handleAction(alertsActions.removeAlertPopup, (state) => ({
		...state,
		alerts: [
			...state.alerts.slice(1),
		]
	}))

	.handleAction(alertsActions.popupSocketNotification, (state, { payload: { type, data } }) => ({
		...state,
		socketNotifications: [
			...state.socketNotifications,
			{ type, data },
		]
	}))
  .handleAction(alertsActions.removeSocketNotification, (state, { payload: { id }}) => ({
		...state,
		socketNotifications: state.socketNotifications.filter(item => item.data.id !== id)
	}))

	.handleAction(alertsActions.clearAllSocketNotifications, (state) => ({
		...state,
		socketNotifications: []
	}))
