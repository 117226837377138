import { createSelector, createStructuredSelector, Selector } from 'reselect'
import { State } from 'store/reducers'
import { Loading, LoadingDefault } from 'utils/types/store'

export const getOfficeEntities = (state: State): State['office']['entities'] =>
	state.office.entities
export const getOfficeUI = (state: State): State['office']['ui'] => state.office.ui

export const getDoctorWorking = createSelector(
	getOfficeEntities,
	entities => entities.isDoctorWorking
)
export const getPatientInfo = createSelector(getOfficeEntities, entities => entities.patientInfo)
export const getConferenceUrl = createSelector(
	getOfficeEntities,
	entities => entities.conferenceUrl
)
export const getConsultations = createSelector(
	getOfficeEntities,
	entities => entities.consultations
)
export const getNotifications = createSelector(
	getOfficeEntities,
	entities => entities.notifications
)
export const getDoctorBalance = createSelector(
	getOfficeEntities,
	entities => entities.balance
)

export const getAccessToken = createSelector(getOfficeEntities, entities => entities.accessToken)

export const getCards = createSelector(getOfficeEntities, entities => entities.cards)

export const getTransactionRequestsIds = createSelector(getOfficeEntities, (entity) => entity.transactionHistoryIds)

export const getTransactionRequestsList = createSelector(
	getTransactionRequestsIds,
	getOfficeEntities,
	(ids, entity) => ids.map((id: any) => entity.transactionHistoryById[id])
)

export const getToggleDoctorIsWorkingLoading = createSelector(
	getOfficeUI,
	entities => entities.toggleDoctorIsWorkingLoading
)
export const getCreateRoomLoading = createSelector(
	getOfficeUI,
	entities => entities.createRoomLoading
)
export const getWithdrawMoneyLoading = createSelector(
	getOfficeUI,
	entities => entities.withdrawMoneyLoading
)
export const getDoctorBalanceLoading = createSelector(
	getOfficeUI,
	entities => entities.getDoctorBalanceLoading
)
export const getDoctorStatusLoading = createSelector(
	getOfficeUI,
	entities => entities.getDoctorStatusLoading
)
export const getSendReportLoading = createSelector(
	getOfficeUI,
	entities => entities.sendReportLoading
)
export const getPaymentMethodsLoading = createSelector(
	getOfficeUI,
	entities => entities.getPaymentMethodsLoading
)
export const getCreatePaymentMethodLoading = createSelector(
	getOfficeUI,
	entities => entities.createPaymentMethodLoading
)
export const getPostFeedbackLoading = createSelector(
	getOfficeUI,
	entities => entities.postFeedbackLoading
)
export const getConsultationsLoading = createSelector(
	getOfficeUI,
	entities => entities.getConsultationsLoading
)
export const getWithdrawalListLoading = createSelector(
	getOfficeUI,
	entities => entities.getWithdrawalListLoading
)
export const makegetWithdrawalStatusLoadingById = (id: string): Selector<State, Loading> =>
  createSelector(getOfficeUI, (ui) => ui.getWithdrawalStatusLoadingById[id] || LoadingDefault)
export const getSplashVisibleLoading = createSelector(
	getOfficeUI,
	entities => entities.splashVisible
)
export const getIsContactUsOpen = createSelector(
	getOfficeUI,
	entities => entities.isContactUsOpen
)

export const getTransactionHistoryLimit = createSelector(getOfficeEntities, (entity) => entity.transactionHistoryLimit)
export const getTransactionHistoryTotal = createSelector(getOfficeEntities, (entity) => entity.transactionHistoryTotal)
export const getTransactionHistoryPage = createSelector(getOfficeEntities, (entity) => entity.transactionHistoryPage)

export const getTransactionRequestsInfo = createStructuredSelector<
  State,
  {
    items: any[]
    loading: Loading
    page: number
    limit: number
    total: number
  }
>({
  items: getTransactionRequestsList,
  loading: getWithdrawalListLoading,
  page: getTransactionHistoryPage,
  limit: getTransactionHistoryLimit,
  total: getTransactionHistoryTotal,
})
