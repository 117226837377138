import { createAsyncAction, createAction } from 'typesafe-actions'
import * as actionType from './const'
import { MedicalReport } from './model'

export const callActions = {
	diconnectFromCall: createAsyncAction(
		actionType.DICONNECT_FROM_CALL_REQUEST,
		actionType.DICONNECT_FROM_CALL_SUCCESS,
		actionType.DICONNECT_FROM_CALL_FAILURE
	)<any, any, { message?: string }>(),
	saveReport: createAsyncAction(
		actionType.SAVE_REPORT_REQUEST,
		actionType.SAVE_REPORT_SUCCESS,
		actionType.SAVE_REPORT_FAILURE
	)<{ medicalReport: MedicalReport }, { medicalReport: MedicalReport }, { message?: string }>(),
	leaveFromCall: createAsyncAction(
		actionType.LEAVE_FROM_CALL_REQUEST,
		actionType.LEAVE_FROM_CALL_SUCCESS,
		actionType.LEAVE_FROM_CALL_FAILURE
	)<any, any, { message?: string }>(),
	sendUserConnectedEvent: createAsyncAction(
		actionType.SEND_USER_CONNECTED_EVENT,
		actionType.SEND_USER_CONNECTED_EVENT_SUCCESS,
		actionType.SEND_USER_CONNECTED_EVENT_FAILURE
	)<any, { callDuration: string }, { message?: string }>(),
	sendUserDisconnectedEvent: createAsyncAction(
		actionType.SEND_USER_DISCONNECTED_EVENT,
		actionType.SEND_USER_DISCONNECTED_EVENT_SUCCESS,
		actionType.SEND_USER_DISCONNECTED_EVENT_FAILURE
	)<any, any, { message?: string }>(),
	canLeaveCall: createAction(actionType.CAN_LEAVE_CALL)<any>(),
	toggleSelfieCamera: createAction(actionType.TOGGLE_SELFIE_CAMERA)(),
	toggleRemoteParticipantConnected: createAction(actionType.TOGGLE_REMOTE_PARTICIPANT_CONNECTED)<
		any
	>(),
}
