import React, { useCallback, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import Button from '@material-ui/core/Button'
import VideoOffIcon from '../../../icons/VideoOffIcon'
import VideoOnIcon from '../../../icons/VideoOnIcon'

import { useHasVideoInputDevices } from '../../../hooks/deviceHooks/deviceHooks'
import useLocalVideoToggle from '../../../hooks/useLocalVideoToggle/useLocalVideoToggle'

export default function ToggleVideoButton(props: { disabled?: boolean; className?: string }) {
	const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle()
	const { t } = useTranslation()
	const lastClickTimeRef = useRef(0)
	const hasVideoDevices = useHasVideoInputDevices()

	const toggleVideo = useCallback(() => {
		if (Date.now() - lastClickTimeRef.current > 500) {
			lastClickTimeRef.current = Date.now()
			toggleVideoEnabled()
		}
	}, [toggleVideoEnabled])

	return (
		<Button
			className={props.className}
			onClick={toggleVideo}
			disabled={!hasVideoDevices || props.disabled}
			startIcon={isVideoEnabled ? <VideoOnIcon /> : <VideoOffIcon />}
		>
			{!hasVideoDevices
				? t('LEOMED_ADMIN_CALL_NO_VIDEO')
				: isVideoEnabled
				? t('LEOMED_ADMIN_CALL_STOP_VIDEO')
				: t('LEOMED_ADMIN_CALL_START_VIDEO')}
		</Button>
	)
}
