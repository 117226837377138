import { ConsultationRequest } from 'pages/ConsultationRequests/redux/dto/ConsultationRequest'
import { createAction } from 'typesafe-actions'
import * as actionType from './const'
import { SocketNotificationTypes } from './dtos'

export type AlertType = {
  message: string
  severity: "error" | "info" | "success" | "warning" | "pending"
  color?: string
}

export type NotificationType = {
  type: SocketNotificationTypes
  data: ConsultationRequest
}

export const alertsActions = {
  popupAlert: createAction(actionType.POPUP_ALERT)<AlertType>(),
  popupSocketNotification: createAction(actionType.POPUP_SOCKET_NOTIFICATION)<NotificationType>(),
  removeSocketNotification: createAction(actionType.REMOVE_SOCKET_NOTIFICATION)<{ id: string }>(),
  removeAlertPopup: createAction(actionType.REMOVE_ALERT_POPUP)(),
  clearAllSocketNotifications: createAction(actionType.CLEAR_ALL_SOCKET_NOTIFICATIONS)(),
}
