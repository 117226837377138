import React, { FC, useMemo } from 'react'
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'

import { SelectField } from 'components/common/inputs/SelectField'
import { useLocalization } from 'pages/Profile/hooks/useLocalization'

const useStyles = makeStyles(theme => ({
	root: {
		marginLeft: theme.spacing(4),
		[theme.breakpoints.down('xs')]: {
			paddingLeft: theme.spacing(6),
		},
	},
	select: {
		width: '70px',
		color: theme.palette.primary.light,
	},
	contentSelect: {
		color: theme.palette.primary.light,
	},
	selectRoot: {
		color: theme.palette.background.paper,
		width: '100px',
		'&:focus': {
			backgroundColor: 'transparent',
		},
	},
}))

type LocalizationProps = {
	isHeader?: boolean
}

export const Localization: FC<LocalizationProps> = ({ isHeader }) => {
	const classes = useStyles()
	const { changeLngHandler } = useLocalization()

	const lngOptions = useMemo(
		() => [
			{ label: 'English', value: 'en' },
			{ label: 'Русский', value: 'ru' },
			{ label: 'Українська', value: 'uk' },
		],
		[]
	)

	const logoContainerStyles = clsx(classes.select, {
		[classes.contentSelect]: isHeader,
	})

	return (
		<div className={classes.root}>
			<SelectField
				options={lngOptions}
				variant="standard"
				className={logoContainerStyles}
				onChangeHandler={changeLngHandler}
				defaultValue={localStorage.i18nextLng}
				isHeader={isHeader}
				classes={{ root: classes.selectRoot }}
			/>
		</div>
	)
}
