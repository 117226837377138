import noop from 'lodash/fp/noop'
import { createContext } from 'react'
import { ProfileStep } from './redux/model'

type ProfileContext = {
	step: ProfileStep
	setStep(step: ProfileStep): void
	previousStep(): void
	nextStep(): void
}

export const ProfileContext = createContext<ProfileContext>({
	step: ProfileStep.personal,
	setStep: noop,
	previousStep: noop,
	nextStep: noop,
})
