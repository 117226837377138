import React, { FC, useCallback, useEffect } from 'react'
import { Typography, makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import queryString from 'query-string'
import { NumberParam, useQueryParams, withDefault } from 'use-query-params'
import { useLocation } from 'react-router'
import { MyScheduleContainer } from './containers/MySchedule/MyScheduleContainer'
import { TabsNavigation } from 'components/common/layout/TabsNavigation'
import { ConsultationHistoryContainer } from './containers/History/ConsultationHistoryContainer'
import { consultationRequestsActions } from 'pages/ConsultationRequests/redux/actions'
import { useDispatch } from 'react-redux'

const useStyles = makeStyles(theme => ({
  root: {
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
		marginTop: theme.spacing(14),
	},
  title: {
		fontSize: '24px',
		fontWeight: 700,
		marginBottom: theme.spacing(2),
	},
  tabsWrapper: {
		marginTop: theme.spacing(4),
	},
}))

export const MyCalendarPage: FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const location = useLocation()
  const dispatch = useDispatch()

  const search = queryString.parse(location.search)
  const page = search?.page || 1

  const [query, setQuery] = useQueryParams({
    page: withDefault(NumberParam, +page),
  })

  useEffect(() => {
    dispatch(consultationRequestsActions.getConsultationRequests.request({ page: 1, limit: 10, subStatus: 'CLIENT_CREATED' }))
  }, [dispatch])

  const handlePageChange = useCallback(
    (value: number) => {
      setQuery({ page: value })
    },
    [setQuery]
  )

  const resetPage = useCallback(() => {
    setQuery({ page: 1 })
  }, [setQuery])

  return (
    <div className={classes.root}>
      <Typography className={classes.title}>{t('LEOMED_ADMIN_CALENDAR_PAGE_TITLE')}</Typography>
      <div className={classes.tabsWrapper}>
        <TabsNavigation tabs={[
          {
            label: t('LEOMED_ADMIN_OFFICE_MY_SCHEDULE_TITLE'),
            onClick: resetPage,
            content: (
              <MyScheduleContainer
                handlePageChange={handlePageChange}
                page={+query.page}
              />
            )
          },
          {
            label: t('LEOMED_ADMIN_OFFICE_CONSULTATION_HISTORY_TITLE'),
            onClick: resetPage,
            content: (
              <ConsultationHistoryContainer
                handlePageChange={handlePageChange}
                page={+query.page}
              />
            )
          },
        ]} />
      </div>
    </div>
  )
}