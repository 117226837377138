export const AUTH_AUTHORIZE_REQUEST = 'auth/AUTHORIZE_REQUEST'
export const AUTH_AUTHORIZE_SUCCESS = 'auth/AUTHORIZE_SUCCESS'
export const AUTH_AUTHORIZE_FAILURE = 'auth/AUTHORIZE_FAILURE'

export const AUTH_GET_SESSION_REQUEST = 'auth/AUTH_GET_SESSION_REQUEST'
export const AUTH_GET_SESSION_SUCCESS = 'auth/AUTH_GET_SESSION_SUCCESS'
export const AUTH_GET_SESSION_FAILURE = 'auth/AUTH_GET_SESSION_FAILURE'

export const AUTH_UPDATE_SESSION_REQUEST = 'auth/AUTH_UPDATE_SESSION_REQUEST'
export const AUTH_UPDATE_SESSION_SUCCESS = 'auth/AUTH_UPDATE_SESSION_SUCCESS'
export const AUTH_UPDATE_SESSION_FAILURE = 'auth/AUTH_UPDATE_SESSION_FAILURE'

export const AUTH_LOGOUT_REQUEST = 'auth/AUTH_LOGOUT_REQUEST'
export const AUTH_LOGOUT_SUCCESS = 'auth/AUTH_LOGOUT_SUCCESS'
export const AUTH_LOGOUT_FAILURE = 'auth/AUTH_LOGOUT_FAILURE'

export const AUTH_STORE_TOKEN = 'auth/STORE_TOKEN'
