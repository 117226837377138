import React, { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { InfoBanner } from 'layouts/parts/InfoBanner'
import { RoutePath } from 'router/routes'

export const NoProfileBanner: FC = () => {
	const { t } = useTranslation()
	const history = useHistory()

	const clickHandler = useCallback(() => {
		history.push(RoutePath.profile)
	}, [history])

	return (
		<InfoBanner
			title={t('LEOMED_ADMIN_WELCOME_TITLE')}
			description={t('LEOMED_ADMIN_OFFICE_PAGE_NO_PROFILE_TEXT')}
			clickHandler={clickHandler}
			btnText={t('LEOMED_ADMIN_OFFICE_PAGE_NO_PROFILE_BUTTON')}
		/>
	)
}
