import { format } from 'date-fns'
import { PatientStatuses } from 'layouts/OfficeLayout'
import { MedicalReport } from 'pages/Call/redux/model'

export type FormatPatient = {
	attachments: string[]
	ageCategory: string
	comments: string
	fever: string
	medications: string
	allergies: string
	diseases: string
	name: string
	symptoms: string[]
	processed: boolean
	medicalReport: MedicalReport | null
	createdAt: string
	height: string
	weight: string
	drinking: string
	avatar: string
	id: string
	status: string
	acceptedPatientAt: string
}

export const formatPatient = (data: any): FormatPatient | null => {
	if (!data?.session) return null

	return {
		attachments:
			data.session?.data?.liveConsultation?.attachments?.map((item: any) => ({ 
				url: item.url,
				isFile: (!item.mimetype.includes('/png') && !item.mimetype.includes('/jpeg')),
				name: item.name,
			})) ?? [],
		ageCategory: data.session?.data?.liveConsultation?.category?.ageCategory || '',
		comments: data.session?.data?.liveConsultation?.category?.comments || '',
		medications: data.session?.data?.liveConsultation?.category?.medications || [],
		allergies: data.session?.data?.liveConsultation?.category?.allergies || [],
		diseases: data.session?.data?.liveConsultation?.category?.diseases || [],
		fever: data.session?.data?.liveConsultation?.category?.fever?.value || '',
		symptoms: data.session?.data?.liveConsultation?.category?.symptoms || [],
		name: data.session?.data?.liveConsultation?.patient?.name || '',
		height: data.session?.data?.liveConsultation?.patient?.height || '',
		weight: data.session?.data?.liveConsultation?.patient?.weight || '',
		drinking: data.session?.data?.liveConsultation?.patient?.drinking || '',
		avatar: data.session?.data?.liveConsultation?.patient?.avatar?.url ?? '',
		processed: data.session?.data?.processed || false,
		medicalReport: data.session?.data?.medicalReport || null,
		createdAt: data.session?.created ? format(new Date(data.session.created), 'MM/dd/yyyy') : '',
		id: data.session?._id,
		status: data?.status?.status,
		acceptedPatientAt:
			data?.status?.status === PatientStatuses.accepted ? data?.status?.updatedAt : '',
	}
}
