import React, { FC } from 'react'
import { Colors } from 'utils/theme'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import debounce from 'lodash/debounce'
import noop from 'lodash/noop'
import { useTranslation } from 'react-i18next'
import { CircularProgress, makeStyles } from '@material-ui/core'

import { TextareaField } from 'components/common/inputs/TextareadField'
import { OverlayButton } from 'components/common/OverlayButton'
import { getPatientInfo } from 'pages/Office/redux/selectors'

const useStyles = makeStyles(theme => ({
	root: {
		width: '100%',
		height: '100%',
		backgroundColor: theme.palette.background.paper,
		overflowY: 'auto',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
	},
	title: {
		fontWeight: 700,
		fontSize: '18px',
		marginBottom: theme.spacing(2),
	},
	subTitle: {
		fontSize: '12px',
		color: Colors.grey[500],
		marginBottom: theme.spacing(1),
	},
	symptoms: {
		display: 'flex',
		width: 'fit-content',
		backgroundColor: Colors.red[200],
		padding: theme.spacing(1, 2),
		borderRadius: '25px',
		marginBottom: theme.spacing(1),
	},
	temperature: {
		display: 'flex',
		alignItems: 'center',
		backgroundColor: theme.palette.warning.main,
		width: 'fit-content',
		borderRadius: '25px',
		color: theme.palette.background.paper,
		padding: theme.spacing(0.5, 1),
		marginBottom: theme.spacing(2),
	},
	icon: {
		width: 7,
		height: 14,
		backgroundImage: `url(${process.env.PUBLIC_URL}/temperature.svg)`,
		backgroundSize: 'cover',
		marginRight: theme.spacing(1),
	},
	field: {
		marginBottom: theme.spacing(2),
	},
	submit: {
		display: 'flex',
		width: '45%',
		justifyContent: 'center',
		alignItems: 'center',
		marginTop: theme.spacing(4),
	},
	buttonClassName: {
		backgroundColor: theme.palette.primary.dark,
		color: theme.palette.primary.light,
		textTransform: 'capitalize',
		width: '100%',
		fontSize: 14,
		padding: theme.spacing(2, 0),
		lineHeight: 1.2,
		borderRadius: 10,
	},
	disconnectSubmit: {
		width: '100%',
	},
	disconnectBtn: {
		backgroundColor: theme.palette.warning.main,
		'&:hover': {
			backgroundColor: theme.palette.warning.main,
		},
	},
}))

type ReportContainerProps = {
	onSubmit: (values: any) => void
	submitting: boolean
	btnText?: string
}

export const ReportContainer: FC<ReportContainerProps> = ({ onSubmit, submitting, btnText }) => {
	const classes = useStyles()
	const { t } = useTranslation()

	const { medicalReport } = useSelector(getPatientInfo)

	const { register, handleSubmit, errors, getValues } = useForm({
		defaultValues: {
			description: medicalReport?.description,
			appointment: medicalReport?.appointment,
		},
	})

	const handleChange = debounce(() => onSubmit(getValues()), 500)

	const onChange = btnText ? noop : handleChange

	return (
		<div className={classes.root}>
			<div>
				<div className={classes.title}>{`${t('LEOMED_ADMIN_DIALOG_REPORT_TEXT')}`}</div>
				<div className={classes.field}>
					<div className={classes.subTitle}>{t('LEOMED_ADMIN_DIALOG_DIAGNOSIS_TEXT')}</div>
					<TextareaField
						name="description"
						multiline
						inputRef={register({ required: btnText ? 'LEOMED_ADMIN_REQUIRED_FIELD_ERROR' : false })}
						error={!!errors.description}
						onChange={onChange}
						helperText={t(errors.description?.message as string)}
					/>
				</div>
				<div className={classes.field}>
					<div className={classes.subTitle}>{t('LEOMED_ADMIN_DIALOG_APPOINTMENT_TEXT')}</div>
					<TextareaField
						name="appointment"
						multiline
						inputRef={register({ required: btnText ? 'LEOMED_ADMIN_REQUIRED_FIELD_ERROR' : false })}
						error={!!errors.appointment}
						onChange={onChange}
						helperText={t(errors.appointment?.message as string)}
					/>
				</div>
				{btnText && (
					<OverlayButton
						className={classes.submit}
						buttonClassName={classes.buttonClassName}
						type="submit"
						variant="contained"
						color="primary"
						onClick={handleSubmit(onSubmit)}
						overlay={submitting && <CircularProgress size={24} />}
					>
						{btnText}
					</OverlayButton>
				)}
			</div>
		</div>
	)
}
