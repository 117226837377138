import { alertsActions } from "store/alerts/actions"
import { ActionType, createReducer } from "typesafe-actions"
import { oneInsert } from "utils/mutators/relations"
import { OneToOne } from "utils/types/store"
import { consultationRequestsActions } from "../actions"
import { ConsultationRequest } from "../dto/ConsultationRequest"

export type State = {
  idForChangeTimeDialog: string
	idForNewRequestDialog: string
	consultationRequestHasDateConflict: boolean
	consultationRequestsIds: ConsultationRequest['id'][]
	consultationRequestsById: OneToOne<ConsultationRequest['id'], ConsultationRequest>
  consultationRequestsPage: number,
  consultationRequestsTotal: number,
  consultationRequestsLimit: number,
}

export const initialState: State = {
  idForChangeTimeDialog: '',
	idForNewRequestDialog: '',
	consultationRequestsIds: [],
	consultationRequestsById: {},
  consultationRequestsPage: 1,
  consultationRequestsTotal: 0,
  consultationRequestsLimit: 10,
	consultationRequestHasDateConflict: false,
} 

export default createReducer<State, ActionType<typeof consultationRequestsActions | typeof alertsActions>>(
	initialState
)
  .handleAction(consultationRequestsActions.getConsultationRequests.success, (state, { payload: {
    data,
    page,
    total,
    limit
  } }) => {
    const consultationRequestsById = data.reduce(
      (accumulator, item) => oneInsert(item, item.id, accumulator),
      state.consultationRequestsById
    )

    return {
      ...state,
      consultationRequestsIds: data.map(item => item.id),
      consultationRequestsById,
      consultationRequestsPage: page,
      consultationRequestsLimit: limit,
      consultationRequestsTotal: total,
    }
  })
  .handleAction([
    consultationRequestsActions.approveConsultationRequest.success,
    consultationRequestsActions.rejectConsultationRequest.success,
  ],
  (state, { payload: { id, data } }) => ({
    ...state,
    consultationRequestsIds: state.consultationRequestsIds.filter(item => item !== id),
    consultationRequestsById: {
      ...state.consultationRequestsById,
      [id]: data
    },
  }))
  .handleAction(consultationRequestsActions.updateConsultationRequest.success, (state, { payload: { id, data } }) => ({
    ...state,
    consultationRequestsById: {
      ...state.consultationRequestsById,
      [id]: data
    },
    pickedRequestId: '',
  }))
  .handleAction(consultationRequestsActions.pickIdForChangeTimeDialog, (state, { payload: { id } }) => ({
    ...state,
    idForChangeTimeDialog: id,
  }))
  .handleAction(consultationRequestsActions.pickIdForNewRequestDialog, (state, { payload: { id } }) => ({
    ...state,
    idForNewRequestDialog: id,
  }))

  .handleAction(consultationRequestsActions.consultationRequestHasDateConflict, (state) => ({
    ...state,
    consultationRequestHasDateConflict: true,
  }))

  .handleAction(alertsActions.popupSocketNotification, (state, { payload: { data } }) => ({
    ...state,
    consultationRequestsById: {
      ...state.consultationRequestsById,
      [data.id]: data
    },
  }))