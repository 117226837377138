import { createSelector, createStructuredSelector } from 'reselect'
import { State } from 'store/reducers'
import { Loading } from 'utils/types/store'

export const getScheduledRequestsEntities = (state: State): State['scheduledRequests']['entities'] =>
	state.scheduledRequests.entities
export const getScheduledRequestsUI = (state: State): State['scheduledRequests']['ui'] => state.scheduledRequests.ui

// Scheduled Requests
export const getScheduledRequestsIds = createSelector(getScheduledRequestsEntities, (entity) => entity.scheduledRequestsIds)
export const getScheduledRequestsList = createSelector(
	getScheduledRequestsIds,
	getScheduledRequestsEntities,
	(ids, entity) => ids.map(id => entity.scheduledRequestsById[id])
)

export const getScheduledRequestsLoading = createSelector(
	getScheduledRequestsUI,
	entities => entities.getScheduledRequestsLoading
)

export const getScheduledRequestsLimit = createSelector(getScheduledRequestsEntities, (entity) => entity.scheduledRequestsLimit)
export const getScheduledRequestsTotal = createSelector(getScheduledRequestsEntities, (entity) => entity.scheduledRequestsTotal)
export const getScheduledRequestsPage = createSelector(getScheduledRequestsEntities, (entity) => entity.scheduledRequestsPage)

export const getScheduledRequestsInfo = createStructuredSelector<
  State,
  {
    items: any[]
    loading: Loading
    page: number
    limit: number
    total: number
  }
>({
  items: getScheduledRequestsList,
  loading: getScheduledRequestsLoading,
  page: getScheduledRequestsPage,
  limit: getScheduledRequestsLimit,
  total: getScheduledRequestsTotal,
})

// Consultation History

export const getConsultationHistoryIds = createSelector(getScheduledRequestsEntities, (entity) => entity.consultationHistoryIds)
export const getConsultationHistoryList = createSelector(
	getConsultationHistoryIds,
	getScheduledRequestsEntities,
	(ids, entity) => ids.map((id: string) => entity.consultationHistoryById[id])
)

export const getConsultationHistoryLoading = createSelector(
	getScheduledRequestsUI,
	entities => entities.getConsultationHistoryLoading
)

export const getConsultationDataLoading = createSelector(
	getScheduledRequestsUI,
	entities => entities.getConsultationDataLoading
)

export const getConsultationHistoryLimit = createSelector(getScheduledRequestsEntities, (entity) => entity.consultationHistoryLimit)
export const getConsultationHistoryTotal = createSelector(getScheduledRequestsEntities, (entity) => entity.consultationHistoryTotal)
export const getConsultationHistoryPage = createSelector(getScheduledRequestsEntities, (entity) => entity.consultationHistoryPage)

export const getConsultationHistoryInfo = createStructuredSelector<
  State,
  {
    items: any[]
    loading: Loading
    page: number
    limit: number
    total: number
  }
>({
  items: getConsultationHistoryList,
  loading: getConsultationHistoryLoading,
  page: getConsultationHistoryPage,
  limit: getConsultationHistoryLimit,
  total: getConsultationHistoryTotal,
})

export const getConsultationHistoryId = createSelector(getScheduledRequestsEntities, (entity) => entity.consultationHistoryById[entity.consultationHistoryId])