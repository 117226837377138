import React, { FC, useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { ProfileHeader } from '../components/ProfileHeader'
import { profileActions } from '../redux/actions'
import { personalEditId, ProfileAttachments, ProfileStep } from '../redux/model'
import { makeStyles } from '@material-ui/core'
import {
	getEducationItems,
	getExperienceItems,
	getPersonalInfo,
	getSpecializationItems,
} from '../redux/selectors'
import { EducationBanner } from './parts/EducationBanner'
import { SpecializationBanner } from './parts/SpecializationBanner'
import { ExperienceBanner } from './parts/ExperienceBanner'
import { PersonalInfoBanner } from './parts/PersonalInfoBanner'
import { ConfirmDialog } from 'components/common/layout/ConfirmDialog'

const useStyles = makeStyles(theme => ({
	subtitle: {
		fontWeight: 600,
		fontSize: '18px',
		paddingLeft: theme.spacing(1),
		margin: theme.spacing(5, 0, 2, 0),
	},
	wrapper: {
		padding: theme.spacing(0, 2),
	},
}))

export const AlmostDoneContainer: FC = () => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const [isConfirmOpen, setIsConfirmOpen] = useState(false)
	const [deleteId, setDeleteId] = useState('')
	const [name, setName] = useState('')
	const personalInfo = useSelector(getPersonalInfo)
	const educationItems = useSelector(getEducationItems)
	const specializationItems = useSelector(getSpecializationItems)
	const experienceItems = useSelector(getExperienceItems)

	const onSubmit = useCallback(() => {
		dispatch(
			profileActions.updateProfile.request({
				step: ProfileStep.firstStart,
			})
		)
	}, [dispatch])

	const editPersonalInfo = useCallback(() => {
		dispatch(profileActions.editItemById({ itemId: personalEditId }))
		dispatch(profileActions.setProfileStep({ step: ProfileStep.personal }))
	}, [dispatch])

	const editEducation = useCallback(
		(id: string) => {
			dispatch(profileActions.editItemById({ itemId: id }))
			dispatch(profileActions.setProfileStep({ step: ProfileStep.education }))
		},
		[dispatch]
	)

	const editSpecialization = useCallback(
		(id: string) => {
			dispatch(profileActions.editItemById({ itemId: id }))
			dispatch(profileActions.setProfileStep({ step: ProfileStep.specialization }))
		},
		[dispatch]
	)

	const editExperience = useCallback(
		(id: string) => {
			dispatch(profileActions.editItemById({ itemId: id }))
			dispatch(profileActions.setProfileStep({ step: ProfileStep.experience }))
		},
		[dispatch]
	)

	const deleteBanner = useCallback(
		(name: string, itemId: string) => {
			setName(name)
			setDeleteId(itemId)
			setIsConfirmOpen(!isConfirmOpen)
		},
		[isConfirmOpen]
	)

	const educationBanners = useMemo(
		() => (
			<>
				{educationItems.map(item => (
					<EducationBanner
						item={item}
						editItem={editEducation}
						deleteBanner={
							educationItems.length > 1
								? () => deleteBanner(ProfileAttachments.education, item.id)
								: undefined
						}
					/>
				))}
			</>
		),
		[deleteBanner, editEducation, educationItems]
	)

	const specializationBanners = useMemo(
		() => (
			<>
				{specializationItems.map(item => (
					<SpecializationBanner
						item={item}
						editItem={editSpecialization}
						deleteBanner={
							specializationItems.length > 1
								? () => deleteBanner(ProfileAttachments.speciality, item.id)
								: undefined
						}
					/>
				))}
			</>
		),
		[deleteBanner, editSpecialization, specializationItems]
	)

	const experienceBanners = useMemo(
		() => (
			<>
				{experienceItems.map(item => (
					<ExperienceBanner
						key={item.id}
						item={item}
						editItem={editExperience}
						deleteBanner={
							experienceItems.length > 1
								? () => deleteBanner(ProfileAttachments.employmentHistory, item.id)
								: undefined
						}
					/>
				))}
			</>
		),
		[deleteBanner, editExperience, experienceItems]
	)

	const toggleConfirm = useCallback(() => {
		setIsConfirmOpen(!isConfirmOpen)
	}, [isConfirmOpen])

	const handleConfirm = useCallback(() => {
		dispatch(
			profileActions.deleteBanner.request({
				categoryId: deleteId,
				name,
			})
		)
		setIsConfirmOpen(false)
	}, [deleteId, dispatch, name])

	return (
		<div>
			<ProfileHeader
				title={t('LEOMED_ADMIN_PROFILE_PAGE_ALMOST_DONE_TITLE')}
				buttonText={t('LEOMED_ADMIN_FINISH_BUTTON_TEXT')}
				onSubmit={onSubmit}
			/>
			<div className={classes.wrapper}>
				<div className={classes.subtitle}>{t('LEOMED_ADMIN_PROFILE_PAGE_PERSONAL_DATA')}</div>
				<PersonalInfoBanner item={personalInfo} editItem={editPersonalInfo} />
				<div className={classes.subtitle}>{t('LEOMED_ADMIN_PROFILE_PAGE_EDUCATION')}</div>
				{educationBanners}
				<div className={classes.subtitle}>{t('LEOMED_ADMIN_PROFILE_PAGE_SPECIALIZATION')}</div>
				{specializationBanners}
				<div className={classes.subtitle}>{t('LEOMED_ADMIN_PROFILE_PAGE_WORK_EXPERIENCE')}</div>
				{experienceBanners}
			</div>
			<ConfirmDialog
				open={isConfirmOpen}
				handleClose={toggleConfirm}
				handleConfirm={handleConfirm}
				title={t('LEOMED_ADMIN_DELETE_BANNER_CONFIRM_TITLE')}
				cancelText={t('LEOMED_ADMIN_NO_TEXT')}
				acceptText={t('LEOMED_ADMIN_YES_TEXT')}
			/>
		</div>
	)
}
