import { SplashScreen } from 'components/common/layout/SplashScreen'
import { getSplashVisibleLoading } from 'pages/Office/redux/selectors'
import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router'
import { useAuthorization } from '../../pages/Auth/hooks/useAuthorization'
import { defaultGuestUrl } from '../../router/ClientRouter'

import RouterWrapper, { RouterWrapperProps } from './RouterWrapper'

type ProtectedRouteProps<LP extends {}> = RouterWrapperProps<LP>

const ProtectedRoute = <LP extends {}>({
	...props
}: ProtectedRouteProps<LP>): React.ReactElement => {
	const { isAuthenticated } = useAuthorization()
	const splashVisible = useSelector(getSplashVisibleLoading)

	if (splashVisible) {
		return <SplashScreen open />
	}

	if (!isAuthenticated) {
		return <Redirect to={defaultGuestUrl} />
	}

	return (
		<>
			<RouterWrapper {...props} />
		</>
	)
}

export default ProtectedRoute
