import React, { FC } from 'react'
import { Typography, makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { Features } from 'components/Home/Features'
import { PickDoctorBtn } from 'components/common/layout/PickDoctorBtn'
import { Colors } from 'utils/theme'

const useStyles = makeStyles(theme => ({
	root: {
		padding: theme.spacing('10%', 0, 0),
	},
	title: {
		color: theme.palette.primary.light,
		fontSize: '48px',
		width: '100%',
		lineHeight: 1.2,
	},
	description: {
		width: '100%',
		color: theme.palette.background.paper,
		marginTop: theme.spacing(4),
	},
	primaryBtn: {
		backgroundColor: Colors.green[400],
		color: theme.palette.primary.light,
		margin: theme.spacing(8, 0, '10%'),
		'&:hover': {
			backgroundColor: Colors.green[400],
		},
	},
}))

export const HomePage: FC = () => {
	const classes = useStyles()
	const { t } = useTranslation()

	return (
		<div className={classes.root}>
			<Typography className={classes.title}>
				{t('LEOMED_ADMIN_DOCTOR_REGISTRATION_TEXT')}
			</Typography>
			<Typography className={classes.description}>
				{t('LEOMED_ADMIN_HOME_PAGE_DESCRIPTION')}
			</Typography>
			<PickDoctorBtn className={classes.primaryBtn} text={t('LEOMED_ADMIN_GET_ACCESS')} />
			<Features />
		</div>
	)
}
