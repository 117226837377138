import React, { FC, useCallback, useMemo } from 'react'
import { Avatar, Button, Dialog, IconButton, makeStyles } from "@material-ui/core"
import CloseIcon from '@material-ui/icons/Close'
import { useTranslation } from 'react-i18next'
import queryString from 'query-string'
import clx from 'clsx'
import { Colors } from 'utils/theme'
import { useCancelRequestDialog } from 'hooks/scheduledReuqests/useCancelRequestDialog'
import { useApproveRequestDialog } from 'hooks/scheduledReuqests/useApproveRequestDialog'
import { useDispatch, useSelector } from 'react-redux'
import { ConfirmDialog } from 'components/common/layout/ConfirmDialog'
import { ConsultationDateConflictBanner } from './ConsultationDateConflictBanner'
import { getConsultationRequestHasDateConflict } from 'pages/ConsultationRequests/redux/selectors'
import { consultationRequestsActions } from 'pages/ConsultationRequests/redux/actions'
import { useLocation } from 'react-router'
import { NumberParam, useQueryParams, withDefault } from 'use-query-params'

const useStyles = makeStyles(theme => ({
  root: {
		padding: theme.spacing(4),
    position: 'relative'
	},
  title: {
    fontSize: '24px',
    padding: theme.spacing(1, 1, 0, 0),
    fontWeight: 700,
  },
  subtitle: {
    fontWeight: 700,
    fontSize: 14,
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.background.paper,
    fontSize: 14,
    marginRight: theme.spacing(2),
  },
  closeBtn: {
    position: 'absolute',
    top: 5,
    right: 5,
    width: 30,
    height: 30,
  },
  infoWrapper: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#F3F7FC',
    borderRadius: '10px',
    border: '1px solid #DEEBF7',
    padding: theme.spacing(2),
    margin: theme.spacing(3, 0),
  },
  info: {
    display: 'flex',
    flexDirection: 'column'
  },
  dateText: {
    fontWeight: 700,
    fontSize: 14,
    display: 'inline-flex'
  },
  buttonsWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  button: {
    width: '100%',
    height: 62,
    fontSize: '22px',
    textTransform: 'none',
    borderRadius: '10px'
  },
  acceptBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.background.paper,
    marginBottom: theme.spacing(2),
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    }
  },
  cancelBtn: {
    borderColor: Colors.red[500],
    color: Colors.red[500],
    marginBottom: theme.spacing(2),
  },
  changeBtn: {
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: 'transparent',
    }
  },
}))

type NewRequestActionsDialogProps = {
  isOpen: boolean
  requestData: any
}

export const NewRequestActionsDialog: FC<NewRequestActionsDialogProps> = ({ isOpen, requestData }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const location = useLocation()
  const consultationRequestHasDateConflict = useSelector(getConsultationRequestHasDateConflict)
  const date = `${requestData.date} ${t('LEOMED_ADMIN_OFFICE_ON_TITLE')} ${requestData.time}`
  const nameInitials = useMemo(() => `${requestData.firstName?.[0] ?? ''}${requestData.lastName?.[0] ?? ''}`.toUpperCase(), [requestData.firstName, requestData.lastName])
  const search = queryString.parse(location.search)
  const page = search?.page || 1

  // eslint-disable-next-line
  const [query, setQuery] = useQueryParams({
    page: withDefault(NumberParam, +page),
  })

  const handlePageChange = useCallback(
    (value: number) => {
      setQuery({ page: value })
    },
    [setQuery]
  )

  const { isConfirmOpen, handleConfirm, toggleConfirm } = useCancelRequestDialog(requestData.id, handlePageChange)
  const { isApproveOpen, handleApprove, toggleApprove } = useApproveRequestDialog(requestData.id, handlePageChange)

  const onClose = useCallback(() => {
    dispatch(consultationRequestsActions.pickIdForNewRequestDialog({ id: '' }))
  }, [dispatch])

  const handlePickRequestId = useCallback((id: string) => {
    dispatch(consultationRequestsActions.pickIdForChangeTimeDialog({ id }))
  }, [dispatch])

  const toggleApproveHandler = useCallback(() => {
    toggleApprove()
  }, [toggleApprove])

  const approveHandler = useCallback(() => {
    onClose()
    handleApprove()
  }, [handleApprove, onClose])

  const toggleCancelHandler = useCallback(() => {
    toggleConfirm()
  }, [toggleConfirm])

  const cancelHandler = useCallback(() => {
    onClose()
    handleConfirm()
  }, [handleConfirm, onClose])

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <div className={classes.root}>
        <div className={classes.title}>{`${t('LEOMED_ADMIN_OFFICE_REQUEST_TITLE')} № ${requestData.request}`}</div>
        {consultationRequestHasDateConflict && <ConsultationDateConflictBanner />}
        <div className={classes.infoWrapper}>
          <Avatar src={requestData.avatar} className={classes.avatar}>
            {nameInitials}
          </Avatar>
          <div className={classes.info}>
            <div className={classes.subtitle}>
              {`${requestData.firstName} ${requestData.lastName}`}
            </div>
            <div>{t('LEOMED_ADMIN_OFFICE_FOR_TITLE')} <div className={classes.dateText}>{date}</div></div>
          </div>
        </div>
        <div className={classes.buttonsWrapper}>
          <Button
            className={clx(classes.button, classes.acceptBtn)}
            variant="contained"
            onClick={toggleApprove}
            disabled={consultationRequestHasDateConflict}
          >
            {t('LEOMED_ADMIN_NEW_CONSULTATION_DIALOG_OFFICE_ACCEPT_TITLE')}
          </Button>
          <Button
            className={clx(classes.button, classes.cancelBtn)}
            variant="outlined"
            onClick={toggleConfirm}
          >
            {t('LEOMED_ADMIN_NEW_CONSULTATION_DIALOG_CANCEL_PATIENT_TEXT')}
          </Button>
          <Button
            className={clx(classes.button, classes.changeBtn)}
            variant="outlined"
            onClick={() => handlePickRequestId(requestData.id)}
          >
            {t('LEOMED_ADMIN_NEW_CONSULTATION_DIALOG_OFFICE_CHANGE_TITLE')}
          </Button>
        </div>
        <IconButton onClick={onClose} className={classes.closeBtn}>
          <CloseIcon color="primary" />
        </IconButton>
      </div>
      <ConfirmDialog
				open={isConfirmOpen}
				handleClose={toggleCancelHandler}
				handleConfirm={cancelHandler}
				title={`${t('LEOMED_ADMIN_OFFICE_REQUEST_TITLE')} № ${requestData.request}`}
        description={t('LEOMED_ADMIN_OFFICE_CONSULTATION_CANCEL_CONFIRM_TEXT')}
				cancelText={t('LEOMED_ADMIN_NO_TEXT')}
				acceptText={t('LEOMED_ADMIN_OFFICE_CONSULTATION_CONFIRM_TEXT')}
			/>
      <ConfirmDialog
				open={isApproveOpen}
				handleClose={toggleApproveHandler}
				handleConfirm={approveHandler}
				title={`${t('LEOMED_ADMIN_OFFICE_REQUEST_TITLE')} № ${requestData.request}`}
        description={t('LEOMED_ADMIN_OFFICE_CONSULTATION_APPROVE_DESCRIPTION')}
				cancelText={t('LEOMED_ADMIN_NO_TEXT')}
				acceptText={t('LEOMED_ADMIN_OFFICE_CONSULTATION_APPROVE_TEXT')}
        isPrimary
			/>
    </Dialog>
  )
}
