import { createSelector, createStructuredSelector, Selector } from 'reselect'
import { State } from 'store/reducers'
import { Loading, LoadingDefault } from 'utils/types/store'

export const getConsultationRequestsEntities = (state: State): State['consultationRequests']['entities'] =>
	state.consultationRequests.entities
export const getConsultationRequestsUI = (state: State): State['consultationRequests']['ui'] => state.consultationRequests.ui

export const makegetApproveConsultationRequestLoadingById = (id: string): Selector<State, Loading> =>
  createSelector(getConsultationRequestsUI, (ui) => ui.approveConsultationRequestLoadingById[id] || LoadingDefault)
export const makegetRejectConsultationRequestLoadingById = (id: string): Selector<State, Loading> =>
  createSelector(getConsultationRequestsUI, (ui) => ui.rejectConsultationRequestLoadingById[id] || LoadingDefault)

export const getConsultationRequestsLoading = createSelector(
	getConsultationRequestsUI,
	entities => entities.getConsultationRequestsLoading
)
export const getUpdateConsultationRequestLoading = createSelector(
	getConsultationRequestsUI,
	entities => entities.updateConsultationRequestLoading
)

export const getConsultationRequestsIds = createSelector(getConsultationRequestsEntities, (entity) => entity.consultationRequestsIds)
export const getConsultationRequestsList = createSelector(
	getConsultationRequestsIds,
	getConsultationRequestsEntities,
	(ids, entity) => ids.map(id => entity.consultationRequestsById[id])
)

export const getChangeTimeDialogId = createSelector(getConsultationRequestsEntities, (entity) => entity.consultationRequestsById[entity.idForChangeTimeDialog])
export const getNewRequestDialogId = createSelector(getConsultationRequestsEntities, (entity) => entity.consultationRequestsById[entity.idForNewRequestDialog])
export const getConsultationRequestHasDateConflict = createSelector(getConsultationRequestsEntities, (entity) => entity.consultationRequestHasDateConflict)
export const getConsultationRequestsLimit = createSelector(getConsultationRequestsEntities, (entity) => entity.consultationRequestsLimit)
export const getConsultationRequestsTotal = createSelector(getConsultationRequestsEntities, (entity) => entity.consultationRequestsTotal)
export const getConsultationRequestsPage = createSelector(getConsultationRequestsEntities, (entity) => entity.consultationRequestsPage)

export const getConsultationRequestsInfo = createStructuredSelector<
  State,
  {
    items: any[]
    loading: Loading
    page: number
    limit: number
    total: number
  }
>({
  items: getConsultationRequestsList,
  loading: getConsultationRequestsLoading,
  page: getConsultationRequestsPage,
  limit: getConsultationRequestsLimit,
  total: getConsultationRequestsTotal,
})
