export const TOGGLE_DOCTOR_STATUS_REQUEST = 'TOGGLE_DOCTOR_STATUS_REQUEST'
export const TOGGLE_DOCTOR_STATUS_SUCCESS = 'TOGGLE_DOCTOR_STATUS_SUCCESS'
export const TOGGLE_DOCTOR_STATUS_FAILURE = 'TOGGLE_DOCTOR_STATUS_FAILURE'

export const GET_DOCTOR_STATUS_REQUEST = 'GET_DOCTOR_STATUS_REQUEST'
export const GET_DOCTOR_STATUS_SUCCESS = 'GET_DOCTOR_STATUS_SUCCESS'
export const GET_DOCTOR_STATUS_FAILURE = 'GET_DOCTOR_STATUS_FAILURE'

export const GET_PATIENT_INFO_REQUEST = 'GET_PATIENT_INFO_REQUEST'
export const GET_PATIENT_INFO_SUCCESS = 'GET_PATIENT_INFO_SUCCESS'
export const GET_PATIENT_INFO_FAILURE = 'GET_PATIENT_INFO_FAILURE'

export const ACCEPT_PATIENT_REQUEST = 'ACCEPT_PATIENT_REQUEST'
export const ACCEPT_PATIENT_SUCCESS = 'ACCEPT_PATIENT_SUCCESS'
export const ACCEPT_PATIENT_FAILURE = 'ACCEPT_PATIENT_FAILURE'

export const CREATE_ROOM_REQUEST = 'CREATE_ROOM_REQUEST'
export const CREATE_ROOM_SUCCESS = 'CREATE_ROOM_SUCCESS'
export const CREATE_ROOM_FAILURE = 'CREATE_ROOM_FAILURE'

export const DECLINE_PATIENT_REQUEST = 'DECLINE_PATIENT_REQUEST'
export const DECLINE_PATIENT_SUCCESS = 'DECLINE_PATIENT_SUCCESS'
export const DECLINE_PATIENT_FAILURE = 'DECLINE_PATIENT_FAILURE'

export const SEND_REPORT_REQUEST = 'SEND_REPORT_REQUEST'
export const SEND_REPORT_SUCCESS = 'SEND_REPORT_SUCCESS'
export const SEND_REPORT_FAILURE = 'SEND_REPORT_FAILURE'

export const GET_PAYMENT_METHODS_REQUEST = 'GET_PAYMENT_METHODS_REQUEST'
export const GET_PAYMENT_METHODS_SUCCESS = 'GET_PAYMENT_METHODS_SUCCESS'
export const GET_PAYMENT_METHODS_FAILURE = 'GET_PAYMENT_METHODS_FAILURE'

export const CREATE_PAYMENT_METHOD_REQUEST = 'CREATE_PAYMENT_METHOD_REQUEST'
export const CREATE_PAYMENT_METHOD_SUCCESS = 'CREATE_PAYMENT_METHOD_SUCCESS'
export const CREATE_PAYMENT_METHOD_FAILURE = 'CREATE_PAYMENT_METHOD_FAILURE'

export const DELETE_PAYMENT_METHOD_REQUEST = 'DELETE_PAYMENT_METHOD_REQUEST'
export const DELETE_PAYMENT_METHOD_SUCCESS = 'DELETE_PAYMENT_METHOD_SUCCESS'
export const DELETE_PAYMENT_METHOD_FAILURE = 'DELETE_PAYMENT_METHOD_FAILURE'

export const MAKE_DEFAULT_PAYMENT_METHOD_REQUEST = 'MAKE_DEFAULT_PAYMENT_METHOD_REQUEST'
export const MAKE_DEFAULT_PAYMENT_METHOD_SUCCESS = 'MAKE_DEFAULT_PAYMENT_METHOD_SUCCESS'
export const MAKE_DEFAULT_PAYMENT_METHOD_FAILURE = 'MAKE_DEFAULT_PAYMENT_METHOD_FAILURE'

export const GET_DOCTOR_CONSULTATIONS_REQUEST = 'GET_DOCTOR_CONSULTATIONS_REQUEST'
export const GET_DOCTOR_CONSULTATIONS_SUCCESS = 'GET_DOCTOR_CONSULTATIONS_SUCCESS'
export const GET_DOCTOR_CONSULTATIONS_FAILURE = 'GET_DOCTOR_CONSULTATIONS_FAILURE'
export const GET_DOCTOR_BALANCE_REQUEST = 'GET_DOCTOR_BALANCE_REQUEST'
export const GET_DOCTOR_BALANCE_SUCCESS = 'GET_DOCTOR_BALANCE_SUCCESS'
export const GET_DOCTOR_BALANCE_FAILURE = 'GET_DOCTOR_BALANCE_FAILURE'

export const WITHDRAW_MONEY_REQUEST = 'WITHDRAW_MONEY_REQUEST'
export const WITHDRAW_MONEY_SUCCESS = 'WITHDRAW_MONEY_SUCCESS'
export const WITHDRAW_MONEY_FAILURE = 'WITHDRAW_MONEY_FAILURE'

export const GET_WITHDRAWAL_LIST_REQUEST = 'GET_WITHDRAWAL_LIST_REQUEST'
export const GET_WITHDRAWAL_LIST_SUCCESS = 'GET_WITHDRAWAL_LIST_SUCCESS'
export const GET_WITHDRAWAL_LIST_FAILURE = 'GET_WITHDRAWAL_LIST_FAILURE'

export const GET_WITHDRAWAL_STATUS_REQUEST = 'GET_WITHDRAWAL_STATUS_REQUEST'
export const GET_WITHDRAWAL_STATUS_SUCCESS = 'GET_WITHDRAWAL_STATUS_SUCCESS'
export const GET_WITHDRAWAL_STATUS_FAILURE = 'GET_WITHDRAWAL_STATUS_FAILURE'

export const GET_NOTIFICATIONS_REQUEST = 'GET_NOTIFICATIONS_REQUEST'
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS'
export const GET_NOTIFICATIONS_FAILURE = 'GET_NOTIFICATIONS_FAILURE'

export const UPDATE_NOTIFICATION_ID_REQUEST = 'UPDATE_NOTIFICATION_ID_REQUEST'
export const UPDATE_NOTIFICATION_ID_SUCCESS = 'UPDATE_NOTIFICATION_ID_SUCCESS'
export const UPDATE_NOTIFICATION_ID_FAILURE = 'UPDATE_NOTIFICATION_ID_FAILURE'

export const POST_FEEDBACK_REQUEST = 'POST_FEEDBACK_REQUEST'
export const POST_FEEDBACK_SUCCESS = 'POST_FEEDBACK_SUCCESS'
export const POST_FEEDBACK_FAILURE = 'POST_FEEDBACK_FAILURE'

export const HIDE_SPLASH = 'HIDE_SPLASH'
export const INITIAL_LOAD = 'INITIAL_LOAD'
export const TOGGLE_CONTACT_US = 'TOGGLE_CONTACT_US'
