import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Colors } from 'utils/theme'
import { Box, Button, Divider, InputLabel, makeStyles } from '@material-ui/core'
import { Controller, useForm } from 'react-hook-form'
import uuidv4 from 'uuid/v4'
import clsx from 'clsx'

import { ProfileHeader } from '../components/ProfileHeader'
import { profileActions } from '../redux/actions'
import { ProfileAttachments, ProfileStep, SpecializationItem } from '../redux/model'
import ImagePicker from 'components/common/inputs/ImagePicker'
import useUploadFile from 'hooks/useUploadFile'
import { SelectField } from 'components/common/inputs/SelectField'
import {
	getEditId,
	getProfileStep,
	getSpecializationItemById,
	getSpecializationItems,
} from '../redux/selectors'
import { InputDatePicker } from 'components/common/inputs/InputDatePicker'
import { SpecializationBanner } from './parts/SpecializationBanner'
import { ConfirmDialog } from 'components/common/layout/ConfirmDialog'

const useStyles = makeStyles(theme => ({
	root: {
		padding: theme.spacing(3),
		borderBottom: `1px solid ${theme.palette.divider}`,
		display: 'flex',
		justifyContent: 'space-between',
		flexDirection: 'column',
	},
	wrapper: { width: '64%' },
	fieldsWrapper: {
		display: 'flex',
		justifyContent: 'space-between',
		marginBottom: theme.spacing(2),
	},
	field: {
		padding: theme.spacing(1),
		width: '48%',
	},
	tagField: {
		width: '100%',
	},
	rightField: {
		padding: theme.spacing(1),
		width: '32%',
	},
	imageWrapper: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	imageText: {
		width: '75%',
	},
	labelWrapper: {
		marginBottom: theme.spacing(2),
	},
	label: {
		color: theme.palette.text.primary,
	},
	terms: {
		padding: theme.spacing(3, 3, 0, 4),
	},
	termsLink: {
		color: theme.palette.primary.main,
		cursor: 'pointer',
		textDecoration: 'underline',
	},
	addBtnWrapper: {
		padding: theme.spacing(1),
	},
	addBtn: {
		backgroundColor: Colors.green[500],
		color: theme.palette.background.paper,
		'&:hover': {
			backgroundColor: Colors.green[500],
		},
	},
	saveBtn: {
		backgroundColor: theme.palette.primary.main,
		marginRight: theme.spacing(2),
		'&:hover': {
			backgroundColor: theme.palette.primary.main,
		},
	},
	divider: {
		marginBottom: theme.spacing(3),
	},
	contentWrapper: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	images: {
		display: 'flex',
		marginTop: theme.spacing(4),
		gap: '10px',
	},
}))

export const SpecializationContainer: FC = () => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const { t } = useTranslation(['translation', 'specialty', 'degree', 'category'])
	const [startDate, setStartDate] = useState<Date | null>(null)
	const [isConfirmOpen, setIsConfirmOpen] = useState(false)
	const [deleteId, setDeleteId] = useState('')
	const editItemId = useSelector(getEditId)
	const [editId, setEditId] = useState<string | undefined>(editItemId as string)
	const {
		onFileChange,
		selectedFiles,
		handleDelete,
		formData,
		setSelectedFiles,
		filesForFormData,
		setFilesForFormData,
	} = useUploadFile({
		name: 'speciality',
		editId,
	})
	const profileStep = useSelector(getProfileStep)
	const specializationItemId = useSelector(getSpecializationItemById(editId || ''))
	const isProfileDone = profileStep > ProfileStep.almost_done
	const { handleSubmit, errors, control, setValue, reset } = useForm<SpecializationItem>({
		mode: 'onChange',
		defaultValues: {
			start: new Date(),
		},
	})
	const specialtyOptions = t('specialty:SPECIALTY') as any[]
	const gradesOptions = t('degree:DEGREE') as any[]
	const categoryOptions = t('category:CATEGORY') as any[]

	const specializationItems = useSelector(getSpecializationItems)

	const editItem = useCallback(
		(itemId: string) => {
			setEditId(itemId)
			const item = specializationItems.find(({ id }) => id === itemId)
			reset(item)
			setStartDate(item?.start as Date)
		},
		[specializationItems, reset]
	)

	useEffect(() => {
		if (editItemId) {
			editItem(editItemId as string)
		}
	}, [editItem, editItemId])

	const toggleConfirm = useCallback(() => {
		setIsConfirmOpen(!isConfirmOpen)
	}, [isConfirmOpen])

	const handleConfirm = useCallback(() => {
		dispatch(
			profileActions.deleteBanner.request({
				categoryId: deleteId,
				name: ProfileAttachments.speciality,
			})
		)
		setIsConfirmOpen(false)
	}, [deleteId, dispatch])

	const deleteBanner = useCallback(
		(itemId: string) => {
			setDeleteId(itemId)
			setIsConfirmOpen(!isConfirmOpen)
		},
		[isConfirmOpen]
	)

	const onSubmit = useCallback(() => {
		dispatch(
			profileActions.updateProfile.request({
				step: ProfileStep.experience,
			})
		)
	}, [dispatch])

	const resetHandler = useCallback(() => {
		reset({
			category: '',
			gradeLevel: '',
			type: '',
			start: new Date(),
		})
		setStartDate(new Date())
		setEditId(undefined)
		setSelectedFiles([])
		setFilesForFormData([])
	}, [reset, setFilesForFormData, setSelectedFiles])

	const addHandler = useCallback(
		values => {

			filesForFormData.forEach(item => {
				formData?.append('speciality', item, item.name)
			})

			dispatch(
				profileActions.addSpecializationItem({
					specializationItem: {
						...values,
						documents: selectedFiles.map((item: any) => ({ url: item })),
						attachments: formData,
						id: editId || uuidv4(),
					},
				})
			)
			if (editItemId) {
				dispatch(profileActions.editItemById({ itemId: null }))
				dispatch(profileActions.setProfileStep({ step: ProfileStep.almost_done }))
			}
			resetHandler()
		},
		[
			dispatch,
			editId,
			editItemId,
			filesForFormData,
			formData,
			resetHandler,
			selectedFiles,
		]
	)

	const onItemDelete = useCallback(
		id => {
			handleDelete(id)

			if (
				editId &&
				specializationItemId?.documents.length > 0 &&
				specializationItemId?.documents[id - 1]
			) {
				const fileId = specializationItemId?.documents[id - 1].id
				const categoryId = editId
				dispatch(
					profileActions.deleteAttachment.request({
						categoryId,
						fileId,
						name: ProfileAttachments.speciality,
					})
				)
			}
		},
		[dispatch, editId, handleDelete, specializationItemId]
	)

	const selectDate = useCallback(
		date => {
			setValue('start', date)
			setStartDate(date)
		},
		[setValue]
	)

	const cancelHandler = useCallback(
		errors => {
			if (editItemId) {
				dispatch(profileActions.editItemById({ itemId: null }))
				dispatch(profileActions.setProfileStep({ step: ProfileStep.almost_done }))
			}
			resetHandler()
		},
		[dispatch, editItemId, resetHandler]
	)

	const specializationBanners = useMemo(
		() => (
			<>
				{specializationItems.map(item => (
					<SpecializationBanner
						key={item.id}
						item={item}
						editItem={editItem}
						deleteBanner={specializationItems.length > 1 ? deleteBanner : undefined}
					/>
				))}
				{specializationItems.length > 0 && <Divider className={classes.divider} />}
			</>
		),
		[classes.divider, deleteBanner, editItem, specializationItems]
	)

	const addBtnStyles = clsx(classes.addBtn, {
		[classes.saveBtn]: editId,
	})

	return (
		<div>
			<ProfileHeader
				title={t('LEOMED_ADMIN_PROFILE_PAGE_SPECIALIZATION_TITLE')}
				buttonText={isProfileDone ? '' : t('LEOMED_ADMIN_NEXT_BUTTON_TEXT')}
				onSubmit={onSubmit}
				disabled={specializationItems.length === 0 || !!editItemId}
				noBack={!!editItemId}
			/>
			<div className={classes.root}>
				{!editId && specializationBanners}
				<div className={classes.contentWrapper}>
					<div className={classes.wrapper}>
						<div className={classes.fieldsWrapper}>
							<div className={classes.field}>
								<Controller
									control={control}
									name="type"
									defaultValue=""
									rules={{ required: 'LEOMED_ADMIN_REQUIRED_FIELD_ERROR' }}
									as={
										<SelectField
											label={t('LEOMED_ADMIN_PROFILE_PAGE_SPECIALIZATION_LABEL')}
											options={specialtyOptions}
											error={!!errors.type}
											errorMessage={t(errors.type?.message as string)}
										/>
									}
								/>
							</div>
							<div className={classes.field}>
								<Controller
									control={control}
									name="gradeLevel"
									defaultValue=""
									as={
										<SelectField
											label={t('LEOMED_ADMIN_PROFILE_PAGE_PICK_RANK_LABEL')}
											options={gradesOptions}
										/>
									}
								/>
							</div>
						</div>
						<div className={classes.fieldsWrapper}>
							<div className={classes.field}>
								<Controller
									control={control}
									name="category"
									defaultValue=""
									as={
										<SelectField
											label={t('LEOMED_ADMIN_PROFILE_PAGE_PICK_CATEGORY_LABEL')}
											options={categoryOptions}
										/>
									}
								/>
							</div>
							<div className={classes.field}>
								<Controller
									control={control}
									name="start"
									defaultValue=""
									render={props => (
										<InputDatePicker
											selectDate={selectDate}
											maxDate={new Date('2021-12-31')}
											startDate={startDate}
											label={t('LEOMED_ADMIN_PROFILE_PAGE_START_PRACTICE_LABEL')}
											{...props}
										/>
									)}
								/>
							</div>
						</div>
						<div className={classes.addBtnWrapper}>
							<Button
								className={addBtnStyles}
								variant="contained"
								onClick={handleSubmit(addHandler /*, onError*/)}
							>
								{t(
									editId ? 'LEOMED_ADMIN_SAVE_BUTTON_TEXT' : 'LEOMED_ADMIN_PROFILE_PAGE_ADD_LABEL'
								)}
							</Button>
							{editId && (
								<Button variant="contained" onClick={cancelHandler}>
									{t('LEOMED_ADMIN_CANCEL_BUTTON_TEXT')}
								</Button>
							)}
						</div>
					</div>
					<div className={classes.rightField}>
						<Box className={classes.labelWrapper}>
							<InputLabel className={classes.label}>
								{t('LEOMED_ADMIN_PROFILE_PAGE_ATTACH_FILES_LABEL')}
							</InputLabel>
							{/* {errorMessage && <ErrorTooltip text={t(errorMessage)} />} */}
						</Box>
						<div className={classes.imageWrapper}>
							{selectedFiles.length < 3 && (
								<ImagePicker onChange={onFileChange} id={6} count={selectedFiles.length} multiple />
							)}
							<div className={classes.imageText}>{t('LEOMED_ADMIN_ADD_FILE_SPECIALITY_PAGE')}</div>
						</div>
						<div className={classes.images}>
							{selectedFiles.map((item, index) => (
								<ImagePicker
									key={index}
									img={item.url}
									mimetype={item.type}
									onChange={onFileChange}
									handleDelete={onItemDelete}
									id={index + 1}
								/>
							))}
						</div>
					</div>
				</div>
			</div>
			<div className={classes.terms}>
				{t('LEOMED_ADMIN_PROFILE_PAGE_AGREE_DESCRIPTION')}
				<span className={classes.termsLink}>
					{t('LEOMED_ADMIN_PROFILE_PAGE_AGREE_DESCRIPTION_TWO')}
				</span>
			</div>
			<ConfirmDialog
				open={isConfirmOpen}
				handleClose={toggleConfirm}
				handleConfirm={handleConfirm}
				title={t('LEOMED_ADMIN_DELETE_BANNER_CONFIRM_TITLE')}
				cancelText={t('LEOMED_ADMIN_NO_TEXT')}
				acceptText={t('LEOMED_ADMIN_YES_TEXT')}
			/>
		</div>
	)
}
