import { getNotifications } from './selectors';
import { formatConsultations } from './utils/formatConsultations';
import { SagaIterator } from 'redux-saga'
import { call, put, takeLatest, select, take, all, delay, takeEvery } from 'redux-saga/effects'
import { ActionType } from 'typesafe-actions'
import moment from 'moment'

import { officeActions } from './actions'
import { serverAxios } from 'utils/api/serverAxios'
import { getDoctorWorking } from 'pages/Office/redux/selectors'
import { formatPatient } from './utils/formatPatient'
import { ProfileStep } from 'pages/Profile/redux/model'
import { getProfileStep, getCreatedDate } from 'pages/Profile/redux/selectors'
import { profileActions } from 'pages/Profile/redux/actions'
import { formatProfile } from 'pages/Profile/redux/utils/formatProfile'
import { extractErrorMessage } from 'utils/errors'

function* toggleDoctorStatus(
	action: ActionType<typeof officeActions.toggleDoctorStatus.request>
): SagaIterator {
	try {
		const isDoctorWirking = yield select(getDoctorWorking)
		let newStatus
		const currentStep = yield select(getProfileStep)

		if (currentStep === ProfileStep.firstStart) {
			const result = yield call(serverAxios.put, `api/doctor`, {
				data: {
					state: {
						pageId: ProfileStep.done,
					},
				},
			})

			yield put(profileActions.updateProfile.success({ profile: formatProfile(result.data) }))
		}

		if (isDoctorWirking) {
			const response = yield call(serverAxios.delete, `/api/doctor/status`)
			newStatus = response.status
		} else {
			const response = yield call(serverAxios.post, `/api/doctor/status`)
			newStatus = !!response.created
		}

		yield put(officeActions.toggleDoctorStatus.success({ isDoctorWorking: newStatus }))
	} catch (error) {
		yield put(officeActions.toggleDoctorStatus.failure({ message: extractErrorMessage(error) }))
	}
}

function* getDoctorStatus(
	action: ActionType<typeof officeActions.getDoctorStatus.request>
): SagaIterator {
	try {
		const response = yield call(serverAxios.get, `/api/doctor/status`)
		const isDoctorWorking = !!response.created || false

		yield put(officeActions.getDoctorStatus.success({ isDoctorWorking }))
	} catch (error) {
		yield put(officeActions.getDoctorStatus.failure({ message: extractErrorMessage(error) }))
	}
}

function* getDoctorConsultations(
	action: ActionType<typeof officeActions.getDoctorConsultations.request>
): SagaIterator {
	try {
		const createdDate = yield select(getCreatedDate)
		const toDate = moment().toISOString()
		const response = yield call(serverAxios.get, `/api/doctor/stats/consultations?fromDate=${createdDate}&toDate=${toDate}`)

		yield put(officeActions.getDoctorConsultations.success({ consultations: formatConsultations(response)}))
	} catch (error) {
		yield put(officeActions.getDoctorConsultations.failure({ message: extractErrorMessage(error) }))
	}
}

function* getPaymentMethods(
	action: ActionType<typeof officeActions.getPaymentMethods.request>
): SagaIterator {
	try {
		const response = yield call(serverAxios.get, `/api/doctor/cards`)

		yield put(officeActions.getPaymentMethods.success({ cards: response.data.data.cards }))
	} catch (error) {
		yield put(officeActions.getPaymentMethods.failure({ message: extractErrorMessage(error) }))
	}
}

function* getDoctorBalance(
	action: ActionType<typeof officeActions.getDoctorBalance.request>
): SagaIterator {
	try {
		const createdDate = yield select(getCreatedDate)
		const toDate = moment().toISOString()
		const response = yield call(serverAxios.get, `/api/doctor/stats/balance?fromDate=${createdDate}&toDate=${toDate}`)

		yield put(officeActions.getDoctorBalance.success({ balance: response.balance - response.totalWithdrawnAndPending }))
	} catch (error) {
		yield put(officeActions.getDoctorBalance.failure({ message: extractErrorMessage(error) }))
	}
}

function* withdrawMoney(
	action: ActionType<typeof officeActions.withdrawMoney.request>
): SagaIterator {
	const { amount, cardNumber } = action.payload
	try {
		const response = yield call(serverAxios.post, `/api/doctor/withdraw`, {
			cardNumber,
			amount,
		})
		yield delay(3000)
		yield call(serverAxios.get, `/api/doctor/withdraw/status`, {
			headers: {
				'X-ID': response.data.id,
			}
		})
		yield put(officeActions.getDoctorBalance.request({}))

		if (action.payload.callback) {
			action.payload.callback()
		}

		yield put(officeActions.withdrawMoney.success({}))
	} catch (error) {
		yield put(officeActions.withdrawMoney.failure({ message: extractErrorMessage(error) }))
	}
}

function* deletePaymentMethod(
	action: ActionType<typeof officeActions.deletePaymentMethod.request>
): SagaIterator {
	try {
		const response = yield call(serverAxios.delete, `/api/doctor/cards`, {
			headers: {
				'X-ID': action.payload.id,
			},
		})

		yield put(officeActions.deletePaymentMethod.success({ cards: response.data.data.cards }))
	} catch (error) {
		yield put(officeActions.deletePaymentMethod.failure({ message: extractErrorMessage(error) }))
	}
}

function* makeDefaultPaymentMethod(
	action: ActionType<typeof officeActions.makeDefaultPaymentMethod.request>
): SagaIterator {
	try {
		const response = yield call(serverAxios.post, `/api/doctor/cards/default`, null, {
			headers: {
				'X-ID': action.payload.id,
			},
		})

		yield put(officeActions.makeDefaultPaymentMethod.success({ cards: response.data.data.cards }))
	} catch (error) {
		yield put(officeActions.makeDefaultPaymentMethod.failure({ message: extractErrorMessage(error) }))
	}
}

function* createPaymentMethod(
	action: ActionType<typeof officeActions.createPaymentMethod.request>
): SagaIterator {
	const {
		cardData: { cardHolder, cardName, cardNumber },
		callback,
	} = action.payload

	try {
		const response = yield call(serverAxios.put, `/api/doctor/cards`, {
			cardHolder,
			cardName,
			cardNumber,
		})
		const cards = response.data.data.cards
		yield put(officeActions.createPaymentMethod.success({ cards }))
		callback()
	} catch (error) {
		yield put(officeActions.createPaymentMethod.failure({ message: extractErrorMessage(error) }))
	}
}

function* postFeedback(
	action: ActionType<typeof officeActions.postFeedback.request>
): SagaIterator {
	const { data, alertMessage, callback } = action.payload

	try {
		yield call(serverAxios.post, `/api/doctor/feedback`, data, {
			headers: {
				"Content-Type": "multipart/form-data",
			}
		})

		yield put(officeActions.postFeedback.success({ alertMessage }))
		callback()
		yield put(officeActions.toggleContactUs())
	} catch (error) {
		yield put(officeActions.postFeedback.failure({ message: extractErrorMessage(error) }))
	}
}

function* getPatientInfo(
	action: ActionType<typeof officeActions.getPatientInfo.request>
): SagaIterator {
	try {
		const response = yield call(serverAxios.get, `/api/doctor/clients`)
		const conferenceUrl = response?.room?.id
		const accessToken = response?.room?.token

		yield put(
			officeActions.getPatientInfo.success({
				patientInfo: formatPatient(response),
				conferenceUrl,
				accessToken,
			})
		)
		if (action.payload.callback) {
			action.payload.callback()
		}
	} catch (error) {
		yield put(officeActions.getPatientInfo.failure({ message: extractErrorMessage(error) }))
	}
}

function* createRoom(action: ActionType<typeof officeActions.createRoom.request>): SagaIterator {
	const { callback } = action.payload

	try {
		const response = yield call(serverAxios.post, `/api/doctor/connect`)
		const conferenceUrl = response?.room?.id
		const accessToken = response?.room?.token
		const status = response?.status?.status

		yield put(officeActions.createRoom.success({ conferenceUrl, accessToken, status }))
		callback(accessToken)
	} catch (error) {
		yield put(officeActions.createRoom.failure({ message: extractErrorMessage(error) }))
	}
}

function* getWithdrawalList(action: ActionType<typeof officeActions.getWithdrawalList.request>): SagaIterator {
	const { page, limit } = action.payload

	try {
		const response = yield call(serverAxios.get, `/api/doctor/withdraw`, {
			headers: {
				'x-pagination-page': page || 1,
				'x-pagination-limit': limit || 10,
			}
		})

		yield put(officeActions.getWithdrawalList.success({
			data: response?.result?.data?.withdraw || [],
			page: response.meta.page,
			total: response.meta.count,
			limit: response.meta.limit,
		}))
	} catch (error) {
		yield put(officeActions.getWithdrawalList.failure({ message: extractErrorMessage(error) }))
	}
}

function* getNotificationsList(action: ActionType<typeof officeActions.getNotifications.request>): SagaIterator {
	try {
		const response = yield call(serverAxios.get, `/api/doctor/notifications`)
		const notifications = response?.filter((item: any) => !item.status).reverse() ?? []

		yield put(officeActions.getNotifications.success({ notifications }))
	} catch (error) {
		yield put(officeActions.getNotifications.failure({ message: extractErrorMessage(error) }))
	}
}

function* updateNotificationId(action: ActionType<typeof officeActions.updateNotificationId.request>): SagaIterator {
	const { id } = action.payload

	try {
		yield call(serverAxios.put, `/api/doctor/notifications`, { status: true }, {
			headers: {
				'x-ids': id
			}
		})
		const notifications = yield select(getNotifications)
		const filteredNotifications = notifications.filter((item: any) => item._id !== id)

		yield put(officeActions.updateNotificationId.success({ id, notifications: filteredNotifications }))
	} catch (error) {
		yield put(officeActions.updateNotificationId.failure({ id, message: extractErrorMessage(error) }))
	}
}

function* getWithdrawalStatus(action: ActionType<typeof officeActions.getWithdrawalStatus.request>): SagaIterator {
	try {
		const { id } = action.payload
		const response = yield call(serverAxios.get, `/api/doctor/withdraw/status`, {
			headers: {
				'X-ID': id
			}
		})
		yield delay(2000)

		yield put(officeActions.getWithdrawalStatus.success({ id: response.data.id, data: response.data }))
	} catch (error) {
		console.log(error)
		yield put(officeActions.getWithdrawalStatus.failure({ id: error.id, message: extractErrorMessage(error) }))
	}
}

function* acceptPatient(
	action: ActionType<typeof officeActions.acceptPatient.request>
): SagaIterator {
	const { callback } = action.payload

	try {
		const response = yield call(serverAxios.post, `/api/doctor/queue/status`, {
			status: '1020',
		})

		yield put(
			officeActions.acceptPatient.success({
				patientInfo: formatPatient(response),
			})
		)
		callback()
	} catch (error) {
		yield put(officeActions.acceptPatient.failure({ message: extractErrorMessage(error) }))
	}
}

function* declinePatient(
	action: ActionType<typeof officeActions.declinePatient.request>
): SagaIterator {
	try {
		const response = yield call(serverAxios.post, `/api/doctor/decline`)
		yield put(officeActions.toggleDoctorStatus.request({}))
		const conferenceUrl = ''

		yield put(
			officeActions.declinePatient.success({
				patientInfo: formatPatient(response?.session),
				conferenceUrl,
			})
		)
		if (action.payload.callback) {
			action.payload.callback()
		}
	} catch (error) {
		yield put(officeActions.declinePatient.failure({ message: extractErrorMessage(error) }))
	}
}

function* sendReport(action: ActionType<typeof officeActions.sendReport.request>): SagaIterator {
	const { medicalReport } = action.payload

	try {
		yield call(serverAxios.put, `/api/doctor/medical-report`, { data: { medicalReport } })
		yield call(serverAxios.post, `/api/doctor/confirm-report`)
		yield put(officeActions.getDoctorBalance.request({}))

		yield put(officeActions.sendReport.success({}))
	} catch (error) {
		yield put(officeActions.sendReport.failure({ message: extractErrorMessage(error) }))
	}
}

function* initialLoad(): SagaIterator {
	try {
		yield put(profileActions.getProfile.request({}))
		yield put(officeActions.getDoctorStatus.request({}))

		yield all([
			take(profileActions.getProfile.success),
			take(officeActions.getDoctorStatus.success),
		])

		yield put(officeActions.hideSplash())
	} catch (error) {
		// yield put(officeActions.sendReport.failure({ message: error }))
	}
}

export default [
	takeLatest(officeActions.toggleDoctorStatus.request, toggleDoctorStatus),
	takeLatest(officeActions.getPatientInfo.request, getPatientInfo),
	takeLatest(officeActions.createRoom.request, createRoom),
	takeLatest(officeActions.declinePatient.request, declinePatient),
	takeLatest(officeActions.acceptPatient.request, acceptPatient),
	takeLatest(officeActions.getDoctorStatus.request, getDoctorStatus),
	takeLatest(officeActions.sendReport.request, sendReport),
	takeLatest(officeActions.getPaymentMethods.request, getPaymentMethods),
	takeLatest(officeActions.getDoctorConsultations.request, getDoctorConsultations),
	takeLatest(officeActions.createPaymentMethod.request, createPaymentMethod),
	takeLatest(officeActions.getDoctorBalance.request, getDoctorBalance),
	takeLatest(officeActions.withdrawMoney.request, withdrawMoney),
	takeLatest(officeActions.getWithdrawalList.request, getWithdrawalList),
	takeLatest(officeActions.getNotifications.request, getNotificationsList),
	takeLatest(officeActions.updateNotificationId.request, updateNotificationId),
	takeLatest(officeActions.deletePaymentMethod.request, deletePaymentMethod),
	takeLatest(officeActions.makeDefaultPaymentMethod.request, makeDefaultPaymentMethod),
	takeEvery(officeActions.getWithdrawalStatus.request, getWithdrawalStatus),
	takeEvery(officeActions.postFeedback.request, postFeedback),
	takeLatest(officeActions.initialLoad, initialLoad),
]
