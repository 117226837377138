import React, { FC, useCallback } from 'react'
import { CircularProgress, IconButton, makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import clx from 'clsx'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import StopIcon from '@material-ui/icons/Stop'
import { Colors } from 'utils/theme'
import { useDispatch, useSelector } from 'react-redux'
import { officeActions } from 'pages/Office/redux/actions'
import { getDoctorWorking, getToggleDoctorIsWorkingLoading } from 'pages/Office/redux/selectors'
import { isLoading } from 'utils/types/store'

const useStyle = makeStyles(theme => ({
	root: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		position: 'absolute',
		margin: 'auto',
		width: '100%',
		top: 25,
		[theme.breakpoints.down('md')]: {
      position: 'static'
    },
	},
	iconWrapper: {
		marginRight: theme.spacing(2),
		backgroundColor: Colors.green[500],
		width: 35,
		height: 35,
		'&:hover': {
			backgroundColor: Colors.green[500],
		},
	},
	stopWrapper: {
		backgroundColor: Colors.red[500],
		'&:hover': {
			backgroundColor: Colors.red[500],
		},
	},
	icon: {
		color: theme.palette.background.paper,
	},
	loading: {
		marginRight: theme.spacing(5),
		display: 'flex',
		alignItems: 'center',
	},
	text: {
		fontWeight: 500,
	},
	onlineText: {
		color: Colors.green[500],
	},
	offlineText: {
		color: Colors.red[500],
	}
}))

export const ToggleActiveStatus: FC = () => {
	const classes = useStyle()
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const active = useSelector(getDoctorWorking)
	const submitting = useSelector(getToggleDoctorIsWorkingLoading)
	const isSubmitting = isLoading(submitting)
	const iconWrapperStyles = clx(classes.iconWrapper, {
		[classes.stopWrapper]: active,
	})
	const textClasses = clx(classes.text, {
		[classes.onlineText]: !active,
		[classes.offlineText]: active,
	})

	const toggleDoctorStatus = useCallback(() => {
		dispatch(officeActions.toggleDoctorStatus.request({}))
	}, [dispatch])

	if (isSubmitting) {
		return (
			<div className={classes.loading}>
				<CircularProgress size={24} />
			</div>
		)
	}

	return (
		<div className={classes.root}>
			<IconButton className={iconWrapperStyles} onClick={toggleDoctorStatus}>
				{active ? (
					<StopIcon className={classes.icon} />
				) : (
					<PlayArrowIcon className={classes.icon} />
				)}
			</IconButton>
			<div className={textClasses}>{t(active ? 'LEOMED_ADMIN_STOP_WORK_TEXT' : 'LEOMED_ADMIN_START_WORK_TEXT')}</div>
		</div>
	)
}
