import { Avatar, Button, CircularProgress, makeStyles, Paper } from '@material-ui/core'
import React, { FC, useCallback } from 'react'
import { Colors } from 'utils/theme'
import clx from 'clsx'
import { useTranslation } from 'react-i18next'
import { useCancelRequestDialog } from 'hooks/scheduledReuqests/useCancelRequestDialog'
import { useApproveRequestDialog } from 'hooks/scheduledReuqests/useApproveRequestDialog'
import { ConfirmDialog } from 'components/common/layout/ConfirmDialog'
import { ConsultationRequest } from 'pages/ConsultationRequests/redux/dto/ConsultationRequest'
import { useDispatch, useSelector } from 'react-redux'
import { officeActions } from 'pages/Office/redux/actions'
import { useSelectorFactory } from 'hooks/useSelectorFactory'
import { getNotifications } from 'pages/Office/redux/selectors'
import { isLoading } from 'utils/types/store'
import { useEffect } from 'react'
import { alertsActions } from 'store/alerts/actions'
import { makegetApproveConsultationRequestLoadingById, makegetRejectConsultationRequestLoadingById } from 'pages/ConsultationRequests/redux/selectors'
import { consultationRequestsActions } from 'pages/ConsultationRequests/redux/actions'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    border: `1px solid ${Colors.blue[50]}`,
    borderRadius: '10px',
    marginBottom: theme.spacing(2),
    '&:hover': {
      backgroundColor: '#F3F7FC',
    }
  },
  dateConflict: {
    border: `2px solid ${Colors.orange[500]}`,
    borderLeftWidth: '9px',
    backgroundColor: '#F3F7FC'
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
    flex: 1.5,
  },
  flex1: {
    flex: 1,
  },
  name: {
    marginLeft: theme.spacing(2),
    fontWeight: 700,
    width: 175,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  subtitle: {
    fontSize: '12px',
    color: Colors.blue[900],
  },
  avatar: {
    width: 48,
    height: 48,
    color: theme.palette.background.paper,
  },
  buttonsWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  button: {
    width: 92,
    height: 20,
    fontSize: '12px',
    textTransform: 'none'
  },
  acceptBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.background.paper,
    marginBottom: theme.spacing(1),
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    }
  },
  cancelBtn: {
    borderColor: Colors.red[500],
    color: Colors.red[500],
    marginBottom: theme.spacing(1),
  },
  changeBtn: {
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: 'transparent',
    }
  },
  loadingRoot: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    minHeight: 100,
  },
}))

type ConsultationRequestsRowProps = {
  data: ConsultationRequest
  scheduledRequestsMapByDate: {
    [key: string]: number
  }
  handlePageChange?(value: number): void
}

export const ConsultationRequestsRow: FC<ConsultationRequestsRowProps> = ({ data, scheduledRequestsMapByDate, handlePageChange }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const submittingApprove = useSelectorFactory(() => makegetApproveConsultationRequestLoadingById(data.id), [data.id])
  const submittingReject = useSelectorFactory(() => makegetRejectConsultationRequestLoadingById(data.id), [data.id])
  const notifications = useSelector(getNotifications)
  const notificationId = notifications.find((item: any) => item.data.id === data.id)?._id ?? null
  const isSubmitting = isLoading(submittingApprove) || isLoading(submittingReject)
  const { isConfirmOpen, handleConfirm, toggleConfirm } = useCancelRequestDialog(data.id, handlePageChange)
  const { isApproveOpen, handleApprove, toggleApprove } = useApproveRequestDialog(data.id, handlePageChange)
  const date = `${data.date} ${t('LEOMED_ADMIN_OFFICE_ON_TITLE')} ${data.time}`
  const isSameDate = scheduledRequestsMapByDate[data.datetime] > 1
  const rootStyles = clx(classes.root, {
    [classes.dateConflict]: isSameDate
  })

  useEffect(() => {
    if (isSameDate) {
      dispatch(consultationRequestsActions.consultationRequestHasDateConflict())
    }
  }, [dispatch, isSameDate])

  const handlePickRequestId = useCallback((id: string) => {
    if (notificationId) {
      dispatch(officeActions.updateNotificationId.request({ id: notificationId }))
    }
    dispatch(alertsActions.removeSocketNotification({ id: data.id }))
    dispatch(consultationRequestsActions.pickIdForChangeTimeDialog({ id }))
  }, [dispatch, notificationId, data.id])

  const toggleApproveHandler = useCallback(() => {
    if (notificationId) {
      dispatch(officeActions.updateNotificationId.request({ id: notificationId }))
    }
    dispatch(alertsActions.removeSocketNotification({ id: data.id }))
    toggleApprove()
  }, [dispatch, toggleApprove, notificationId, data.id])

  const approveHandler = useCallback(() => {
    if (notificationId) {
      dispatch(officeActions.updateNotificationId.request({ id: notificationId }))
    }
    dispatch(alertsActions.removeSocketNotification({ id: data.id }))
    handleApprove()
  }, [dispatch, handleApprove, notificationId, data.id])

  const toggleCancelHandler = useCallback(() => {
    if (notificationId) {
      dispatch(officeActions.updateNotificationId.request({ id: notificationId }))
    }
    dispatch(alertsActions.removeSocketNotification({ id: data.id }))
    toggleConfirm()
  }, [dispatch, toggleConfirm, notificationId, data.id])

  const cancelHandler = useCallback(() => {
    if (notificationId) {
      dispatch(officeActions.updateNotificationId.request({ id: notificationId }))
    }
    dispatch(alertsActions.removeSocketNotification({ id: data.id }))
    handleConfirm()
  }, [dispatch, handleConfirm, notificationId, data.id])

  if (isSubmitting) {
    return (
			<Paper className={classes.loadingRoot}>
				<CircularProgress />
			</Paper>
		)
  }

  return (
    <>
      <div className={rootStyles}>
        <div className={classes.flex}>
          <Avatar src={data.avatar} className={classes.avatar} />
          <div className={classes.name}>{`${data.firstName} ${data.lastName}`}</div>
        </div>
        <div className={classes.flex1}>
          <div className={classes.subtitle}>{`${t('LEOMED_ADMIN_OFFICE_REQUEST_TITLE')} №`}</div>
          <div>{data.request}</div>
        </div>
        <div className={classes.flex1}>
          <div className={classes.subtitle}>{t('LEOMED_ADMIN_OFFICE_DATE_TITLE')}</div>
          <div>{date}</div>
        </div>
        <div className={classes.flex1}>
          <div className={classes.subtitle}>{t('LEOMED_ADMIN_OFFICE_PAGE_CONSULTATIONS_TITLE')}</div>
          <div>{data.consultationN}</div>
        </div>
        <div className={classes.buttonsWrapper}>
          <Button
            className={clx(classes.button, classes.acceptBtn)}
            variant="contained"
            onClick={toggleApprove}
            disabled={isSameDate}
          >
            {t('LEOMED_ADMIN_OFFICE_ACCEPT_TITLE')}
          </Button>
          <Button
            className={clx(classes.button, classes.cancelBtn)}
            variant="outlined"
            onClick={toggleConfirm}
          >
            {t('LEOMED_ADMIN_DIALOG_CANCEL_PATIENT_TEXT')}
          </Button>
          <Button
            className={clx(classes.button, classes.changeBtn)}
            variant="outlined"
            onClick={() => handlePickRequestId(data.id)}
          >
            {t('LEOMED_ADMIN_OFFICE_CHANGE_TITLE')}
          </Button>
        </div>
      </div>
      <ConfirmDialog
				open={isConfirmOpen}
				handleClose={toggleCancelHandler}
				handleConfirm={cancelHandler}
				title={`${t('LEOMED_ADMIN_OFFICE_REQUEST_TITLE')} № ${data.request}`}
        description={t('LEOMED_ADMIN_OFFICE_CONSULTATION_CANCEL_CONFIRM_TEXT')}
				cancelText={t('LEOMED_ADMIN_NO_TEXT')}
				acceptText={t('LEOMED_ADMIN_OFFICE_CONSULTATION_CONFIRM_TEXT')}
			/>
      <ConfirmDialog
				open={isApproveOpen}
				handleClose={toggleApproveHandler}
				handleConfirm={approveHandler}
				title={`${t('LEOMED_ADMIN_OFFICE_REQUEST_TITLE')} № ${data.request}`}
        description={t('LEOMED_ADMIN_OFFICE_CONSULTATION_APPROVE_DESCRIPTION')}
				cancelText={t('LEOMED_ADMIN_NO_TEXT')}
				acceptText={t('LEOMED_ADMIN_OFFICE_CONSULTATION_APPROVE_TEXT')}
        isPrimary
			/>
    </>
  )
}
