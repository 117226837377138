import { Auth0ProviderOptions } from '@auth0/auth0-react'
import { config } from 'utils/config'

export const auth0ProviderOptions: Auth0ProviderOptions = {
	domain: `${config?.auth0Domain}`,
	clientId: `${config?.auth0ClientId}`,
	audience: `${config?.auth0Audience}`,
	redirectUri: `${config?.auth0RedirectURI}`,
	useRefreshTokens: true,
	cacheLocation: 'localstorage',
	login_hint: 'passwordless'
}
