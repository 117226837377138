export enum LoadingState {
	idle = 'idle',
	loading = 'loading',
	success = 'success',
	failure = 'failure',
}

export interface Loading {
	state: LoadingState
	message?: string
}

export const LoadingDefault: Loading = { state: LoadingState.idle }

export const makeLoading = (
	state: LoadingState = LoadingState.idle,
	message?: string
): Loading => ({
	state,
	message,
})

export type OneToOne<K extends string | number | symbol, T> = { [P in K]: T }

export const isLoading = (loading: Loading): boolean => loading.state === LoadingState.loading
export const isSuccess = (loading: Loading): boolean => loading.state === LoadingState.success
export const isFailure = (loading: Loading): boolean => loading.state === LoadingState.failure
