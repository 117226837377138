export const personalEditId = 'personalInfo'

export enum ProfileStep {
	personal,
	education,
	specialization,
	experience,
	almost_done,
	firstStart,
	done,
}

export const ProfileOrderOffset = 1

export const ProfileOrder = [
	ProfileStep.personal,
	ProfileStep.education,
	ProfileStep.specialization,
	ProfileStep.experience,
	ProfileStep.almost_done,
	ProfileStep.firstStart,
	ProfileStep.done,
]

export enum ProfileAttachments {
	avatar = 'avatar',
	education = 'education',
	speciality = 'speciality',
	employmentHistory = 'employmentHistory',
}

/// Personal info

export type PersonalInfo = {
	lastName?: string
	firstName?: string
	middleName?: string
	birthDate?: Date
	gender?: string
	country?: string
	city?: string
	language?: string
	email?: string
	phone?: string
	avatar?: string
	activated?: boolean
}

/// Education

export type EducationItem = {
	id: string
	currentPlaceOfWork: string
	department: string
	specialty: string
	gradeLevel: string
	documents: any
	toDeleteDocumentsIds: string[]
	periodOfStudy: Date | null
}

/// Specialization

export type SpecializationItem = {
	id: string
	type: string
	gradeLevel: string
	category: string
	start: Date
	documents: any
	toDeleteDocumentsIds: string[]
}

/// Work experience

export type ExperienceItem = {
	id: string
	title: string
	address: string
	end?: Date | string
	periodOfWork: {
		from: Date
		to: Date
	}
	documents: any
	toDeleteDocumentsIds: string[]
	workingNow: boolean
}
