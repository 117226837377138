export const SET_PROFILE_STEP = 'profile/SET_PROFILE_STEP'

export const UPDATE_PROFILE_REQUEST = 'profile/UPDATE_PROFILE_REQUEST'
export const UPDATE_PROFILE_SUCCESS = 'profile/UPDATE_PROFILE_SUCCESS'
export const UPDATE_PROFILE_FAILURE = 'profile/UPDATE_PROFILE_FAILURE'

export const GET_PROFILE_REQUEST = 'profile/GET_PROFILE_REQUEST'
export const GET_PROFILE_SUCCESS = 'profile/GET_PROFILE_SUCCESS'
export const GET_PROFILE_FAILURE = 'profile/GET_PROFILE_FAILURE'

export const DELETE_ATTACHMENT_REQUEST = 'profile/DELETE_ATTACHMENT_REQUEST'
export const DELETE_ATTACHMENT_SUCCESS = 'profile/DELETE_ATTACHMENT_SUCCESS'
export const DELETE_ATTACHMENT_FAILURE = 'profile/DELETE_ATTACHMENT_FAILURE'

export const DELETE_BANNER_REQUEST = 'profile/DELETE_BANNER_REQUEST'
export const DELETE_BANNER_SUCCESS = 'profile/DELETE_BANNER_SUCCESS'
export const DELETE_BANNER_FAILURE = 'profile/DELETE_BANNER_FAILURE'

export const ADD_EDUCATION_ITEM = 'profile/ADD_EDUCATION_ITEM'
export const ADD_SPECIALIZATION_ITEM = 'profile/ADD_SPECIALIZATION_ITEM'
export const ADD_EXPERIENCE_ITEM = 'profile/ADD_EXPERIENCE_ITEM'
export const EDIT_ITEM_BY_ID = 'profile/EDIT_ITEM_BY_ID'
export const NEED_REDIRECT_TO_OFFICE = 'profile/NEED_REDIRECT_TO_OFFICE'
