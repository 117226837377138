import React, { FC } from 'react'
import { Box, InputLabel, makeStyles, TextField, TextFieldProps } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		marginBottom: '10px',
	},
	labelWrapper: {
		marginBottom: theme.spacing(1),
	},
	label: {
		fontSize: '14px',
		color: theme.palette.primary.dark,
		marginBottom: theme.spacing(1),
	},
	input: {
		borderRadius: '10px',
	},
}))

type InputFieldProps = TextFieldProps & {
	placeholder?: string
	name: string
	label: string
}

export const InputField: FC<InputFieldProps> = ({
	name,
	placeholder,
	label,
	className,
	...rest
}) => {
	const classes = useStyles()

	return (
		<Box className={classes.root}>
			<Box className={classes.labelWrapper}>
				<InputLabel className={classes.label} htmlFor={name}>
					{label}
				</InputLabel>
			</Box>

			<TextField
				className={classes.input}
				id={name}
				placeholder={placeholder}
				name={name}
				{...rest}
			/>
		</Box>
	)
}
