import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Typography, makeStyles } from '@material-ui/core'
import { NavLink } from 'react-router-dom'
import clsx from 'clsx'
import { RoutePath } from 'router/routes'
import { useAuthorization } from 'pages/Auth/hooks/useAuthorization'
import { Localization } from './Localization'

const useStyles = makeStyles(theme => ({
	root: {
		padding: theme.spacing(3, 0),
		display: 'flex',
		justifyContent: 'space-between',
		[theme.breakpoints.down('xs')]: {
			paddingLeft: theme.spacing(6),
		},
	},
	logoContainer: {
		display: 'inline-flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		textDecoration: 'none',
		color: theme.palette.primary.light,
		[theme.breakpoints.down('xs')]: {
			display: 'none',
		},
	},
	imgWrapper: {
		width: '65px',
		height: '65px',
	},
	contentLogoContainer: {
		color: theme.palette.primary.main,
	},
	logoName: {
		marginLeft: theme.spacing(3),
		fontWeight: 600,
	},
	loginContainer: {
		display: 'flex',
		alignItems: 'center',
	},
	login: {
		padding: theme.spacing(1, 3),
		borderRadius: '10px',
		height: 'fit-content',
		color: theme.palette.primary.light,
		border: `2px solid ${theme.palette.primary.light}`,
		'&:hover': {
			backgroundColor: 'transparent',
		},
	},
	contentLogin: {
		color: theme.palette.primary.main,
		borderColor: theme.palette.primary.main,
	},
}))

type HeaderProps = {
	isContent?: boolean
}

export const Header: FC<HeaderProps> = ({ isContent = false }) => {
	const classes = useStyles()
	const { loginHandler } = useAuthorization()
	const { t } = useTranslation()

	const loginStyles = clsx(classes.login, {
		[classes.contentLogin]: isContent,
	})

	const logoContainerStyles = clsx(classes.logoContainer, {
		[classes.contentLogin]: isContent,
	})

	return (
		<div className={classes.root}>
			<NavLink className={logoContainerStyles} to={RoutePath.home}>
				<img alt="Logo" src={`${process.env.PUBLIC_URL}/logo.svg`} className={classes.imgWrapper} />
				<Typography variant="h6" className={classes.logoName}>
					LEODOC
				</Typography>
			</NavLink>

			<div className={classes.loginContainer}>
				<Button onClick={loginHandler} className={loginStyles}>
					{t('LEOMED_ADMIN_NAV_LOGIN_TEXT')}
				</Button>
				<Localization isHeader />
			</div>
		</div>
	)
}
