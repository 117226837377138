import React, { FC } from 'react'
import { Typography, makeStyles, IconButton } from '@material-ui/core'
import { NavLink } from 'react-router-dom'
import { RoutePath } from 'router/routes'
import MenuIcon from '@material-ui/icons/Menu'
import { ToggleActiveStatus } from './ToggleActiveStatus'
import { useSelector } from 'react-redux'
import {
	getIsProfileActivated,
	getProfileStep,
} from 'pages/Profile/redux/selectors'
import { ProfileStep } from 'pages/Profile/redux/model'
import { NotificationCenter } from './NotificationCenter'
import { useScheduledCallSoon } from 'hooks/useScheduledCallSoon'
import { ConsultationSoonBanner } from './ConsultationSoonBanner'
import { getDoctorWorking } from 'pages/Office/redux/selectors'

const useStyles = makeStyles(theme => ({
	root: {
		marginBottom: theme.spacing(4),
		position: 'fixed',
		width: '100%',
		top: 0,
		zIndex: 100,
		borderBottom: `1px solid #000`
	},
	wrapper: {
		padding: theme.spacing(1, 3),
		display: 'flex',
		justifyContent: 'space-between',
		backgroundColor: theme.palette.background.paper,
		[theme.breakpoints.down('xs')]: {
			paddingLeft: theme.spacing(6),
		},
	},
	logoContainer: {
		display: 'inline-flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		textDecoration: 'none',
		marginRight: theme.spacing(12),
		color: theme.palette.text.primary,
		[theme.breakpoints.down('xs')]: {
			display: 'none',
		},
	},
	logo: {
		width: '50px',
		height: '50px',
		borderRadius: '50%',
		backgroundColor: theme.palette.primary.main,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		color: theme.palette.background.paper,
	},
	navContainer: {
		display: 'flex',
		alignItems: 'center',
		position: 'relative',
		zIndex: 1,
	},
	contentLogoContainer: {
		color: theme.palette.primary.main,
	},
	logoName: {
		marginLeft: theme.spacing(3),
		fontWeight: 600,
	},
	loginContainer: {
		display: 'flex',
		alignItems: 'center',
		position: 'relative'
	},
	imgWrapper: {
		width: '65px',
		height: '65px',
	},
}))

type OfficeHeaderProps = {
	isContent?: boolean
	handleIsMenuOpenToggle: () => void
}

export const OfficeHeader: FC<OfficeHeaderProps> = ({ isContent = false, handleIsMenuOpenToggle }) => {
	const classes = useStyles()
	const { scheduledCall, date, time, removeClosestTime } = useScheduledCallSoon()
	const active = useSelector(getDoctorWorking)
	const isProfileActivated = useSelector(getIsProfileActivated)
	const profileStep = useSelector(getProfileStep)
	const isProfileFinished = profileStep > ProfileStep.almost_done
	const isCallSoon = !active && isProfileFinished && isProfileActivated && scheduledCall &&  date

	return (
		<div className={classes.root}>
			<div className={classes.wrapper}>
				<div className={classes.navContainer}>
					<IconButton onClick={handleIsMenuOpenToggle}>
						<MenuIcon color="primary" />
					</IconButton>
					<NavLink className={classes.logoContainer} to={RoutePath.home}>
						<img
							alt="Logo"
							src={`${process.env.PUBLIC_URL}/logo.svg`}
							className={classes.imgWrapper}
						/>
						<Typography variant="h6" className={classes.logoName}>
							LEODOC
						</Typography>
					</NavLink>

				</div>

				{isProfileFinished && isProfileActivated && <ToggleActiveStatus />}

				<div className={classes.loginContainer}>
					{isProfileFinished && <NotificationCenter />}
				</div>
			</div>
			{isCallSoon && (
				<ConsultationSoonBanner date={date} time={time} removeClosestTime={removeClosestTime} />
			)}
		</div>
	)
}
