import React, { useState, useRef } from 'react'
import Button from '@material-ui/core/Button'
import { useTranslation } from 'react-i18next'
import DeviceSelectionDialog from '../../DeviceSelectionDialog/DeviceSelectionDialog'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import MenuContainer from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MoreIcon from '@material-ui/icons/MoreVert'
import Typography from '@material-ui/core/Typography'
import { Theme, useMediaQuery } from '@material-ui/core'
import SettingsIcon from 'video/icons/SettingsIcon'

export default function Menu(props: { buttonClassName?: string }) {
	const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
	const { t } = useTranslation()
	const [menuOpen, setMenuOpen] = useState(false)
	const [settingsOpen, setSettingsOpen] = useState(false)

	const anchorRef = useRef<HTMLButtonElement>(null)

	return (
		<>
			<Button
				onClick={() => setMenuOpen(isOpen => !isOpen)}
				ref={anchorRef}
				className={props.buttonClassName}
			>
				{isMobile ? (
					<MoreIcon />
				) : (
					<>
						<SettingsIcon />
						<ExpandMoreIcon />
					</>
				)}
			</Button>
			<MenuContainer
				open={menuOpen}
				onClose={() => setMenuOpen(isOpen => !isOpen)}
				anchorEl={anchorRef.current}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
				transformOrigin={{
					vertical: isMobile ? -55 : 'bottom',
					horizontal: 'center',
				}}
			>
				<MenuItem onClick={() => setSettingsOpen(true)}>
					<Typography variant="body1">{t('LEOMED_ADMIN_CALL_AUDIO_AND_VIDEO')}</Typography>
				</MenuItem>
			</MenuContainer>
			<DeviceSelectionDialog
				open={settingsOpen}
				onClose={() => {
					setSettingsOpen(false)
					setMenuOpen(false)
				}}
			/>
		</>
	)
}
