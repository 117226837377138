import { makeStyles, fade } from '@material-ui/core'
import React, { FC, useMemo } from 'react'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { NotificationRow } from './NotificationRow'
import { Notification } from 'pages/Office/redux/dto/Notification'

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    width: 450,
    maxHeight: 480,
    overflowY: 'auto',
    top: 57,
    right: 0,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 2, 0, 2),
    border: `1px solid ${fade(theme.palette.primary.main, 0.3)}`,
    borderRadius: 10,
    zIndex: 100
  },
  subtitle: {
    fontWeight: 700,
    marginBottom: theme.spacing(2),
  },
  noData: {
    padding: theme.spacing(2),
    textAlign: 'center',
    fontWeight: 700,
  }
}))

type NotificationsWindowProps = {
  notifications: Notification[]
}

const statusOrderMap = {
  'CLIENT_CREATED': 1,
  'CLIENT_UPDATED': 2,
  'CLIENT_APPROVED': 3,
  'CLIENT_REJECTED': 4,
  'DOCTOR_UPDATED': 5
}

export const NotificationsWindow: FC<NotificationsWindowProps> = ({ notifications }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const noDataStyles = clsx(classes.root, classes.noData)

  const filteredNotifications = useMemo(() => 
    notifications.sort((a, b) => (statusOrderMap[a.data.subStatus] - statusOrderMap[b.data.subStatus]))
  , [notifications])

  return (
    <>
      {filteredNotifications.length !== 0 ? (
        <div className={classes.root}>
          <div className={classes.subtitle}>{t('LEOMED_ADMIN_OFFICE_CONSULTATION_REQUESTS_TITLE')}</div>
          {filteredNotifications.map(notification => <NotificationRow data={notification} />)}
        </div>
      ) : <div className={noDataStyles}>{t('LEOMED_ADMIN_NO_NOTIFICATIONS')}</div>}
    </>
  )
}
