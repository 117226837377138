import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import { store } from 'store'
import { getAccessToken } from 'pages/Auth/redux/selectors'
import { config } from 'utils/config'

export type ServerSuccessResponse = any

export interface ServerErrorResponse {
	message: string
}

const serverAxios = axios.create({
	timeout: 30000,
	baseURL: `${config?.server?.host}`,
	withCredentials: true,
})

serverAxios.interceptors.request.use(
	(requestConfig: AxiosRequestConfig): AxiosRequestConfig => {
		const state = store.getState()

		const accessToken = getAccessToken(state)

		if (accessToken) {
			requestConfig.headers.Authorization = `Bearer ${accessToken}`
		}

		return requestConfig
	}
)

serverAxios.interceptors.response.use(
	(response: AxiosResponse<ServerSuccessResponse>) => response.data,
	(error: AxiosError<ServerErrorResponse>) => Promise.reject(error)
)

export { serverAxios }
