import { CircularProgress, createStyles, makeStyles, Paper } from '@material-ui/core'
import { usePatientConsultationDialog } from 'hooks/consultationHistory.ts/usePatientConsultationDialog'
import { PaginationControls } from 'layouts/parts/Controls/PaginationControls'
import { useConsultationHistory } from 'pages/MyCalendar/hooks/useConsultationHistory'
import { ConsultationHistory } from 'pages/MyCalendar/redux/dto/ConsultationHistory'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { ConsultationHistoryDetailsDialog } from './ConsultationHistoryDetailsDialog'
import { ConsultationHistoryRow } from './ConsultationHistoryRow'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: { position: 'relative' },
    empty: { padding: theme.spacing(3), textAlign: 'center' },
    noData: {
      padding: theme.spacing(4),
      textAlign: 'center',
      textTransform: 'uppercase',
      fontWeight: 700
    },
    loadingRoot: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: theme.spacing(2),
      minHeight: 100,
    },
    wrapper: {
      padding: theme.spacing(2)
    },
    pagination: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }
  })
)

export type ConsultationHistoryListProps = {
  history: ConsultationHistory[];
  loading: boolean;
  handlePageChange: (value: number) => void
}

export const ConsultationHistoryList: FC<ConsultationHistoryListProps> = ({ history, loading, handlePageChange }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { total, limit, page } = useConsultationHistory()
  const { consultationHistoryData, isPatientConsultationOpen } = usePatientConsultationDialog()

  const pageTotal = Math.ceil(total / limit)

  if (loading) {
    return (
			<Paper className={classes.loadingRoot}>
				<CircularProgress />
			</Paper>
		)
  }

  return (
    <div>
      {history.length !== 0 ? (
        <div className={classes.wrapper}>
          {history.map(item => <ConsultationHistoryRow data={item} />)}
          <div className={classes.pagination}>
            <PaginationControls
              page={page}
              pageCount={pageTotal}
              onPageChange={handlePageChange}
            />
          </div>
          {consultationHistoryData && <ConsultationHistoryDetailsDialog isOpen={isPatientConsultationOpen} data={consultationHistoryData} />}
        </div>
      ) : <div className={classes.noData}>{t('LEOMED_ADMIN_NO_DATA')}</div>}
    </div>
  )
}
