import React, { FC } from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos'
import AccountBoxIcon from '@material-ui/icons/AccountBox'
import EmailIcon from '@material-ui/icons/Email'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import ContactSupportIcon from '@material-ui/icons/ContactSupport'
import AppsIcon from '@material-ui/icons/Apps'
import DateRangeIcon from '@material-ui/icons/DateRange'
import { List } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { RoutePath } from 'router/routes'
import { NavigationLink } from './NavigationLink'
import { useAuthorization } from 'pages/Auth/hooks/useAuthorization'
import { useSelector } from 'react-redux'
import { getPersonalInfo } from 'pages/Profile/redux/selectors'
import { useMemo } from 'react'
import { Colors } from 'utils/theme'
import { getConsultationRequestsTotal } from 'pages/ConsultationRequests/redux/selectors'

const useStyles = makeStyles((theme) =>
	createStyles({
		root: { 
			display: 'flex',
			flexDirection: 'column',
			flexGrow: 1,
			justifyContent: 'space-between'
		},
		main: {
			[theme.breakpoints.down('xs')]: {
				paddingBottom: theme.spacing(2),
			},
		},
		email: {
			display: 'flex',
			alignItems: 'center',
			color: '#9CB1C9',
			fontWeight: 600,
			padding: theme.spacing(0, 3),
			marginBottom: theme.spacing(3),
		},
		logout: {
			display: 'flex',
			alignItems: 'center',
			cursor: 'pointer',
			padding: theme.spacing(0, 3),
			color: '#9CB1C9',
			fontWeight: 600,
			'&:hover': {
				color: '#000',
			},
			'&:hover $exitIcon': {
				color: '#000',
			},
		},
		exitIcon: {
			marginRight: theme.spacing(1),
			color: '#9CB1C9',
		},
		countWrapper: {
			display: 'flex',
			alignItems: 'center',
		},
		counter: {
			backgroundColor: Colors.red[500],
			width: 15,
			height: 15,
			fontSize: 10,
			textAlign: 'center',
			borderRadius: '50%',
			color: theme.palette.background.paper,
			marginLeft: theme.spacing(1),
			lineHeight: '15px',
		}
	})
)

type DrawerMenuProps = {
	isMenuOpen: boolean
}

export const DrawerMenu: FC<DrawerMenuProps> = ({ isMenuOpen }) => {
	const classes = useStyles()
	const profile = useSelector(getPersonalInfo)
	const { logoutHandler } = useAuthorization()
	const { t } = useTranslation()

  const requestsTotal = useSelector(getConsultationRequestsTotal)

	const requestsCounter = useMemo(() => (
		<div className={classes.countWrapper}>
			{requestsTotal > 0 && <div className={classes.counter}>{requestsTotal}</div>}
		</div>
	), [classes.countWrapper, requestsTotal, classes.counter])

	return (
		<div className={classes.root}>
			<List className={classes.main}>
				<NavigationLink to={`${RoutePath.office}?page=1`} text={t('LEOMED_ADMIN_OFFICE_PAGE_TITLE')} icon={<AppsIcon />} onlyIcon={!isMenuOpen} />
				<NavigationLink to={RoutePath.profile} text={t('LEOMED_ADMIN_PROFILE_PAGE_TITLE')} icon={<AccountBoxIcon />} onlyIcon={!isMenuOpen} />
				<NavigationLink to={`${RoutePath.requests}?page=1`} text={t('LEOMED_ADMIN_REQUESTS_PAGE_TITLE')} icon={<AddToPhotosIcon />} counter={requestsCounter} onlyIcon={!isMenuOpen} />
				<NavigationLink to={`${RoutePath.calendar}?page=1`} text={t('LEOMED_ADMIN_CALENDAR_PAGE_TITLE')} icon={<DateRangeIcon />} onlyIcon={!isMenuOpen} />
				<NavigationLink to={RoutePath.support} text={t('LEOMED_ADMIN_SUPPORT_PAGE_TITLE')} icon={<ContactSupportIcon />} onlyIcon={!isMenuOpen} />
			</List>
			<div>
				<div className={classes.email}>
					<EmailIcon className={classes.exitIcon} />
					{isMenuOpen && profile.email}
				</div>
				<div onClick={logoutHandler} className={classes.logout}>
					<ExitToAppIcon className={classes.exitIcon} />
					{isMenuOpen && t('LEOMED_ADMIN_NAV_LOGOUT_TEXT')}
				</div>
			</div>
		</div>
	)
}
