import { createReducer, ActionType } from 'typesafe-actions'
import { callActions } from 'pages/Call/redux/actions'
import { MedicalReport } from './../model'

export type State = {
	medicalReport: MedicalReport
	canLeaveCall: boolean
	remoteParticipantConnected: boolean
	callDuration: string
	isSelfieCameraShown: boolean
}

export const initialState: State = {
	medicalReport: {
		appointment: '',
		drugs: '',
		description: '',
	},
	canLeaveCall: false,
	remoteParticipantConnected: false,
	callDuration: '',
	isSelfieCameraShown: true,
}

export default createReducer<State, ActionType<typeof callActions>>(initialState)
	.handleAction(callActions.saveReport.success, (state, { payload: { medicalReport } }) => ({
		...state,
		medicalReport,
	}))
	.handleAction(callActions.canLeaveCall, state => ({
		...state,
		canLeaveCall: true,
	}))
	.handleAction(callActions.toggleRemoteParticipantConnected, state => ({
		...state,
		remoteParticipantConnected: true,
	}))
	.handleAction(callActions.toggleSelfieCamera, state => ({
		...state,
		isSelfieCameraShown: !state.isSelfieCameraShown,
	}))
	.handleAction(
		callActions.sendUserConnectedEvent.success,
		(state, { payload: { callDuration } }) => ({
			...state,
			callDuration,
		})
	)
