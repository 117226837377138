import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isLoading } from "utils/types/store"
import { consultationRequestsActions } from '../redux/actions'
import { getConsultationRequestsInfo } from '../redux/selectors'

export type UseConsultationsRequests = {
  items: any[]
  page: number
  limit: number
  total: number
  loading: boolean

  load(options: {
    page?: number
    limit?: number
    subStatus?: string
  }): void
}

export const useConsultationsRequests = (): UseConsultationsRequests => {
  const dispatch = useDispatch()

  const consultationRequests = useSelector(getConsultationRequestsInfo)

  const handleLoad = useCallback(
    (options: {
      page: number
      limit: number
      subStatus?: string
    }) => {
      dispatch(
        consultationRequestsActions.getConsultationRequests.request({
          page: options.page,
          limit: options.limit,
          subStatus: options.subStatus || '',
        })
      )
    },
    [dispatch]
  )

  return {
    ...consultationRequests,
    loading: isLoading(consultationRequests.loading),
    load: handleLoad,
  }
}
