import { Avatar, makeStyles } from '@material-ui/core'
import { officeActions } from 'pages/Office/redux/actions'
import { Notification } from 'pages/Office/redux/dto/Notification'
import React, { FC, useCallback } from 'react'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Colors } from 'utils/theme'
import { useMemo } from 'react'
import { consultationRequestsActions } from 'pages/ConsultationRequests/redux/actions'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    border: `1px solid ${Colors.blue[50]}`,
    borderRadius: '10px',
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    '&:hover': {
      backgroundColor: '#F3F7FC',
    }
  },
  subtitle: {
    fontWeight: 700,
  },
  rightWrapper: {
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'column'
  },
  details: {
    color: theme.palette.primary.main,
    marginTop: theme.spacing(2),
    cursor: 'pointer'
  },
  statusBubble: {
    borderRadius: '15px',
    color: theme.palette.background.paper,
    fontSize: 12,
    padding: theme.spacing(0, 1)
  },
  rejected: {
    backgroundColor: Colors.red[500]
  },
  accepted: {
    backgroundColor: Colors.green[500]
  },
  pending: {
    backgroundColor: Colors.orange[500]
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.background.paper,
    fontSize: 14,
    marginRight: theme.spacing(2),
  },
  leftWrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  idText: {
    fontSize: 12,
    color: theme.palette.primary.main,
  }
}))

type NotificationRowProps = {
  data: Notification
}

const showDetailsMap = {
  'CLIENT_UPDATED': true,
  'CLIENT_CREATED': true,
  'CLIENT_REJECTED': false,
  'DOCTOR_UPDATED': false,
  'CLIENT_APPROVED': false,
}

export const NotificationRow: FC<NotificationRowProps> = ({ data }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const date = `${data.data.date} ${t('LEOMED_ADMIN_OFFICE_ON_TITLE')} ${data.data.time}`
  const statusBubbleStyles = clsx(classes.statusBubble, {
    [classes.rejected]: data.data.subStatus === 'CLIENT_REJECTED',
    [classes.accepted]: data.data.subStatus === 'CLIENT_APPROVED',
    [classes.pending]: data.data.subStatus === 'CLIENT_UPDATED' || data.data.subStatus === 'CLIENT_CREATED',
  })
  const nameInitials = useMemo(() => `${data.data.firstName?.[0] ?? ''}${data.data.lastName?.[0] ?? ''}`.toUpperCase(), [data.data.firstName, data.data.lastName])

  const detailClickHandler = useCallback(() => {
    dispatch(consultationRequestsActions.pickIdForNewRequestDialog({ id: data.data.id }))
    dispatch(officeActions.updateNotificationId.request({ id: data._id }))
  }, [dispatch, data.data.id, data._id])

  return (
    <div className={classes.root}>
      <div className={classes.leftWrapper}>
        <Avatar src={data.data.avatar} className={classes.avatar}>
          {nameInitials}
        </Avatar>
        <div>
          <div className={classes.idText}>{`№ ${data.data.request}`}</div>
          <div className={classes.subtitle}>
            {`${data.data.firstName} ${data.data.lastName}`}
          </div>
          <div>{date}</div>
        </div>
      </div>
      <div className={classes.rightWrapper}>
        <div className={statusBubbleStyles}>{t(data.data.status)}</div>
        {showDetailsMap[data.data.subStatus] && <div className={classes.details} onClick={detailClickHandler}>{t('LEOMED_ADMIN_OFFICE_DETAILS_TEXT')}</div>}
      </div>
    </div>
  )
}
