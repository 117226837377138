export const GET_SCHEDULED_REQUESTS_REQUEST = 'GET_SCHEDULED_REQUESTS_REQUEST'
export const GET_SCHEDULED_REQUESTS_SUCCESS = 'GET_SCHEDULED_REQUESTS_SUCCESS'
export const GET_SCHEDULED_REQUESTS_FAILURE = 'GET_SCHEDULED_REQUESTS_FAILURE'

export const GET_CONSULTATION_HISTORY_REQUEST = 'GET_CONSULTATION_HISTORY_REQUEST'
export const GET_CONSULTATION_HISTORY_SUCCESS = 'GET_CONSULTATION_HISTORY_SUCCESS'
export const GET_CONSULTATION_HISTORY_FAILURE = 'GET_CONSULTATION_HISTORY_FAILURE'

export const GET_CONSULTATION_DATA_REQUEST = 'GET_CONSULTATION_DATA_REQUEST'
export const GET_CONSULTATION_DATA_SUCCESS = 'GET_CONSULTATION_DATA_SUCCESS'
export const GET_CONSULTATION_DATA_FAILURE = 'GET_CONSULTATION_DATA_FAILURE'

export const PICK_CONSULTATION_HISTORY_ID = 'PICK_CONSULTATION_HISTORY_ID'