import { createAction, createAsyncAction } from 'typesafe-actions'
import * as actionType from './const'

import { AuthorizeDTO } from './dtos/AuthorizeDTO'
import { SessionDTO } from './model'

export const authActions = {
	authorize: createAsyncAction(
		actionType.AUTH_AUTHORIZE_REQUEST,
		actionType.AUTH_AUTHORIZE_SUCCESS,
		actionType.AUTH_AUTHORIZE_FAILURE
	)<AuthorizeDTO, {}, { message?: string }>(),
	getSession: createAsyncAction(
		actionType.AUTH_GET_SESSION_REQUEST,
		actionType.AUTH_GET_SESSION_SUCCESS,
		actionType.AUTH_GET_SESSION_FAILURE
	)<{ sessionId?: string }, { session: SessionDTO }, { message?: string }>(),
	updateSession: createAsyncAction(
		actionType.AUTH_UPDATE_SESSION_REQUEST,
		actionType.AUTH_UPDATE_SESSION_SUCCESS,
		actionType.AUTH_UPDATE_SESSION_FAILURE
	)<{ session: any }, { session: any; attachments?: any }, { message?: string }>(),
	logout: createAsyncAction(
		actionType.AUTH_LOGOUT_REQUEST,
		actionType.AUTH_LOGOUT_SUCCESS,
		actionType.AUTH_LOGOUT_FAILURE
	)<{ callback: any }, any, { message?: string }>(),
	storeToken: createAction(actionType.AUTH_STORE_TOKEN)<{ token: string }>(),
}
