import { createReducer, ActionType } from 'typesafe-actions'
import {
	ProfileStep,
	EducationItem,
	SpecializationItem,
	ExperienceItem,
	PersonalInfo,
} from '../model'
import { profileActions } from './../actions'

type DeleteAttachments = {
	[key: string]: any
}

export type State = {
	step: ProfileStep
	needRedirectToOffice: boolean
	personalInfo: PersonalInfo
	editId: string | null
	educationItemsIds: string[]
	educationItems: {
		[key: string]: EducationItem
	}
	deleteAttachments: DeleteAttachments
	specializationItemsIds: string[]
	specializationItems: {
		[key: string]: SpecializationItem
	}
	experienceItemsIds: string[]
	experienceItems: {
		[key: string]: ExperienceItem
	}
	activated: boolean
	created: string
}

export const initialState: State = {
	step: ProfileStep.personal,
	needRedirectToOffice: false,
	editId: null,
	personalInfo: {},
	educationItemsIds: [],
	educationItems: {},
	deleteAttachments: {
		education: {},
		speciality: {},
		employmentHistory: {},
	},
	specializationItemsIds: [],
	specializationItems: {},
	experienceItemsIds: [],
	experienceItems: {},
	activated: false,
	created: '',
}

export default createReducer<State, ActionType<typeof profileActions>>(initialState)
	.handleAction(profileActions.setProfileStep, (state, { payload: { step } }) => ({
		...state,
		step,
	}))
	.handleAction(profileActions.addEducationItem, (state, { payload: { educationItem } }) => {
		const educationItemsIds = state.educationItemsIds.includes(educationItem.id)
			? state.educationItemsIds
			: [...state.educationItemsIds, educationItem.id]
		const educationItems = { ...state.educationItems, [educationItem.id]: educationItem }
		const educationDeleteAttachments = {
			fileIds: educationItem.toDeleteDocumentsIds,
			id: educationItem.id,
		}
		return {
			...state,
			educationItemsIds,
			educationItems,
			deleteAttachments: {
				education: educationDeleteAttachments,
			},
		}
	})
	.handleAction(
		profileActions.addSpecializationItem,
		(state, { payload: { specializationItem } }) => {
			const specializationItemsIds = state.specializationItemsIds.includes(specializationItem.id)
				? state.specializationItemsIds
				: [...state.specializationItemsIds, specializationItem.id]
			const specializationItems = {
				...state.specializationItems,
				[specializationItem.id]: specializationItem,
			}
			const specialityDeleteAttachments = {
				fileIds: specializationItem.toDeleteDocumentsIds,
				id: specializationItem.id,
			}
			return {
				...state,
				specializationItemsIds,
				specializationItems,
				deleteAttachments: {
					speciality: specialityDeleteAttachments,
				},
			}
		}
	)
	.handleAction(profileActions.addExperienceItem, (state, { payload: { experienceItem } }) => {
		const experienceItemsIds = state.experienceItemsIds.includes(experienceItem.id)
			? state.experienceItemsIds
			: [...state.experienceItemsIds, experienceItem.id]
		const experienceItems = { ...state.experienceItems, [experienceItem.id]: experienceItem }
		const experienceDeleteAttachments = {
			fileIds: experienceItem.toDeleteDocumentsIds,
			id: experienceItem.id,
		}
		return {
			...state,
			experienceItemsIds,
			experienceItems,
			deleteAttachments: {
				employmentHistory: experienceDeleteAttachments,
			},
		}
	})
	.handleAction(profileActions.editItemById, (state, { payload: { itemId } }) => {
		return { ...state, editId: itemId }
	})
	.handleAction(profileActions.needRedirectToOffice, (state, { payload }) => {
		return { ...state, needRedirectToOffice: payload }
	})
	.handleAction(profileActions.getProfile.success, (state, { payload: { profile, created } }) => {
		return { ...state, ...profile, created }
	})
	.handleAction(
		profileActions.updateProfile.success,
		(state, { payload: { profile } }) => {
			return {
				...state,
				...profile,
				deleteAttachments: {},
			}
		}
	)
