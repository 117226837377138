import React, { FC, useState, useCallback } from 'react'
import {
	Box,
	InputLabel,
	makeStyles,
	Select,
	MenuItem,
	SelectProps,
	FormHelperText,
} from '@material-ui/core'
import { noop } from 'lodash/fp'
import clsx from 'clsx'
// import { useTranslation } from 'react-i18next'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		justifyContent: 'space-between',
		flexDirection: 'column',
	},
	labelWrapper: {
		display: 'flex',
		alignItems: 'center',
		marginBottom: theme.spacing(2),
	},
	label: {
		fontSize: '14px',
		color: theme.palette.primary.dark,
	},
	select: {},
	selected: {
		backgroundColor: 'transparent',
	},
	arrow: {
		position: 'absolute',
		top: '50%',
		marginTop: '-12px',
		right: '10px',
		cursor: 'pointer',
		color: theme.palette.primary.main,
	},
	arrowContent: {
		color: theme.palette.background.paper,
	},
	errorMessage: {
		paddingLeft: theme.spacing(2),
	},
}))

type Option = {
	value: string
	label: string
}

type SelectFieldProps = SelectProps & {
	name?: string
	label?: string
	options: Option[]
	errorMessage?: string
	className?: any
	isHeader?: boolean
	onChangeHandler?: (data: any) => void
}

export const SelectField: FC<SelectFieldProps> = ({
	name,
	label,
	options,
	errorMessage,
	className,
	isHeader,
	onChangeHandler = noop,
	...rest
}) => {
	const classes = useStyles()
	const [isOpen, setIsOpen] = useState(false)
	// const { t } = useTranslation()

	const toggleHandler = useCallback(() => {
		setIsOpen(!isOpen)
	}, [isOpen])

	const selectStyles = clsx(className, classes.select)
	const arrowStyles = clsx(classes.arrow, {
		[classes.arrowContent]: isHeader,
	})

	return (
		<Box className={classes.root} onClick={toggleHandler}>
			{label && (
				<Box className={classes.labelWrapper}>
					<InputLabel className={classes.label} htmlFor={name}>
						{label}
					</InputLabel>
				</Box>
			)}

			<Select
				classes={{ select: selectStyles }}
				disableUnderline
				displayEmpty
				variant="outlined"
				id={name}
				open={isOpen}
				IconComponent={() => <ExpandMoreIcon className={arrowStyles} />}
				{...rest}
			>
				{options.map(({ value, label }) => (
					<MenuItem
						key={value}
						classes={{ selected: classes.selected }}
						selected
						value={value}
						onClick={() => onChangeHandler(value)}
					>
						{label}
					</MenuItem>
				))}
			</Select>
			{errorMessage && (
				<FormHelperText error className={classes.errorMessage}>
					{errorMessage}
				</FormHelperText>
			)}
		</Box>
	)
}
