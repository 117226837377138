import { makeStyles } from '@material-ui/core'
import WarningIcon from '@material-ui/icons/Warning'
import React, { FC } from 'react'
import { Colors } from 'utils/theme'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
  root: {
    border: `2px solid ${Colors.orange[500]}`,
    borderRadius: '10px',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2),
    margin: theme.spacing(2, 0, 2, 0),
    backgroundColor: Colors.orange[50]
  },
  icon: {
    color: Colors.orange[500],
    marginRight: theme.spacing(2),
  }
}))

export const ConsultationDateConflictBanner: FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <div className={classes.root}>
      <WarningIcon className={classes.icon} />
      <div>{t('LEOMED_ADMIN_OFFICE_CONSULTATION_REQUEST_DATE_CONFLICT_TEXT')}</div>
    </div>
  )
}
