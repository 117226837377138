import { OneToOne } from 'utils/types/store'
import { callActions } from 'pages/Call/redux/actions'
import { createReducer, ActionType } from 'typesafe-actions'
import { officeActions } from 'pages/Office/redux/actions'
import { Consultations } from './../utils/formatConsultations'
import { TransactionHistory } from '../dto/TransactionHistory'
import { oneInsert } from 'utils/mutators/relations'

export type State = {
	isDoctorWorking: boolean
	patientInfo: any
	cards: any[]
	conferenceUrl: string
	accessToken: string
	consultations: Consultations
	balance: number
	notifications: any[]

	transactionHistoryIds: TransactionHistory['id'][] | any
	transactionHistoryById: OneToOne<TransactionHistory['id'], TransactionHistory>
  transactionHistoryPage: number,
  transactionHistoryTotal: number,
  transactionHistoryLimit: number,
}

export const initialState: State = {
	isDoctorWorking: false,
	cards: [],
	patientInfo: null,
	conferenceUrl: '',
	accessToken: '',
	consultations: {},
	balance: 0,
	notifications: [],

	transactionHistoryIds: [],
	transactionHistoryById: {},
  transactionHistoryPage: 1,
  transactionHistoryTotal: 0,
  transactionHistoryLimit: 10,
}

export default createReducer<State, ActionType<typeof officeActions | typeof callActions>>(
	initialState
)
	.handleAction(
		[officeActions.toggleDoctorStatus.success, officeActions.getDoctorStatus.success],
		(state, { payload: { isDoctorWorking } }) => ({
			...state,
			isDoctorWorking,
			patientInfo: !isDoctorWorking ? null : state.patientInfo,
		})
	)
	.handleAction(
		officeActions.getPatientInfo.success,
		(state, { payload: { patientInfo, conferenceUrl, accessToken = '' } }) => ({
			...state,
			patientInfo,
			conferenceUrl,
			accessToken,
		})
	)
	.handleAction(
		officeActions.declinePatient.success,
		(state, { payload: { patientInfo, conferenceUrl, accessToken = '' } }) => ({
			...state,
			patientInfo,
			conferenceUrl,
			accessToken,
			isDoctorWorking: false,
		})
	)
	.handleAction(officeActions.acceptPatient.success, (state, { payload: { patientInfo } }) => ({
		...state,
		patientInfo,
	}))
	.handleAction(
		officeActions.createRoom.success,
		(state, { payload: { conferenceUrl, accessToken, status } }) => ({
			...state,
			conferenceUrl,
			accessToken,
			patientInfo: {
				...state.patientInfo,
				status,
			},
		})
	)
	.handleAction(
		[callActions.leaveFromCall.success, callActions.diconnectFromCall.success],
		state => ({
			...state,
			patientInfo: null,
			conferenceUrl: '',
		})
	)
	.handleAction(officeActions.sendReport.success, state => ({
		...state,
		patientInfo: null,
	}))
	.handleAction(officeActions.getDoctorConsultations.success, (state, { payload: { consultations } } ) => ({
		...state,
		consultations,
	}))
	.handleAction(
    [
      officeActions.getPaymentMethods.success,
      officeActions.createPaymentMethod.success,
      officeActions.deletePaymentMethod.success,
      officeActions.makeDefaultPaymentMethod.success,
    ],
    (state, { payload: { cards } }) => ({
      ...state,
      cards,
    })
  )
	.handleAction([
		officeActions.getNotifications.success,
		officeActions.updateNotificationId.success,
	], (state, { payload: { notifications } } ) => ({
		...state,
		notifications,
	}))
	.handleAction(officeActions.getDoctorBalance.success, (state, { payload: { balance } }) => ({
		...state,
		balance,
	}))
	.handleAction(officeActions.getWithdrawalStatus.success, (state, { payload: {
		data,
	} }) => {
		const transactionHistoryById = oneInsert(data, data.id, state.transactionHistoryById)

    return {
      ...state,
      transactionHistoryById,
    }
	})
	.handleAction(officeActions.getWithdrawalList.success, (state, { payload: {
		data,
		page,
		total,
    limit
	} }) => {

		const transactionHistoryById = data.reduce(
      (accumulator, item) => oneInsert(item, item.id, accumulator),
      state.transactionHistoryIds
    )

		return {
      ...state,
      transactionHistoryIds: data.map(item => item.id),
      transactionHistoryById,
      transactionHistoryPage: page,
      transactionHistoryLimit: limit,
      transactionHistoryTotal: total,
    }
	})
