import React, { useCallback } from 'react'
import { Pagination } from '@material-ui/lab'
import { FC } from 'react'

type PaginationControlsProps = {
  page: number
  pageCount: number
  onPageChange?(value: number): void
}

export const PaginationControls: FC<PaginationControlsProps> = ({
  page,
  pageCount,
  onPageChange,
}) => {

  const handlePageChange = useCallback(
    (event, value: number) => {
      onPageChange?.(value)
    },
    [onPageChange]
  )

  return (
    <Pagination
      shape="rounded"
      variant="outlined"
      size="large"
      color="primary"
      count={pageCount}
      page={page}
      onChange={handlePageChange}
      showFirstButton
      showLastButton
    />
  )
}
