import React, { FC, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { Typography, makeStyles, Divider } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import clsx from 'clsx'

import { CommonBtn } from 'components/common/layout/CommonBtn'
import { RoutePath } from 'router/routes'
import { useProfileStep } from '../hooks/useProfileStep'
import { ProfileStep } from '../redux/model'

const useStyles = makeStyles(theme => ({
	root: {
		position: 'relative',
		width: '100%',
		padding: theme.spacing(3, 0),
		textAlign: 'center',
	},
	link: {
		position: 'absolute',
		display: 'flex',
		alignItems: 'center',
		textDecoration: 'none',
		gap: `${theme.spacing(2)}px`,
		color: theme.palette.text.primary,
		left: theme.spacing(4),
		top: '50%',
		marginTop: '-12px',
		cursor: 'pointer',
	},
	button: {
		position: 'absolute',
		right: theme.spacing(4),
		top: '50%',
		marginTop: '-18px',
	},
	title: {
		fontSize: '24px',
		fontWeight: 700,
	},
	finalButton: {
		backgroundColor: theme.palette.warning.main,
	},
	finalRoot: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		borderBottom: `1px solid ${theme.palette.divider}`,
		backgroundColor: theme.palette.background.paper,
		position: 'sticky',
		top: 0,
		zIndex: 10,
	},
}))

type ProfileHeaderProps = {
	title: string
	buttonText: string
	disabled?: boolean
	noBack?: boolean
	onSubmit?: () => void
}

export const ProfileHeader: FC<ProfileHeaderProps> = ({
	title,
	buttonText,
	disabled,
	noBack,
	onSubmit,
}) => {
	const classes = useStyles()
	const history = useHistory()
	const { t } = useTranslation()
	const { step, previousStep } = useProfileStep()
	const isFinalStep = step === ProfileStep.firstStart || step === ProfileStep.done

	const backHandler = useCallback(() => {
		if (step > 0) {
			previousStep()
		} else {
			history.push(RoutePath.office)
		}
	}, [history, previousStep, step])

	const nextHandler = useCallback(() => {
		onSubmit?.()
	}, [onSubmit])

	const buttonStyles = clsx(classes.button, {
		[classes.finalButton]: noBack,
	})

	const finalRootStyles = clsx(classes.root, {
		[classes.finalRoot]: isFinalStep,
	})

	if (isFinalStep) {
		return (
			<div className={finalRootStyles}>
				<Typography className={classes.title}>{title}</Typography>
				{buttonText && <CommonBtn text={buttonText} onClick={nextHandler} disabled={disabled} />}
			</div>
		)
	}

	return (
		<>
			<div className={classes.root}>
				{!noBack && (
					<div onClick={backHandler} className={classes.link}>
						<ArrowBackIcon color="primary" />
						{t('LEOMED_ADMIN_BACK_TEXT')}
					</div>
				)}
				<Typography className={classes.title}>{title}</Typography>
				{buttonText && (
					<CommonBtn
						text={buttonText}
						className={buttonStyles}
						onClick={nextHandler}
						disabled={disabled}
					/>
				)}
			</div>
			<Divider />
		</>
	)
}
