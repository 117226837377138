import React, { FC } from 'react'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
	root: {
		width: '30%',
		height: '100px',
		borderRadius: '10px',
		color: theme.palette.text.primary,
		backgroundColor: theme.palette.background.paper,
		padding: theme.spacing(2),
		textDecoration: 'none',
		display: 'flex',
		alignItems: 'center',
	},
	wrapper: {
		paddingLeft: theme.spacing(2),
		height: '100%',
	},
	title: {
		fontSize: '18px',
		position: 'relative',
		overflow: 'hidden',
		fontWeight: 600,
		display: '-webkit-box',
		'-webkit-line-clamp': 3,
		'-webkit-box-orient': 'vertical',
	},
	date: {
		marginTop: theme.spacing(1),
		fontSize: '12px',
	},
}))

type FeaturesItemProps = {
	description: string
	title: string
	icon: any
}

export const FeaturesItem: FC<FeaturesItemProps> = ({ description, title, icon }) => {
	const classes = useStyles()

	return (
		<div className={classes.root}>
			{icon}
			<div className={classes.wrapper}>
				<div className={classes.title}>{title}</div>
				<div className={classes.date}>{description}</div>
			</div>
		</div>
	)
}
