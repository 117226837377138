import { DependencyList, useMemo } from 'react'
import { DefaultRootState, useSelector } from 'react-redux'

// TODO: Consider removing this in favor of selectorFactory utils
export const useSelectorFactory = <TState = DefaultRootState, TSelected = unknown>(
  factory: () => (state: TState) => TSelected,
  deps: DependencyList
): TSelected => {
  // We should not include the factory to the deps
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const selector = useMemo(factory, deps)
  return useSelector(selector)
}