import React, { ChangeEvent, FC, useCallback } from 'react'
import Avatar from '@material-ui/core/Avatar'
import DescriptionIcon from '@material-ui/icons/Description'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { fade } from '@material-ui/core/styles/colorManipulator'
import AddIcon from '@material-ui/icons/Add'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import clsx from 'clsx'
import { Link } from '@material-ui/core'

const useStyles = makeStyles(theme =>
	createStyles({
		wrapper: {
			position: 'relative',
			width: '50px',
			height: '50px',
			marginRight: theme.spacing(2),
		},
		content: {
			position: 'absolute',
			width: '100%',
			height: '100%',
			left: 0,
			top: 0,
			borderRadius: '10px',
		},
		input: {
			display: 'none',
		},
		disabledContent: {
			background: fade(theme.palette.primary.light, 0.7),
			opacity: 1,
			zIndex: 1,
		},
		overlay: {
			zIndex: 1,
			opacity: 0,
			cursor: 'pointer',
			borderRadius: '10px',
			backgroundColor: fade(theme.palette.primary.main, 0.7),
		},
		enabledContent: {
			opacity: 1,
			background: fade(theme.palette.primary.main, 0.7),
		},
		zIndex: {
			zIndex: 2,
		},
		deleteIcon: {
			position: 'absolute',
			top: -20,
			right: -20,
			cursor: 'pointer',
		},
		pdfContent: {
			background: fade(theme.palette.primary.main, 0.7),
		},
		pdfIcon: {
			color: theme.palette.primary.light,
		},
	})
)

export type ImagePickerProps = {
	img?: string
	disabled?: boolean
	handleDelete?: any
	id: number
	onChange: (files: FileList | null, callback?: (() => void) | undefined) => void
	count?: number
	multiple?: boolean
	mimetype?: string
}

const FileMimes = {
	pdf: "application/pdf",
	xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
	doc: "octet-stream",
	docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
}

const ImagePicker: FC<ImagePickerProps> = ({
	img,
	onChange,
	disabled,
	count,
	id,
	handleDelete,
	multiple,
	mimetype,
}) => {
	const classes = useStyles()
	const image = typeof img === 'string' ? img : ''
	const isFileRemote = Object.values(FileMimes).some(mime => mimetype?.includes(mime))
	const isFile = isFileRemote || Object.values(FileMimes).some(mime => image.includes(mime))

	const onImageChange = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => {
			if (count === undefined) return

			const files = event?.target?.files
			const filesLeft = 3 - count
			if (files && files.length > filesLeft) {
				alert(`You can pick maximum ${filesLeft} more files!`)
				return
			}
			if (files) {
				onChange(files)
			}
		},
		[count, onChange]
	)

	const overlayClasses = clsx(classes.content, {
		[classes.overlay]: !disabled,
		[classes.enabledContent]: !disabled && !img,
		[classes.disabledContent]: disabled,
	})

	const contentStyles = clsx(classes.content, {
		[classes.zIndex]: img && !disabled,
		[classes.pdfContent]: isFile,
	})

	return (
		<div className={classes.wrapper}>
			<Avatar className={overlayClasses} component="label">
				{!disabled && <AddIcon />}
				<input
					type="file"
					// accept="image/png, image/jpeg"
					onChange={onImageChange}
					multiple={multiple}
					id={id.toString()}
					className={classes.input}
					disabled={disabled}
				/>
			</Avatar>
			{isFile ? (
					<Avatar src={img} alt="Avatar" className={contentStyles} component={Link} href={isFileRemote ? img : undefined}>
						<DescriptionIcon className={classes.pdfIcon} />
					</Avatar>
			) : (
				<Avatar src={img} alt="Avatar" className={contentStyles}>
					<AddIcon color="disabled" />
				</Avatar>
			)}
			{img && !disabled && (
				<HighlightOffIcon className={classes.deleteIcon} onClick={() => handleDelete?.(id)} />
			)}
		</div>
	)
}

export default ImagePicker
