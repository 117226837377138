import { makeStyles } from '@material-ui/core'
import React, { FC, useCallback, useState } from 'react'
import DescriptionIcon from '@material-ui/icons/Description'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import { useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import { Colors } from 'utils/theme'
import { maxSizeMb, Mimes } from 'hooks/useUploadFile'
import fileSize from 'filesize'

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(2),
  },
  subTitle: {
		fontSize: '14px',
		color: theme.palette.primary.dark,
		marginBottom: theme.spacing(2),
	},
  dropzone: {
    border: `1px dashed ${theme.palette.primary.dark}`,
    borderRadius: '10px',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2)
  },
  icon: {
    width: 40,
    height: 40,
  },
  upload: {
    color: theme.palette.primary.main,
    textDecoration: 'underline',
  },
  dndText: {
    fontSize: '16px',
    margin: theme.spacing(1, 0),
    textAlign: 'center',
  },
  subText: {
    fontSize: 12,
    color: Colors.grey[500],
  },
  file: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  fileIcon: {
    marginRight: theme.spacing(1),
  },
  deleteIcon: {
    color: Colors.red[500],
    cursor: 'pointer',
    marginLeft: theme.spacing(1),
  },
  fileName: {
    maxWidth: 220,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
}))

function formatBytes(bytes: number, decimals = 2) {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

type DnDFilePickerProps = {
  onDrop: any
  onRemove: any
}

export const DnDFilePicker: FC<DnDFilePickerProps> = ({ onDrop, onRemove }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [myFiles, setMyFiles] = useState<any[]>([])

  const onFilesDrop = useCallback(files => {
    const removeIndexes: number[] = []
    for (let i = 0; i < files.length; i++) {
      const [sizeInMb] = fileSize(files[i].size, { exponent: 2, output: 'array' })
      const parsedSizeInMb = Number.parseFloat(sizeInMb)
      const isWrongSize = !parsedSizeInMb || parsedSizeInMb > maxSizeMb
      const isAllowed = Mimes['image'].includes(files[i].type)

      if (isAllowed) {
        if (isWrongSize) {
          window.alert(t('LEOMED_ADMIN_WRONG_FILE_SIZE'))
          removeIndexes.push(i)
        }
      } else {
				window.alert(t('LEOMED_ADMIN_WRONG_FILE_TYPE'))
        removeIndexes.push(i)
			}
    }
    const filteredFiles = files.filter((item: any, index: number) => !removeIndexes.includes(index))
    onDrop(filteredFiles)
    setMyFiles([...myFiles, ...filteredFiles])
  }, [myFiles, onDrop, t])

  const { getRootProps, getInputProps } = useDropzone({ onDrop: onFilesDrop })

  const remove = (file: any) => {
    const newFiles = [...myFiles]
    newFiles.splice(file, 1)
    onRemove(file)
    setMyFiles(newFiles)
  }
  
  const files = myFiles.map((file: any, i: any) => (
    <div className={classes.file} key={file.path}>
      <div className={classes.flex}>
        <DescriptionIcon color="primary" className={classes.fileIcon} />
        <span className={classes.fileName}>{file.path}</span>
      </div>
      <div className={classes.flex}>
        {formatBytes(file.size)}
        <HighlightOffIcon className={classes.deleteIcon} onClick={() => remove(i)} />
      </div>
    </div>
  ))

  return (
    <div className={classes.root}>
      <div className={classes.subTitle}>{t('LEOMED_ADMIN_PROFILE_PAGE_ATTACH_FILES_LABEL')}</div>
      <div {...getRootProps({ className: classes.dropzone })}>
        <input {...getInputProps()} />
        <CloudUploadIcon color="primary" className={classes.icon} />
        <div className={classes.dndText}>
          {t('LEOMED_ADMIN_DRAG_YOUR_FILES_TEXT')}
          <span className={classes.upload}>{t('LEOMED_ADMIN_UPLOAD_TEXT')}</span>
        </div>
        <div className={classes.subText}>{t('LEOMED_ADMIN_MAXIMUM_FILE_SIZE_TEXT')}</div>
        <div className={classes.subText}>{t('LEOMED_ADMIN_ACCEPTED_FORMAT_TEXT')}</div>
      </div>
      <aside>
        <ul>{files}</ul>
      </aside>
    </div>
  )
}
