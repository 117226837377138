import { Avatar, Dialog, Typography, makeStyles, Button, CircularProgress } from "@material-ui/core"
import { InputDatePicker } from "components/common/inputs/InputDatePicker"
import React, { FC, useCallback, useState } from "react"
import moment from 'moment'
import queryString from 'query-string'
import { Controller, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Colors } from "utils/theme"
import { InputTimePicker } from "components/common/inputs/InputTimePicker"
import { ConsultationRequest } from "pages/ConsultationRequests/redux/dto/ConsultationRequest"
import { useDispatch, useSelector } from "react-redux"
import { isLoading } from "utils/types/store"
import { OverlayButton } from "components/common/OverlayButton"
import { isEmpty } from "lodash"
import { useEffect } from "react"
import { getConsultationRequestsInfo, getUpdateConsultationRequestLoading } from "pages/ConsultationRequests/redux/selectors"
import { consultationRequestsActions } from "pages/ConsultationRequests/redux/actions"
import { NumberParam, useQueryParams, withDefault } from "use-query-params"
import { useLocation } from "react-router"

const useStyles = makeStyles(theme => ({
  root: {
		padding: theme.spacing(2)
	},
  title: {
    fontSize: '24px',
    padding: theme.spacing(1, 1, 0, 1),
    fontWeight: 700,
  },
  descr: {
    padding: theme.spacing(0, 1),
    fontSize: '16px',
  },
  patientWrapper: {
    borderRadius: '10px',
    backgroundColor: Colors.blue[50],
    border: '1px solid #DEEBF7',
    marginTop: theme.spacing(3),
    width: '100%',
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {},
  name: {
    marginLeft: theme.spacing(1),
    fontWeight: 700,
  },
  timeWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
  },
  timePicker: {
    width: 200,
    marginTop: '8px',
  },
  datePicker: {
    marginRight: theme.spacing(2),
  },
  at: {
    margin: theme.spacing(2, 2, 0, 0),
  },
  buttonsWrapper: {
    marginTop: theme.spacing(4),
  },
  setTimeBtn: {
    marginBottom: theme.spacing(2),
  }
}))

type ChangeTimeModalProps = {
  isOpen: boolean
  requestData: ConsultationRequest
}

type ChangeTimeData = {
  consultationDate: string
  consultationTime: string
}

export const ChangeTimeModal: FC<ChangeTimeModalProps> = ({ isOpen, requestData }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const location = useLocation()
  const [date, setDate] = useState<string>(requestData.date)
  const [time, setTime] = useState<string>(requestData.time)
  const submitting = useSelector(getUpdateConsultationRequestLoading)
  const isSubmitting = isLoading(submitting)
  const consultationRequests = useSelector(getConsultationRequestsInfo)
  const search = queryString.parse(location.search)
  const page = search?.page || 1

  // eslint-disable-next-line
  const [query, setQuery] = useQueryParams({
    page: withDefault(NumberParam, +page),
  })

  const { handleSubmit, control, setValue, setError, errors, clearErrors } = useForm<ChangeTimeData>({
		defaultValues: {},
	})

  useEffect(() => {
    setDate(requestData.date)
    setTime(requestData.time)
  }, [isOpen, requestData.date, requestData.time])

  const handleClose = useCallback(() => {
    dispatch(consultationRequestsActions.pickIdForChangeTimeDialog({ id: '' }))
    dispatch(consultationRequestsActions.pickIdForNewRequestDialog({ id: '' }))
  }, [dispatch])
  const isToday = moment(date, 'DD/MM/YYYY').format('DD/MM/YYYY') === moment().format('DD/MM/YYYY')

  useEffect(() => {
    if (isToday && moment().diff(moment(time, 'H:mm'), 'minutes') > 0) {
      setError('consultationTime', {
        message: t('LEOMED_ADMIN_CONSULTATION_TIME_ERROR'),
      })
    } else {
      clearErrors()
    }
  }, [setError, t, time, clearErrors, date, isToday])

  const selectDate = (date: string) => {
    if (moment().diff(moment(date), 'days') > 0) {
      setError('consultationDate', {
        message: t('LEOMED_ADMIN_CONSULTATION_DATE_ERROR'),
      })
    } else {
      clearErrors()
    }
    setValue('consultationDate', date)
    setDate(date)
  }

  const selectTime = useCallback(
		date => {
			setValue('consultationTime', date)
			setTime(date)
		},
		[setValue]
	)

  const handlePageChange = useCallback(
    (value: number) => {
      setQuery({ page: value })
    },
    [setQuery]
  )

  const submitTime = useCallback((values) => {
    
    if (errors.consultationDate?.message) {
      return
    }
    
    dispatch(consultationRequestsActions.updateConsultationRequest.request({
      id: requestData.id,
      date: moment(values.consultationDate, 'DD/MM/YYYY').format('DD/MM/YYYY'),
      time: moment(values.consultationTime, 'H:mm').format('H:mm'),
      callback: () => {
        if (+page > 1 && consultationRequests.items.length === 1) {
          handlePageChange?.(+page - 1)
        } else {
          dispatch(consultationRequestsActions.getConsultationRequests.request({ page: +page, limit: 10, subStatus: 'CLIENT_CREATED' }))
        }
        handleClose()
      },
      alertMessage: t('LEOMED_ADMIN_OFFICE_REQUEST_DATE_CHANGED_TITLE')
    }))
  }, [dispatch, requestData.id, handleClose, errors, t, consultationRequests.items.length, handlePageChange, page])

  if (!requestData) return null

  return (
    <Dialog open={isOpen} maxWidth="sm" onClose={handleClose} disableEnforceFocus>
      <div className={classes.root}>
        <div className={classes.title}>{`${t('LEOMED_ADMIN_OFFICE_REQUEST_TITLE')} № ${requestData.request}`}</div>
        <Typography className={classes.descr}>{`
          ${t('LEOMED_ADMIN_OFFICE_FOR_TITLE')} ${requestData.date} ${t('LEOMED_ADMIN_OFFICE_AT_TITLE')} ${requestData.time}`}
        </Typography>
        <div className={classes.patientWrapper}>
          <div className={classes.flex}>
            <Avatar src="123" className={classes.avatar} />
            <div className={classes.name}>{`${requestData.firstName} ${requestData.lastName}`}</div>
          </div>
        </div>
        <div className={classes.timeWrapper}>
          <div className={classes.datePicker}>
            <Controller
              control={control}
              name="consultationDate"
              defaultValue={date}
              rules={{ required: 'LEOMED_ADMIN_REQUIRED_FIELD_ERROR' }}
              render={props => (
                <InputDatePicker
                  selectDate={selectDate}
                  startDate={moment(date, ['DD/MM/YYYY', 'MM/DD/YYYY']) as any}
                  minDate={new Date()}
                  errorMessage={errors.consultationDate?.message}
                  views={["year", "month", "date"]}
                  {...props}
                />
              )}
            />
          </div>
          <div className={classes.at}>{t('LEOMED_ADMIN_OFFICE_AT_TITLE')}</div>
          <div className={classes.timePicker}>
            <Controller
              control={control}
              name="consultationTime"
              defaultValue={time}
              rules={{ required: 'LEOMED_ADMIN_REQUIRED_FIELD_ERROR' }}
              render={props => (
                <InputTimePicker
                  selectDate={selectTime}
                  startTime={moment(time, 'H:mm') as any}
                  errorMessage={errors.consultationTime?.message}
                  ampm={false}
                  {...props}
                />
              )}
            />
          </div>
        </div>
        <div className={classes.buttonsWrapper}>
            <OverlayButton 
              variant="contained" 
              fullWidth 
              color="primary"
              className={classes.setTimeBtn}
              onClick={handleSubmit(submitTime)}
              disabled={isSubmitting || !isEmpty(errors)}
						  overlay={isSubmitting && <CircularProgress size={24} />}
            >
              {t('LEOMED_ADMIN_OFFICE_SET_TIME_TEXT')}
            </OverlayButton>
            <Button 
              variant="outlined" 
              fullWidth
              onClick={handleClose}
            >
              {t('LEOMED_ADMIN_CANCEL_BUTTON_TEXT')}
            </Button>
          </div>
      </div>
    </Dialog>
  )
}
