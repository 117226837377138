import React, { FC, useState, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Dialog, makeStyles, Tooltip } from '@material-ui/core'
import { fade } from '@material-ui/core/styles/colorManipulator'
import DescriptionIcon from '@material-ui/icons/Description'
import SearchIcon from '@material-ui/icons/Search'
import GetAppIcon from '@material-ui/icons/GetApp'
import { Colors } from 'utils/theme'
import { useSelector } from 'react-redux'
import { getPatientInfo } from 'pages/Office/redux/selectors'
import { formatArrayToMap } from 'utils/formatArrayToMap'

import ImageGallery from 'react-image-gallery'

const useStyles = makeStyles(theme => ({
	root: {
		width: '25%',
		height: '100%',
		backgroundColor: theme.palette.background.paper,
		padding: theme.spacing(4),
		overflowY: 'auto',
	},
	title: {
		fontWeight: 700,
		fontSize: '18px',
		marginBottom: theme.spacing(2),
	},
	subTitle: {
		fontSize: '12px',
		color: Colors.grey[500],
		marginBottom: theme.spacing(1),
	},
	symptoms: {
		display: 'flex',
		width: 'fit-content',
		backgroundColor: '#FFE6E6',
		padding: theme.spacing(1, 2),
		borderRadius: '25px',
		marginBottom: theme.spacing(1),
	},
	temperature: {
		display: 'flex',
		alignItems: 'center',
		backgroundColor: theme.palette.warning.main,
		width: 'fit-content',
		borderRadius: '25px',
		color: theme.palette.background.paper,
		padding: theme.spacing(0.5, 1),
		marginBottom: theme.spacing(2),
	},
	icon: {
		width: 7,
		height: 14,
		backgroundImage: `url(${process.env.PUBLIC_URL}/temperature.svg)`,
		backgroundSize: 'cover',
		marginRight: theme.spacing(1),
	},
	wrapper: {
		paddingBottom: theme.spacing(2),
		marginBottom: theme.spacing(2),
		borderBottom: `1px solid ${Colors.blue[100]}`,
	},
	patientBlock: {
		marginBottom: theme.spacing(2),
		paddingBottom: theme.spacing(2),
		borderBottom: `1px solid ${theme.palette.divider}`,
	},
	flex: {
		display: 'flex',
		alignItems: 'center',
	},
	heightWeight: {
		display: 'flex',
		alignItems: 'center',
		marginTop: theme.spacing(2),
	},
	height: {
		marginLeft: theme.spacing(2),
	},
	patientText: {
		fontWeight: 700,
		overflowWrap: 'break-word',
	},
	patientLogo: {
		width: 50,
		height: 50,
		borderRadius: '50%',
		backgroundColor: theme.palette.divider,
		marginRight: theme.spacing(2),
	},
	images: {
		display: 'inline-flex',
		flexWrap: 'wrap',
	},
	image: {
		maxWidth: '100%',
		height: '100%',
		cursor: 'pointer',
		borderRadius: 4,
	},
	imgWrapper: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		position: 'relative',
		width: 75,
		height: 50,
		margin: theme.spacing(0, 1, 1, 0),
		'&:hover > div': {
			zIndex: 1,
		}
	},
	imgButtons: {
		position: 'absolute',
		width: '100%',
		height: '100%',
		top: 0,
		left: 0,
		zIndex: -1,
		alignItems: 'center',
		justifyContent: 'center',
		backgroundColor: fade(theme.palette.primary.main, 0.5),
		borderRadius: 4,
		display: 'flex',
		'& > svg': {
			width: '50%',
			height: '50%',
			cursor: 'pointer',
			color: theme.palette.background.paper
		}
	},
	description: {
		fontWeight: 500,
		fontSize: '12px',
	},
	fileName: {
		width: 50,
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
	file: {
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
	},
	downloadLink: {
		color: theme.palette.background.paper,
		height: 24,
	}
}))

export type Option = { value: string; label: string }
export type SlideImage = { original: string; thumbnail: string; isFile: boolean; name: string }

export const SymptomsContainer: FC = () => {
	const classes = useStyles()
	const [isOpen, setIsOpen] = useState(false)
	const [startIndex, setStartIndex] = useState(0)
	const { t } = useTranslation([
		'translation',
		'conditions',
		'diseases',
		'allergies',
		'medications',
	])

	const patientInfo = useSelector(getPatientInfo)
	const symptoms = t('conditions:CONDITIONS') as Option[]
	const diseases = t('diseases:DISEASES') as Option[]
	const allergies = t('allergies:ALLERGIES') as Option[]
	const medications = t('medications:MEDICATIONS') as Option[]
	const symptomsMap = useMemo(() => formatArrayToMap(patientInfo?.symptoms), [patientInfo])
	const diseasesMap = useMemo(() => formatArrayToMap(patientInfo?.diseases), [patientInfo])
	const allergiesMap = useMemo(() => formatArrayToMap(patientInfo?.allergies), [patientInfo])
	const medicationsMap = useMemo(() => formatArrayToMap(patientInfo?.medications), [patientInfo])

	const items: SlideImage[] = useMemo(
		() => patientInfo?.attachments.map((item: any) => ({
			original: item.url,
			thumbnail: item.url,
			isFile: item.isFile,
			name: item.name,
		})),
		[patientInfo]
	)

	const gallaryItems = items.filter(item => !item.isFile)

	const toggleOpenHandler = useCallback(
		url => {
			const item = gallaryItems.find(item => item.original === url) as SlideImage
			const index = gallaryItems.indexOf(item)
			setIsOpen(!isOpen)
			setStartIndex(index)
		},
		[isOpen, gallaryItems]
	)

	return (
		<div className={classes.root}>
			<Dialog open={isOpen} onClose={toggleOpenHandler} fullWidth={true} maxWidth="xs">
				<ImageGallery showPlayButton={false} items={gallaryItems} startIndex={startIndex} />
			</Dialog>
			<div className={classes.wrapper}>
				<div className={classes.title}>{t('LEOMED_ADMIN_DIALOG_APPEAL_TEXT')}</div>
				<div className={classes.subTitle}>{t('LEOMED_ADMIN_DIALOG_SYMPTOMS_TEXT')}</div>
				{symptoms.map(
					(item: Option) =>
						symptomsMap[item.value] && <div className={classes.symptoms}>{item.label}</div>
				)}
				{
					<div className={classes.temperature}>
						<div className={classes.icon} />
						{patientInfo.fever || ' - '}
					</div>
				}
				<div className={classes.subTitle}>{t('LEOMED_ADMIN_DIALOG_ATTACHMENTS_TEXT')}</div>
				<div className={classes.images}>
					{items.map((item) => (
						<div className={classes.imgWrapper}>
							{item.isFile ? (
								<div className={classes.file}>
									<DescriptionIcon />
									<div className={classes.fileName}>{item.name}</div>
								</div>
							) : <img
								alt="attch"
								src={item.original}
								className={classes.image}
							/>}
							<Tooltip title={item.name}>
								<div className={classes.imgButtons}>
									<a
										href={item.original}
										download
										target="_blank" 
										rel="noopener noreferrer"
										className={classes.downloadLink}
									>
										<GetAppIcon />
									</a>
									{!item.isFile && <SearchIcon onClick={() => toggleOpenHandler(item.original)} />}
								</div>
							</Tooltip>
						</div>
					))}
				</div>
			</div>
			<div className={classes.title}>{t('LEOMED_ADMIN_DIALOG_PATIENT_TEXT')}</div>
			<div className={classes.patientBlock}>
				<div className={classes.flex}>
					<div className={classes.patientLogo} />
					<div className={classes.patientText}>{patientInfo.name || ' - '}</div>
				</div>
				<div className={classes.heightWeight}>
					<div>
						<div className={classes.subTitle}>{t('LEOMED_ADMIN_HEIGHT_TEXT')}</div>
						{patientInfo.height ? (
							<div className={classes.description}>{`${patientInfo.height} ${t(
								'LEOMED_ADMIN_SANTIMETERS_TEXT'
							)}`}</div>
						) : (
							' - '
						)}
					</div>
					<div className={classes.height}>
						<div className={classes.subTitle}>{t('LEOMED_ADMIN_WEIGHT_TEXT')}</div>
						{patientInfo.weight ? (
							<div className={classes.description}>{`${patientInfo.weight} ${t(
								'LEOMED_ADMIN_KILOGRAMS_TEXT'
							)}`}</div>
						) : (
							' - '
						)}
					</div>
				</div>
			</div>
			<div className={classes.patientBlock}>
				<div className={classes.subTitle}>{t('LEOMED_ADMIN_PATIENT_CHRONIC_DISEASES')}</div>
				<div className={classes.patientText}>
					{diseases
						.filter((item: Option) => diseasesMap[item.value])
						.map((item: Option) => item.label)
						.join(', ')}
				</div>
			</div>
			<div className={classes.patientBlock}>
				<div className={classes.subTitle}>{t('LEOMED_ADMIN_PATIENT_ALLERGIES')}</div>
				<div className={classes.patientText}>
					{allergies
						.filter((item: Option) => allergiesMap[item.value])
						.map((item: Option) => item.label)
						.join(', ')}
				</div>
			</div>
			<div className={classes.patientBlock}>
				<div className={classes.subTitle}>{t('LEOMED_ADMIN_PATIENT_TAKING_MEDICATIONS')}</div>
				<div className={classes.patientText}>
					{medications
						.filter((item: Option) => medicationsMap[item.value])
						.map((item: Option) => item.label)
						.join(', ')}
				</div>
			</div>
			<div className={classes.patientBlock}>
				<div className={classes.subTitle}>{t('LEOMED_ADMIN_PATIENT_IS_SMOKING')}</div>
				<div className={classes.patientText}>{t(patientInfo.drinking) || ' - '}</div>
			</div>
		</div>
	)
}
