import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Colors } from 'utils/theme'
import { Box, Button, Divider, InputLabel, makeStyles } from '@material-ui/core'
import { Controller, useForm } from 'react-hook-form'
import uuidv4 from 'uuid/v4'
import clsx from 'clsx'

import { ProfileHeader } from '../components/ProfileHeader'
import { profileActions } from '../redux/actions'
import { EducationItem, ProfileAttachments, ProfileStep } from '../redux/model'
import { InputField } from 'components/common/inputs/InputField'
import ImagePicker from 'components/common/inputs/ImagePicker'
import useUploadFile from 'hooks/useUploadFile'
import { SelectField } from 'components/common/inputs/SelectField'
import {
	getEditId,
	getEducationItemById,
	getEducationItems,
	getProfileStep,
} from '../redux/selectors'
import { EducationBanner } from './parts/EducationBanner'
import { InputDatePicker } from 'components/common/inputs/InputDatePicker'
import { ConfirmDialog } from 'components/common/layout/ConfirmDialog'

const useStyles = makeStyles(theme => ({
	root: {
		padding: theme.spacing(3),
		borderBottom: `1px solid ${theme.palette.divider}`,
	},
	fieldsWrapper: {
		display: 'flex',
		flexDirection: 'column',
		width: '64%',
		justifyContent: 'space-between',
		marginBottom: theme.spacing(2),
	},
	field: {
		padding: theme.spacing(1),
		width: '100%',
	},
	rightField: {
		padding: theme.spacing(1),
		width: '32%',
	},
	imageWrapper: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	imageText: {
		width: '75%',
	},
	labelWrapper: {
		marginBottom: theme.spacing(2),
	},
	label: {
		color: theme.palette.text.primary,
	},
	terms: {
		padding: theme.spacing(3, 3, 0, 4),
	},
	termsLink: {
		color: theme.palette.primary.main,
		cursor: 'pointer',
		textDecoration: 'underline',
	},
	addBtnWrapper: {
		padding: theme.spacing(1),
	},
	addBtn: {
		backgroundColor: Colors.green[500],
		color: theme.palette.background.paper,
		'&:hover': {
			backgroundColor: Colors.green[500],
		},
	},
	saveBtn: {
		backgroundColor: theme.palette.primary.main,
		marginRight: theme.spacing(2),
		'&:hover': {
			backgroundColor: theme.palette.primary.main,
		},
	},
	divider: {
		marginBottom: theme.spacing(3),
	},
	wrapper: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	images: {
		display: 'flex',
		marginTop: theme.spacing(4),
		gap: '10px',
	},
}))

export const EducationContainer: FC = () => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const { t } = useTranslation(['translation', 'grades'])
	const [isConfirmOpen, setIsConfirmOpen] = useState(false)
	const [date, setDate] = useState<Date | null>(null)
	const [, setDirty] = useState(false)
	const editItemId = useSelector(getEditId)
	const profileStep = useSelector(getProfileStep)
	const isProfileDone = profileStep > ProfileStep.almost_done
	const [editId, setEditId] = useState<string | undefined>(editItemId as string)
	const [deleteId, setDeleteId] = useState('')
	const educationItemId = useSelector(getEducationItemById(editId || ''))
	const {
		onFileChange,
		selectedFiles,
		setSelectedFiles,
		handleDelete,
		formData,
		filesForFormData,
		setFilesForFormData,
	} = useUploadFile({
		name: 'education',
		editId,
	})

	const { register, handleSubmit, errors, control, setValue, reset } = useForm<EducationItem>({
		defaultValues: {
			periodOfStudy: new Date(),
		},
	})
	const educationItems = useSelector(getEducationItems)
	const gradesOptions = t('grades:GRADES') as any[]

	const editItem = useCallback(
		(itemId: string) => {
			setEditId(itemId)
			const item = educationItems.find(({ id }) => id === itemId)
			reset(item)
			setDate(item?.periodOfStudy as Date)
		},
		[educationItems, reset]
	)

	const toggleConfirm = useCallback(() => {
		setIsConfirmOpen(!isConfirmOpen)
	}, [isConfirmOpen])

	const handleConfirm = useCallback(() => {
		dispatch(
			profileActions.deleteBanner.request({
				categoryId: deleteId,
				name: ProfileAttachments.education,
			})
		)
		setIsConfirmOpen(false)
	}, [deleteId, dispatch])

	const deleteBanner = useCallback(
		(itemId: string) => {
			setDeleteId(itemId)
			setIsConfirmOpen(!isConfirmOpen)
		},
		[isConfirmOpen]
	)

	useEffect(() => {
		if (editItemId) {
			editItem(editItemId as string)
		}
	}, [editItem, editItemId])

	const onSubmit = useCallback(() => {
		dispatch(
			profileActions.updateProfile.request({
				step: ProfileStep.specialization,
			})
		)
	}, [dispatch])

	const resetHandler = useCallback(() => {
		reset({
			currentPlaceOfWork: '',
			department: '',
			specialty: '',
			gradeLevel: '',
		})
		setDate(new Date())
		setDirty(false)
		setEditId(undefined)
		setSelectedFiles([])
		setFilesForFormData([])
	}, [reset, setFilesForFormData, setSelectedFiles])

	const addHandler = useCallback(
		values => {
			filesForFormData.forEach(item => {
				formData?.append('education', item, item.name)
			})

			dispatch(
				profileActions.addEducationItem({
					educationItem: {
						...values,
						periodOfStudy: values.periodOfStudy,
						documents: selectedFiles.map((item: any) => ({ url: item })),
						attachments: formData,
						id: editId || uuidv4(),
					},
				})
			)
			if (editItemId) {
				dispatch(profileActions.editItemById({ itemId: null }))
				dispatch(profileActions.setProfileStep({ step: ProfileStep.almost_done }))
			}
			resetHandler()
		},
		[
			dispatch,
			editId,
			editItemId,
			filesForFormData,
			formData,
			resetHandler,
			selectedFiles,
		]
	)

	const onItemDelete = useCallback(
		id => {
			handleDelete(id)

			if (editId && educationItemId?.documents.length > 0 && educationItemId?.documents[id - 1]) {
				const fileId = educationItemId?.documents[id - 1].id
				const categoryId = editId
				dispatch(
					profileActions.deleteAttachment.request({
						categoryId,
						fileId,
						name: ProfileAttachments.education,
					})
				)
			}
		},
		[dispatch, editId, educationItemId, handleDelete]
	)

	const selectDate = useCallback(
		date => {
			setValue('periodOfStudy', date)
			setDate(date)
		},
		[setValue]
	)

	const cancelHandler = useCallback(
		errors => {
			if (editItemId) {
				dispatch(profileActions.editItemById({ itemId: null }))
				dispatch(profileActions.setProfileStep({ step: ProfileStep.almost_done }))
			}
			resetHandler()
		},
		[dispatch, editItemId, resetHandler]
	)

	const educationBanners = useMemo(
		() => (
			<>
				{educationItems.map(item => (
					<EducationBanner
						key={item.id}
						item={item}
						editItem={editItem}
						deleteBanner={educationItems.length > 1 ? deleteBanner : undefined}
					/>
				))}
				{educationItems.length > 0 && <Divider className={classes.divider} />}
			</>
		),
		[classes.divider, editItem, educationItems, deleteBanner]
	)

	const addBtnStyles = clsx(classes.addBtn, {
		[classes.saveBtn]: editId,
	})

	return (
		<div>
			<ProfileHeader
				title={t('LEOMED_ADMIN_PROFILE_PAGE_EDUCATION_TITLE')}
				buttonText={isProfileDone ? '' : t('LEOMED_ADMIN_NEXT_BUTTON_TEXT')}
				onSubmit={onSubmit}
				disabled={educationItems.length === 0 || !!editItemId}
				noBack={!!editItemId}
			/>
			<div className={classes.root}>
				{!editId && educationBanners}
				<div className={classes.wrapper}>
					<div className={classes.fieldsWrapper}>
						<div className={classes.field}>
							<InputField
								variant="outlined"
								name="currentPlaceOfWork"
								label={t('LEOMED_ADMIN_PROFILE_PAGE_EDUCATION_PLACE_LABEL')}
								placeholder={t('LEOMED_ADMIN_PROFILE_PAGE_EDUCATION_PLACE_PLACEHOLDER')}
								inputRef={register({
									required: 'LEOMED_ADMIN_REQUIRED_FIELD_ERROR',
									validate: value => value.trim() !== '',
								})}
								error={!!errors.currentPlaceOfWork}
								helperText={t(errors.currentPlaceOfWork?.message as string)}
							/>
						</div>
						<div className={classes.field}>
							<Controller
								control={control}
								name="periodOfStudy"
								defaultValue=""
								rules={{ required: 'LEOMED_ADMIN_REQUIRED_FIELD_ERROR' }}
								render={props => (
									<InputDatePicker
										selectDate={selectDate}
										startDate={date}
										maxDate={new Date('2021-12-31')}
										views={['year']}
										label={t('LEOMED_ADMIN_PROFILE_PAGE_GRADUATE_YEAR_LABEL')}
										{...props}
									/>
								)}
							/>
						</div>
						<div className={classes.field}>
							<InputField
								variant="outlined"
								name="department"
								label={t('LEOMED_ADMIN_PROFILE_PAGE_FACULTY_LABEL')}
								placeholder={t('LEOMED_ADMIN_PROFILE_PAGE_FACULTY_PLACEHOLDER')}
								inputRef={register({
									required: 'LEOMED_ADMIN_REQUIRED_FIELD_ERROR',
									validate: value => value.trim() !== '',
								})}
								error={!!errors.department}
								helperText={t(errors.department?.message as string)}
							/>
						</div>
						<div className={classes.field}>
							<InputField
								variant="outlined"
								name="specialty"
								label={t('LEOMED_ADMIN_PROFILE_PAGE_SPECIALIZATION_LABEL')}
								placeholder={t('LEOMED_ADMIN_PROFILE_PAGE_SPECIALIZATION_PLACEHOLDER')}
								inputRef={register({
									required: 'LEOMED_ADMIN_REQUIRED_FIELD_ERROR',
									validate: value => value.trim() !== '',
								})}
								error={!!errors.specialty}
								helperText={t(errors.specialty?.message as string)}
							/>
						</div>
						<div className={classes.field}>
							<Controller
								control={control}
								name="gradeLevel"
								defaultValue="specialist"
								rules={{ required: 'LEOMED_ADMIN_REQUIRED_FIELD_ERROR' }}
								as={
									<SelectField
										label={t('LEOMED_ADMIN_PROFILE_PAGE_QUALIFICATION_LABEL')}
										options={gradesOptions}
										error={!!errors.gradeLevel}
										errorMessage={t(errors.gradeLevel?.message as string)}
									/>
								}
							/>
						</div>
					</div>
					<div className={classes.rightField}>
						<Box className={classes.labelWrapper}>
							<InputLabel className={classes.label}>
								{t('LEOMED_ADMIN_PROFILE_PAGE_ATTACH_FILES_LABEL')}
							</InputLabel>
							{/* {errorMessage && <ErrorTooltip text={t(errorMessage)} />} */}
						</Box>
						<div className={classes.imageWrapper}>
							{selectedFiles.length < 3 && (
								<ImagePicker onChange={onFileChange} id={6} count={selectedFiles.length} multiple />
							)}
							<div className={classes.imageText}>{t('LEOMED_ADMIN_ADD_FILE_EDUCATION_PAGE')}</div>
						</div>
						<div className={classes.images}>
							{selectedFiles.map((item, index) => (
								<ImagePicker
									key={index}
									img={item.url}
									mimetype={item.type}
									onChange={onFileChange}
									handleDelete={onItemDelete}
									id={index + 1}
								/>
							))}
						</div>
					</div>
				</div>
				<div className={classes.addBtnWrapper}>
					<Button
						className={addBtnStyles}
						variant="contained"
						onClick={handleSubmit(addHandler /*, onError*/)}
					>
						{t(editId ? 'LEOMED_ADMIN_SAVE_BUTTON_TEXT' : 'LEOMED_ADMIN_PROFILE_PAGE_ADD_LABEL')}
					</Button>
					{editId && (
						<Button variant="contained" onClick={cancelHandler}>
							{t('LEOMED_ADMIN_CANCEL_BUTTON_TEXT')}
						</Button>
					)}
				</div>
			</div>
			<div className={classes.terms}>
				{t('LEOMED_ADMIN_PROFILE_PAGE_AGREE_DESCRIPTION')}
				<span className={classes.termsLink}>
					{t('LEOMED_ADMIN_PROFILE_PAGE_AGREE_DESCRIPTION_TWO')}
				</span>
			</div>
			<ConfirmDialog
				open={isConfirmOpen}
				handleClose={toggleConfirm}
				handleConfirm={handleConfirm}
				title={t('LEOMED_ADMIN_DELETE_BANNER_CONFIRM_TITLE')}
				cancelText={t('LEOMED_ADMIN_NO_TEXT')}
				acceptText={t('LEOMED_ADMIN_YES_TEXT')}
			/>
		</div>
	)
}
