import { Avatar, makeStyles } from '@material-ui/core'
import React, { FC } from 'react'
import { Colors } from 'utils/theme'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { ConsultationRequest } from 'pages/ConsultationRequests/redux/dto/ConsultationRequest'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    border: `1px solid ${Colors.blue[50]}`,
    borderRadius: '10px',
    marginBottom: theme.spacing(2),
    '&:hover': {
      backgroundColor: '#F3F7FC',
    }
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
    flex: 1.5,
  },
  flex1: {
    flex: 1,
  },
  name: {
    marginLeft: theme.spacing(2),
    fontWeight: 700,
    width: 175,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  subtitle: {
    fontSize: '12px',
    color: Colors.blue[900],
  },
  avatar: {
    width: 48,
    height: 48,
    color: theme.palette.background.paper,
  },
  buttonsWrapper: {
    width: 140,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end'
  },
  button: {
    width: 92,
    height: 20,
    fontSize: '12px',
    textTransform: 'none'
  },
  acceptBtn: {
    backgroundColor: Colors.green[500],
    color: theme.palette.background.paper,
    marginBottom: theme.spacing(1),
    '&:hover': {
      backgroundColor: Colors.green[500],
    }
  },
  cancelBtn: {
    borderColor: Colors.red[500],
    color: Colors.red[500],
    marginBottom: theme.spacing(1),
  },
  expiredText: {
    fontWeight: 700,
    color: Colors.green[500],
  },
  changeBtn: {
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: 'transparent',
    }
  },
  basicStatus: {
    color: theme.palette.background.paper,
    borderRadius: '15px',
    padding: theme.spacing(0.5, 1),
    fontSize: '12px',
    width: 'max-content'
  },
  acceptedStatus: {
    backgroundColor: Colors.green[500]
  },
  pendingStatus: {
    backgroundColor: Colors.orange[500]
  },
  rejectedStatus: {
    backgroundColor: Colors.red[500]
  },
  awaitingStatus: {
    backgroundColor: Colors.orange[900]
  },
  expiredStatus: {
    backgroundColor: Colors.grey[500],
  }
}))

type MyScheduleRowProps = {
  data: ConsultationRequest
}

export const MyScheduleRow: FC<MyScheduleRowProps> = ({ data }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  if (!data) return null

  const date = `${data.date} ${t('LEOMED_ADMIN_OFFICE_ON_TITLE')} ${data.time}`

  const statusClassName = clsx(classes.basicStatus, {
    [classes.acceptedStatus]: data.status === 'APPROVED',
    [classes.pendingStatus]: data.status === 'PENDING',
    [classes.rejectedStatus]: data.status === 'REJECTED',
    [classes.awaitingStatus]: data.subStatus === 'DOCTOR_UPDATED',
    [classes.expiredStatus]: data.isExpired,
  })

  return (
    <>
      <div className={classes.root}>
        <div className={classes.flex}>
          <Avatar src={data.avatar} className={classes.avatar} />
          <div className={classes.name}>{`${data.firstName} ${data.lastName}`}</div>
        </div>
        <div className={classes.flex1}>
          <div className={classes.subtitle}>{`${t('LEOMED_ADMIN_OFFICE_REQUEST_TITLE')} №`}</div>
          <div>{data.request}</div>
        </div>
        <div className={classes.flex1}>
          <div className={classes.subtitle}>{t('LEOMED_ADMIN_OFFICE_DATE_TITLE')}</div>
          <div>{date}</div>
        </div>
        <div className={classes.flex1}>
          <div className={classes.subtitle}>{t('LEOMED_ADMIN_OFFICE_PAGE_CONSULTATIONS_TITLE')}</div>
          <div>{data.consultationN}</div>
        </div>
        <div className={classes.buttonsWrapper}>
          <div className={statusClassName}>
            {data.isExpired ? t('LEOMED_ADMIN_OFFICE_EXPIRED_TEXT') : t(`${data.subStatus === 'DOCTOR_UPDATED' ? data.subStatus : data.status}`)}
          </div>
        </div>
      </div>
    </>
  )
}
