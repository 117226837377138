import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Colors } from 'utils/theme'
import {
	Box,
	Button,
	Checkbox,
	Divider,
	FormControlLabel,
	InputLabel,
	makeStyles,
	Typography,
} from '@material-ui/core'
import { Controller, useForm } from 'react-hook-form'
import uuidv4 from 'uuid/v4'
import clsx from 'clsx'

import { ProfileHeader } from '../components/ProfileHeader'
import { profileActions } from '../redux/actions'
import { ExperienceItem, ProfileAttachments, ProfileStep } from '../redux/model'
import { InputField } from 'components/common/inputs/InputField'
import ImagePicker from 'components/common/inputs/ImagePicker'
import useUploadFile from 'hooks/useUploadFile'
import {
	getEditId,
	getExperienceItems,
	getProfileStep,
	getExperienceItemById,
} from '../redux/selectors'
import { InputDatePicker } from 'components/common/inputs/InputDatePicker'
import { ExperienceBanner } from './parts/ExperienceBanner'
import { ConfirmDialog } from 'components/common/layout/ConfirmDialog'

const useStyles = makeStyles(theme => ({
	root: {
		padding: theme.spacing(3),
		borderBottom: `1px solid ${theme.palette.divider}`,
	},
	fieldsWrapper: {
		display: 'flex',
		flexDirection: 'column',
		width: '64%',
		justifyContent: 'space-between',
		marginBottom: theme.spacing(2),
	},
	field: {
		padding: theme.spacing(1),
		width: '100%',
	},
	singleDateField: {
		padding: theme.spacing(1),
		width: '48%',
	},
	rightField: {
		padding: theme.spacing(1),
		width: '32%',
	},
	imageWrapper: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	imageText: {
		width: '75%',
	},
	labelWrapper: {
		marginBottom: theme.spacing(2),
	},
	label: {
		color: theme.palette.text.primary,
	},
	terms: {
		padding: theme.spacing(3, 3, 0, 4),
	},
	termsLink: {
		color: theme.palette.primary.main,
		cursor: 'pointer',
		textDecoration: 'underline',
	},
	addBtnWrapper: {
		padding: theme.spacing(1),
	},
	addBtn: {
		backgroundColor: Colors.green[500],
		color: theme.palette.background.paper,
		'&:hover': {
			backgroundColor: Colors.green[500],
		},
	},
	saveBtn: {
		backgroundColor: theme.palette.primary.main,
		marginRight: theme.spacing(2),
		'&:hover': {
			backgroundColor: theme.palette.primary.main,
		},
	},
	divider: {
		marginBottom: theme.spacing(3),
	},
	addTempCheck: {
		marginBottom: theme.spacing(2),
	},
	wrapper: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	images: {
		display: 'flex',
		marginTop: theme.spacing(4),
		gap: '10px',
	},
}))

export const ExperienceContainer: FC = () => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const [start, setStart] = useState<Date | null>(new Date())
	const [end, setEnd] = useState<Date | null>(new Date())
	const [isConfirmOpen, setIsConfirmOpen] = useState(false)
	const [deleteId, setDeleteId] = useState('')
	const editItemId = useSelector(getEditId)
	const [editId, setEditId] = useState<string | undefined>(editItemId as string)
	const [tillNow, setTillNow] = useState(false)
	const {
		onFileChange,
		selectedFiles,
		handleDelete,
		formData,
		setSelectedFiles,
		filesForFormData,
		setFilesForFormData,
	} = useUploadFile({
		name: 'employmentHistory',
		editId,
	})
	const profileStep = useSelector(getProfileStep)
	const experienceItemId = useSelector(getExperienceItemById(editId || ''))
	const isProfileDone = profileStep > ProfileStep.almost_done
	const { register, handleSubmit, errors, control, setValue, reset } = useForm<ExperienceItem>()
	const experienceItems = useSelector(getExperienceItems)

	const editItem = useCallback(
		(itemId: string) => {
			setEditId(itemId)
			const item = experienceItems.find(({ id }) => id === itemId)
			setStart(item?.periodOfWork.from as Date)
			setEnd(item?.periodOfWork.to as Date)
			reset({
				...item,
				periodOfWork: { from: item?.periodOfWork.from, to: item?.periodOfWork.to } as any,
			})
			setTillNow(item?.workingNow as boolean)
		},
		[experienceItems, reset]
	)

	const toggleConfirm = useCallback(() => {
		setIsConfirmOpen(!isConfirmOpen)
	}, [isConfirmOpen])

	const handleConfirm = useCallback(() => {
		dispatch(
			profileActions.deleteBanner.request({
				categoryId: deleteId,
				name: ProfileAttachments.employmentHistory,
			})
		)
		setIsConfirmOpen(false)
	}, [deleteId, dispatch])

	const deleteBanner = useCallback(
		(itemId: string) => {
			setDeleteId(itemId)
			setIsConfirmOpen(!isConfirmOpen)
		},
		[isConfirmOpen]
	)

	useEffect(() => {
		if (editItemId) {
			editItem(editItemId as string)
		}
	}, [editId, editItem, editItemId])

	const onSubmit = useCallback(() => {
		dispatch(
			profileActions.updateProfile.request({
				step: ProfileStep.almost_done,
			})
		)
	}, [dispatch])

	const tillNowHandler = useCallback(() => {
		setTillNow(!tillNow)
		setValue('workingNow', !tillNow)
		setValue('start', start as Date)
		setEnd(null)
	}, [setValue, start, tillNow])

	const resetHandler = useCallback(() => {
		reset({
			title: '',
			address: '',
		})
		setStart(new Date())
		setEnd(new Date())
		setValue('start', '')
		setValue('end', '')
		setValue('workingNow', false)
		setTillNow(false)
		setEditId(undefined)
		setSelectedFiles([])
		setFilesForFormData([])
	}, [reset, setValue, setSelectedFiles, setFilesForFormData])

	const addHandler = useCallback(
		values => {

			filesForFormData.forEach(item => {
				formData?.append('employmentHistory', item, item.name)
			})

			dispatch(
				profileActions.addExperienceItem({
					experienceItem: {
						...values,
						periodOfWork: {
							from: values.start || new Date(),
							to: values.end || values.workingNow ? values.end : new Date(),
						},
						documents: selectedFiles.map((item: any) => ({ url: item })),
						attachments: formData,
						id: editId || uuidv4(),
					},
				})
			)
			if (editItemId) {
				dispatch(profileActions.editItemById({ itemId: null }))
				dispatch(profileActions.setProfileStep({ step: ProfileStep.almost_done }))
			}
			resetHandler()
		},
		[
			dispatch,
			editId,
			editItemId,
			filesForFormData,
			formData,
			resetHandler,
			selectedFiles,
		]
	)

	const selectStartDate = useCallback(
		date => {
			setValue('start', date)
			setStart(date)
		},
		[setValue]
	)

	const selectEndDate = useCallback(
		date => {
			setValue('end', date)
			setEnd(date)
		},
		[setValue]
	)

	const cancelHandler = useCallback(
		errors => {
			if (editItemId) {
				dispatch(profileActions.editItemById({ itemId: null }))
				dispatch(profileActions.setProfileStep({ step: ProfileStep.almost_done }))
			}
			resetHandler()
		},
		[dispatch, editItemId, resetHandler]
	)

	const onItemDelete = useCallback(
		id => {
			handleDelete(id)

			if (editId && experienceItemId?.documents.length > 0 && experienceItemId?.documents[id - 1]) {
				const fileId = experienceItemId?.documents[id - 1].id
				const categoryId = editId
				dispatch(
					profileActions.deleteAttachment.request({
						categoryId,
						fileId,
						name: ProfileAttachments.employmentHistory,
					})
				)
			}
		},
		[dispatch, editId, experienceItemId, handleDelete]
	)

	const experienceBanners = useMemo(
		() => (
			<>
				{experienceItems.map(item => (
					<ExperienceBanner
						key={item.id}
						item={item}
						editItem={editItem}
						deleteBanner={experienceItems.length > 1 ? deleteBanner : undefined}
					/>
				))}
				{experienceItems.length > 0 && <Divider className={classes.divider} />}
			</>
		),
		[classes.divider, deleteBanner, editItem, experienceItems]
	)

	const addBtnStyles = clsx(classes.addBtn, {
		[classes.saveBtn]: editId,
	})

	return (
		<div>
			<ProfileHeader
				title={t('LEOMED_ADMIN_PROFILE_PAGE_WORK_EXPERIENCE_TITLE')}
				buttonText={isProfileDone ? '' : t('LEOMED_ADMIN_NEXT_BUTTON_TEXT')}
				onSubmit={onSubmit}
				disabled={experienceItems.length === 0 || !!editItemId}
				noBack={!!editItemId}
			/>
			<div className={classes.root}>
				{!editId && experienceBanners}
				<div className={classes.wrapper}>
					<div className={classes.fieldsWrapper}>
						<div className={classes.field}>
							<InputField
								variant="outlined"
								name="title"
								label={t('LEOMED_ADMIN_PROFILE_PAGE_WORK_PLACE_LABEL')}
								placeholder={t('LEOMED_ADMIN_PROFILE_PAGE_WORK_PLACE_PLACEHOLDER')}
								inputRef={register({
									required: 'LEOMED_ADMIN_REQUIRED_FIELD_ERROR',
									validate: value => value.trim() !== '',
								})}
								error={!!errors.title}
								helperText={t(errors.title?.message as string)}
							/>
						</div>
						<div className={classes.field}>
							<InputField
								variant="outlined"
								name="address"
								label={t('LEOMED_ADMIN_PROFILE_PAGE_ADDRESS_LABEL')}
								placeholder={t('LEOMED_ADMIN_PROFILE_PAGE_ADDRESS_PLACEHOLDER')}
								inputRef={register({
									required: 'LEOMED_ADMIN_REQUIRED_FIELD_ERROR',
									validate: value => value.trim() !== '',
								})}
								error={!!errors.address}
								helperText={t(errors.address?.message as string)}
							/>
						</div>
						<div className={classes.wrapper}>
							<div className={classes.singleDateField}>
								<Controller
									control={control}
									name="start"
									defaultValue=""
									render={props => (
										<InputDatePicker
											selectDate={selectStartDate}
											startDate={start}
											disableFuture
											maxDate={end}
											label={t('LEOMED_ADMIN_PROFILE_PAGE_WORK_PERIOD_LABEL_START')}
											{...props}
										/>
									)}
								/>
							</div>
							{!tillNow && (
								<div className={classes.singleDateField}>
									<Controller
										control={control}
										name="end"
										defaultValue=""
										render={props => (
											<InputDatePicker
												selectDate={selectEndDate}
												startDate={end}
												minDate={start}
												disableFuture
												label={t('LEOMED_ADMIN_PROFILE_PAGE_WORK_PERIOD_LABEL_END')}
												{...props}
											/>
										)}
									/>
								</div>
							)}
						</div>
						<div className={classes.field}>
							<FormControlLabel
								control={
									<Controller
										name="workingNow"
										control={control}
										defaultValue={tillNow}
										render={() => (
											<Checkbox
												onChange={tillNowHandler}
												color="primary"
												disabled={!start}
												checked={tillNow}
											/>
										)}
									/>
								}
								label={<Typography>{t('LEOMED_ADMIN_PROFILE_PAGE_STILL_WORKS_LABEL')}</Typography>}
								className={classes.addTempCheck}
							/>
						</div>
						<div className={classes.addBtnWrapper}>
							<Button
								className={addBtnStyles}
								variant="contained"
								onClick={handleSubmit(addHandler)}
							>
								{t(
									editId ? 'LEOMED_ADMIN_SAVE_BUTTON_TEXT' : 'LEOMED_ADMIN_PROFILE_PAGE_ADD_LABEL'
								)}
							</Button>
							{editId && (
								<Button variant="contained" onClick={cancelHandler}>
									{t('LEOMED_ADMIN_CANCEL_BUTTON_TEXT')}
								</Button>
							)}
						</div>
					</div>
					<div className={classes.rightField}>
						<Box className={classes.labelWrapper}>
							<InputLabel className={classes.label}>
								{t('LEOMED_ADMIN_PROFILE_PAGE_ATTACH_FILES_LABEL')}
							</InputLabel>
							{/* {errorMessage && <ErrorTooltip text={t(errorMessage)} />} */}
						</Box>
						<div className={classes.imageWrapper}>
							{selectedFiles.length < 3 && (
								<ImagePicker onChange={onFileChange} id={6} count={selectedFiles.length} multiple />
							)}
							<div className={classes.imageText}>{t('LEOMED_ADMIN_ADD_FILE_EXPERIENCE_PAGE')}</div>
						</div>
						<div className={classes.images}>
							{selectedFiles.map((item, index) => (
								<ImagePicker
									key={index}
									img={item.url}
									mimetype={item.type}
									onChange={onFileChange}
									handleDelete={onItemDelete}
									id={index + 1}
								/>
							))}
						</div>
					</div>
				</div>
			</div>
			<div className={classes.terms}>
				{t('LEOMED_ADMIN_PROFILE_PAGE_AGREE_DESCRIPTION')}
				<span className={classes.termsLink}>
					{t('LEOMED_ADMIN_PROFILE_PAGE_AGREE_DESCRIPTION_TWO')}
				</span>
			</div>
			<ConfirmDialog
				open={isConfirmOpen}
				handleClose={toggleConfirm}
				handleConfirm={handleConfirm}
				title={t('LEOMED_ADMIN_DELETE_BANNER_CONFIRM_TITLE')}
				cancelText={t('LEOMED_ADMIN_NO_TEXT')}
				acceptText={t('LEOMED_ADMIN_YES_TEXT')}
			/>
		</div>
	)
}
