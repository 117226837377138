import { makeStyles, Paper } from '@material-ui/core'
import { ConsultationRequestsList } from 'pages/ConsultationRequests/components/ConsultationRequests/ConsultationRequestsList'
import React, { FC, useCallback, useEffect, useMemo } from 'react'
import { NumberParam, useQueryParams, withDefault } from 'use-query-params'
import { useDispatch } from 'react-redux'
import queryString from 'query-string'
import { useConsultationsRequests } from '../hooks/useConsultationsRequests'
import { useLocation } from 'react-router'

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(4),
    paddingTop: theme.spacing(2),
  },
  title: {
    fontSize: '24px',
    fontWeight: 700,
    padding: theme.spacing(2)
  },
}))

export const filterByStatusMap: { [key: string]: boolean } = {
  'CLIENT_CREATED': true,
  'CLIENT_UPDATED': true,
}

export const ConsultationRequestsContainer: FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const location = useLocation()
  const { load, loading, items, limit } = useConsultationsRequests()
  const search = queryString.parse(location.search)
  const page = search?.page || 1

  const [query, setQuery] = useQueryParams({
    page: withDefault(NumberParam, +page),
  })

  const filteredRequests = useMemo(() => 
    items
      .filter(item => filterByStatusMap[item?.subStatus])
    , [items])

  useEffect(() => {
    load({
      page: query.page,
      limit,
      subStatus: 'CLIENT_CREATED',
    })
  }, [dispatch, query.page, limit, load])

  const handlePageChange = useCallback(
    (value: number) => {
      setQuery({ page: value })
    },
    [setQuery]
  )

  return (
    <Paper className={classes.root}>
      <ConsultationRequestsList
        requests={filteredRequests}
        loading={loading}
        handlePageChange={handlePageChange}
      />
    </Paper>
  )
}
