export const toEntityIds = (entity = []) =>
	entity.filter((item: any) => item.id).map((item: any) => item.id)

export const toEntityItems = (entity = []) =>
	entity
		.filter((item: any) => item.id)
		.reduce((acc: any, item: any) => {
			acc[item.id] = item
			return acc
		}, {})
