import { makeStyles, Typography } from '@material-ui/core'
import React, { FC, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { ProfileSteps } from './components/ProfileSteps'
import { AlmostDoneContainer } from './containers/AlmostDoneContainer'
import { EducationContainer } from './containers/EducationContainer'
import { ExperienceContainer } from './containers/ExperienceContainer'
import { PersonalContainer } from './containers/PersonalContainer'
import { SpecializationContainer } from './containers/SpecializationContainer'
import { useProfileFlow } from './hooks/useProfileFlow'
import { ProfileStep } from './redux/model'
import { ProfileContext } from './ProfileContext'
import { getProfileLoading, updateProfileLoading } from './redux/selectors'
import { isLoading } from 'utils/types/store'
import { OverlayContainer } from 'components/common/OverlayContainer'
import { ProfileContainer } from './containers/ProfileContainer'
import { consultationRequestsActions } from 'pages/ConsultationRequests/redux/actions'

export const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		marginTop: theme.spacing(14),
	},
	title: {
		fontSize: '24px',
		fontWeight: 700,
		marginBottom: theme.spacing(3),
	},
	wrapper: {
		position: 'relative',
		padding: theme.spacing(0, 0, 4, 0),
		backgroundColor: theme.palette.background.paper,
		height: '100%',
	},
	description: {
		color: theme.palette.primary.light,
		fontSize: '32px',
		marginBottom: theme.spacing(4),
	},
	loadingContainer: {
		paddingTop: theme.spacing(6),
		minHeight: '800px',
		position: 'relative',
	},
}))

export const ProfilePage: FC = () => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const updateProfileSubmitting = useSelector(updateProfileLoading)
	const isUpdateProfileSubmitting = isLoading(updateProfileSubmitting)
	const submitting = useSelector(getProfileLoading)
	const isSubmitting = isLoading(submitting)
	const { step, setStep, previousStep, nextStep, finished } = useProfileFlow()

	useEffect(() => {
    dispatch(consultationRequestsActions.getConsultationRequests.request({ page: 1, limit: 10, subStatus: 'CLIENT_CREATED' }))
  }, [dispatch])

	const stepContent = useMemo(() => {
		switch (step) {
			case ProfileStep.personal:
				return <PersonalContainer />

			case ProfileStep.education:
				return <EducationContainer />

			case ProfileStep.specialization:
				return <SpecializationContainer />

			case ProfileStep.experience:
				return <ExperienceContainer />

			case ProfileStep.almost_done:
				return <AlmostDoneContainer />
			case ProfileStep.firstStart:
				return <ProfileContainer />
			case ProfileStep.done:
				return <ProfileContainer />
			default:
				return null
		}
	}, [step])

	if (isSubmitting)
		return (
			<div className={classes.loadingContainer}>
				<OverlayContainer />
			</div>
		)

	return (
		<div className={classes.root}>
			<Typography className={classes.title}>
				{t(finished ? 'LEOMED_ADMIN_PROFILE_PAGE_TITLE' : 'LEOMED_ADMIN_NEW_DOCTOR_TEXT')}
			</Typography>
			<ProfileContext.Provider
				value={{
					step,
					setStep,
					previousStep,
					nextStep,
				}}
			>
				{!finished && <ProfileSteps />}
				<div className={classes.wrapper}>
					{isUpdateProfileSubmitting && <OverlayContainer />}
					{stepContent}
				</div>
			</ProfileContext.Provider>
		</div>
	)
}
