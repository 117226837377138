import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useAuth0 } from '@auth0/auth0-react'

import { authActions } from 'pages/Auth/redux/actions'
import { getAccessToken } from 'pages/Auth//redux/selectors'

export const useAuthorization = () => {
	const dispatch = useDispatch()
	const {
		isAuthenticated,
		isLoading,
		loginWithRedirect,
		logout,
		getAccessTokenSilently,
	} = useAuth0()
	const accessToken = useSelector(getAccessToken)

	const loginHandler = useCallback(() => {
		loginWithRedirect()
	}, [loginWithRedirect])

	const logoutHandler = useCallback(() => {
		dispatch(authActions.logout.request({ callback: logout }))
	}, [dispatch, logout])

	const storeTokenHandler = useCallback(async () => {
		let accessToken

		try {
			accessToken = await getAccessTokenSilently({
				audience: 'http://localhost:7500',
			})

			if (accessToken) {
				dispatch(authActions.storeToken({ token: accessToken }))
			}
		} catch (e) {
			console.log(e)
		}
	}, [dispatch, getAccessTokenSilently])

	return {
		isAuthenticated,
		storeTokenHandler,
		isLoading: isLoading || !accessToken,
		loginHandler,
		logoutHandler,
	}
}
