import React, { FC, useMemo, useCallback } from 'react'
import { Drawer, Hidden, IconButton, Avatar } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import MenuIcon from '@material-ui/icons/Menu'
import clsx from 'clsx'

import { DrawerMenu } from 'layouts/parts/DrawerMenu'
import { useSelector } from 'react-redux'
import { ProfileStep } from 'pages/Profile/redux/model'
import { getPersonalInfo, getProfileStep } from 'pages/Profile/redux/selectors'

const drawerWidth = 260
const mobileDrawerWidth = 240

const useStyles = makeStyles((theme) => ({
	mobileNav: {
		padding: theme.spacing(2),
	},
	menuButton: {
		width: '40px',
		height: '40px',
		[theme.breakpoints.up('sm')]: {
			display: 'none',
		},
	},
	cancelButton: {
		width: '40px',
		height: '40px',
		position: 'absolute',
		top: '50%',
		right: '10px',
		[theme.breakpoints.up('sm')]: {
			display: 'none',
		},
	},
	drawer: {
		[theme.breakpoints.up('xs')]: {
			width: drawerWidth,
			flexShrink: 0,
		},
	},
	menuClosed: {
		width: 72,
	},
	drawerPaper: {
		width: drawerWidth,
		borderRight: 'none',
		[theme.breakpoints.down('xs')]: {
			width: mobileDrawerWidth,
		},
		top: '82px',
    height: 'calc(100% - 82px)',
		padding: theme.spacing(3, 0, 2, 0)
	},
	menuClosedDrawerPaper: {
		width: 72,
	},
	emailWrapper: {
		display: 'flex',
		flexDirection: 'column',
		margin: theme.spacing(0, 2),
	},
	email: {
		fontWeight: 600,
		fontSize: 16
	},
	profileWrapper: {
		display: 'flex',
		alignItems: 'center',
		padding: theme.spacing(0, 2, 3, 2),
		borderBottom: `1px solid ${theme.palette.divider}`,
		marginBottom: theme.spacing(1),
	},
	logoContainer: {
		display: 'inline-flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		textDecoration: 'none',
		padding: theme.spacing(5, 3, 20, 0),
		color: theme.palette.text.primary,
		[theme.breakpoints.down('xs')]: {
			display: 'none',
		},
	},
	logoName: {
		marginLeft: theme.spacing(3),
		fontWeight: 600,
	},

	menuFooter: { padding: theme.spacing(1) },
}))

type DrawerLayoutProps = {
	isMenuOpen: boolean
}

export const DrawerLayout: FC<DrawerLayoutProps> = ({ isMenuOpen }) => {
	const classes = useStyles()
	const theme = useTheme()
	const profile = useSelector(getPersonalInfo)
	const profileStep = useSelector(getProfileStep)
	const isProfileFinished = profileStep > ProfileStep.almost_done
	const [mobileOpen, setMobileOpen] = React.useState(false)

	const drawerStyles = clsx(classes.drawer, {
		[classes.menuClosed]: !isMenuOpen
	})

	const drawerPaperStyles = clsx(classes.drawerPaper, {
		[classes.menuClosedDrawerPaper]: !isMenuOpen
	})

	const handleDrawerToggle = useCallback(() => {
		setMobileOpen(!mobileOpen)
	}, [mobileOpen])

	const drawerContent = useMemo(
		() => (
			<>
				{isProfileFinished && (
					<div className={classes.profileWrapper}>
						<Avatar src={profile.avatar} />
						{isMenuOpen && <div className={classes.emailWrapper}>
							<div className={classes.email}>{`${profile.firstName} ${profile.lastName}`}</div>
						</div>}
					</div>
				)}
				<DrawerMenu isMenuOpen={isMenuOpen} />
			</>
		),
		[
			classes.profileWrapper,
			classes.emailWrapper,
			classes.email,
			isProfileFinished,
			profile.avatar,
			profile.firstName,
			profile.lastName,
			isMenuOpen
		]
	)

	return (
		<>
			<div className={classes.mobileNav}>
				<IconButton
					color="inherit"
					aria-label="open drawer"
					edge="start"
					onClick={handleDrawerToggle}
					className={classes.menuButton}
				>
					<MenuIcon />
				</IconButton>
			</div>

			<nav className={drawerStyles} aria-label="mailbox folders">
				<Hidden smUp implementation="js">
					<Drawer
						variant="temporary"
						anchor={theme.direction === 'rtl' ? 'right' : 'left'}
						open={mobileOpen}
						onClose={handleDrawerToggle}
						classes={{
							paper: drawerPaperStyles,
						}}
						ModalProps={{
							keepMounted: true, // Better open performance on mobile.
						}}
					>
						{drawerContent}
					</Drawer>
				</Hidden>
				<Hidden xsDown implementation="js">
					<Drawer
						classes={{
							paper: drawerPaperStyles,
						}}
						variant="permanent"
						open
					>
						{drawerContent}
					</Drawer>
				</Hidden>
			</nav>
		</>
	)
}
