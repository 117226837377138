import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isLoading } from "utils/types/store"
import { scheduledRequestsActions } from '../redux/actions'
import { getScheduledRequestsInfo } from '../redux/selectors'

export type UseScheduledRequests = {
  items: any[]
  page: number
  limit: number
  total: number
  loading: boolean

  load(options: {
    page?: number
    limit?: number
  }): void
}

export const useScheduledRequests = (): UseScheduledRequests => {
  const dispatch = useDispatch()

  const scheduledRequests = useSelector(getScheduledRequestsInfo)

  const handleLoad = useCallback(
    (options: {
      page: number
      limit: number
    }) => {
      dispatch(
        scheduledRequestsActions.getScheduledRequests.request({
          page: options.page,
          limit: options.limit,
        })
      )
    },
    [dispatch]
  )

  return {
    ...scheduledRequests,
    loading: isLoading(scheduledRequests.loading),
    load: handleLoad,
  }
}
