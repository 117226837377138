import { SagaIterator } from 'redux-saga'
import { call, put, takeLatest, select } from 'redux-saga/effects'
import { ActionType } from 'typesafe-actions'

import { authActions } from './actions'
import { serverAxios } from 'utils/api/serverAxios'
import { getDoctorWorking } from 'pages/Office/redux/selectors'
import { extractErrorMessage } from 'utils/errors'

function* authorize(action: ActionType<typeof authActions.authorize.request>): SagaIterator {
	const { email } = action.payload

	try {
		yield call(serverAxios.post, `/profile/${email}`, { email })

		yield put(authActions.authorize.success({}))
	} catch (error) {
		yield put(authActions.authorize.failure({ message: extractErrorMessage(error) }))
	}
}

function* logout(action: ActionType<typeof authActions.logout.request>): SagaIterator {
	const { callback } = action.payload
	const isDoctorWirking = yield select(getDoctorWorking)

	try {
		if (isDoctorWirking) {
			yield call(serverAxios.delete, `/api/doctor/status`)
		}

		callback()
		yield put(authActions.logout.success({}))
	} catch (error) {
		yield put(authActions.logout.failure({ message: extractErrorMessage(error) }))
	}
}

export default [
	takeLatest(authActions.authorize.request, authorize),
	takeLatest(authActions.logout.request, logout),
]
