import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import AccessibilityIcon from '@material-ui/icons/Accessibility'
import EmojiEmotionsIcon from '@material-ui/icons/EmojiEmotions'
import LocalAtmIcon from '@material-ui/icons/LocalAtm'

import { FeaturesItem } from './FeaturesItem'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		justifyContent: 'space-between',
	},
}))

export const Features: FC = () => {
	const classes = useStyles()
	const { t } = useTranslation()

	return (
		<div className={classes.root}>
			<FeaturesItem
				title={t('LEOMED_ADMIN_HOME_PAGE_FEATURE_ONE_TITLE')}
				description={t('LEOMED_ADMIN_HOME_PAGE_FEATURE_ONE_DESCRIPTION')}
				icon={<AccessibilityIcon color="primary" fontSize="large" />}
			/>
			<FeaturesItem
				title={t('LEOMED_ADMIN_HOME_PAGE_FEATURE_TWO_TITLE')}
				description={t('LEOMED_ADMIN_HOME_PAGE_FEATURE_TWO_DESCRIPTION')}
				icon={<EmojiEmotionsIcon color="primary" fontSize="large" />}
			/>
			<FeaturesItem
				title={t('LEOMED_ADMIN_HOME_PAGE_FEATURE_THREE_TITLE')}
				description={t('LEOMED_ADMIN_HOME_PAGE_FEATURE_THREE_DESCRIPTION')}
				icon={<LocalAtmIcon color="primary" fontSize="large" />}
			/>
		</div>
	)
}
