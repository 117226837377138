import { createReducer, ActionType } from 'typesafe-actions'
import { Loading, makeLoading, LoadingDefault, LoadingState } from 'utils/types/store'
import { callActions } from 'pages/Call/redux/actions'

export type State = {
	diconnectFromCallLoading: Loading
	saveReportLoading: Loading
}

export const initialState: State = {
	diconnectFromCallLoading: LoadingDefault,
	saveReportLoading: LoadingDefault,
}

export default createReducer<State, ActionType<typeof callActions>>(initialState)
	.handleAction(
		callActions.diconnectFromCall.request,
		(state): State => ({ ...state, diconnectFromCallLoading: makeLoading(LoadingState.loading) })
	)
	.handleAction(
		callActions.diconnectFromCall.success,
		(state): State => ({ ...state, diconnectFromCallLoading: makeLoading(LoadingState.success) })
	)
	.handleAction(
		callActions.diconnectFromCall.failure,
		(state, { payload: { message } }): State => ({
			...state,
			diconnectFromCallLoading: makeLoading(LoadingState.failure, message),
		})
	)

	.handleAction(
		callActions.saveReport.request,
		(state): State => ({ ...state, saveReportLoading: makeLoading(LoadingState.loading) })
	)
	.handleAction(
		callActions.saveReport.success,
		(state): State => ({ ...state, saveReportLoading: makeLoading(LoadingState.success) })
	)
	.handleAction(
		callActions.saveReport.failure,
		(state, { payload: { message } }): State => ({
			...state,
			saveReportLoading: makeLoading(LoadingState.failure, message),
		})
	)
