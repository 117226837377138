import { SagaIterator } from 'redux-saga'
import { call, put, takeLatest, select, all, fork, take } from 'redux-saga/effects'
import { ActionType } from 'typesafe-actions'
import isEmpty from 'lodash/fp/isEmpty'

import { profileActions } from './actions'
import { serverAxios } from 'utils/api/serverAxios'
import { ProfileAttachments, ProfileStep } from './model'
import {
	getEducationItems,
	getProfileStep,
	getDeleteAttachmentsByCategory,
	getSpecializationItems,
	getExperienceItems,
	getPersonalInfo,
} from './selectors'
import { formatProfile } from './utils/formatProfile'
import { extractErrorMessage } from 'utils/errors'

function* deleteAttachmentsByCategory(profile: any, name: string) {
	yield all(
		profile[name].map((item: any) => {
			if (item.attachments instanceof FormData) {
				return call(serverAxios.put, 'api/doctor/documents', item.attachments, {
					headers: {
						'x-profile-category': name,
						'x-profile-category-id': item.id,
					},
				})
			}
			return undefined
		})
	)
	const deleteIds = yield select(getDeleteAttachmentsByCategory(name))

	if (!isEmpty(deleteIds) && deleteIds.fileIds) {
		yield all(
			deleteIds?.fileIds?.map((item: any) => {
				return call(serverAxios.delete, 'api/doctor/documents', {
					headers: {
						'x-profile-category': name,
						'x-profile-category-id': deleteIds.id,
						'x-file-ids': item,
					},
				})
			})
		)
	}
}

function* deleteAttachment(
	action: ActionType<typeof profileActions.deleteAttachment.request>
): SagaIterator {
	const { categoryId, fileId, name } = action.payload

	try {
		yield call(serverAxios.delete, 'api/doctor/documents', {
			headers: {
				'x-profile-category': name,
				'x-profile-category-id': categoryId,
				'x-file-ids': fileId,
			},
		})

		const refetchedProfile = yield call(serverAxios.get, `api/doctor`)
		yield put(
			profileActions.updateProfile.success({ profile: formatProfile(refetchedProfile.data) })
		)

		yield put(profileActions.deleteAttachment.success({}))
	} catch (error) {
		yield put(profileActions.deleteAttachment.failure({ message: extractErrorMessage(error) }))
	}
}

function* deleteBanner(
	action: ActionType<typeof profileActions.deleteBanner.request>
): SagaIterator {
	const { categoryId, name } = action.payload

	try {
		yield call(serverAxios.delete, 'api/doctor', {
			headers: {
				'x-profile-category': name,
				'x-profile-category-id': categoryId,
			},
		})

		const currentStep = yield select(getProfileStep)

		yield call(serverAxios.put, `api/doctor`, {
			data: {
				state: {
					pageId: currentStep,
				},
			},
		})

		const refetchedProfile = yield call(serverAxios.get, `api/doctor`)
		yield put(
			profileActions.updateProfile.success({ profile: formatProfile(refetchedProfile.data) })
		)

		yield put(profileActions.deleteBanner.success({}))
	} catch (error) {
		yield put(profileActions.deleteBanner.failure({ message: extractErrorMessage(error) }))
	}
}

function* updateProfile(
	action: ActionType<typeof profileActions.updateProfile.request>
): SagaIterator {
	const { profile, step, attachments } = action.payload
	const currentStep = yield select(getProfileStep)

	try {
		const newStep =
			currentStep === ProfileStep.done || currentStep === ProfileStep.firstStart
				? currentStep
				: step

		yield call(serverAxios.put, `api/doctor`, {
			data: {
				profile,
				state: {
					pageId: newStep,
				},
			},
		})

		if (profile?.email && attachments) {
			const personalInfo = yield select(getPersonalInfo)
			if (personalInfo.avatar) {
				yield call(serverAxios.delete, 'api/doctor/avatar')
			}
			yield call(serverAxios.post, 'api/doctor/avatar', attachments)
		}

		if (profile?.education) {
			yield call(deleteAttachmentsByCategory, profile, ProfileAttachments.education)
		}

		if (profile?.speciality) {
			yield call(deleteAttachmentsByCategory, profile, ProfileAttachments.speciality)
		}

		if (profile?.employmentHistory) {
			yield call(deleteAttachmentsByCategory, profile, ProfileAttachments.employmentHistory)
		}

		const refetchedProfile = yield call(serverAxios.get, `api/doctor`)
		if (currentStep === ProfileStep.almost_done) {
			yield put(profileActions.needRedirectToOffice(true))
		}

		yield put(
			profileActions.updateProfile.success({ profile: formatProfile(refetchedProfile.data) })
		)
	} catch (error) {
		yield put(profileActions.updateProfile.failure({ message: extractErrorMessage(error) }))
	}
}

function* getProfile(action: ActionType<typeof profileActions.getProfile.request>): SagaIterator {
	try {
		const profile = yield call(serverAxios.get, `api/doctor`)

		yield put(profileActions.getProfile.success({ profile: formatProfile(profile.data), created: profile.created }))
	} catch (error) {
		yield put(profileActions.getProfile.failure({ message: extractErrorMessage(error) }))
	}
}

function* addEducationItem(): SagaIterator {
	while (true) {
		yield take(profileActions.addEducationItem)
		const educationItems = yield select(getEducationItems)
		yield put(
			profileActions.updateProfile.request({
				profile: {
					education: educationItems.map(({ documents, toDeleteDocumentsIds, ...rest }: any) => ({
						...rest,
					})),
				},
			})
		)
	}
}

function* addSpecializationItem(): SagaIterator {
	while (true) {
		yield take(profileActions.addSpecializationItem)
		const specializationItems = yield select(getSpecializationItems)
		yield put(
			profileActions.updateProfile.request({
				profile: {
					speciality: specializationItems.map(
						({ documents, toDeleteDocumentsIds, ...rest }: any) => ({ ...rest })
					),
				},
			})
		)
	}
}

function* addExperienceItem(): SagaIterator {
	while (true) {
		yield take(profileActions.addExperienceItem)
		const experienceItems = yield select(getExperienceItems)
		yield put(
			profileActions.updateProfile.request({
				profile: {
					employmentHistory: experienceItems.map(
						({ documents, toDeleteDocumentsIds, ...rest }: any) => ({ ...rest })
					),
				},
			})
		)
	}
}

export default [
	takeLatest(profileActions.updateProfile.request, updateProfile),
	takeLatest(profileActions.getProfile.request, getProfile),
	takeLatest(profileActions.deleteAttachment.request, deleteAttachment),
	takeLatest(profileActions.deleteBanner.request, deleteBanner),
	fork(addEducationItem),
	fork(addSpecializationItem),
	fork(addExperienceItem),
]
