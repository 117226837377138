import { createSelector } from 'reselect'
import { State } from 'store/reducers'

export const getCallEntities = (state: State): State['call']['entities'] => state.call.entities
export const getCallUI = (state: State): State['call']['ui'] => state.call.ui

export const getDiconnectFromCallLoading = createSelector(
	getCallUI,
	entities => entities.diconnectFromCallLoading
)
export const getSaveReportLoading = createSelector(
	getCallUI,
	entities => entities.saveReportLoading
)
export const getMedicalReport = createSelector(getCallEntities, entities => entities.medicalReport)
export const getCallDuration = createSelector(getCallEntities, entities => entities.callDuration)

export const getCanLeaveCall = createSelector(getCallEntities, entities => entities.canLeaveCall)
export const getIsSelfieCameraShown = createSelector(getCallEntities, entities => entities.isSelfieCameraShown)
export const getRemoteParticipantConnected = createSelector(
	getCallEntities,
	entities => entities.remoteParticipantConnected
)
