import { makeStyles, Paper } from '@material-ui/core'
import React, { FC, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useScheduledRequests } from 'pages/MyCalendar/hooks/useScheduledRequests'
import { MyScheduleList } from 'pages/MyCalendar/components/MySchedule/MyScheduleList'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(4),
    paddingTop: theme.spacing(2),
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 2)
  },
  headerTitle: {
    color: theme.palette.text.primary,
    fontWeight: 700,
  }
}))

export const statusOrderMapForShceduledRequests = {
  'APPROVED': 1,
  'REJECTED': 2,
  'PENDING': 3
}

type MyScheduleContainerProps = {
  handlePageChange: (value: number) => void,
  page: number
}

export const MyScheduleContainer: FC<MyScheduleContainerProps> = ({ handlePageChange, page }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { load, loading, items, limit } = useScheduledRequests()

  useEffect(() => {
    load({
      page,
      limit,
    })
  }, [dispatch, page, limit, load])

  return (
    <Paper className={classes.root}>
      {!loading && (
        <div className={classes.header}>
          <div className={classes.headerTitle}>{t('LEOMED_ADMIN_OFFICE_MY_CALENDAR_CONSULTATION_LIST_TEXT')}</div>
        </div>
      )}
      <MyScheduleList
        requests={items} 
        loading={loading} 
        handlePageChange={handlePageChange}
      />
    </Paper>
  )
}
