import React, { FC, useState, useCallback } from 'react'
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone'
import IconButton from '@material-ui/core/IconButton'
import { ClickAwayListener, makeStyles } from '@material-ui/core'
import { NotificationsWindow } from './NotificationsWindow'
import { useDispatch, useSelector } from 'react-redux'
import { getNotifications } from 'pages/Office/redux/selectors'
import { Colors } from 'utils/theme'
import { officeActions } from 'pages/Office/redux/actions'
import { alertsActions } from 'store/alerts/actions'

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative'
  },
  counter: {
    position: 'absolute',
    top: 0,
    right: 0,
    lineHeight: '15px',
		backgroundColor: Colors.red[500],
		width: 15,
		height: 15,
		fontSize: 10,
		textAlign: 'center',
		borderRadius: '50%',
		color: theme.palette.background.paper,
		marginLeft: theme.spacing(1),
	}
}))

export const NotificationCenter: FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [isOpen, setIsOpen] = useState(false)
  const notifications = useSelector(getNotifications)
  const notificationsLength = notifications.length

  const toggleIsOpen = useCallback(() => {
    if (!isOpen) {
      dispatch(alertsActions.clearAllSocketNotifications())
    }
    setIsOpen(!isOpen)
  }, [dispatch, isOpen])

  const clickAwayHandler = useCallback(() => {
    notifications.forEach(item => {
      if (item.data.status !== 'PENDING') {
        dispatch(officeActions.updateNotificationId.request({ id: item._id }))
      }
    })
    setIsOpen(false)
  }, [dispatch, notifications])

  return (
    <ClickAwayListener onClickAway={clickAwayHandler}>
      <div className={classes.root}>
        <IconButton onClick={toggleIsOpen}>
          <NotificationsNoneIcon color="primary" />
          {notificationsLength > 0 && <div className={classes.counter}>{notificationsLength}</div>}
        </IconButton>
        {isOpen && <NotificationsWindow notifications={notifications} />}
      </div>
    </ClickAwayListener>
  )
}
