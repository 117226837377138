import { FC, useEffect } from 'react'

import { useAuth0 } from '@auth0/auth0-react'

export const LogoutPage: FC = () => {
	const { logout } = useAuth0()

	useEffect(() => logout(), [logout])

	return null
}
