import { SagaIterator } from 'redux-saga'
import { all } from 'redux-saga/effects'

import profileSagas from 'pages/Profile/redux/sagas'
import authSagas from 'pages/Auth/redux/sagas'
import officeSagas from 'pages/Office/redux/sagas'
import consultationRequestsSagas from 'pages/ConsultationRequests/redux/sagas'
import scheduledRequestsSagas from 'pages/MyCalendar/redux/sagas'
import callSagas from 'pages/Call/redux/sagas'

export function* saga(): SagaIterator {
	yield all([
		...authSagas,
		...profileSagas,
		...officeSagas,
		...callSagas,
		...consultationRequestsSagas,
		...scheduledRequestsSagas
	])
}
