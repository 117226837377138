import { getConsultationHistoryInfo } from './../redux/selectors';
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isLoading } from "utils/types/store"
import { scheduledRequestsActions } from '../redux/actions'

export type UseConsultationHistory = {
  items: any[]
  page: number
  limit: number
  total: number
  loading: boolean

  load(options: {
    page?: number
    limit?: number
  }): void
}

export const useConsultationHistory = (): UseConsultationHistory => {
  const dispatch = useDispatch()

  const consultationHistory = useSelector(getConsultationHistoryInfo)

  const handleLoad = useCallback(
    (options: {
      page: number
      limit: number
    }) => {
      dispatch(
        scheduledRequestsActions.getConsultationHistory.request({
          page: options.page,
          limit: options.limit,
        })
      )
    },
    [dispatch]
  )

  return {
    ...consultationHistory,
    loading: isLoading(consultationHistory.loading),
    load: handleLoad,
  }
}
