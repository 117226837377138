import { createAction, createAsyncAction } from 'typesafe-actions'
import * as actionType from './const'
import { ConsultationRequest } from './dto/ConsultationRequest'

export const consultationRequestsActions = {
  getConsultationRequests: createAsyncAction(
		actionType.GET_CONSULTATION_REQUESTS_REQUEST,
		actionType.GET_CONSULTATION_REQUESTS_SUCCESS,
		actionType.GET_CONSULTATION_REQUESTS_FAILURE
	)<
		{
			page: number,
			limit: number,
			subStatus?: string
		},
		{
			data: ConsultationRequest[],
			page: number,
			total: number,
			limit: number,
		},
		{ message?: string }>(),
  approveConsultationRequest: createAsyncAction(
		actionType.APPROVE_CONSULTATION_REQUEST_REQUEST,
		actionType.APPROVE_CONSULTATION_REQUEST_SUCCESS,
		actionType.APPROVE_CONSULTATION_REQUEST_FAILURE
	)<{ id: string; alertMessage: string; page: string | string[] | 1; callback?: () => void }, { id: string; data: ConsultationRequest; alertMessage: string }, { id: string; message?: string }>(),
	rejectConsultationRequest: createAsyncAction(
		actionType.REJECT_CONSULTATION_REQUEST_REQUEST,
		actionType.REJECT_CONSULTATION_REQUEST_SUCCESS,
		actionType.REJECT_CONSULTATION_REQUEST_FAILURE
	)<{ id: string; alertMessage: string; page: string | string[] | 1; callback?: () => void }, { id: string; data: ConsultationRequest; alertMessage: string; color: string }, { id: string; message?: string }>(),
  updateConsultationRequest: createAsyncAction(
		actionType.UPDATE_CONSULTATION_REQUEST_REQUEST,
		actionType.UPDATE_CONSULTATION_REQUEST_SUCCESS,
		actionType.UPDATE_CONSULTATION_REQUEST_FAILURE
	)<{ id: string; date: string; time: string; callback: () => void; alertMessage: string }, { id: string; data: ConsultationRequest; alertMessage: string; color: string }, { id: string; message?: string }>(),
  pickIdForChangeTimeDialog: createAction(actionType.PICK_ID_FOR_CHANGE_TIME_DIALOG)<{ id: string }>(),
	pickIdForNewRequestDialog: createAction(actionType.PICK_ID_FOR_NEW_REQUEST_DIALOG)<{ id: string }>(),
	consultationRequestHasDateConflict: createAction(actionType.CONSULTATION_REQUEST_HAS_DATE_CONFLICT)(),
}
