import React, { FC, useCallback, useMemo, useState } from 'react'
import { Avatar, CircularProgress, Dialog, fade, IconButton, makeStyles, Tooltip } from "@material-ui/core"
import CloseIcon from '@material-ui/icons/Close'
import DescriptionIcon from '@material-ui/icons/Description'
import SearchIcon from '@material-ui/icons/Search'
import GetAppIcon from '@material-ui/icons/GetApp'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { scheduledRequestsActions } from 'pages/MyCalendar/redux/actions'
import { Option, SlideImage } from 'pages/Call/containers/SymptomsContainer'
import { formatArrayToMap } from 'utils/formatArrayToMap'

import ImageGallery from 'react-image-gallery'
import { useEffect } from 'react'
import { getConsultationDataLoading } from 'pages/MyCalendar/redux/selectors'
import { isLoading } from 'utils/types/store'

const useStyles = makeStyles(theme => ({
  root: {
		padding: theme.spacing(2, 4),
    position: 'relative',
    
	},
  loadingRoot: {
    width: 866,
    height: 592,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  title: {
    fontSize: '16px',
    fontWeight: 700,
  },
  infoBlockWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(3),
  },
  infoBlock: {
    width: '50%',
  },
  subtitle: {
    fontWeight: 500,
    fontSize: 14,
    color: '#597DA6',
  },
  text: {
    fontSize: 14,
    color: '#133153',
    fontWeight: 500,
  },
  avatar: {
    width: 100,
    height: 100,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.background.paper,
    fontSize: 14,
    marginRight: theme.spacing(4),
  },
  closeBtn: {
    position: 'absolute',
    top: 16,
    right: 15,
    width: 30,
    height: 30,
  },
  infoWrapper: {
    width: 385,
    height: 490,
    overflowY: 'auto',
    backgroundColor: '#F3F7FC',
    borderRadius: '10px',
    padding: theme.spacing(2),
    margin: theme.spacing(3, 0),
  },
  requestWrapper: {
    width: 385,
    height: 490,
    overflowY: 'auto',
    marginLeft: theme.spacing(4),
    borderRadius: '10px',
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.divider}`
  },
  requestHeader: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  avatarWrapper: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: theme.spacing(2),
    borderBottom: '1px solid #DEEBF7',
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2, 0)
  },
  dateText: {
    fontWeight: 700,
    fontSize: 14,
    display: 'inline-flex'
  },
  m2: {
    margin: theme.spacing(2, 0),
  },
  symptomsWrapper: {
    marginTop: theme.spacing(1),
  },
  temperature: {
		display: 'inline-flex',
		alignItems: 'center',
		backgroundColor: theme.palette.warning.main,
		width: 'fit-content',
		borderRadius: '25px',
    fontSize: 14,
		color: theme.palette.background.paper,
		padding: theme.spacing(0.5, 1),
    marginRight: theme.spacing(1),
	},
	icon: {
		width: 7,
		height: 14,
		backgroundImage: `url(${process.env.PUBLIC_URL}/temperature.svg)`,
		backgroundSize: 'cover',
		marginRight: theme.spacing(1),
	},
  symptoms: {
		display: 'inline-flex',
		width: 'fit-content',
		backgroundColor: '#FFE6E6',
		padding: theme.spacing(0.5, 1),
		borderRadius: '25px',
    fontSize: 14,
    marginRight: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
  images: {
		display: 'inline-flex',
		flexWrap: 'wrap',
	},
	image: {
		maxWidth: '100%',
		height: '100%',
		cursor: 'pointer',
		borderRadius: 4,
	},
	imgWrapper: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		position: 'relative',
		width: 75,
		height: 50,
		margin: theme.spacing(0, 1, 1, 0),
		'&:hover > div': {
			zIndex: 1,
		}
	},
	imgButtons: {
		position: 'absolute',
		width: '100%',
		height: '100%',
		top: 0,
		left: 0,
		zIndex: -1,
		alignItems: 'center',
		justifyContent: 'center',
		backgroundColor: fade(theme.palette.primary.main, 0.5),
		borderRadius: 4,
		display: 'flex',
		'& > svg': {
			width: '50%',
			height: '50%',
			cursor: 'pointer',
			color: theme.palette.background.paper
		}
	},
	description: {
		fontWeight: 500,
		fontSize: '12px',
	},
	fileName: {
		width: 50,
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
	file: {
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
	},
	downloadLink: {
		color: theme.palette.background.paper,
		height: 24,
	}
}))

type ConsultationHistoryDetailsDialogProps = {
  isOpen: boolean
  data: any
}

export const ConsultationHistoryDetailsDialog: FC<ConsultationHistoryDetailsDialogProps> = ({ isOpen, data }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [isGalleryOpen, setIsGalleryOpen] = useState(false)
	const [startIndex, setStartIndex] = useState(0)
  const { t } = useTranslation([
		'translation',
		'conditions',
		'diseases',
		'medications',
	])
  const symptoms = t('conditions:CONDITIONS') as Option[]
	const diseases = t('diseases:DISEASES') as Option[]
	const medications = t('medications:MEDICATIONS') as Option[]
  const submitting = useSelector(getConsultationDataLoading)
  const isSubmitting = isLoading(submitting)

  const symptomsMap = useMemo(() => formatArrayToMap(data?.symptoms ?? []), [data])
	const diseasesMap = useMemo(() => formatArrayToMap(data?.diseases ?? []), [data])
	const medicationsMap = useMemo(() => formatArrayToMap(data?.medications ?? []), [data])
  const date = `${moment(data.date).format('DD/MM/YYYY')} ${t('LEOMED_ADMIN_OFFICE_ON_TITLE')} ${moment(data.date).format('HH:mm')}`
  const nameInitials = useMemo(() => `${data.firstName?.[0] ?? ''}${data.lastName?.[0] ?? ''}`.toUpperCase(), [data.firstName, data.lastName])

  const items: SlideImage[] = useMemo(
		() => data?.documents?.map((item: any) => ({
			original: item.url,
			thumbnail: item.url,
      isFile: (!item.mimetype.includes('/png') && !item.mimetype.includes('/jpeg')),
			name: item.name,
		})),
		[data]
	)

  const gallaryItems = items?.filter(item => !item.isFile)

  useEffect(() => {
    dispatch(scheduledRequestsActions.getConsultationData.request({ id: data.id }))
  }, [dispatch, data.id])

	const toggleOpenHandler = useCallback(
		url => {
			const item = gallaryItems.find(item => item.original === url) as SlideImage
			const index = gallaryItems.indexOf(item)
			setIsGalleryOpen(!isGalleryOpen)
			setStartIndex(index)
		},
		[isGalleryOpen, gallaryItems]
	)

  const onClose = useCallback(() => {
    dispatch(scheduledRequestsActions.pickConsultationHistoryId(''))
  }, [dispatch])

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="lg">
      <Dialog open={isGalleryOpen} onClose={toggleOpenHandler} fullWidth={true} maxWidth="xs">
				<ImageGallery showPlayButton={false} items={gallaryItems} startIndex={startIndex} />
			</Dialog>
      {isSubmitting ? (
        <div className={classes.loadingRoot}>
          <CircularProgress />
        </div> 
      ) : (
        <div className={classes.root}>
          <div className={classes.title}>{t('LEOMED_ADMIN_CONSULTATION_DETAILS_TEXT')}</div>
          <div className={classes.wrapper}>
            <div className={classes.infoWrapper}>
              <div className={classes.avatarWrapper}>
                <Avatar src={data.avatar} className={classes.avatar}>
                  {nameInitials}
                </Avatar>
                <div className={classes.title}>
                  <div>{data.firstName}</div>
                  <div>{data.lastName}</div>
                </div>
              </div>
              <div className={classes.info}>
                <div className={classes.infoBlockWrapper}>
                  <div className={classes.infoBlock}>
                    <div className={classes.subtitle}>{t('LEOMED_ADMIN_PROFILE_PAGE_AGE_LABEL')}</div>
                    <div className={classes.text}>{data.age || ' - '}</div>
                  </div>
                  <div className={classes.infoBlock}>
                    <div className={classes.subtitle}>{t('LEOMED_ADMIN_PROFILE_PAGE_GENDER_LABEL')}</div>
                    <div className={classes.text}>{t(data.gender)}</div>
                  </div>
                </div>

                <div className={classes.infoBlockWrapper}>
                  <div className={classes.infoBlock}>
                    <div className={classes.subtitle}>{t('LEOMED_ADMIN_HEIGHT_TEXT')}</div>
                    <div className={classes.text}>
                      {data.height ? (
                        <div>{`${data.height} ${t(
                          'LEOMED_ADMIN_SANTIMETERS_TEXT'
                        )}`}</div>
                      ) : (
                        ' - '
                      )}
                    </div>
                  </div>
                  <div className={classes.infoBlock}>
                    <div className={classes.subtitle}>{t('LEOMED_ADMIN_WEIGHT_TEXT')}</div>
                    <div className={classes.text}>
                      {data.weight ? (
                        <div>{`${data.weight} ${t(
                          'LEOMED_ADMIN_KILOGRAMS_TEXT'
                        )}`}</div>
                      ) : (
                        ' - '
                      )}
                    </div>
                  </div>
                </div>

                <div className={classes.infoBlockWrapper}>
                  <div className={classes.infoBlock}>
                    <div className={classes.subtitle}>{t('LEOMED_ADMIN_SMOKES_TEXT')}</div>
                    <div className={classes.text}>{t(data.smoking)}</div>
                  </div>
                  <div className={classes.infoBlock}>
                    <div className={classes.subtitle}>{t('LEOMED_ADMIN_DRINKS_TEXT')}</div>
                    <div className={classes.text}>{t(data.drinking)}</div>
                  </div>
                </div>

                <div className={classes.subtitle}>{t('LEOMED_ADMIN_MEDICATIONS_TAKEN_TEXT')}</div>
                <div className={classes.text}>
                {medications.length ? medications
                  .filter((item: Option) => medicationsMap[item.value])
                  .map((item: Option) => item.label)
                  .join(', ') : ' - '}
                </div>

                <div className={classes.m2}>
                  <div className={classes.subtitle}>{t('LEOMED_ADMIN_CHRONICAL_DISEASE_TEXT')}</div>
                  <div className={classes.text}>
                  {diseases.length ? diseases
                    .filter((item: Option) => diseasesMap[item.value])
                    .map((item: Option) => item.label)
                    .join(', ') : ' - '}
                  </div>
                </div>
              </div>
            </div>

            <div className={classes.requestWrapper}>
              <div className={classes.requestHeader}>
                <div>
                  <div className={classes.subtitle}>{t('LEOMED_ADMIN_OFFICE_REQUEST_TITLE')}</div>
                  <div className={classes.title}>{data.request}</div>
                </div>
                {date}
              </div>

              <div className={classes.m2}>
                <div className={classes.subtitle}>{t('LEOMED_ADMIN_DIALOG_SYMPTOMS_TEXT')}</div>
                <div className={classes.symptomsWrapper}>
                  <div className={classes.temperature}>
                    <div className={classes.icon} />
                    {data.temperature ? `${data.temperature} °С` : ' - '}
                  </div>
                  {symptoms.map(
                    (item: Option) =>
                      symptomsMap[item.value] && <div className={classes.symptoms}>{item.label}</div>
                  )}
                </div>
              </div>

              <div className={classes.m2}>
                <div className={classes.subtitle}>{t('LEOMED_ADMIN_DIALOG_REPORT_TEXT')}</div>
                <div className={classes.text}>{data.diagnosis || ' - '}</div>
              </div>

              <div className={classes.m2}>
                <div className={classes.subtitle}>{t('LEOMED_ADMIN_DIALOG_APPOINTMENT_TEXT')}</div>
                <div className={classes.text}>{data.recommendation || ' - '}</div>
              </div>

              <div className={classes.m2}>
                <div className={classes.subtitle}>{t('LEOMED_ADMIN_DIALOG_ATTACHMENTS_TEXT')}</div>
                <div className={classes.images}>
                  {items?.map((item) => (
                    <div className={classes.imgWrapper}>
                      {item.isFile ? (
                        <div className={classes.file}>
                          <DescriptionIcon />
                          <div className={classes.fileName}>{item.name}</div>
                        </div>
                      ) : <img
                        alt="attch"
                        src={item.original}
                        className={classes.image}
                      />}
                      <Tooltip title={item.name}>
                        <div className={classes.imgButtons}>
                          <a
                            href={item.original}
                            download
                            target="_blank" 
                            rel="noopener noreferrer"
                            className={classes.downloadLink}
                          >
                            <GetAppIcon />
                          </a>
                          {!item.isFile && <SearchIcon onClick={() => toggleOpenHandler(item.original)} />}
                        </div>
                      </Tooltip>
                    </div>
                  )) ?? ' - '}
                </div>
              </div>
            </div>
          </div>
          <IconButton onClick={onClose} className={classes.closeBtn}>
            <CloseIcon color="primary" />
          </IconButton>
        </div>
      )}
    </Dialog>
  )
}
