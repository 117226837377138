export const DICONNECT_FROM_CALL_REQUEST = 'DICONNECT_FROM_CALL_REQUEST'
export const DICONNECT_FROM_CALL_SUCCESS = 'DICONNECT_FROM_CALL_SUCCESS'
export const DICONNECT_FROM_CALL_FAILURE = 'DICONNECT_FROM_CALL_FAILURE'

export const SAVE_REPORT_REQUEST = 'SAVE_REPORT_REQUEST'
export const SAVE_REPORT_SUCCESS = 'SAVE_REPORT_SUCCESS'
export const SAVE_REPORT_FAILURE = 'SAVE_REPORT_FAILURE'

export const LEAVE_FROM_CALL_REQUEST = 'LEAVE_FROM_CALL_REQUEST'
export const LEAVE_FROM_CALL_SUCCESS = 'LEAVE_FROM_CALL_SUCCESS'
export const LEAVE_FROM_CALL_FAILURE = 'LEAVE_FROM_CALL_FAILURE'

export const GET_ROOM_TOKEN_REQUEST = 'GET_ROOM_TOKEN_REQUEST'
export const GET_ROOM_TOKEN_SUCCESS = 'GET_ROOM_TOKEN_SUCCESS'
export const GET_ROOM_TOKEN_FAILURE = 'GET_ROOM_TOKEN_FAILURE'

export const SEND_USER_CONNECTED_EVENT = 'SEND_USER_CONNECTED_EVENT'
export const SEND_USER_CONNECTED_EVENT_SUCCESS = 'SEND_USER_CONNECTED_EVENT_SUCCESS'
export const SEND_USER_CONNECTED_EVENT_FAILURE = 'SEND_USER_CONNECTED_EVENT_FAILURE'

export const SEND_USER_DISCONNECTED_EVENT = 'SEND_USER_DISCONNECTED_EVENT'
export const SEND_USER_DISCONNECTED_EVENT_SUCCESS = 'SEND_USER_DISCONNECTED_EVENT_SUCCESS'
export const SEND_USER_DISCONNECTED_EVENT_FAILURE = 'SEND_USER_DISCONNECTED_EVENT_FAILURE'

export const CAN_LEAVE_CALL = 'CAN_LEAVE_CALL'
export const TOGGLE_SELFIE_CAMERA = 'TOGGLE_SELFIE_CAMERA'
export const TOGGLE_REMOTE_PARTICIPANT_CONNECTED = 'TOGGLE_REMOTE_PARTICIPANT_CONNECTED'
