import { useContext } from 'react'

import { ProfileContext } from '../ProfileContext'
import { ProfileStep } from '../redux/model'

export type useProfileStep = {
	setStep(step: ProfileStep): void
	previousStep(): void
	nextStep(): void
	step: ProfileStep
}

export const useProfileStep = (): useProfileStep => {
	const { setStep, previousStep, nextStep, step } = useContext(ProfileContext)

	return { setStep, previousStep, nextStep, step }
}
