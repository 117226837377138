import React, { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { NoProfileBanner } from './containers/NoProfileBanner'
import { getIsProfileActivated, getProfileStep } from 'pages/Profile/redux/selectors'
import { ProfileStep } from 'pages/Profile/redux/model'
import { makeStyles, Typography } from '@material-ui/core'
// import { FinanceContainer } from './containers/FinanceContainer'
import { InfoBanner } from 'layouts/parts/InfoBanner'
import { EarnedGraphContainer } from './containers/EarnedGraphContainer'
// import { TransactionHistoryContainer } from './containers/Transactions/TransactionHistoryContainer'
import { consultationRequestsActions } from 'pages/ConsultationRequests/redux/actions'

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
		minHeight: '700px',
		marginTop: theme.spacing(14),
	},
	title: {
		fontSize: '24px',
		fontWeight: 700,
		marginBottom: theme.spacing(2),
	},
	graphsWrapper: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		marginTop: theme.spacing(1),
	},
	tabsWrapper: {
		marginTop: theme.spacing(4),
	},
}))

export const OfficePage: FC = () => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const profileStep = useSelector(getProfileStep)
	const isProfileActivated = useSelector(getIsProfileActivated)
	const isProfileFinished = profileStep >= ProfileStep.firstStart
	const isNoProfileStep = profileStep < ProfileStep.firstStart

	useEffect(() => {
    dispatch(consultationRequestsActions.getConsultationRequests.request({ page: 1, limit: 10, subStatus: 'CLIENT_CREATED' }))
  }, [dispatch])

	if (isProfileFinished && !isProfileActivated) {
		return (
			<InfoBanner
				title={t('LEOMED_ADMIN_YOU_ARE_RERISTERED')}
				description={t('LEOMED_ADMIN_PROFILE_IS_ON_REVIEW')}
				status={t('LEOMED_ADMIN_PROFILE_IS_ON_REVIEW_STATUS')}
			/>
		)
	}

	return (
		<div className={classes.root}>
			{isNoProfileStep && <NoProfileBanner />}
			{isProfileFinished && (
				<>
					<Typography className={classes.title}>{t('LEOMED_ADMIN_OFFICE_PAGE_TITLE')}</Typography>
					{/* <FinanceContainer /> */}
					<div className={classes.graphsWrapper}>
						<EarnedGraphContainer />
					</div>
					{/* <div className={classes.tabsWrapper}>
						<TransactionHistoryContainer />
					</div> */}
				</>
			)}
		</div>
	)
}
