import { curry, isNil } from 'lodash/fp'

export interface OneInsert {
  <TI, TS>(item: TI, id: string | number): (state: TS) => TS
  <TI, TS>(item: TI, id: string | number, state: TS): TS
}

export const oneInsert: OneInsert = curry(
  (item: unknown, id: string | number, state: any): typeof state =>
    isNil(id) ? state : { ...state, [id.toString()]: item }
)