import { ConsultationRequest } from 'pages/ConsultationRequests/redux/dto/ConsultationRequest'
import { createAsyncAction, createAction } from 'typesafe-actions'
import * as actionType from './const'
import { ConsultationHistory } from './dto/ConsultationHistory'

export const scheduledRequestsActions = {
  getScheduledRequests: createAsyncAction(
    actionType.GET_SCHEDULED_REQUESTS_REQUEST,
    actionType.GET_SCHEDULED_REQUESTS_SUCCESS,
    actionType.GET_SCHEDULED_REQUESTS_FAILURE
  )<
    {
      page: number,
      limit: number,
    },
    {
      data: ConsultationRequest[],
      page: number,
      total: number,
      limit: number,
    },
    { message?: string }>(),
  getConsultationHistory: createAsyncAction(
      actionType.GET_CONSULTATION_HISTORY_REQUEST,
      actionType.GET_CONSULTATION_HISTORY_SUCCESS,
      actionType.GET_CONSULTATION_HISTORY_FAILURE
    )<
      {
        page: number,
        limit: number,
      },
      {
        data: ConsultationHistory[],
        page: number,
        total: number,
        limit: number,
      },
      { message?: string }>(),
  getConsultationData: createAsyncAction(
    actionType.GET_CONSULTATION_DATA_REQUEST,
    actionType.GET_CONSULTATION_DATA_SUCCESS,
    actionType.GET_CONSULTATION_DATA_FAILURE
  )<{ id: string }, { data: ConsultationHistory }, { message?: string }>(),
  pickConsultationHistoryId: createAction(actionType.PICK_CONSULTATION_HISTORY_ID)<string>(),
}
