import { makeStyles, Snackbar } from '@material-ui/core'
import { Alert, Color } from '@material-ui/lab'
import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import ErrorIcon from '@material-ui/icons/Error'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import clsx from 'clsx'
import { getPopupAlerts } from 'store/alerts/selectors'
import { Colors } from 'utils/theme'

const useStyles = makeStyles(theme => ({
  alert: {
		color: theme.palette.background.paper,
	},
	alertIcon: {
		color: theme.palette.background.paper,
	},
  error: {
    backgroundColor: Colors.red[300],
  },
  success: {
    backgroundColor: Colors.green[500],
  },
  pending: {
    backgroundColor: Colors.orange[500],
  }
}))

export const ErrorBoundary: FC = () => {
  const classes = useStyles()
  const alerts = useSelector(getPopupAlerts)

  return (
    <>
      {alerts.map((item, index) => (
        <Snackbar
          open
          anchorOrigin={{ vertical: 'top', horizontal: item.severity === 'success' ? 'center' : 'right' }}
          style={{ top: `calc(95px + ${60*index}px)`}}
          key={index}
        >
          <Alert
            severity={item.severity as Color}
            className={clsx(classes.alert, {
              [classes.error]: item.color === 'error' || item.severity === 'error',
              [classes.success]: item.severity === 'success',
              [classes.pending]: item.color === 'pending',
            })}
            iconMapping={{
              error: <ErrorIcon className={classes.alertIcon} />,
              success: <CheckCircleOutlineIcon className={classes.alertIcon} />
            }}
          >
            {item.message}
          </Alert>
        </Snackbar>
      ))}
    </>
  )
}
