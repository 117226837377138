import React, { FC } from 'react'
import clsx from 'clsx'
import { Button, makeStyles, ButtonProps } from '@material-ui/core'

import { useAuth0 } from '@auth0/auth0-react'

const useStyles = makeStyles(theme => ({
	root: {
		textDecoration: 'none',
	},
	doctorBtn: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.primary.light,
		marginRight: theme.spacing(10),

		'&:hover': {
			backgroundColor: theme.palette.primary.main,
		},
	},
}))

type PickDoctorBtnProps = { text: string } & ButtonProps

export const PickDoctorBtn: FC<PickDoctorBtnProps> = ({ text, className, ...rest }) => {
	const classes = useStyles()
	const btnStyles = clsx(classes.doctorBtn, className)
	const { loginWithRedirect } = useAuth0()

	return (
		<div className={classes.root}>
			<Button variant="contained" onClick={loginWithRedirect} className={btnStyles} {...rest}>
				{text}
			</Button>
		</div>
	)
}
