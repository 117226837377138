import React, { FC } from 'react'
import { Box, Typography } from '@material-ui/core'

interface TabPanelProps {
	children?: React.ReactNode
	index: any
	value: any
}

export const NavPanel: FC<TabPanelProps> = ({ children, value, index, ...other }) => {
	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box p={3}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	)
}
