import { useSelector } from 'react-redux'
import { useCallback, useEffect, useState } from 'react'
import moment from 'moment'
import { getConsultationRequestsList } from 'pages/ConsultationRequests/redux/selectors'

export const useScheduledCallSoon = () => {
  const consultationRequests = useSelector(getConsultationRequestsList)
  const closestScheduledCall = consultationRequests?.find(item =>
    moment(item?.datetime).diff(moment(), 'minutes') <= 15
    &&
    moment(item?.datetime).diff(moment(), 'minutes') >= 0
  ) ?? null
  const [scheduledCall, setScheduledCall] = useState(closestScheduledCall)
  const date = scheduledCall?.date
  const time = moment(scheduledCall?.datetime).format('H:mm')

  const removeClosestTime = useCallback(() => setScheduledCall(null), [setScheduledCall])

  useEffect(() => {
    let timerID = setInterval(() => {
      const closestScheduledCallRecheck = consultationRequests.find(item =>
        item?.status === 'APPROVED'
        &&
        moment(item?.datetime).diff(moment(), 'minutes') <= 15
        &&
        moment(item?.datetime).diff(moment(), 'minutes') >= 0
      )

      if (closestScheduledCallRecheck) {
        setScheduledCall(closestScheduledCallRecheck)
      }
    }, 30000)
    return () => clearInterval(timerID)
  }, [consultationRequests])

  return {
    scheduledCall,
    removeClosestTime,
    date,
    time,
  }
}
