import { Avatar, makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import React, { FC, useCallback, useMemo, useState } from 'react'
import { Colors } from 'utils/theme'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import { useSelector } from 'react-redux'
import { getPatientInfo } from 'pages/Office/redux/selectors'
import { formatArrayToMap } from 'utils/formatArrayToMap'

const useStyles = makeStyles(theme => ({
	root: {
		backgroundColor: theme.palette.background.default,
		borderRadius: '10px',
		padding: theme.spacing(2),
		marginBottom: theme.spacing(3),
		position: 'relative',
	},
	wrapper: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	logo: {
		width: '50px',
		height: '50px',
		borderRadius: '50%',
		backgroundColor: theme.palette.primary.main,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		color: theme.palette.background.paper,
	},
	univer: {
		marginLeft: theme.spacing(2),
	},
	univerTitle: {
		fontWeight: 600,
		fontSize: '18px',
		marginBottom: theme.spacing(1),
	},
	faculty: {
		fontSize: '12px',
	},
	ml10: {
		marginLeft: theme.spacing(10),
	},
	title: {
		fontSize: '12px',
		color: Colors.grey[700],
		marginBottom: theme.spacing(1),
	},
	description: {
		fontWeight: 500,
		fontSize: '12px',
	},
	flex: {
		display: 'flex',
		alignItems: 'center',
	},
	icon: {
		cursor: 'pointer',
	},
	subTitle: {
		fontSize: '12px',
		color: Colors.grey[500],
		marginBottom: theme.spacing(1),
	},
	patientBlock: {
		marginBottom: theme.spacing(2),
		paddingBottom: theme.spacing(2),
		borderBottom: `1px solid ${theme.palette.divider}`,
	},
	patientText: {
		fontWeight: 700,
		overflowWrap: 'break-word',
	},
	padding: {
		paddingTop: theme.spacing(2),
	},
}))

type Option = { value: string; label: string }

export const PatientInfoBanner: FC = () => {
	const classes = useStyles()
	const [isOpen, setIsOpen] = useState(false)
	const { t } = useTranslation(['translation', 'diseases', 'allergies', 'medications'])
	const patientInfo = useSelector(getPatientInfo)

	const diseases = t('diseases:DISEASES') as Option[]
	const allergies = t('allergies:ALLERGIES') as Option[]
	const medications = t('medications:MEDICATIONS') as Option[]
	const diseasesMap = useMemo(() => formatArrayToMap(patientInfo?.diseases), [patientInfo])
	const allergiesMap = useMemo(() => formatArrayToMap(patientInfo?.allergies), [patientInfo])
	const medicationsMap = useMemo(() => formatArrayToMap(patientInfo?.medications), [patientInfo])

	const toggleArrow = useCallback(() => {
		setIsOpen(!isOpen)
	}, [isOpen])

	return (
		<div className={classes.root}>
			<div className={classes.wrapper}>
				<div className={classes.flex}>
					<Avatar src={patientInfo.avatar} />
					<div className={classes.univer}>
						<div className={classes.title}>{t('LEOMED_ADMIN_PROFILE_PAGE_NAME_LABEL')}</div>
						{patientInfo.name ? (
							<div className={classes.description}>{patientInfo.name}</div>
						) : (
							' - '
						)}
					</div>
					{/* <div className={classes.univer}>
						<div className={classes.univerTitle}>{`${patientInfo.lastName || ' - '} ${patientInfo.firstName || ' - '} ${patientInfo.middleName || ' - '}`}</div>
						<div className={classes.faculty}>{`${patientInfo.gender || ' - '}, ${patientInfo.age || ' - '}`}</div>
					</div> */}
					<div className={classes.ml10}>
						<div className={classes.title}>{t('LEOMED_ADMIN_HEIGHT_TEXT')}</div>
						{patientInfo.height ? (
							<div className={classes.description}>{`${patientInfo.height} ${t(
								'LEOMED_ADMIN_SANTIMETERS_TEXT'
							)}`}</div>
						) : (
							' - '
						)}
					</div>
					<div className={classes.ml10}>
						<div className={classes.title}>{t('LEOMED_ADMIN_WEIGHT_TEXT')}</div>
						{patientInfo.weight ? (
							<div className={classes.description}>{`${patientInfo.weight} ${t(
								'LEOMED_ADMIN_KILOGRAMS_TEXT'
							)}`}</div>
						) : (
							' - '
						)}
					</div>
				</div>
				<div className={classes.flex}>
					{t('LEOMED_ADMIN_PATIENT_VISIT_REASON')}
					<div onClick={toggleArrow} className={classes.flex}>
						{!isOpen ? (
							<ExpandMoreIcon color="primary" className={classes.icon} />
						) : (
							<ExpandLessIcon color="primary" className={classes.icon} />
						)}
					</div>
				</div>
			</div>
			{isOpen && (
				<div className={classes.padding}>
					<div className={classes.patientBlock}>
						<div className={classes.subTitle}>{t('LEOMED_ADMIN_TEMPERATURE_TEXT')}</div>
						{patientInfo.fever ? (
							<div className={classes.patientText}>{patientInfo.fever}</div>
						) : (
							' - '
						)}
					</div>
					<div className={classes.patientBlock}>
						<div className={classes.subTitle}>{t('LEOMED_ADMIN_PATIENT_CHRONIC_DISEASES')}</div>
						<div className={classes.patientText}>
							{diseases
								.filter((item: Option) => diseasesMap[item.value])
								.map((item: Option) => item.label)
								.join(', ')}
						</div>
					</div>
					<div className={classes.patientBlock}>
						<div className={classes.subTitle}>{t('LEOMED_ADMIN_PATIENT_ALLERGIES')}</div>
						<div className={classes.patientText}>
							{allergies
								.filter((item: Option) => allergiesMap[item.value])
								.map((item: Option) => item.label)
								.join(', ')}
						</div>
					</div>
					<div className={classes.patientBlock}>
						<div className={classes.subTitle}>{t('LEOMED_ADMIN_PATIENT_TAKING_MEDICATIONS')}</div>
						<div className={classes.patientText}>
							{medications
								.filter((item: Option) => medicationsMap[item.value])
								.map((item: Option) => item.label)
								.join(', ')}
						</div>
					</div>
				</div>
			)}
		</div>
	)
}
