import { captureException, init } from '@sentry/browser'

const SentryClient = {
  init: (): void => {
    init({ dsn: 'https://093dba6a08e749faa6c9e2dee55eb180@o568976.ingest.sentry.io/5733663', environment: process.env.NODE_ENV })
  },
  captureException: (exception: unknown): void => {
    captureException(exception)
  },
}

export default SentryClient
