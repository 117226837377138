import { EmptyLayout } from 'layouts/EmptyLayout'
import React, { PropsWithChildren } from 'react'
import { Route, RouteComponentProps, RouteProps } from 'react-router-dom'

export type RouterWrapperProps<LP extends object> = RouteProps & {
	layout?: React.ComponentType<PropsWithChildren<LP | {}>>
	layoutProps?: LP | {}
}

const RouterWrapper = <LP extends object = {}>({
	component: Component,
	layout: Layout = EmptyLayout,
	layoutProps = {},
	...rest
}: RouterWrapperProps<LP>): React.ReactElement => {
	const render = Component
		? (componentProperties: RouteComponentProps<{}>): React.ReactElement => (
				<div>
					<Layout {...(layoutProps || {})}>
						<Component {...componentProperties} />
					</Layout>
				</div>
		  )
		: undefined

	return <Route {...rest} render={render} />
}

export default RouterWrapper
