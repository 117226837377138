import React from 'react'

import Button from '@material-ui/core/Button'
import { useTranslation } from 'react-i18next'
import MicIcon from '../../../icons/MicIcon'
import MicOffIcon from '../../../icons/MicOffIcon'

import useLocalAudioToggle from '../../../hooks/useLocalAudioToggle/useLocalAudioToggle'
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext'

export default function ToggleAudioButton(props: { disabled?: boolean; className?: string }) {
	const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle()
	const { t } = useTranslation()
	const { localTracks } = useVideoContext()
	const hasAudioTrack = localTracks.some(track => track.kind === 'audio')

	return (
		<Button
			className={props.className}
			onClick={toggleAudioEnabled}
			disabled={!hasAudioTrack || props.disabled}
			startIcon={isAudioEnabled ? <MicIcon /> : <MicOffIcon />}
			data-cy-audio-toggle
		>
			{!hasAudioTrack
				? t('LEOMED_ADMIN_CALL_NO_AUDIO')
				: isAudioEnabled
				? t('LEOMED_ADMIN_CALL_MUTE')
				: t('LEOMED_ADMIN_CALL_UNMUTE')}
		</Button>
	)
}
