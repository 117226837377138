import React from 'react'
import ParticipantList from '../ParticipantList/ParticipantList'
import { styled } from '@material-ui/core/styles'
import MainParticipant from '../MainParticipant/MainParticipant'

const Container = styled('div')(({ theme }) => {
	const totalMobileSidebarHeight = `${theme.sidebarMobileHeight +
		theme.sidebarMobilePadding * 2 +
		theme.participantBorderWidth}px`

	return {
		position: 'relative',
		height: '100%',
		display: 'grid',
		gridTemplateColumns: `1fr ${theme.sidebarWidth}px`,
		gridTemplateRows: '100%',
		[theme.breakpoints.down('sm')]: {
			gridTemplateColumns: `100%`,
			gridTemplateRows: `calc(100% - ${totalMobileSidebarHeight}) ${totalMobileSidebarHeight}`,
		},
	}
})

export default function Room() {
	return (
		<Container>
			<MainParticipant />
			<ParticipantList />
		</Container>
	)
}
