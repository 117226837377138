import { makeStyles, Tab, Tabs, Typography } from '@material-ui/core'
import React, { FC, useState } from 'react'

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function a11yProps(index: any) {
  return {
    id: `wrapped-tab-${index}`,
    'aria-controls': `wrapped-tabpanel-${index}`,
  };
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Typography>{children}</Typography>
      )}
    </div>
  )
}

type TabsProps = {
  tabs: {
    label: any
    content: any
    onClick?: () => void
  }[]
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}))

export const TabsNavigation: FC<TabsProps> = ({ tabs }) => {
  const classes = useStyles()
  const [value, setValue] = useState(0)

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue)
  }

  return (
    <div className={classes.root}>
      <Tabs value={value} onChange={handleChange} textColor="primary" indicatorColor="primary">
        {tabs.map((item, index) => (
          <Tab label={item.label} onClick={item.onClick} style={{ textTransform: 'none', fontWeight: 500, padding: '6px 36px' }} {...a11yProps(index)} />
        ))}
      </Tabs>
      {tabs.map((item, index) => (
        <TabPanel value={value} index={index}>
          {item.content}
        </TabPanel>
      ))}
    </div>
  )
}
