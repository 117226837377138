import { useState, useEffect, useCallback } from 'react'

type UseCountdown = {
	time: string
	over: boolean
}

export const useCountdown = (secondsLeft?: number): UseCountdown => {
	const isNoTimeLeft = secondsLeft! <= 0
	const [over, setOver] = useState(isNoTimeLeft || false)
	const [time, setTime] = useState({
		minutes: 0,
		seconds: secondsLeft || 30,
	})
	const finalTime = `${time.minutes.toString().padStart(2, '0')}:${
		isNoTimeLeft ? '00' : time.seconds.toString().padStart(2, '0')
	}
    `

	const tick = useCallback(() => {
		if (over) return
		if ((time.minutes === 0 && time.seconds === 0) || time.seconds <= 0) setOver(true)
		else {
			setTime({
				minutes: time.minutes,
				seconds: time.seconds - 1,
			})
		}
	}, [over, time.minutes, time.seconds])

	useEffect(() => {
		let timerID = setInterval(() => tick(), 1000)
		return () => clearInterval(timerID)
	}, [tick])

	return { time: finalTime, over }
}
