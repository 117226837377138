import React, { FC } from 'react'
import clsx from 'clsx'
import { Paper, makeStyles, Typography, CircularProgress } from '@material-ui/core'
import { OverlayButton } from 'components/common/OverlayButton'
import { Colors } from 'utils/theme'
import { useSelector } from 'react-redux'
import { getDoctorWorking } from 'pages/Office/redux/selectors'

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		padding: theme.spacing(20),
	},
	wrapper: {
		width: 470,
		padding: theme.spacing(6),
		textAlign: 'center',
	},
	logoName: {
		fontWeight: 700,
	},
	registered: {
		fontWeight: 700,
		marginTop: theme.spacing(4),
		fontSize: '24px',
	},
	onReview: {
		fontSize: '16px',
		marginTop: theme.spacing(4),
	},
	status: {
		fontSize: '16px',
		marginTop: theme.spacing(8),
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	statusCircle: {
		width: 28,
		height: 28,
		backgroundColor: theme.palette.primary.main,
		marginRight: theme.spacing(2),
		borderRadius: '50%',
	},
	submit: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		marginTop: theme.spacing(4),
	},
	imgWrapper: {
		width: '65px',
		height: '65px',
	},
	buttonClassName: {
		backgroundColor: theme.palette.primary.dark,
		color: theme.palette.primary.light,
		textTransform: 'capitalize',
		width: '75%',
		fontSize: 16,
		padding: theme.spacing(2, 0),
		lineHeight: 1.2,
		borderRadius: 10,
		'&:hover': {
			backgroundColor: theme.palette.primary.dark,
		},
	},
	green: {
		backgroundColor: Colors.green[500],
		'&:hover': {
			backgroundColor: Colors.green[500],
		},
	},
}))

type InfoBannerProps = {
	title: string
	description: string
	status?: string
	clickHandler?: () => void
	btnText?: string
	isSubmitting?: boolean
	color?: 'green'
}

export const InfoBanner: FC<InfoBannerProps> = ({
	title,
	description,
	status,
	clickHandler,
	btnText,
	isSubmitting,
	color,
}) => {
	const classes = useStyles()
	const active = useSelector(getDoctorWorking)
	const btnClasses = clsx(classes.buttonClassName, {
		[classes.green]: color === 'green',
	})
	const statusClasses = clsx(classes.statusCircle, {
		[classes.green]: color === 'green',
	})

	return (
		<div className={classes.root}>
			<Paper className={classes.wrapper}>
				<div>
					<img
						alt="Logo"
						src={`${process.env.PUBLIC_URL}/logo.svg`}
						className={classes.imgWrapper}
					/>
				</div>
				<Typography variant="body1" className={classes.logoName}>
					LEODOC
				</Typography>
				<div className={classes.registered}>{title}</div>
				<div className={classes.onReview}>{description}</div>
				{status && (
					<div className={classes.status}>
						<div className={statusClasses} />
						{status}
					</div>
				)}
				{clickHandler && !active && (
					<OverlayButton
						className={classes.submit}
						buttonClassName={btnClasses}
						type="submit"
						variant="contained"
						onClick={clickHandler}
						overlay={isSubmitting && <CircularProgress size={24} />}
					>
						{btnText}
					</OverlayButton>
				)}
			</Paper>
		</div>
	)
}
