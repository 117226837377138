import React, { FC } from 'react'
import { CircularProgress, fade, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
	root: {
		position: 'absolute',
		width: '100%',
		height: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		backgroundColor: fade(theme.palette.background.paper, 0.7),
		zIndex: 10,
	},
}))

export const OverlayContainer: FC = () => {
	const classes = useStyles()

	return (
		<div className={classes.root}>
			<CircularProgress size={50} />
		</div>
	)
}
