import React, { FC, useEffect } from 'react'
import { makeStyles, Tab, Tabs } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { NavPanel } from 'pages/Office/components/NavPanel'
import { PersonalContainer } from './PersonalContainer'
import { EducationContainer } from './EducationContainer'
import { SpecializationContainer } from './SpecializationContainer'
import { ExperienceContainer } from './ExperienceContainer'
import { useDispatch, useSelector } from 'react-redux'
import { getNeedRedirectToOffice } from '../redux/selectors'
import { useHistory } from 'react-router'
import { RoutePath } from 'router/routes'
import { profileActions } from '../redux/actions'

export const useStyles = makeStyles(theme => ({
	tabs: {
		padding: theme.spacing(2, 2, 0, 2),
		border: `1px solid ${theme.palette.divider}`,
	},
}))

export const ProfileContainer: FC = () => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const [value, setValue] = React.useState(0)
	const { t } = useTranslation()
	const history = useHistory()
	const needRedirectToOffice = useSelector(getNeedRedirectToOffice)

	const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
		setValue(newValue)
	}

	useEffect(() => {
		if (needRedirectToOffice) {
			dispatch(profileActions.needRedirectToOffice(false))
			history.push(RoutePath.office)
		}
	}, [dispatch, history, needRedirectToOffice])

	return (
		<div>
			<Tabs value={value} onChange={handleChange} className={classes.tabs} indicatorColor="primary">
				<Tab label={t('LEOMED_ADMIN_PROFILE_PAGE_PERSONAL_DATA')} />
				<Tab label={t('LEOMED_ADMIN_PROFILE_PAGE_EDUCATION')} />
				<Tab label={t('LEOMED_ADMIN_PROFILE_PAGE_SPECIALIZATION')} />
				<Tab label={t('LEOMED_ADMIN_PROFILE_PAGE_WORK_EXPERIENCE')} />
			</Tabs>
			<NavPanel value={value} index={0}>
				<PersonalContainer />
			</NavPanel>
			<NavPanel value={value} index={1}>
				<EducationContainer />
			</NavPanel>
			<NavPanel value={value} index={2}>
				<SpecializationContainer />
			</NavPanel>
			<NavPanel value={value} index={3}>
				<ExperienceContainer />
			</NavPanel>
		</div>
	)
}
