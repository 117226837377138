import { Snackbar } from '@material-ui/core'
import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { getSocketNotifications } from 'store/alerts/selectors'
import { SocketNotification } from './SocketNotification'

export const SocketNotifications: FC = () => {
  const notifications = useSelector(getSocketNotifications)

  return (
    <>
      {notifications.map((item, index) => (
        <Snackbar
          open
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          style={{ top: `calc(95px + ${100*index}px)`}}
          key={index}
        >
          <SocketNotification data={item.data} type={item.type} />
        </Snackbar>
      ))}
    </>
  )
}
