import { toEntityIds, toEntityItems } from 'utils/redux/helpers'

export const formatProfile = ({ profile, state }: any) => {
	return {
		personalInfo: {
			city: profile.city,
			birthDate: profile.birthDate,
			country: profile.country,
			email: profile.email,
			firstName: profile.firstName,
			gender: profile.gender,
			language: profile.language,
			lastName: profile.lastName,
			middleName: profile.middleName,
			phone: profile.phone,
			avatar: profile.avatar?.url || '',
		},
		educationItemsIds: toEntityIds(profile.education),
		educationItems: toEntityItems(profile.education),
		specializationItemsIds: toEntityIds(profile.speciality),
		specializationItems: toEntityItems(profile.speciality),
		experienceItemsIds: toEntityIds(profile.employmentHistory),
		experienceItems: toEntityItems(profile.employmentHistory),
		activated: profile.activation?.activated ?? false,
		step: state.pageId || 0,
	}
}
