import { createAsyncAction, createAction } from 'typesafe-actions'
import { MedicalReport } from 'pages/Call/redux/model'
import { Consultations } from './utils/formatConsultations'
import * as actionType from './const'
import { CardDTO } from './model'
import { TransactionHistory } from './dto/TransactionHistory'

export const officeActions = {
	toggleDoctorStatus: createAsyncAction(
		actionType.TOGGLE_DOCTOR_STATUS_REQUEST,
		actionType.TOGGLE_DOCTOR_STATUS_SUCCESS,
		actionType.TOGGLE_DOCTOR_STATUS_FAILURE
	)<any, { isDoctorWorking: boolean }, { message?: string }>(),
	getDoctorStatus: createAsyncAction(
		actionType.GET_DOCTOR_STATUS_REQUEST,
		actionType.GET_DOCTOR_STATUS_SUCCESS,
		actionType.GET_DOCTOR_STATUS_FAILURE
	)<any, { isDoctorWorking: boolean }, { message?: string }>(),
	getPatientInfo: createAsyncAction(
		actionType.GET_PATIENT_INFO_REQUEST,
		actionType.GET_PATIENT_INFO_SUCCESS,
		actionType.GET_PATIENT_INFO_FAILURE
	)<any, { patientInfo: any; conferenceUrl: string; accessToken: string }, { message?: string }>(),
	createRoom: createAsyncAction(
		actionType.CREATE_ROOM_REQUEST,
		actionType.CREATE_ROOM_SUCCESS,
		actionType.CREATE_ROOM_FAILURE
	)<
		{ callback: any },
		{ conferenceUrl: string; accessToken: string; status: string },
		{ message?: string }
	>(),
	acceptPatient: createAsyncAction(
		actionType.ACCEPT_PATIENT_REQUEST,
		actionType.ACCEPT_PATIENT_SUCCESS,
		actionType.ACCEPT_PATIENT_FAILURE
	)<{ callback: any }, { patientInfo: any }, { message?: string }>(),
	declinePatient: createAsyncAction(
		actionType.DECLINE_PATIENT_REQUEST,
		actionType.DECLINE_PATIENT_SUCCESS,
		actionType.DECLINE_PATIENT_FAILURE
	)<any, { patientInfo: any; conferenceUrl: string; accessToken?: string }, { message?: string }>(),
	sendReport: createAsyncAction(
		actionType.SEND_REPORT_REQUEST,
		actionType.SEND_REPORT_SUCCESS,
		actionType.SEND_REPORT_FAILURE
	)<{ medicalReport: MedicalReport }, {}, { message?: string }>(),
	getPaymentMethods: createAsyncAction(
		actionType.GET_PAYMENT_METHODS_REQUEST,
		actionType.GET_PAYMENT_METHODS_SUCCESS,
		actionType.GET_PAYMENT_METHODS_FAILURE
	)<{}, { cards: any[] }, { message?: string }>(),
	createPaymentMethod: createAsyncAction(
		actionType.CREATE_PAYMENT_METHOD_REQUEST,
		actionType.CREATE_PAYMENT_METHOD_SUCCESS,
		actionType.CREATE_PAYMENT_METHOD_FAILURE
	)<{ cardData: CardDTO; callback: () => void }, { cards: any[] }, { message?: string }>(),
	deletePaymentMethod: createAsyncAction(
		actionType.DELETE_PAYMENT_METHOD_REQUEST,
		actionType.DELETE_PAYMENT_METHOD_SUCCESS,
		actionType.DELETE_PAYMENT_METHOD_FAILURE
	)<{ id: string }, { cards: any[] }, { message?: string }>(),
	makeDefaultPaymentMethod: createAsyncAction(
		actionType.MAKE_DEFAULT_PAYMENT_METHOD_REQUEST,
		actionType.MAKE_DEFAULT_PAYMENT_METHOD_SUCCESS,
		actionType.MAKE_DEFAULT_PAYMENT_METHOD_FAILURE
	)<{ id: string }, { cards: any[] }, { message?: string }>(),
	getDoctorConsultations: createAsyncAction(
		actionType.GET_DOCTOR_CONSULTATIONS_REQUEST,
		actionType.GET_DOCTOR_CONSULTATIONS_SUCCESS,
		actionType.GET_DOCTOR_CONSULTATIONS_FAILURE
	)<{}, { consultations: Consultations }, { message?: string }>(),
	getDoctorBalance: createAsyncAction(
		actionType.GET_DOCTOR_BALANCE_REQUEST,
		actionType.GET_DOCTOR_BALANCE_SUCCESS,
		actionType.GET_DOCTOR_BALANCE_FAILURE
	)<{}, { balance: number }, { message?: string }>(),
	withdrawMoney: createAsyncAction(
		actionType.WITHDRAW_MONEY_REQUEST,
		actionType.WITHDRAW_MONEY_SUCCESS,
		actionType.WITHDRAW_MONEY_FAILURE
	)<{ cardNumber: string; amount: string; callback: any }, {}, { message?: string }>(),
	getWithdrawalList: createAsyncAction(
		actionType.GET_WITHDRAWAL_LIST_REQUEST,
		actionType.GET_WITHDRAWAL_LIST_SUCCESS,
		actionType.GET_WITHDRAWAL_LIST_FAILURE
	)<{
		page: number,
		limit: number
	}, {
		data: TransactionHistory[],
		page: number,
		total: number,
		limit: number,
	}, { message?: string }>(),
	getWithdrawalStatus: createAsyncAction(
		actionType.GET_WITHDRAWAL_STATUS_REQUEST,
		actionType.GET_WITHDRAWAL_STATUS_SUCCESS,
		actionType.GET_WITHDRAWAL_STATUS_FAILURE
	)<{ id: string }, { id: string; data: TransactionHistory }, { id: string; message?: string }>(),
	getNotifications: createAsyncAction(
		actionType.GET_NOTIFICATIONS_REQUEST,
		actionType.GET_NOTIFICATIONS_SUCCESS,
		actionType.GET_NOTIFICATIONS_FAILURE
	)<{}, { notifications: any[] }, { message?: string }>(),
	postFeedback: createAsyncAction(
		actionType.POST_FEEDBACK_REQUEST,
		actionType.POST_FEEDBACK_SUCCESS,
		actionType.POST_FEEDBACK_FAILURE
	)<
		{
			data: FormData,
			alertMessage: string,
			callback: () => void
		},
		{ alertMessage: string }, { message?: string }>(),
	updateNotificationId: createAsyncAction(
		actionType.UPDATE_NOTIFICATION_ID_REQUEST,
		actionType.UPDATE_NOTIFICATION_ID_SUCCESS,
		actionType.UPDATE_NOTIFICATION_ID_FAILURE
	)<{ id: string }, { id: string; notifications: any[] }, { id: string; message?: string }>(),
	initialLoad: createAction(actionType.INITIAL_LOAD)<any>(),
	hideSplash: createAction(actionType.HIDE_SPLASH)(),
	toggleContactUs: createAction(actionType.TOGGLE_CONTACT_US)(),
}
