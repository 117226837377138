import React, { FC, useMemo } from 'react'
import { Backdrop, CircularProgress, makeStyles } from '@material-ui/core'
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
	suspenseBackdrop: {
		backgroundColor: theme.palette.primary.main,
	},
}))

type SplashScreenProps = {
	open: boolean
	suspense?: boolean
}

export const SplashScreen: FC<SplashScreenProps> = ({ open, suspense }) => {
	const classes = useStyles()
	const backdropStyles = clsx(classes.backdrop, {
		[classes.suspenseBackdrop]: suspense,
	})
	const loadingText = useMemo(
		() => (localStorage.i18nextLng === 'en' ? 'Loading...' : 'Загрузка...'),
		[]
	)

	return (
		<Backdrop className={backdropStyles} open={open}>
			{suspense ? <h1>{loadingText}</h1> : <CircularProgress color="inherit" />}
		</Backdrop>
	)
}
