import { Paper, makeStyles, CircularProgress } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts'
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import clsx from 'clsx'
import { Colors } from 'utils/theme'
import { useDispatch, useSelector } from 'react-redux'
import { officeActions } from '../redux/actions'
import { getConsultations, getConsultationsLoading } from '../redux/selectors'
import { SelectField } from 'components/common/inputs/SelectField'
import { Month } from '../redux/utils/formatConsultations'
import { isLoading } from 'utils/types/store'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: theme.spacing(1),
  },
  flexAlign: {
		display: 'flex',
		alignItems: 'center',
	},
  mb2: {
		marginBottom: theme.spacing(2),
	},
  numbers: {
		fontSize: '36px',
		color: theme.palette.primary.main,
		fontWeight: 500,
		marginRight: theme.spacing(2),
	},
  loadingRoot: {
    width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		padding: theme.spacing(2),
		minHeight: 300,
	},
  header: {
    display: 'flex',
    alignItems: 'center',
		justifyContent: 'space-between',
  },
  select: {
    width: 150,
  },
  customTooltip: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4),
    border: `1px solid ${theme.palette.primary.main}`
  },
  tooltipLabel: {
    marginBottom: theme.spacing(2),
  },
  noData: {
    padding: theme.spacing(4),
    textAlign: 'center',
    textTransform: 'uppercase',
    fontWeight: 700
  }
}))

const CustomTooltip = ({ active, payload, label }: any) => {
  const classes = useStyles()
  const { t } = useTranslation()
  if (active && payload && payload.length) {
    return (
      <div className={classes.customTooltip}>
        <div className={classes.tooltipLabel}>{`${t('LEOMED_ADMIN_OFFICE_CONSULTATIONS_TOOLTIP_DATE')}: ${label}`}</div>
        <p>{`${t('LEOMED_ADMIN_OFFICE_CONSULTATIONS_TOOLTIP_COUNT')} : ${payload[0].value}`}</p>
      </div>
    );
  }

  return null;
};

export const EarnedGraphContainer: FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation(['translation', 'months'])
  const consultations = useSelector(getConsultations)
  const [month, setMonth] = useState<Month | null>(null)
  const mbClasses = clsx(classes.flexAlign, classes.mb2)
  const consultationsEntries = Object.entries(consultations)
  const getConsultationsSubmitting = useSelector(getConsultationsLoading)
	const isGetConsultationsSubmitting = isLoading(getConsultationsSubmitting)

  useEffect(() => {
    dispatch(officeActions.getDoctorConsultations.request({}))
  }, [dispatch])

  const onSelectChange = useCallback((month) => {
    const newMonth = consultations[month.target.value]
    setMonth(newMonth)
  }, [consultations])

  const selectOptions = useMemo(() => {
    return consultationsEntries.map((item => {
      const date = moment(item[0], "MMMM YYYY")
      const month = date.format('MMMM')
      const year = date.format('YYYY')
      return {
        value: item[0],
        label: `${t(`months:${month}`)} ${year}`,
      }
    })).reverse()
  }, [consultationsEntries, t])

  useEffect(() => {
    if (!month) {
      setMonth(consultations[selectOptions[0]?.value])
    }
  }, [month, consultations, selectOptions])

  if (isGetConsultationsSubmitting) {
    return (
			<Paper className={classes.loadingRoot}>
				<CircularProgress />
			</Paper>
		)
  }

  if (consultationsEntries.length === 0) {
    return (
      <Paper className={classes.root}>
        <div className={classes.noData}>{t('LEOMED_ADMIN_NO_DATA')}</div>
      </Paper>
    )
  }

  return (
    <Paper className={classes.root}>
      <div className={classes.header}>
        <div className={mbClasses}>
          <div className={classes.numbers}>{month?.total}</div>
          <div>{t('LEOMED_ADMIN_OFFICE_CONSULTATIONS_TITLE')}</div>
        </div>
        <div className={classes.select}>
          <SelectField
            options={selectOptions}
            variant="standard"
            defaultValue={selectOptions[0]?.value}
            onChange={onSelectChange}
          />
        </div>
      </div>
      <div style={{ width: "100%", height: 300 }}>
        <ResponsiveContainer>
          <BarChart data={month?.data} margin={{ right: 30, left: 30, bottom: 30 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip content={<CustomTooltip />} />
            <XAxis dataKey="date" label={{ value: t('LEOMED_ADMIN_OFFICE_CONSULTATIONS_GRAPH_X'), position: 'bottom' }} />
            <YAxis label={{ value: t('LEOMED_ADMIN_OFFICE_CONSULTATIONS_GRAPH_Y'), position: 'insideLeft', angle: -90 }} />
            <Bar dataKey="patientsCount" fill={Colors.green[500]} maxBarSize={200} />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </Paper>
  )
}
