import { createReducer, ActionType } from 'typesafe-actions'
import { Loading, makeLoading, LoadingDefault, LoadingState, OneToOne } from 'utils/types/store'
import { officeActions } from 'pages/Office/redux/actions'
import { oneInsert } from 'utils/mutators/relations'

export type State = {
	splashVisible: boolean
	isContactUsOpen: boolean
	toggleDoctorIsWorkingLoading: Loading
	getDoctorStatusLoading: Loading
	createRoomLoading: Loading
	sendReportLoading: Loading
	getPaymentMethodsLoading: Loading
	createPaymentMethodLoading: Loading
	getDoctorBalanceLoading: Loading
	withdrawMoneyLoading: Loading
	getConsultationsLoading: Loading
	getWithdrawalListLoading: Loading
	postFeedbackLoading: Loading
	getWithdrawalStatusLoadingById: OneToOne<string, Loading>
}

export const initialState: State = {
	splashVisible: true,
	isContactUsOpen: false,
	toggleDoctorIsWorkingLoading: LoadingDefault,
	getDoctorStatusLoading: LoadingDefault,
	createRoomLoading: LoadingDefault,
	sendReportLoading: LoadingDefault,
	getPaymentMethodsLoading: LoadingDefault,
	createPaymentMethodLoading: LoadingDefault,
	getDoctorBalanceLoading: LoadingDefault,
	withdrawMoneyLoading: LoadingDefault,
	getConsultationsLoading: LoadingDefault,
	getWithdrawalListLoading: LoadingDefault,
	postFeedbackLoading: LoadingDefault,
	getWithdrawalStatusLoadingById: {},
}

export default createReducer<State, ActionType<typeof officeActions>>(initialState)
	.handleAction(
		officeActions.toggleDoctorStatus.request,
		(state): State => ({
			...state,
			toggleDoctorIsWorkingLoading: makeLoading(LoadingState.loading),
		})
	)
	.handleAction(
		officeActions.toggleDoctorStatus.success,
		(state): State => ({
			...state,
			toggleDoctorIsWorkingLoading: makeLoading(LoadingState.success),
		})
	)
	.handleAction(
		officeActions.toggleDoctorStatus.failure,
		(state, { payload: { message } }): State => ({
			...state,
			toggleDoctorIsWorkingLoading: makeLoading(LoadingState.failure, message),
		})
	)

	.handleAction(
		[officeActions.createRoom.request, officeActions.declinePatient.request],
		(state): State => ({ ...state, createRoomLoading: makeLoading(LoadingState.loading) })
	)
	.handleAction(
		[officeActions.createRoom.success, officeActions.declinePatient.success],
		(state): State => ({ ...state, createRoomLoading: makeLoading(LoadingState.success) })
	)
	.handleAction(
		[officeActions.createRoom.failure, officeActions.declinePatient.failure],
		(state, { payload: { message } }): State => ({
			...state,
			createRoomLoading: makeLoading(LoadingState.failure, message),
		})
	)

	.handleAction(
		officeActions.getDoctorStatus.request,
		(state): State => ({ ...state, getDoctorStatusLoading: makeLoading(LoadingState.loading) })
	)
	.handleAction(
		officeActions.getDoctorStatus.success,
		(state): State => ({ ...state, getDoctorStatusLoading: makeLoading(LoadingState.success) })
	)
	.handleAction(
		officeActions.getDoctorStatus.failure,
		(state, { payload: { message } }): State => ({
			...state,
			getDoctorStatusLoading: makeLoading(LoadingState.failure, message),
		})
	)

	.handleAction(
		officeActions.sendReport.request,
		(state): State => ({ ...state, sendReportLoading: makeLoading(LoadingState.loading) })
	)
	.handleAction(
		officeActions.sendReport.success,
		(state): State => ({ ...state, sendReportLoading: makeLoading(LoadingState.success) })
	)
	.handleAction(
		officeActions.sendReport.failure,
		(state, { payload: { message } }): State => ({
			...state,
			sendReportLoading: makeLoading(LoadingState.failure, message),
		})
	)

	.handleAction(
		officeActions.postFeedback.request,
		(state): State => ({ ...state, postFeedbackLoading: makeLoading(LoadingState.loading) })
	)
	.handleAction(
		officeActions.postFeedback.success,
		(state): State => ({ ...state, postFeedbackLoading: makeLoading(LoadingState.success) })
	)
	.handleAction(
		officeActions.postFeedback.failure,
		(state, { payload: { message } }): State => ({
			...state,
			postFeedbackLoading: makeLoading(LoadingState.failure, message),
		})
	)

	.handleAction(
		officeActions.getPaymentMethods.request,
		(state): State => ({ ...state, getPaymentMethodsLoading: makeLoading(LoadingState.loading) })
	)
	.handleAction(
		officeActions.getPaymentMethods.success,
		(state): State => ({ ...state, getPaymentMethodsLoading: makeLoading(LoadingState.success) })
	)
	.handleAction(
		officeActions.getPaymentMethods.failure,
		(state, { payload: { message } }): State => ({
			...state,
			getPaymentMethodsLoading: makeLoading(LoadingState.failure, message),
		})
	)

	.handleAction(
		officeActions.getWithdrawalList.request,
		(state): State => ({ ...state, getWithdrawalListLoading: makeLoading(LoadingState.loading) })
	)
	.handleAction(
		officeActions.getWithdrawalList.success,
		(state): State => ({ ...state, getWithdrawalListLoading: makeLoading(LoadingState.success) })
	)
	.handleAction(
		officeActions.getWithdrawalList.failure,
		(state, { payload: { message } }): State => ({
			...state,
			getWithdrawalListLoading: makeLoading(LoadingState.failure, message),
		})
	)

	.handleAction(
		officeActions.createPaymentMethod.request,
		(state): State => ({ ...state, createPaymentMethodLoading: makeLoading(LoadingState.loading) })
	)
	.handleAction(
		officeActions.createPaymentMethod.success,
		(state): State => ({ ...state, createPaymentMethodLoading: makeLoading(LoadingState.success) })
	)
	.handleAction(
		officeActions.createPaymentMethod.failure,
		(state, { payload: { message } }): State => ({
			...state,
			createPaymentMethodLoading: makeLoading(LoadingState.failure, message),
		})
	)

	.handleAction(
		officeActions.getDoctorBalance.request,
		(state): State => ({ ...state, getDoctorBalanceLoading: makeLoading(LoadingState.loading) })
	)
	.handleAction(
		officeActions.getDoctorBalance.success,
		(state): State => ({ ...state, getDoctorBalanceLoading: makeLoading(LoadingState.success) })
	)
	.handleAction(
		officeActions.getDoctorBalance.failure,
		(state, { payload: { message } }): State => ({
			...state,
			getDoctorBalanceLoading: makeLoading(LoadingState.failure, message),
		})
	)

	.handleAction(
		officeActions.withdrawMoney.request,
		(state): State => ({ ...state, withdrawMoneyLoading: makeLoading(LoadingState.loading) })
	)
	.handleAction(
		officeActions.withdrawMoney.success,
		(state): State => ({ ...state, withdrawMoneyLoading: makeLoading(LoadingState.success) })
	)
	.handleAction(
		officeActions.withdrawMoney.failure,
		(state, { payload: { message } }): State => ({
			...state,
			withdrawMoneyLoading: makeLoading(LoadingState.failure, message),
		})
	)

	.handleAction(
		officeActions.getDoctorConsultations.request,
		(state): State => ({ ...state, getConsultationsLoading: makeLoading(LoadingState.loading) })
	)
	.handleAction(
		officeActions.getDoctorConsultations.success,
		(state): State => ({ ...state, getConsultationsLoading: makeLoading(LoadingState.success) })
	)
	.handleAction(
		officeActions.getDoctorConsultations.failure,
		(state, { payload: { message } }): State => ({
			...state,
			getConsultationsLoading: makeLoading(LoadingState.failure, message),
		})
	)

	.handleAction(
    officeActions.getWithdrawalStatus.request,
    (state, { payload: { id } }): State => ({
      ...state,
      getWithdrawalStatusLoadingById: oneInsert(
        makeLoading(LoadingState.loading),
        id,
        state.getWithdrawalStatusLoadingById
      ),
    })
  )
  .handleAction(
    officeActions.getWithdrawalStatus.success,
    (state, { payload: { id } }): State => ({
      ...state,
      getWithdrawalStatusLoadingById: oneInsert(
        makeLoading(LoadingState.success),
        id,
        state.getWithdrawalStatusLoadingById
      ),
    })
  )
  .handleAction(
    officeActions.getWithdrawalStatus.failure,
    (state, { payload: { id, message } }): State => ({
      ...state,
      getWithdrawalStatusLoadingById: oneInsert(
        makeLoading(LoadingState.failure, message),
        id,
        state.getWithdrawalStatusLoadingById
      ),
    })
  )

	.handleAction(officeActions.hideSplash, (state): State => ({ ...state, splashVisible: false }))
	.handleAction(officeActions.toggleContactUs, (state) => ({
		...state,
		isContactUsOpen: !state.isContactUsOpen,
	}))
