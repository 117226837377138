export const GET_CONSULTATION_REQUESTS_REQUEST = 'GET_CONSULTATION_REQUESTS_REQUEST'
export const GET_CONSULTATION_REQUESTS_SUCCESS = 'GET_CONSULTATION_REQUESTS_SUCCESS'
export const GET_CONSULTATION_REQUESTS_FAILURE = 'GET_CONSULTATION_REQUESTS_FAILURE'

export const APPROVE_CONSULTATION_REQUEST_REQUEST = 'APPROVE_CONSULTATION_REQUEST_REQUEST'
export const APPROVE_CONSULTATION_REQUEST_SUCCESS = 'APPROVE_CONSULTATION_REQUEST_SUCCESS'
export const APPROVE_CONSULTATION_REQUEST_FAILURE = 'APPROVE_CONSULTATION_REQUEST_FAILURE'

export const REJECT_CONSULTATION_REQUEST_REQUEST = 'REJECT_CONSULTATION_REQUEST_REQUEST'
export const REJECT_CONSULTATION_REQUEST_SUCCESS = 'REJECT_CONSULTATION_REQUEST_SUCCESS'
export const REJECT_CONSULTATION_REQUEST_FAILURE = 'REJECT_CONSULTATION_REQUEST_FAILURE'

export const UPDATE_CONSULTATION_REQUEST_REQUEST = 'UPDATE_CONSULTATION_REQUEST_REQUEST'
export const UPDATE_CONSULTATION_REQUEST_SUCCESS = 'UPDATE_CONSULTATION_REQUEST_SUCCESS'
export const UPDATE_CONSULTATION_REQUEST_FAILURE = 'UPDATE_CONSULTATION_REQUEST_FAILURE'

export const PICK_ID_FOR_CHANGE_TIME_DIALOG = 'PICK_ID_FOR_CHANGE_TIME_DIALOG'
export const PICK_ID_FOR_NEW_REQUEST_DIALOG = 'PICK_ID_FOR_NEW_REQUEST_DIALOG'
export const CONSULTATION_REQUEST_HAS_DATE_CONFLICT = 'CONSULTATION_REQUEST_HAS_DATE_CONFLICT'