import { CircularProgress, createStyles, makeStyles, Paper } from '@material-ui/core'
import { PaginationControls } from 'layouts/parts/Controls/PaginationControls'
import { ConsultationRequest } from 'pages/ConsultationRequests/redux/dto/ConsultationRequest'
import { useScheduledRequests } from 'pages/MyCalendar/hooks/useScheduledRequests'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { MyScheduleRow } from './MyScheduleRow'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: { position: 'relative' },
    empty: { padding: theme.spacing(3), textAlign: 'center' },
    noData: {
      padding: theme.spacing(4),
      textAlign: 'center',
      textTransform: 'uppercase',
      fontWeight: 700
    },
    loadingRoot: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: theme.spacing(2),
      minHeight: 100,
    },
    wrapper: {
      padding: theme.spacing(2)
    },
    pagination: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }
  })
)

export type MyScheduleListProps = {
  requests: ConsultationRequest[];
  loading: boolean;
  handlePageChange: (value: number) => void
}

export const MyScheduleList: FC<MyScheduleListProps> = ({ requests, loading, handlePageChange }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { total, limit, page } = useScheduledRequests()

  const pageTotal = Math.ceil(total / limit)

  if (loading) {
    return (
			<Paper className={classes.loadingRoot}>
				<CircularProgress />
			</Paper>
		)
  }

  return (
    <div>
      {requests.length !== 0 ? (
        <div className={classes.wrapper}>
          {requests.map(request => <MyScheduleRow data={request} />)}
          <div className={classes.pagination}>
            <PaginationControls
              page={page}
              pageCount={pageTotal}
              onPageChange={handlePageChange}
            />
          </div>
        </div>
      ) : <div className={classes.noData}>{t('LEOMED_ADMIN_NO_DATA')}</div>}
    </div>
  )
}
