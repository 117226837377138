import { useTranslation } from 'react-i18next'
import { useCallback } from 'react'

export const useLocalization = () => {
	const { i18n } = useTranslation()

	const changeLngHandler = useCallback(
		lan => {
			i18n.changeLanguage(lan)
		},
		[i18n]
	)

	return {
		changeLngHandler,
	}
}
