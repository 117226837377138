import React, { FC } from 'react'
import { NavLink as RouterLink } from 'react-router-dom'
import clsx from 'clsx'

import { Link, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
	link: {
		display: 'flex',
		alignItems: 'center',
		color: '#9CB1C9',
		padding: theme.spacing(2),
		borderRadius: 10,
		fontWeight: 600,
		fontSize: 14,
		textDecoration: 'none',
		'&:hover': {
			textDecoration: 'none',
			backgroundColor: '#F3F7FC',
			color: '#000',
			'& > div': {
				backgroundColor: '#F3F7FC',
			},
		},
	},
	selected: {
		backgroundColor: '#F3F7FC',
		color: '#000',
	},
	linkWrapper: {
		padding: theme.spacing(0, 1),
		marginBottom: theme.spacing(1)
	},
	text: {
		marginLeft: theme.spacing(1),
	},
	menuClosedCounter: {
		position: 'relative',
		top: -20,
		right: 10,
	}
}))

type NavigationLinkProps = {
	to: string
	text: string,
	icon: any
	counter?: any
	onlyIcon: boolean
}

export const NavigationLink: FC<NavigationLinkProps> = ({ to, text, icon, counter, onlyIcon }) => {
	const classes = useStyles()
	const counterStyles = clsx({
		[classes.menuClosedCounter]: onlyIcon
	})

	return (
		<div className={classes.linkWrapper}>
			<Link
				component={RouterLink}
				to={to}
				color="textPrimary"
				className={classes.link}
				activeClassName={classes.selected}
			>
				{icon}
				{!onlyIcon && <span className={classes.text}>{text}</span>}
				{counter && (
					<div className={counterStyles}>{counter}</div>
				)}
			</Link>
		</div>
	)
}
