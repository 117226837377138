import { Typography, makeStyles } from '@material-ui/core'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { ConsultationRequestsContainer } from './containers/ConsultationRequestsContainerRequestsContainer'

const useStyles = makeStyles(theme => ({
  root: {
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
		marginTop: theme.spacing(14),
	},
  title: {
		fontSize: '24px',
		fontWeight: 700,
	},
}))

export const ConsultationRequestsPage: FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <div className={classes.root}>
      <Typography className={classes.title}>{t('LEOMED_ADMIN_OFFICE_CONSULTATION_REQUESTS_TITLE')}</Typography>
      <ConsultationRequestsContainer />
    </div>
  )
}