import { ActionType, createReducer } from "typesafe-actions"
import { oneInsert } from "utils/mutators/relations"
import { Loading, LoadingDefault, LoadingState, makeLoading, OneToOne } from "utils/types/store"
import { consultationRequestsActions } from "../actions"

export type State = {
  approveConsultationRequestLoadingById: OneToOne<string, Loading>
	rejectConsultationRequestLoadingById: OneToOne<string, Loading>
	updateConsultationRequestLoading: Loading
  getConsultationRequestsLoading: Loading
}

export const initialState: State = {
  approveConsultationRequestLoadingById: {},
	rejectConsultationRequestLoadingById: {},
	updateConsultationRequestLoading: LoadingDefault,
  getConsultationRequestsLoading: LoadingDefault,
}

export default createReducer<State, ActionType<typeof consultationRequestsActions>>(initialState)
  .handleAction(
    consultationRequestsActions.getConsultationRequests.request,
    (state): State => ({ ...state, getConsultationRequestsLoading: makeLoading(LoadingState.loading) })
  )
  .handleAction(
    consultationRequestsActions.getConsultationRequests.success,
    (state): State => ({ ...state, getConsultationRequestsLoading: makeLoading(LoadingState.success) })
  )
  .handleAction(
    consultationRequestsActions.getConsultationRequests.failure,
    (state, { payload: { message } }): State => ({
      ...state,
      getConsultationRequestsLoading: makeLoading(LoadingState.failure, message),
    })
  )

  .handleAction(
    consultationRequestsActions.updateConsultationRequest.request,
    (state): State => ({ ...state, updateConsultationRequestLoading: makeLoading(LoadingState.loading) })
  )
  .handleAction(
    consultationRequestsActions.updateConsultationRequest.success,
    (state): State => ({ ...state, updateConsultationRequestLoading: makeLoading(LoadingState.success) })
  )
  .handleAction(
    consultationRequestsActions.updateConsultationRequest.failure,
    (state, { payload: { message } }): State => ({
      ...state,
      updateConsultationRequestLoading: makeLoading(LoadingState.failure, message),
    })
  )

  .handleAction(
    consultationRequestsActions.approveConsultationRequest.request,
    (state, { payload: { id } }): State => ({
      ...state,
      approveConsultationRequestLoadingById: oneInsert(
        makeLoading(LoadingState.loading),
        id,
        state.approveConsultationRequestLoadingById
      ),
    })
  )
  .handleAction(
    consultationRequestsActions.approveConsultationRequest.success,
    (state, { payload: { id } }): State => ({
      ...state,
      approveConsultationRequestLoadingById: oneInsert(
        makeLoading(LoadingState.success),
        id,
        state.approveConsultationRequestLoadingById
      ),
    })
  )
  .handleAction(
    consultationRequestsActions.approveConsultationRequest.failure,
    (state, { payload: { id, message } }): State => ({
      ...state,
      approveConsultationRequestLoadingById: oneInsert(
        makeLoading(LoadingState.failure, message),
        id,
        state.approveConsultationRequestLoadingById
      ),
    })
  )

	.handleAction(
    consultationRequestsActions.rejectConsultationRequest.request,
    (state, { payload: { id } }): State => ({
      ...state,
      rejectConsultationRequestLoadingById: oneInsert(
        makeLoading(LoadingState.loading),
        id,
        state.rejectConsultationRequestLoadingById
      ),
    })
  )
  .handleAction(
    consultationRequestsActions.rejectConsultationRequest.success,
    (state, { payload: { id } }): State => ({
      ...state,
      rejectConsultationRequestLoadingById: oneInsert(
        makeLoading(LoadingState.success),
        id,
        state.rejectConsultationRequestLoadingById
      ),
    })
  )
  .handleAction(
    consultationRequestsActions.rejectConsultationRequest.failure,
    (state, { payload: { id, message } }): State => ({
      ...state,
      rejectConsultationRequestLoadingById: oneInsert(
        makeLoading(LoadingState.failure, message),
        id,
        state.rejectConsultationRequestLoadingById
      ),
    })
  )