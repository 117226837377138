import { createReducer, ActionType } from 'typesafe-actions'
import { Loading, makeLoading, LoadingDefault, LoadingState } from 'utils/types/store'
import { profileActions } from '../actions'

export type State = {
	getProfileLoading: Loading
	updateProfileLoading: Loading
}

export const initialState: State = {
	getProfileLoading: LoadingDefault,
	updateProfileLoading: LoadingDefault,
}

export default createReducer<State, ActionType<typeof profileActions>>(initialState)
	.handleAction(
		profileActions.getProfile.request,
		(state): State => ({ ...state, getProfileLoading: makeLoading(LoadingState.loading) })
	)
	.handleAction(
		profileActions.getProfile.success,
		(state): State => ({ ...state, getProfileLoading: makeLoading(LoadingState.success) })
	)
	.handleAction(
		profileActions.getProfile.failure,
		(state, { payload: { message } }): State => ({
			...state,
			getProfileLoading: makeLoading(LoadingState.failure, message),
		})
	)

	.handleAction(
		[
			profileActions.updateProfile.request,
			profileActions.deleteAttachment.request,
			profileActions.deleteBanner.request,
		],
		(state): State => ({ ...state, updateProfileLoading: makeLoading(LoadingState.loading) })
	)
	.handleAction(
		[
			profileActions.updateProfile.success,
			profileActions.deleteAttachment.success,
			profileActions.deleteBanner.success,
		],
		(state): State => ({ ...state, updateProfileLoading: makeLoading(LoadingState.success) })
	)
	.handleAction(
		[
			profileActions.updateProfile.failure,
			profileActions.deleteAttachment.failure,
			profileActions.deleteBanner.failure,
		],
		(state, { payload: { message } }): State => ({
			...state,
			updateProfileLoading: makeLoading(LoadingState.failure, message),
		})
	)
