import { CircularProgress, createStyles, makeStyles, Paper } from '@material-ui/core'
import { ConsultationRequest } from 'pages/ConsultationRequests/redux/dto/ConsultationRequest'
import React, { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ConsultationDateConflictBanner } from './ConsultationDateConflictBanner'
import { ConsultationRequestsRow } from './ConsultationRequestsRow'
import { useSelector } from 'react-redux'
import { filterByStatusMap } from 'pages/ConsultationRequests/containers/ConsultationRequestsContainerRequestsContainer'
import { getConsultationRequestHasDateConflict, getConsultationRequestsList } from 'pages/ConsultationRequests/redux/selectors'
import { PaginationControls } from 'layouts/parts/Controls/PaginationControls'
import { useConsultationsRequests } from 'pages/ConsultationRequests/hooks/useConsultationsRequests'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: { position: 'relative' },
    empty: { padding: theme.spacing(3), textAlign: 'center' },
    noData: {
      padding: theme.spacing(4),
      textAlign: 'center',
      textTransform: 'uppercase',
      fontWeight: 700
    },
    loadingRoot: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: theme.spacing(2),
      minHeight: 100,
    },
    wrapper: {
      padding: theme.spacing(2)
    },
    pagination: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }
  })
)

export type ConsultationRequestsListProps = {
  requests: ConsultationRequest[];
  loading: boolean;
  handlePageChange: (value: number) => void
}

export const ConsultationRequestsList: FC<ConsultationRequestsListProps> = ({ requests, loading, handlePageChange }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const allConsultations = useSelector(getConsultationRequestsList)
  const consultationRequestHasDateConflict = useSelector(getConsultationRequestHasDateConflict)
  const { total, limit, page } = useConsultationsRequests()

  const pageTotal = Math.ceil(total / limit)

  const scheduledRequestsMapByDate = useMemo(() => 
    allConsultations
      .filter(item => filterByStatusMap[item?.subStatus])
      .reduce((acc, item) => {
        if (acc[item?.datetime]) {
          acc[item?.datetime] = acc[item?.datetime] + 1
        } else {
          acc[item?.datetime] = 1
        }
        return acc
      }, {} as {[key: string]: number})
  , [allConsultations])

  if (loading) {
    return (
			<Paper className={classes.loadingRoot}>
				<CircularProgress />
			</Paper>
		)
  }

  return (
    <div>
      {requests.length !== 0 ? (
        <div className={classes.wrapper}>
          {consultationRequestHasDateConflict && <ConsultationDateConflictBanner />}
          {requests.map(request => (
            <ConsultationRequestsRow
              data={request}
              scheduledRequestsMapByDate={scheduledRequestsMapByDate}
              handlePageChange={handlePageChange}
            />
          ))}
          <div className={classes.pagination}>
              <PaginationControls 
                page={page}
                pageCount={pageTotal}
                onPageChange={handlePageChange}
              />
          </div>
        </div>
      ) : <div className={classes.noData}>{t('LEOMED_ADMIN_NO_DATA')}</div>}
    </div>
  )
}
