import i18n from 'i18next'
import Backend from 'i18next-xhr-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

const fallbackLng = ['uk']
const supportedLngs = ['uk', 'en', 'ru']

i18n
	.use(Backend)
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		fallbackLng,
		supportedLngs,
		interpolation: {
			escapeValue: false,
		},
		react: {
			wait: true,
		},
		returnObjects: true,
	})

export default i18n
