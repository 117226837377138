import React, { useCallback, useMemo } from 'react'
import { Grid, Hidden, makeStyles, Theme, Button, colors } from '@material-ui/core'
import LocalVideoPreview from './LocalVideoPreview/LocalVideoPreview'
import SettingsMenu from './SettingsMenu/SettingsMenu'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { Steps } from '../PreJoinScreens'
import ToggleAudioButton from '../../Buttons/ToggleAudioButton/ToggleAudioButton'
import ToggleVideoButton from '../../Buttons/ToggleVideoButton/ToggleVideoButton'
import { useAppState } from '../../../state'
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext'
import { officeActions } from 'pages/Office/redux/actions'
import { getCreateRoomLoading, getPatientInfo } from 'pages/Office/redux/selectors'
import { useCountdown } from 'hooks/useCountdown'
import { useEffect } from 'react'
import { useHistory } from 'react-router'
import { RoutePath } from 'router/routes'
import { PatientStatuses } from 'layouts/OfficeLayout'
import { isLoading } from 'utils/types/store'

const useStyles = makeStyles((theme: Theme) => ({
	gutterBottom: {
		marginBottom: '1em',
	},
	marginTop: {
		marginTop: '1em',
	},
	deviceButton: {
		width: '100%',
		border: '2px solid #aaa',
		margin: '1em 0',
		flex: 3,
	},
	localPreviewContainer: {
		width: '100%',
		[theme.breakpoints.down('sm')]: {
			padding: '0 2.5em',
		},
	},
	joinButtons: {
		display: 'flex',
		justifyContent: 'flex-end',
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column-reverse',
			width: '100%',
			'& button': {
				margin: '0.5em 0',
			},
		},
	},
	mobileButtonBar: {
		[theme.breakpoints.down('sm')]: {
			display: 'flex',
			justifyContent: 'space-between',
			margin: '1.5em 0 1em',
		},
	},
	mobileButton: {
		padding: '0.8em 0',
		margin: 0,
	},
	buttons: {
		display: 'flex',
		gap: '10px',
		marginTop: theme.spacing(2),
	},
	timer: {
		color: colors.red[500],
		fontWeight: 700,
		fontSize: '20px',
	},
	timerWrapper: {
		textAlign: 'center',
		margin: theme.spacing(3, 0),
	},
	title: {
		fontSize: '20px',
		fontWeight: 700,
		marginBottom: theme.spacing(2),
	},
}))

interface DeviceSelectionScreenProps {
	name: string
	roomName: string
	setStep: (step: Steps) => void
}

export default function DeviceSelectionScreen({ name }: DeviceSelectionScreenProps) {
	const dispatch = useDispatch()
	const history = useHistory()
	const { t } = useTranslation()
	const classes = useStyles()
	const { isFetching } = useAppState()
	const {
		connect,
		isAcquiringLocalTracks,
		isConnecting,
		removeLocalAudioTrack,
		removeLocalVideoTrack,
	} = useVideoContext()
	const patientInfo = useSelector(getPatientInfo)
	const submitting = useSelector(getCreateRoomLoading)
	const isSubmitting = isLoading(submitting)
	const disableButtons = isSubmitting || isFetching || isAcquiringLocalTracks || isConnecting
	const secondsLeft = useMemo(() => {
		return 60 - moment().diff(patientInfo.acceptedPatientAt, 'seconds')
	}, [patientInfo.acceptedPatientAt])
	const { time, over } = useCountdown(secondsLeft)

	const isOnCall = useMemo(
		() => patientInfo.status && patientInfo.status === PatientStatuses.onCall,
		[patientInfo.status]
	)

	useEffect(() => {
		if (over && !isOnCall) {
			dispatch(
				officeActions.declinePatient.request({
					callback: () => {
						removeLocalAudioTrack()
						removeLocalVideoTrack()
						history.push(RoutePath.office)
					},
				})
			)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, history, isOnCall, over])

	const handleJoin = useCallback(() => {
		dispatch(officeActions.createRoom.request({ callback: connect }))
	}, [connect, dispatch])

	return (
		<>
			{/* <Typography variant="h5" className={classes.gutterBottom}>
				Join {roomName}
			</Typography> */}

			<Grid justify="center">
				<Grid item sm={12} xs={12}>
					<div className={classes.title}>{`${t('LEOMED_ADMIN_CALL_PREJOIN_TITLE')} ${
						patientInfo.name
					}`}</div>
					<div className={classes.localPreviewContainer}>
						<LocalVideoPreview identity={name} />
					</div>
					<div className={classes.mobileButtonBar}>
						<Hidden mdUp>
							<ToggleAudioButton className={classes.mobileButton} disabled={disableButtons} />
							<ToggleVideoButton className={classes.mobileButton} disabled={disableButtons} />
						</Hidden>
					</div>
				</Grid>
				<Grid item sm={12} xs={12}>
					<Grid container direction="column" justify="space-between">
						<div className={classes.buttons}>
							<ToggleAudioButton className={classes.deviceButton} disabled={disableButtons} />
							<ToggleVideoButton className={classes.deviceButton} disabled={disableButtons} />
							<SettingsMenu mobileButtonClass={classes.mobileButton} />
						</div>
						{!isOnCall ? (
							<div className={classes.timerWrapper}>
								<div className={classes.timer}>{time}</div>
								<div>{t('LEOMED_ADMIN_CALL_TIMEOUT_LABEL')}</div>
							</div>
						) : (
							<div className={classes.timerWrapper}>
								<div>{t('LEOMED_ADMIN_CALL_PATIENT_IS_WAITING_TEXT')}</div>
							</div>
						)}
						<div className={classes.joinButtons}>
							{/* <Button variant="outlined" color="primary" onClick={() => setStep(Steps.roomNameStep)}>
	              Cancel
	            </Button> */}
							<Button
								variant="contained"
								color="primary"
								fullWidth
								data-cy-join-now
								onClick={handleJoin}
								disabled={disableButtons}
							>
								{t('LEOMED_ADMIN_CALL_JOIN_NOW')}
							</Button>
						</div>
					</Grid>
				</Grid>
			</Grid>
		</>
	)
}
