export enum RoutePath {
	home = '/',
	office = '/office',
	profile = '/profile',
	requests = '/requests',
	calendar = '/calendar',
	support = '/support',
	call = `/room/:URLRoomName`,

	logout = '/logout',
}
