import React, { FC } from 'react'
import MaskedInput from 'react-text-mask'
import {
	Box,
	InputBase,
	InputLabel,
	makeStyles,
	InputBaseProps,
	FormHelperText,
} from '@material-ui/core'
import Flag from 'react-world-flags'
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		marginBottom: '10px',
	},
	labelWrapper: {
		marginBottom: theme.spacing(2),
	},
	label: {
		fontSize: '14px',
		color: theme.palette.primary.dark,
	},
	inputRoot: {
		width: '100%',
	},
	input: {
		border: '1px solid rgba(0, 0, 0, 0.23)',
		borderRadius: '4px',
		padding: 0,
		paddingLeft: theme.spacing(6),
		height: '54px',
		width: '100%',
		'&:hover': {
			borderColor: theme.palette.text.primary,
		},
	},
	error: {
		borderColor: theme.palette.warning.main,
		'&:hover': {
			borderColor: theme.palette.warning.main,
		},
	},
	flag: {
		position: 'absolute',
		left: `${theme.spacing(2)}px`,
	},
	errorMessage: {
		paddingLeft: theme.spacing(2),
	},
}))

type PhoneInputFieldProps = InputBaseProps & {
	placeholder: string
	name?: string
	label: string
	code: string
	errorMessage?: string
	dirty?: boolean
	error?: boolean
}

interface TextMaskCustomProps {
	inputRef: (ref: HTMLInputElement | null) => void
}

function TextMaskCustom(props: TextMaskCustomProps) {
	const { inputRef, ...other } = props

	return (
		<MaskedInput
			{...other}
			ref={(ref: any) => {
				inputRef(ref ? ref.inputElement : null)
			}}
			mask={[
				'+',
				'3',
				'8',
				'0',
				' ',
				'(',
				/\d/,
				/\d/,
				')',
				' ',
				/\d/,
				/\d/,
				/\d/,
				'-',
				/\d/,
				/\d/,
				'-',
				/\d/,
				/\d/,
			]}
		/>
	)
}

export const PhoneInputField: FC<PhoneInputFieldProps> = ({
	name,
	placeholder,
	label,
	code,
	errorMessage,
	error,
	dirty,
	...rest
}) => {
	const classes = useStyles()
	const inputStyles = clsx(classes.input, {
		[classes.error]: dirty && error,
	})

	return (
		<Box className={classes.root}>
			<Box className={classes.labelWrapper}>
				<InputLabel className={classes.label} htmlFor={name}>
					{label}
				</InputLabel>
			</Box>

			<Box display="flex" alignItems="center" position="relative">
				<Flag code={code} height={16} className={classes.flag} />
				<InputBase
					classes={{ input: inputStyles, root: classes.inputRoot }}
					id={name}
					name={name}
					placeholder={placeholder}
					inputComponent={TextMaskCustom as any}
					{...rest}
				/>
			</Box>
			{dirty && error && (
				<FormHelperText error className={classes.errorMessage}>
					{errorMessage}
				</FormHelperText>
			)}
		</Box>
	)
}
