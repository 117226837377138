import { Link, makeStyles } from '@material-ui/core'
import { officeActions } from 'pages/Office/redux/actions'
import React, { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { config } from 'utils/config'

const useStyles = makeStyles(theme => ({
	root: {
		padding: theme.spacing(7, 0),
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	btn: {
		display: 'flex',
		backgroundColor: theme.palette.primary.main,
		borderRadius: '4px',
		textDecoration: 'none',
		color: theme.palette.background.paper,
		padding: theme.spacing(1, 3),
		cursor: 'pointer',
		'&:hover': {
			textDecoration: 'none',
			backgroundColor: theme.palette.primary.main,
		},
	},
	icon: {
		width: '20px',
		height: '20px',
		marginLeft: theme.spacing(2),
		backgroundSize: 'cover',
		backgroundImage: `url(${process.env.PUBLIC_URL}/shape.svg)`,
	},
}))

export const Footer: FC = () => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const version = config?.version

	const toggleContactUs = useCallback(() => {
		dispatch(officeActions.toggleContactUs())
	}, [dispatch])

	return (
		<div className={classes.root}>
			<div>
				<div>{t('LEOMED_ADMIN_COPYMARK_TEXT')}</div>
				{`v.${version}`}
			</div>
			<Link component="a" className={classes.btn} onClick={toggleContactUs}>
				{t('LEOMED_ADMIN_ONLINE_SUPPORT_TEXT')} <div className={classes.icon} />
			</Link>
		</div>
	)
}
