import React, { FC, useCallback } from 'react'
import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import { IconButton, makeStyles } from '@material-ui/core'
import { Colors } from 'utils/theme'
import { useTranslation } from 'react-i18next'

import { EducationItem } from 'pages/Profile/redux/model'

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		alignItems: 'center',
		backgroundColor: theme.palette.background.default,
		borderRadius: '10px',
		padding: theme.spacing(2),
		marginBottom: theme.spacing(3),
		position: 'relative',
	},
	logo: {
		width: '50px',
		height: '50px',
		borderRadius: '50%',
		backgroundColor: theme.palette.primary.main,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		color: theme.palette.background.paper,
	},
	univer: {
		marginLeft: theme.spacing(2),
		width: 120,
	},
	univerTitle: {
		fontWeight: 600,
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		fontSize: '18px',
		marginBottom: theme.spacing(1),
	},
	faculty: {
		fontSize: '12px',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
	ml10: {
		marginLeft: theme.spacing(10),
		width: 200,
	},
	title: {
		fontSize: '12px',
		color: Colors.grey[700],
		marginBottom: theme.spacing(1),
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
	description: {
		fontWeight: 500,
		fontSize: '16px',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
	edit: {
		position: 'absolute',
		right: 0,
		top: 0,
		cursor: 'pointer',
	},
	delete: {
		position: 'absolute',
		right: 40,
		top: 0,
		cursor: 'pointer',
	},
}))

type EducationBannerProps = {
	item: EducationItem
	editItem: (itemId: string) => void
	deleteBanner?: (itemId: string) => void
}

export const EducationBanner: FC<EducationBannerProps> = ({
	item: { id, periodOfStudy, currentPlaceOfWork, department, gradeLevel, specialty },
	editItem,
	deleteBanner,
}) => {
	const classes = useStyles()
	const { t } = useTranslation(['translation', 'grades'])
	const gradesOptions = t('grades:GRADES') as any[]
	const grade = gradesOptions.find(item => item.value === gradeLevel)?.label || '-'

	const clickHandler = useCallback(() => {
		editItem(id)
	}, [editItem, id])

	const deleteItem = useCallback(() => {
		deleteBanner?.(id)
	}, [deleteBanner, id])

	return (
		<div className={classes.root}>
			<div className={classes.logo}>
				<AddIcon fontSize="large" />
			</div>
			<div className={classes.univer}>
				<div className={classes.univerTitle}>{currentPlaceOfWork}</div>
				<div className={classes.faculty}>{department}</div>
			</div>
			<div className={classes.ml10}>
				<div className={classes.title}>{t('LEOMED_ADMIN_PROFILE_PAGE_SPECIALIZATION_LABEL')}</div>
				<div className={classes.description}>{specialty}</div>
			</div>
			<div className={classes.ml10}>
				<div className={classes.title}>{t('LEOMED_ADMIN_PROFILE_PAGE_QUALIFICATION_LABEL')}</div>
				<div className={classes.description}>{grade}</div>
			</div>
			<div className={classes.edit}>
				<IconButton onClick={clickHandler}>
					<EditIcon color="primary" />
				</IconButton>
			</div>
			{deleteBanner && (
				<div className={classes.delete}>
					<IconButton onClick={deleteItem}>
						<DeleteIcon color="primary" />
					</IconButton>
				</div>
			)}
		</div>
	)
}
