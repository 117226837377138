import { useSelector } from 'react-redux'
import { useState, useEffect } from "react"
import { getNewRequestDialogId } from 'pages/ConsultationRequests/redux/selectors'

export const useNewRequestDialog = () => {
  const [isNewRequestDialogOpen, setIsNewRequestDialogOpen] = useState(false)
  const newConsultaionData = useSelector(getNewRequestDialogId)

  useEffect(() => {
    if (newConsultaionData) {
      setIsNewRequestDialogOpen(true)
    } else {
      setIsNewRequestDialogOpen(false)
    }
  }, [newConsultaionData])

  return {
    isNewRequestDialogOpen,
    newConsultaionData,
  }
}
