import { Avatar, IconButton, makeStyles } from '@material-ui/core'
import { officeActions } from 'pages/Office/redux/actions'
import React, { FC, useCallback } from 'react'
import { noop } from 'lodash'
import CloseIcon from '@material-ui/icons/Close'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'
import { useMemo } from 'react'
import { SocketNotificationTypes } from 'store/alerts/dtos'
import { ConsultationRequest } from 'pages/ConsultationRequests/redux/dto/ConsultationRequest'
import { getNotifications } from 'pages/Office/redux/selectors'
import { alertsActions } from 'store/alerts/actions'
import { consultationRequestsActions } from 'pages/ConsultationRequests/redux/actions'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1, 2),
    minWidth: 345,
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: '10px',
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
  },
  newConsultationRoot: {
    cursor: 'pointer',
  },
  title: {
    fontWeight: 700,
    fontSize: 16,
    marginBottom: theme.spacing(1),
  },
  subtitle: {
    fontWeight: 700,
    fontSize: 14,
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.background.paper,
    fontSize: 14,
    marginRight: theme.spacing(2),
  },
  closeBtn: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: 15,
    height: 15,
  },
  infoWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  info: {
    display: 'flex',
    flexDirection: 'column'
  },
  dateText: {
    fontWeight: 700,
    fontSize: 14,
    display: 'inline-flex'
  }
}))

type SocketNotificationProps = {
  data: ConsultationRequest
  type: SocketNotificationTypes
}

const titleMap = {
  [SocketNotificationTypes.NEW]: 'LEOMED_ADMIN_OFFICE_NEW_CONSULTATION_REQUEST_TEXT',
  [SocketNotificationTypes.REJECTED]: 'LEOMED_ADMIN_OFFICE_REJECTED_REQUEST_TEXT',
  [SocketNotificationTypes.ACCEPTED]: 'LEOMED_ADMIN_OFFICE_ACCEPTED_REQUEST_TEXT',
  [SocketNotificationTypes.PENDING]: 'LEOMED_ADMIN_OFFICE_UPDATED_REQUEST_TEXT',
}

const subtitleMap = {
  [SocketNotificationTypes.NEW]: 'LEOMED_ADMIN_DIALOG_PATIENT_NEEDS_CONSULTATION',
  [SocketNotificationTypes.REJECTED]: 'LEOMED_ADMIN_OFFICE_CANCELED_TEXT',
  [SocketNotificationTypes.ACCEPTED]: 'LEOMED_ADMIN_OFFICE_ACCEPTED_TEXT',
  [SocketNotificationTypes.PENDING]: 'LEOMED_ADMIN_OFFICE_REQUESTED_CHANGES_TEXT',
}

export const SocketNotification: FC<SocketNotificationProps> = ({ data, type }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const rootStyles = clsx(classes.root, {
    [classes.newConsultationRoot]: type === SocketNotificationTypes.NEW
  })
  const notifications = useSelector(getNotifications)
  const date = `${data.date} ${t('LEOMED_ADMIN_OFFICE_ON_TITLE')} ${data.time}`
  const nameInitials = useMemo(() => `${data.firstName?.[0] ?? ''}${data.lastName?.[0] ?? ''}`.toUpperCase(), [data.firstName, data.lastName])
  const updateNotificationId = useMemo(() => notifications.find(item => item.data.id === data.id)?._id, [notifications, data.id])

  const openNewConsultationDialog = useCallback(() => {
    dispatch(consultationRequestsActions.pickIdForNewRequestDialog({ id: data.id }))
    dispatch(officeActions.updateNotificationId.request({ id: updateNotificationId }))
    dispatch(alertsActions.removeSocketNotification({ id: data.id }))
  }, [dispatch, data.id, updateNotificationId])

  const closeClickHandler = useCallback((e) => {
    e.stopPropagation()
    dispatch(alertsActions.removeSocketNotification({ id: data.id }))
  }, [dispatch, data.id])

  return (
    <div className={rootStyles} onClick={type === SocketNotificationTypes.NEW ? openNewConsultationDialog : noop}>
      <div className={classes.title}>{t(titleMap[type])}</div>
      <div className={classes.infoWrapper}>
        <Avatar src={data.avatar} className={classes.avatar}>
          {nameInitials}
        </Avatar>
        <div className={classes.info}>
          <div className={classes.subtitle}>
            {`${data.firstName} ${data.lastName}`}
          </div>
          <div>{t(subtitleMap[type])} {t('LEOMED_ADMIN_OFFICE_FOR_TITLE')} <div className={classes.dateText}>{date}</div></div>
        </div>
      </div>
      <IconButton className={classes.closeBtn} onClick={closeClickHandler}>
        <CloseIcon fontSize="small" color="primary" />
      </IconButton>
    </div>
  )
}
