import { createAction, createAsyncAction } from 'typesafe-actions'
import * as actionType from './const'
import { ProfileStep, EducationItem, SpecializationItem, ExperienceItem } from './model'

export const profileActions = {
	setProfileStep: createAction(actionType.SET_PROFILE_STEP)<{ step: ProfileStep }>(),
	addEducationItem: createAction(actionType.ADD_EDUCATION_ITEM)<{ educationItem: EducationItem }>(),
	addExperienceItem: createAction(actionType.ADD_EXPERIENCE_ITEM)<{
		experienceItem: ExperienceItem
	}>(),
	addSpecializationItem: createAction(actionType.ADD_SPECIALIZATION_ITEM)<{
		specializationItem: SpecializationItem
	}>(),
	updateProfile: createAsyncAction(
		actionType.UPDATE_PROFILE_REQUEST,
		actionType.UPDATE_PROFILE_SUCCESS,
		actionType.UPDATE_PROFILE_FAILURE
	)<
		{ profile?: any; step?: ProfileStep; attachments?: FormData },
		{ profile: any },
		{ message?: string }
	>(),
	getProfile: createAsyncAction(
		actionType.GET_PROFILE_REQUEST,
		actionType.GET_PROFILE_SUCCESS,
		actionType.GET_PROFILE_FAILURE
	)<any, { profile: any, created?: string }, { message?: string }>(),
	deleteAttachment: createAsyncAction(
		actionType.DELETE_ATTACHMENT_REQUEST,
		actionType.DELETE_ATTACHMENT_SUCCESS,
		actionType.DELETE_ATTACHMENT_FAILURE
	)<{ categoryId: string; fileId: string; name: string }, {}, { message?: string }>(),
	deleteBanner: createAsyncAction(
		actionType.DELETE_BANNER_REQUEST,
		actionType.DELETE_BANNER_SUCCESS,
		actionType.DELETE_BANNER_FAILURE
	)<{ categoryId: string; name: string }, {}, { message?: string }>(),
	editItemById: createAction(actionType.EDIT_ITEM_BY_ID)<{
		itemId: string | null
	}>(),
	needRedirectToOffice: createAction(actionType.NEED_REDIRECT_TO_OFFICE)<boolean>(),
}
