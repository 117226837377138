import { Avatar, makeStyles } from '@material-ui/core'
import React, { FC } from 'react'
import { Colors } from 'utils/theme'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { ConsultationHistory } from 'pages/MyCalendar/redux/dto/ConsultationHistory'
import { Option } from 'pages/Call/containers/SymptomsContainer'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { scheduledRequestsActions } from 'pages/MyCalendar/redux/actions'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    border: `1px solid ${Colors.blue[50]}`,
    borderRadius: '10px',
    marginBottom: theme.spacing(2),
    '&:hover': {
      backgroundColor: '#F3F7FC',
    },
    '&:hover $detailsContent': {
      display: 'flex',
    }
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
    flex: 1.5,
  },
  flex1: {
    flex: 1,
  },
  name: {
    marginLeft: theme.spacing(2),
    fontWeight: 700,
    width: 175,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  subtitle: {
    fontSize: '12px',
    color: Colors.blue[900],
  },
  avatar: {
    width: 48,
    height: 48,
    color: theme.palette.background.paper,
  },
  temperature: {
		display: 'inline-flex',
		alignItems: 'center',
		backgroundColor: theme.palette.warning.main,
		width: 'fit-content',
		borderRadius: '25px',
    fontSize: 14,
		color: theme.palette.background.paper,
		padding: theme.spacing(0.5, 1),
    marginRight: theme.spacing(1),
	},
	icon: {
		width: 7,
		height: 14,
		backgroundImage: `url(${process.env.PUBLIC_URL}/temperature.svg)`,
		backgroundSize: 'cover',
		marginRight: theme.spacing(1),
	},
  symptoms: {
		display: 'inline-flex',
		width: 'fit-content',
		backgroundColor: '#FFE6E6',
		padding: theme.spacing(0.5, 1),
		borderRadius: '25px',
    fontSize: 14,
    marginRight: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
  symptomsWrapper: {
    marginTop: theme.spacing(1),
  },
  width250: {
    flex: 2,
  },
  width170: {
    flex: 1,
  },
  details: {
    flex: 0.3,
  },
  detailsContent: {
    display: 'none',
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.primary.main,
    textDecoration: 'underline',
    cursor: 'pointer',
  }
}))

type ConsultationHistoryRowProps = {
  data: ConsultationHistory
}

export const ConsultationHistoryRow: FC<ConsultationHistoryRowProps> = ({ data }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation(['translation', 'conditions'])
  const symptoms = t('conditions:CONDITIONS') as Option[]

  const pickConsultationHistoryId = useCallback(() => {
    dispatch(scheduledRequestsActions.pickConsultationHistoryId(data.id))
  }, [dispatch, data.id])

  if (!data) return null

  const date = `${moment(data.date).format('DD/MM/YYYY')} ${t('LEOMED_ADMIN_OFFICE_ON_TITLE')} ${moment(data.date).format('HH:mm')}`

  return (
    <>
      <div className={classes.root}>
        <div className={classes.flex}>
          <Avatar src={data.avatar} className={classes.avatar} />
          <div className={classes.name}>{`${data.firstName} ${data.lastName}`}</div>
        </div>
        <div className={classes.flex1}>
          <div className={classes.subtitle}>{`${t('LEOMED_ADMIN_OFFICE_REQUEST_TITLE')} №`}</div>
          <div>{data.request}</div>
        </div>
        <div className={classes.flex1}>
          <div className={classes.subtitle}>{t('LEOMED_ADMIN_OFFICE_DATE_TITLE')}</div>
          <div>{date}</div>
        </div>
        <div className={classes.width250}>
          <div className={classes.subtitle}>{t('LEOMED_ADMIN_DIALOG_SYMPTOMS_TEXT')}</div>
          <div className={classes.symptomsWrapper}>
            <div className={classes.temperature}>
              <div className={classes.icon} />
              {data.temperature ? `${data.temperature} °С` : ' - '}
            </div>
            {data.symptoms[0] && <div className={classes.symptoms}>{symptoms.find(item => item.value === data.symptoms[0])?.label}</div>}
            {data.symptoms[1] && <div className={classes.symptoms}>+ 1</div>}
          </div>
        </div>
        <div className={classes.width170}>
          <div className={classes.subtitle}>{t('LEOMED_ADMIN_OFFICE_PAGE_CONSULTATIONS_TITLE')}</div>
          <div>{data.consultationN}</div>
        </div>
        <div className={classes.details}>
          <div className={classes.detailsContent} onClick={pickConsultationHistoryId}>{t('LEOMED_ADMIN_OFFICE_DETAILS_TEXT')}</div>
        </div>
      </div>
    </>
  )
}
