import { authActions } from 'pages/Auth/redux/actions'
import { createReducer, ActionType } from 'typesafe-actions'
import { Loading, makeLoading, LoadingDefault, LoadingState } from 'utils/types/store'

export type State = {
	authLoading: Loading
	updateSessionLoading: Loading
}

export const initialState: State = {
	authLoading: LoadingDefault,
	updateSessionLoading: LoadingDefault,
}

export default createReducer<State, ActionType<typeof authActions>>(initialState)
	.handleAction(
		authActions.authorize.request,
		(state): State => ({ ...state, authLoading: makeLoading(LoadingState.loading) })
	)
	.handleAction(
		authActions.authorize.success,
		(state): State => ({ ...state, authLoading: makeLoading(LoadingState.success) })
	)
	.handleAction(
		authActions.authorize.failure,
		(state, { payload: { message } }): State => ({
			...state,
			authLoading: makeLoading(LoadingState.failure, message),
		})
	)

	.handleAction(
		authActions.updateSession.request,
		(state): State => ({ ...state, updateSessionLoading: makeLoading(LoadingState.loading) })
	)
	.handleAction(
		authActions.updateSession.success,
		(state): State => ({ ...state, updateSessionLoading: makeLoading(LoadingState.success) })
	)
	.handleAction(
		authActions.updateSession.failure,
		(state, { payload: { message } }): State => ({
			...state,
			updateSessionLoading: makeLoading(LoadingState.failure, message),
		})
	)
