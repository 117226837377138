import React, { FC } from 'react'
import clsx from 'clsx'
import {
	Dialog,
	DialogProps,
	DialogActions,
	Button,
	makeStyles,
} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
	root: {
		padding: theme.spacing(2)
	},
	title: {
    fontSize: '24px',
    padding: theme.spacing(1, 1, 0, 1),
    fontWeight: 700,
  },
	yesBtn: {
		color: theme.palette.warning.main,
		borderColor: theme.palette.warning.main,
		'&:hover': {
			backgroundColor: theme.palette.background.paper,
			color: theme.palette.warning.main,
		},
	},
	primaryBtn: {
		color: theme.palette.background.paper,
		backgroundColor: theme.palette.primary.main,
		borderColor: theme.palette.primary.main,
		'&:hover': {
			backgroundColor: theme.palette.primary.main,
			color: theme.palette.background.paper,
		},
	},
	descr: {
		padding: theme.spacing(2, 1)
	},
}))

type ConfirmDialogProps = {
	handleClose: () => void
	handleConfirm: () => void
	title: string
	description?: string
	cancelText: string
	acceptText: string
	isPrimary?: boolean
} & DialogProps

export const ConfirmDialog: FC<ConfirmDialogProps> = ({
	handleClose,
	handleConfirm,
	title,
	description,
	cancelText,
	acceptText,
	isPrimary,
	...props
}) => {
	const classes = useStyles()
	const confirmBtnStyles = clsx(classes.yesBtn, {
		[classes.primaryBtn]: isPrimary
	})
	const variant = isPrimary ? 'contained' : 'outlined'

	return (
		<Dialog {...props} onClose={handleClose}>
			<div className={classes.root}>
				<div className={classes.title}>
					{title}
				</div>
				{description && <div className={classes.descr}>{description}</div>}
				<DialogActions>
					<Button autoFocus onClick={handleClose} variant="text">
						{cancelText}
					</Button>
					<Button onClick={handleConfirm} variant={variant} className={confirmBtnStyles}>
						{acceptText}
					</Button>
				</DialogActions>
			</div>
		</Dialog>
	)
}
