import isPlainObject from 'is-plain-object'

export const isMobile = (() => {
	if (typeof navigator === 'undefined' || typeof navigator.userAgent !== 'string') {
		return false
	}
	return /Mobile/.test(navigator.userAgent)
})()

// Recursively removes any object keys with a value of undefined
export function removeUndefineds<T>(obj: T): T {
	if (!isPlainObject(obj)) return obj

	const target: { [name: string]: any } = {}

	for (const key in obj) {
		const val = obj[key]
		if (typeof val !== 'undefined') {
			target[key] = removeUndefineds(val)
		}
	}

	return target as T
}
