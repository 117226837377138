import { ActionType, createReducer } from "typesafe-actions"
import { Loading, LoadingDefault, LoadingState, makeLoading } from "utils/types/store"
import { scheduledRequestsActions } from "../actions"

export type State = {
  getScheduledRequestsLoading: Loading
  getConsultationHistoryLoading: Loading
  getConsultationDataLoading: Loading
}

export const initialState: State = {
  getScheduledRequestsLoading: LoadingDefault,
  getConsultationHistoryLoading: LoadingDefault,
  getConsultationDataLoading: LoadingDefault
}

export default createReducer<State, ActionType<typeof scheduledRequestsActions>>(initialState)
  .handleAction(
    scheduledRequestsActions.getScheduledRequests.request,
    (state): State => ({ ...state, getScheduledRequestsLoading: makeLoading(LoadingState.loading) })
  )
  .handleAction(
    scheduledRequestsActions.getScheduledRequests.success,
    (state): State => ({ ...state, getScheduledRequestsLoading: makeLoading(LoadingState.success) })
  )
  .handleAction(
    scheduledRequestsActions.getScheduledRequests.failure,
    (state, { payload: { message } }): State => ({
      ...state,
      getScheduledRequestsLoading: makeLoading(LoadingState.failure, message),
    })
  )

  .handleAction(
    scheduledRequestsActions.getConsultationHistory.request,
    (state): State => ({ ...state, getConsultationHistoryLoading: makeLoading(LoadingState.loading) })
  )
  .handleAction(
    scheduledRequestsActions.getConsultationHistory.success,
    (state): State => ({ ...state, getConsultationHistoryLoading: makeLoading(LoadingState.success) })
  )
  .handleAction(
    scheduledRequestsActions.getConsultationHistory.failure,
    (state, { payload: { message } }): State => ({
      ...state,
      getConsultationHistoryLoading: makeLoading(LoadingState.failure, message),
    })
  )

  .handleAction(
    scheduledRequestsActions.getConsultationData.request,
    (state): State => ({ ...state, getConsultationDataLoading: makeLoading(LoadingState.loading) })
  )
  .handleAction(
    scheduledRequestsActions.getConsultationData.success,
    (state): State => ({ ...state, getConsultationDataLoading: makeLoading(LoadingState.success) })
  )
  .handleAction(
    scheduledRequestsActions.getConsultationData.failure,
    (state, { payload: { message } }): State => ({
      ...state,
      getConsultationDataLoading: makeLoading(LoadingState.failure, message),
    })
  )