import { Button, CircularProgress, Dialog, IconButton, makeStyles } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import isEmpty from 'lodash/isEmpty'
import { DnDFilePicker } from 'components/common/inputs/DnDFilePicker'
import { InputField } from 'components/common/inputs/InputField'
import { PhoneInputField } from 'components/common/inputs/PhoneInputField'
import { TextareaField } from 'components/common/inputs/TextareadField'
import { OverlayButton } from 'components/common/OverlayButton'
import React, { FC, useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { officeActions } from 'pages/Office/redux/actions'
import useUploadFile from 'hooks/useUploadFile'
import { isLoading } from 'utils/types/store'
import { getIsContactUsOpen, getPostFeedbackLoading } from 'pages/Office/redux/selectors'
import { getPersonalInfo } from 'pages/Profile/redux/selectors'

const useStyles = makeStyles(theme => ({
  root: {
		padding: theme.spacing(3, 4),
    position: 'relative',
	},
  title: {
    fontWeight: 700,
    fontSize: 24,
  },
  closeBtn: {
    position: 'absolute',
    top: 5,
    right: 5,
    width: 30,
    height: 30,
  },
  fieldsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    width: 812,
  },
  fields: {
    width: 390,
  },
  field: {
    paddingTop: theme.spacing(2),
  },
  terms: {
		padding: theme.spacing(2, 0),
	},
  subTitle: {
		fontSize: '14px',
		color: theme.palette.primary.dark,
		marginBottom: theme.spacing(1),
	},
	termsLink: {
		color: theme.palette.primary.main,
		cursor: 'pointer',
		textDecoration: 'underline',
	},
  buttonsWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: theme.spacing(4),
  },
  submitBtn: {}
}))

type OnlineSupportForm = {
  name: string
  phoneNumber: string
  email: string
  message: string
}

export const OnlineSupportDialog: FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const isContactUsOpen = useSelector(getIsContactUsOpen)
  const profile = useSelector(getPersonalInfo)
  const submitting = useSelector(getPostFeedbackLoading)
	const isSubmitting = isLoading(submitting)

  const { register, errors, handleSubmit } = useForm<OnlineSupportForm>({
		mode: 'all',
		reValidateMode: 'onChange',
    defaultValues: {
      name: `${profile.firstName} ${profile.lastName}`,
      phoneNumber: profile.phone,
      email: profile.email,
    }
	})

  const {
		onFileChange,
    handleDelete,
		setSelectedFiles,
		filesForFormData,
		setFilesForFormData,
	} = useUploadFile({ name: 'onlineSupportFiles' })

  const onClose = useCallback(() => {
    dispatch(officeActions.toggleContactUs())
    setSelectedFiles([])
		setFilesForFormData([])
  }, [dispatch, setSelectedFiles, setFilesForFormData])

  const onSubmit = useCallback(values => {
    const data = new FormData()
    data.append('message', values.message)
    data.append('email', values.email)
    data.append('phoneNumber', values.phoneNumber)
    data.append('name', values.name)
    filesForFormData.forEach(item => {
      data?.append('files', item, item.name)
    })
    dispatch(officeActions.postFeedback.request({ data, alertMessage: t('LEOMED_ADMIN_YOUR_MESSAGE_WAS_SENT'), callback: () => {
      setSelectedFiles([])
      setFilesForFormData([])
    }}))
  }, [filesForFormData, dispatch, t, setSelectedFiles, setFilesForFormData])

  const onDrop = useCallback(files => {
    setSelectedFiles([])
    setFilesForFormData([])
    onFileChange(files)
  }, [setSelectedFiles, setFilesForFormData, onFileChange])

  const onRemove = useCallback((file: any) => {
    handleDelete(file)
  }, [handleDelete])

  return (
    <Dialog open={isContactUsOpen} maxWidth="lg" onClose={onClose}>
      <div className={classes.root}>
        <div className={classes.title}>{t('LEOMED_ADMIN_ONLINE_SUPPORT_TEXT')}</div>
        <div className={classes.fieldsWrapper}>
          <div className={classes.fields}>
            <div className={classes.field}>
              <InputField
                variant="outlined"
                name="name"
                label={t('LEOMED_ADMIN_PROFILE_PAGE_NAME_LABEL')}
                placeholder={t('LEOMED_ADMIN_PROFILE_PAGE_NAME_PLACEHOLDER')}
                inputRef={register({ 
                  required: 'LEOMED_ADMIN_REQUIRED_FIELD_ERROR', 
                  validate: value => !value.length || value.trim() !== '' 
                })}
                error={!!errors.name}
                helperText={t(errors.name?.message as string)}
              />
            </div>

            <div className={classes.field}>
              <PhoneInputField
                name="phoneNumber"
                placeholder={t('LEOMED_ADMIN_PROFILE_PAGE_PHONE_PLACEHOLDER')}
                label={t('LEOMED_ADMIN_PROFILE_PAGE_PHONE_LABEL')}
                code="UA"
                inputRef={register({
                  required: 'LEOMED_ADMIN_REQUIRED_FIELD_ERROR',
                  pattern: {
                    value: /^\+380 \(\d\d\) \d\d\d-\d\d-\d\d$/,
                    message: 'LEOMED_ADMIN_REQUIRED_FIELD_PHONE_ERROR',
                  },
                })}
                error={!!errors.phoneNumber}
                errorMessage={t(errors.phoneNumber?.message as string)}
                dirty
              />
            </div>

            <div className={classes.field}>
              <InputField
                variant="outlined"
                name="email"
                label={t('LEOMED_ADMIN_PROFILE_PAGE_EMAIL_LABEL')}
                placeholder="example@gmail.com"
                inputRef={register({
                  required: 'LEOMED_ADMIN_REQUIRED_FIELD_ERROR',
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: 'LEOMED_ADMIN_REQUIRED_FIELD_EMAIL_ERROR',
                  },
                })}
                error={!!errors.email}
                helperText={t(errors.email?.message as string)}
              />
            </div>

            <div className={classes.terms}>
              {t('LEOMED_ADMIN_CONTACT_US_DESCRIPTION')}
              <span className={classes.termsLink}>
                {t('LEOMED_ADMIN_CONTACT_US_DESCRIPTION_TWO')}
              </span>
              {t('LEOMED_ADMIN_CONTACT_US_DESCRIPTION_THREE')}
              <span className={classes.termsLink}>
                {t('LEOMED_ADMIN_CONTACT_US_DESCRIPTION_FOUR')}
              </span>
            </div>
          </div>

          <div className={classes.fields}>
            <div className={classes.field}>
              <div className={classes.subTitle}>{t('LEOMED_ADMIN_ENTER_YOUR_MESSAGE_TEXT')}</div>
              <TextareaField
                name="message"
                multiline
                inputRef={register({ required: 'LEOMED_ADMIN_REQUIRED_FIELD_ERROR' })}
                error={!!errors.message}
                // onChange={onChange}
                placeholder={t("LEOMED_ADMIN_ENTER_YOUR_MESSAGE_PLACEHOLDER")}
                helperText={t(errors.message?.message as string)}
              />
            </div>

            <DnDFilePicker onDrop={onDrop} onRemove={onRemove} />

            <div className={classes.buttonsWrapper}>
              <Button
                variant="text"
                fullWidth
                onClick={onClose}
              >
                {t('LEOMED_ADMIN_CANCEL_BUTTON_TEXT')}
              </Button>
              <OverlayButton
                variant="contained" 
                fullWidth 
                color="primary"
                className={classes.submitBtn}
                onClick={handleSubmit(onSubmit)}
                disabled={isSubmitting || !isEmpty(errors)}
                overlay={isSubmitting && <CircularProgress size={24} />}
              >
                {t('LEOMED_ADMIN_SEND_BUTTON_TEXT')}
              </OverlayButton>
            </div>
          </div>

        </div>
        <IconButton onClick={onClose} className={classes.closeBtn}>
          <CloseIcon color="primary" />
        </IconButton>
      </div>
    </Dialog>
  )
}
