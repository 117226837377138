import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import './utils/i18n/i18n'

import './styles.css'
import 'react-image-gallery/styles/css/image-gallery.css'
import { SplashScreen } from 'components/common/layout/SplashScreen'

ReactDOM.render(
	<React.StrictMode>
		<Suspense fallback={<SplashScreen open suspense />}>
				<App />
		</Suspense>
	</React.StrictMode>,
	document.getElementById('root')
)
