import { ConsultationRequest } from "pages/ConsultationRequests/redux/dto/ConsultationRequest"
import { ActionType, createReducer } from "typesafe-actions"
import { oneInsert } from "utils/mutators/relations"
import { OneToOne } from "utils/types/store"
import { scheduledRequestsActions } from "../actions"
import { ConsultationHistory } from "../dto/ConsultationHistory"

export type State = {
	scheduledRequestsIds: ConsultationRequest['id'][]
	scheduledRequestsById: OneToOne<ConsultationRequest['id'], ConsultationRequest>
  scheduledRequestsPage: number,
  scheduledRequestsTotal: number,
  scheduledRequestsLimit: number,

  consultationHistoryIds: ConsultationHistory['id'][] | any
	consultationHistoryById: OneToOne<ConsultationHistory['id'], ConsultationHistory>
  consultationHistoryPage: number,
  consultationHistoryTotal: number,
  consultationHistoryLimit: number,

  consultationHistoryId: string
}

export const initialState: State = {
	scheduledRequestsIds: [],
	scheduledRequestsById: {},
  scheduledRequestsPage: 1,
  scheduledRequestsTotal: 0,
  scheduledRequestsLimit: 10,

  consultationHistoryIds: [],
	consultationHistoryById: {},
  consultationHistoryPage: 1,
  consultationHistoryTotal: 0,
  consultationHistoryLimit: 10,

  consultationHistoryId: ''
} 

export default createReducer<State, ActionType<typeof scheduledRequestsActions>>(
	initialState
)
  .handleAction(scheduledRequestsActions.getScheduledRequests.success, (state, { payload: {
    data,
    page,
    total,
    limit
  } }) => {
    const scheduledRequestsById = data.reduce(
      (accumulator, item) => oneInsert(item, item.id, accumulator),
      state.scheduledRequestsById
    )

    return {
      ...state,
      scheduledRequestsIds: data.map(item => item.id),
      scheduledRequestsById,
      scheduledRequestsPage: page,
      scheduledRequestsLimit: limit,
      scheduledRequestsTotal: total,
    }
  })

  .handleAction(scheduledRequestsActions.getConsultationHistory.success, (state, { payload: {
    data,
    page,
    total,
    limit
  } }) => {
    const consultationHistoryById = data.reduce(
      (accumulator, item) => oneInsert(item, item.id, accumulator),
      state.consultationHistoryIds
    )

    return {
      ...state,
      consultationHistoryIds: data.map(item => item.id),
      consultationHistoryById,
      consultationHistoryPage: page,
      consultationHistoryLimit: limit,
      consultationHistoryTotal: total,
    }
  })

  .handleAction(scheduledRequestsActions.getConsultationData.success, (state, { payload: { data } }) => {
    // const consultationHistoryById = [state.consultationHistoryById, oneInsert(data, data.id)]
    // const consultationHistoryById = [state.consultationHistoryById, oneInsert(data, data.id)]
    
    return {
      ...state,
      consultationHistoryById: {
        ...state.consultationHistoryById,
        [data.id]: data
      },
    }
  })

  .handleAction(scheduledRequestsActions.pickConsultationHistoryId, (state, { payload }) => ({
    ...state,
    consultationHistoryId: payload,
  }))