import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import queryString from 'query-string'
import { useCallback, useState } from "react"
import { consultationRequestsActions } from 'pages/ConsultationRequests/redux/actions'
import { getConsultationRequestsInfo } from 'pages/ConsultationRequests/redux/selectors'

export const useCancelRequestDialog = (id: string, handlePageChange?: ((value: number) => void) | undefined) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const location = useLocation()
  const consultationRequests = useSelector(getConsultationRequestsInfo)
  const search = queryString.parse(location.search)
  const page = search?.page || 1
  const [isConfirmOpen, setIsConfirmOpen] = useState(false)

  const toggleConfirm = useCallback(() => {
		setIsConfirmOpen(!isConfirmOpen)
	}, [isConfirmOpen])

	const handleConfirm = useCallback(() => {
    dispatch(consultationRequestsActions.rejectConsultationRequest.request({
      id,
      alertMessage: t('LEOMED_ADMIN_OFFICE_REQUEST_REJECTED_TITLE'),
      page,
      callback: () => {
        if (+page > 1 && consultationRequests.items.length === 1) {
          handlePageChange?.(+page - 1)
        } else {
          dispatch(consultationRequestsActions.getConsultationRequests.request({ page: +page, limit: 10, subStatus: 'CLIENT_CREATED' }))
        }
      }
    }))
		setIsConfirmOpen(false)
	}, [dispatch, setIsConfirmOpen, id, t, page, consultationRequests.items.length, handlePageChange])

  return {
    isConfirmOpen,
    toggleConfirm,
    handleConfirm,
  }
}
