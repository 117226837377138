import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import {
	Box,
	TextField,
	InputLabel,
	makeStyles,
	TextFieldProps,
	Typography,
} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		marginTop: '10px',
	},
	labelWrapper: {
		display: 'flex',
		alignItems: 'center',
		gap: `${theme.spacing(1)}px`,
	},
	label: {
		fontWeight: 600,
		color: theme.palette.text.primary,
		fontSize: '14px',
		marginBottom: theme.spacing(2),
	},
	disabledLabel: {
		color: theme.palette.divider,
	},
}))

type TextareaFieldProps = TextFieldProps & {
	name: string
	label?: string
	errorMessage?: string
}

export const TextareaField: FC<TextareaFieldProps> = ({
	name,
	label,
	errorMessage,
	className,
	disabled,
	...rest
}) => {
	const classes = useStyles()
	const { t } = useTranslation()

	const labelStyles = clsx(classes.label, {
		[classes.disabledLabel]: disabled,
	})

	return (
		<Box className={classes.root}>
			{label && (
				<Box className={classes.labelWrapper}>
					<InputLabel className={labelStyles} htmlFor={name}>
						{label}
					</InputLabel>
				</Box>
			)}

			<TextField variant="outlined" name={name} disabled={disabled} {...rest} minRows={7} maxRows={7} />
			{errorMessage && <Typography>{t(errorMessage)}</Typography>}
		</Box>
	)
}
