import { useState, useEffect, useCallback } from 'react'

export const useAudio = (url: string) => {
	const [audio] = useState(new Audio(url))
	const [playing, setPlaying] = useState(false)

	const toggle = () => setPlaying(!playing)
	
	const disable = useCallback(() => {
		setPlaying(false)
	}, [])

	useEffect(() => {
		playing ? audio.play() : audio.pause()
	}, [audio, playing])

	useEffect(() => {
		audio.addEventListener('ended', () => setPlaying(false))
		return () => {
			audio.removeEventListener('ended', () => setPlaying(false))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return { playing, toggle, disable }
}
