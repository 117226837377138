import React, { FC, useCallback, useEffect } from 'react'
import { makeStyles } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect } from 'react-router'
import useSocket from 'use-socket.io-client'

import { SymptomsContainer } from './containers/SymptomsContainer'
import { ReportContainer } from './containers/ReportContainer'
import { getDoctorWorking, getPatientInfo } from 'pages/Office/redux/selectors'
import { RoutePath } from 'router/routes'
import { config } from 'utils/config'
import { callActions } from './redux/actions'
import { getSaveReportLoading } from './redux/selectors'
import { isLoading } from 'utils/types/store'
import { getAccessToken } from 'pages/Auth/redux/selectors'
import { VideoPage } from '../../video'
import { officeActions } from 'pages/Office/redux/actions'
import { getSocketSettings } from '../../utils/getSocketSettings'

const useStyles = makeStyles(theme => ({
	callRoot: {
		height: '100vh',
		display: 'flex',
	},
	report: {
		width: '25%',
		padding: theme.spacing(4),
		backgroundColor: theme.palette.background.paper,
	},
	submit: {
		display: 'flex',
		width: '45%',
		justifyContent: 'center',
		alignItems: 'center',
		marginTop: theme.spacing(4),
	},
	buttonClassName: {
		backgroundColor: theme.palette.primary.dark,
		color: theme.palette.primary.light,
		textTransform: 'capitalize',
		width: '100%',
		fontSize: 14,
		padding: theme.spacing(2, 0),
		lineHeight: 1.2,
		borderRadius: 10,
	},
}))

export const CallPage: FC = () => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const isDoctorWorking = useSelector(getDoctorWorking)
	const saveReportSubmitting = useSelector(getSaveReportLoading)
	const isSaveReportSubmitting = isLoading(saveReportSubmitting)
	const patientInfo = useSelector(getPatientInfo)
	const token = useSelector(getAccessToken)
	const [socket] = useSocket(config?.server?.host, getSocketSettings(token))

	useEffect(() => {
		if (!patientInfo) {
			dispatch(officeActions.getPatientInfo.request({}))
		}
	}, [dispatch, patientInfo])

	useEffect(() => {
		if (isDoctorWorking) {
			let timerID = setInterval(() => dispatch(officeActions.getDoctorStatus.request({})), 45000)
			return () => clearInterval(timerID)
		}
	}, [dispatch, isDoctorWorking])

	useEffect(() => {
		socket.connect()
		socket.on('doctor:roomCanceled', (e: any) => {
			dispatch(callActions.canLeaveCall({}))
		})

		socket.on('doctor:room:hangup', (e: any) => {
			dispatch(callActions.diconnectFromCall.request({}))
		})

		return () => {
			socket.off('doctor:roomCanceled')
			socket.off('doctor:room:hangup')
			socket.disconnect()
		}
	}, [socket, dispatch])

	const onSubmit = useCallback(
		values => {
			dispatch(
				callActions.saveReport.request({
					medicalReport: {
						...values,
					},
				})
			)
		},
		[dispatch]
	)

	useEffect(() => {
		window.addEventListener('message', (event: any) => {
			// if (event.origin !== config?.trueConf?.host) return

			if (event.data.type === 'hangUp') {
				dispatch(callActions.diconnectFromCall.request({}))
			}
		})
	}, [dispatch])

	if (!patientInfo) {
		return <Redirect to={RoutePath.office} />
	}

	return (
		<div className={classes.callRoot}>
			<SymptomsContainer />

			<VideoPage />
			<div className={classes.report}>
				<ReportContainer onSubmit={onSubmit} submitting={isSaveReportSubmitting} />
			</div>
		</div>
	)
}
