import React from 'react'

import { CssBaseline } from '@material-ui/core'
import { MuiThemeProvider } from '@material-ui/core/styles'

import App from './App'
import AppStateProvider, { useAppState } from './state'
import { BrowserRouter as Router, Switch } from 'react-router-dom'
import ErrorDialog from './components/ErrorDialog/ErrorDialog'
import PrivateRoute from './components/PrivateRoute/PrivateRoute'
import theme from '../utils/theme'
import './types'
import { VideoProvider } from './components/VideoProvider'
import useConnectionOptions from './utils/useConnectionOptions/useConnectionOptions'
import UnsupportedBrowserWarning from './components/UnsupportedBrowserWarning/UnsupportedBrowserWarning'
import { RoutePath } from 'router/routes'

const VideoApp = () => {
	const { error, setError } = useAppState()
	const connectionOptions = useConnectionOptions()

	return (
		<UnsupportedBrowserWarning>
			<VideoProvider options={connectionOptions} onError={setError}>
				<ErrorDialog dismissError={() => setError(null)} error={error} />
				<App />
			</VideoProvider>
		</UnsupportedBrowserWarning>
	)
}

export const VideoPage = () => (
	<MuiThemeProvider theme={theme}>
		<CssBaseline />
		<Router>
			<AppStateProvider>
				<Switch>
					{/* <PrivateRoute exact path="/call">
            <VideoApp />
          </PrivateRoute> */}
					<PrivateRoute path={RoutePath.call}>
						<VideoApp />
					</PrivateRoute>
					{/* <Route path="/login">
            <LoginPage />
          </Route> */}
				</Switch>
			</AppStateProvider>
		</Router>
	</MuiThemeProvider>
)
