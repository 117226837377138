import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useState, useEffect } from 'react'
import { profileActions } from './../redux/actions'

import { ProfileOrder, ProfileStep, ProfileOrderOffset } from '../redux/model'
import { getProfileStep } from '../redux/selectors'

export type UseProfile = {
	step: ProfileStep
	stepIndex: number
	setStep(step: ProfileStep): void
	previousStep(): void
	nextStep(): void
	finished: boolean
}

export const useProfileFlow = ({ onComplete }: { onComplete?: () => void } = {}): UseProfile => {
	const [finished, setFinished] = useState(false)
	const dispatch = useDispatch()
	const currentStep = useSelector(getProfileStep)
	const stepIndex = ProfileOrder.indexOf(currentStep) + ProfileOrderOffset + (finished ? 1 : 0)

	useEffect(() => {
		if (currentStep >= ProfileStep.firstStart) {
			setFinished(true)
		}
	}, [currentStep])

	const previousStep = useCallback(() => {
		const currentIndex = ProfileOrder.indexOf(currentStep)

		if (currentIndex > 0) {
			dispatch(profileActions.setProfileStep({ step: ProfileOrder[currentIndex - 1] }))
		}
	}, [dispatch, currentStep])

	const nextStep = useCallback(() => {
		const currentIndex = ProfileOrder.indexOf(currentStep)

		if (currentIndex < ProfileOrder.length - 1) {
			// dispatch(profileActions.setProfileStep({ step: ProfileOrder[currentIndex + 1] }))
		} else {
			onComplete?.()
		}
	}, [currentStep, onComplete])

	const setStep = useCallback(
		(step: ProfileStep) => {
			dispatch(profileActions.setProfileStep({ step }))
		},
		[dispatch]
	)

	return {
		step: currentStep,
		stepIndex,
		setStep,
		previousStep,
		nextStep,
		finished,
	}
}
