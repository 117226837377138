import { applyMiddleware, createStore, Middleware, StoreEnhancer } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { alertsMiddleware } from 'store/alerts/alertsMiddleware'
import SentryClient from 'utils/sentry'

import { reducer } from './reducers'
import { saga } from './sagas'

SentryClient.init()

const bindMiddleware = (...middleware: Middleware[]): StoreEnhancer => {
	if (process.env.NODE_ENV !== 'production') {
		const { composeWithDevTools } = require('redux-devtools-extension')
		return composeWithDevTools(applyMiddleware(...middleware))
	}

	return applyMiddleware(...middleware)
}

const sagaMiddleware = createSagaMiddleware()

export const store = createStore(reducer, bindMiddleware(sagaMiddleware, alertsMiddleware))

sagaMiddleware.run(saga)
