import { useSelector } from 'react-redux'
import { useState, useEffect } from "react"
import { getConsultationHistoryId } from 'pages/MyCalendar/redux/selectors'

export const usePatientConsultationDialog = () => {
  const [isPatientConsultationOpen, setIsPatientConsultationOpen] = useState(false)
  const consultationHistoryData = useSelector(getConsultationHistoryId)

  useEffect(() => {
    if (consultationHistoryData) {
      setIsPatientConsultationOpen(true)
    } else {
      setIsPatientConsultationOpen(false)
    }
  }, [consultationHistoryData])

  return {
    isPatientConsultationOpen,
    consultationHistoryData,
  }
}
