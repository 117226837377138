import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import MainParticipantInfo from '../MainParticipantInfo/MainParticipantInfo'
import ParticipantTracks from '../ParticipantTracks/ParticipantTracks'
import useMainParticipant from '../../hooks/useMainParticipant/useMainParticipant'
import useSelectedParticipant from '../VideoProvider/useSelectedParticipant/useSelectedParticipant'
import useScreenShareParticipant from '../../hooks/useScreenShareParticipant/useScreenShareParticipant'
import useVideoContext from '../../hooks/useVideoContext/useVideoContext'
import { getPersonalInfo } from 'pages/Profile/redux/selectors'
import { callActions } from 'pages/Call/redux/actions'
import { useState } from 'react'

export default function MainParticipant() {
	const mainParticipant = useMainParticipant()
	const dispatch = useDispatch()
	const [isConnected, setIsConnected] = useState(false)
	const {
		room: { localParticipant },
	} = useVideoContext()
	const [selectedParticipant] = useSelectedParticipant()
	const screenShareParticipant = useScreenShareParticipant()
	const personalInfo = useSelector(getPersonalInfo)
	const name = `${personalInfo.firstName} ${personalInfo.lastName}`
	const remoteParticipantConnected =
		mainParticipant.identity !== name && mainParticipant.state === 'connected'

	const remoteParticipantDisconnected =
		mainParticipant.identity === name && mainParticipant.state === 'connected' && isConnected

	useEffect(() => {
		if (remoteParticipantConnected) {
			dispatch(callActions.toggleRemoteParticipantConnected({}))
			dispatch(callActions.sendUserConnectedEvent.request({}))
			setIsConnected(true)
		}
	}, [dispatch, remoteParticipantConnected])

	useEffect(() => {
		if (remoteParticipantDisconnected) {
			dispatch(callActions.sendUserDisconnectedEvent.request({}))
			setIsConnected(false)
		}
	}, [dispatch, remoteParticipantDisconnected])

	const videoPriority =
		(mainParticipant === selectedParticipant || mainParticipant === screenShareParticipant) &&
		mainParticipant !== localParticipant
			? 'high'
			: null

	return (
		/* audio is disabled for this participant component because this participant's audio 
       is already being rendered in the <ParticipantStrip /> component.  */
		<MainParticipantInfo participant={mainParticipant}>
			<ParticipantTracks
				participant={mainParticipant}
				videoOnly
				enableScreenShare={mainParticipant !== localParticipant}
				videoPriority={videoPriority}
				isLocalParticipant={mainParticipant === localParticipant}
			/>
		</MainParticipantInfo>
	)
}
