import React from 'react'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import { Button, CircularProgress, Tooltip } from '@material-ui/core'

import useVideoContext from '../../../hooks/useVideoContext/useVideoContext'
import { useCallback, useState } from 'react'
import { getRemoteParticipantConnected, getCanLeaveCall, getDiconnectFromCallLoading } from 'pages/Call/redux/selectors'
import { useDispatch, useSelector } from 'react-redux'
import { callActions } from 'pages/Call/redux/actions'
import { ConfirmDialog } from 'components/common/layout/ConfirmDialog'
import { withStyles } from '@material-ui/styles'
import { red } from '@material-ui/core/colors'
import { useHistory } from 'react-router'
import { RoutePath } from 'router/routes'
import { OverlayButton } from 'components/common/OverlayButton'
import { isLoading } from 'utils/types/store'

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		button: {
			background: theme.brand,
			borderRadius: 4,
			color: 'white',
			'&:hover': {
				background: theme.brand,
			},
		},
		disconnectBtn: {
			background: red[500],
			color: 'white',
			'&:hover': {
				background: red[500],
			},
		},
		buttonClassName: {
			color: 'white',
		},
		endingCallBtn: {
			backgroundColor: theme.palette.divider,
			'&:hover': {
				backgroundColor: theme.palette.divider,
			},
		},
		spinner: {
			color: 'white'
		}
	})
)

const DarkTooltip = withStyles((theme: Theme) => ({
	tooltip: {
		padding: theme.spacing(2),
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.background.paper,
		boxShadow: theme.shadows[1],
		fontSize: 11,
	},
	arrow: {
		color: theme.palette.primary.main,
	},
}))(Tooltip)

export default function EndCallButton(props: { className?: string }) {
	const classes = useStyles()
	const history = useHistory()
	const [open, setOpen] = useState(false)
	const { t } = useTranslation()

	const { room } = useVideoContext()
	const canLeaveCall = useSelector(getCanLeaveCall)
	const dispatch = useDispatch()
	const remoteParticipantConnected = useSelector(getRemoteParticipantConnected)
	const submitting = useSelector(getDiconnectFromCallLoading)
	const isSubmitting = isLoading(submitting)
	const btnClassNames = clsx(classes.button, props.className, {
		[classes.endingCallBtn]: isSubmitting
	})

	const clickHandler = useCallback(() => {
		dispatch(
			callActions.diconnectFromCall.request({
				callback: () => {
					room.disconnect()
					history.push(RoutePath.office)
					window.location.reload()
				},
			})
		)
	}, [dispatch, history, room])

	const toggleConfirm = useCallback(() => {
		setOpen(!open)
	}, [open])

	const handleConfirm = useCallback(() => {
		dispatch(
			callActions.leaveFromCall.request({
				callback: () => {
					history.push(RoutePath.office)
					window.location.reload()
				},
			})
		)
	}, [dispatch, history])

	return (
		<>
			{!remoteParticipantConnected ? (
				<div>
					<DarkTooltip title={t('LEOMED_ADMIN_LEAVE_CALL_DESCRIPTION') as string} arrow>
						<span>
							<Button
								variant="contained"
								className={classes.disconnectBtn}
								onClick={toggleConfirm}
								disabled={!canLeaveCall}
							>
								{t('LEOMED_ADMIN_CANCEL_CALL')}
							</Button>
						</span>
					</DarkTooltip>

					<ConfirmDialog
						open={open}
						handleClose={toggleConfirm}
						handleConfirm={handleConfirm}
						title={t('LEOMED_ADMIN_END_CALL_CONFIRM_TITLE')}
						cancelText={t('LEOMED_ADMIN_NO_TEXT')}
						acceptText={t('LEOMED_ADMIN_YES_TEXT')}
					/>
				</div>
			) : (
				<OverlayButton
					onClick={clickHandler}
					className={btnClassNames}
					buttonClassName={classes.buttonClassName}
					data-cy-disconnect
					overlay={isSubmitting && <CircularProgress size={24} className={classes.spinner} />}
				>
					{t('LEOMED_ADMIN_END_CALL')}
				</OverlayButton>
			)}
		</>
	)
}
