import { createSelector } from 'reselect'
import { State } from 'store/reducers'

export const getProfileEntities = (state: State): State['profile']['entities'] =>
	state.profile.entities
export const getProfileUi = (state: State): State['profile']['ui'] => state.profile.ui

export const getProfileStep = createSelector(getProfileEntities, entities => entities.step)
export const getEditId = createSelector(getProfileEntities, entities => entities.editId)
export const getProfileLoading = createSelector(
	getProfileUi,
	entities => entities.getProfileLoading
)
export const updateProfileLoading = createSelector(
	getProfileUi,
	entities => entities.updateProfileLoading
)
export const getPersonalInfo = createSelector(getProfileEntities, entities => entities.personalInfo)
export const getNeedRedirectToOffice = createSelector(
	getProfileEntities,
	entities => entities.needRedirectToOffice
)
export const getIsProfileActivated = createSelector(
	getProfileEntities,
	entities => entities.activated
)
export const getCreatedDate = createSelector(
	getProfileEntities,
	entities => entities.created
)
export const getEducationItems = createSelector(getProfileEntities, entities =>
	entities.educationItemsIds.map(id => entities.educationItems[id])
)
export const getEducationItemById = (id: string) =>
	createSelector(getProfileEntities, entities => entities.educationItems[id])
export const getSpecializationItemById = (id: string) =>
	createSelector(getProfileEntities, entities => entities.specializationItems[id])
export const getExperienceItemById = (id: string) =>
	createSelector(getProfileEntities, entities => entities.experienceItems[id])
export const getDeleteAttachmentsByCategory = (name: string) =>
	createSelector(getProfileEntities, entities => entities.deleteAttachments[name])
export const getSpecializationItems = createSelector(getProfileEntities, entities =>
	entities.specializationItemsIds.map(id => entities.specializationItems[id])
)
export const getExperienceItems = createSelector(getProfileEntities, entities =>
	entities.experienceItemsIds.map(id => entities.experienceItems[id])
)
