import { SagaIterator } from "redux-saga"
import { call, put, takeLatest } from "redux-saga/effects"
import { ActionType } from "typesafe-actions"
import { serverAxios } from "utils/api/serverAxios"
import { extractErrorMessage } from "utils/errors"
import { consultationRequestsActions } from "./actions"
import { formatRequests } from "./utils/formatRequests"

function* getConsultationRequests(action: ActionType<typeof consultationRequestsActions.getConsultationRequests.request>): SagaIterator {
	const { limit, page, subStatus } = action.payload
	try {
		const response = yield call(serverAxios.get, `/api/doctor/scheduled/consultations`, {
			headers: {
				'x-pagination-page': page || 1,
				'x-pagination-limit': limit || 10,
				'x-filter-sub-status': subStatus || '',
			}
		})

		yield put(consultationRequestsActions.getConsultationRequests.success({ 
			data: formatRequests(response) || [],
			page: response.meta.page,
			total: response.meta.count,
			limit: response.meta.limit,
		}))
	} catch (error) {
		yield put(consultationRequestsActions.getConsultationRequests.failure({ message: extractErrorMessage(error) }))
	}
}

function* approveConsultationRequest(action: ActionType<typeof consultationRequestsActions.approveConsultationRequest.request>): SagaIterator {
	const { id, alertMessage, callback } = action.payload

	try {
		const response = yield call(serverAxios.post, `/api/doctor/scheduled/consultation/approve`, {}, {
			headers: {
				'X-Consultation-ID': id
			}
		})
		yield put(consultationRequestsActions.approveConsultationRequest.success({ id, data: response.data, alertMessage }))
		callback?.()
	} catch (error) {
		yield put(consultationRequestsActions.approveConsultationRequest.failure({ id, message: extractErrorMessage(error) }))
	}
}

function* rejectConsultationRequest(action: ActionType<typeof consultationRequestsActions.rejectConsultationRequest.request>): SagaIterator {
	const { id, alertMessage, callback } = action.payload

	try {
		const response = yield call(serverAxios.delete, `/api/doctor/scheduled/consultation`, {
			headers: {
				'X-Consultation-ID': id
			}
		})

		yield put(consultationRequestsActions.rejectConsultationRequest.success({ id, data: response.data, alertMessage, color: 'error' }))
		callback?.()
	} catch (error) {
		yield put(consultationRequestsActions.rejectConsultationRequest.failure({ id, message: extractErrorMessage(error) }))
	}
}

function* updateConsultationRequest(action: ActionType<typeof consultationRequestsActions.updateConsultationRequest.request>): SagaIterator {
	const { id, date, time, callback, alertMessage } = action.payload

	try {
		const response = yield call(serverAxios.put, `/api/doctor/scheduled/consultation`, {
			date,
			time,
		}, {
			headers: {
				'X-Consultation-ID': id
			}
		})

		yield put(consultationRequestsActions.updateConsultationRequest.success({ id, data: response.data, alertMessage, color: 'pending' }))
		callback()
	} catch (error) {
		yield put(consultationRequestsActions.updateConsultationRequest.failure({ id, message: extractErrorMessage(error) }))
	}
}

export default [
  takeLatest(consultationRequestsActions.getConsultationRequests.request, getConsultationRequests),
  takeLatest(consultationRequestsActions.approveConsultationRequest.request, approveConsultationRequest),
	takeLatest(consultationRequestsActions.rejectConsultationRequest.request, rejectConsultationRequest),
	takeLatest(consultationRequestsActions.updateConsultationRequest.request, updateConsultationRequest),
]
