import React, { useCallback } from 'react'

import Button from '@material-ui/core/Button'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import VideoOffIcon from '../../../icons/VideoOffIcon'
import VideoOnIcon from '../../../icons/VideoOnIcon'

import { getIsSelfieCameraShown } from 'pages/Call/redux/selectors'
import { callActions } from 'pages/Call/redux/actions'

export default function ToggleDoctorCameraBox(props: { disabled?: boolean; className?: string }) {
	const dispatch = useDispatch()
  const isSelfieCameraShown = useSelector(getIsSelfieCameraShown)
	const { t } = useTranslation()

  const toggleSelfieCamera = useCallback(() => {
    dispatch(callActions.toggleSelfieCamera())
  }, [dispatch])

	return (
		<Button
			className={props.className}
			onClick={toggleSelfieCamera}
			startIcon={isSelfieCameraShown ? <VideoOnIcon /> : <VideoOffIcon />}
		>
			{true
				? t('LEOMED_ADMIN_CALL_HIDE_SELFIE_CAMERA')
				: t('LEOMED_ADMIN_CALL_SHOW_SELFIE_CAMERA')
      }
		</Button>
	)
}
