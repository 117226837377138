import Button, { ButtonProps } from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import React, { FC } from 'react'

const useStyles = makeStyles(theme => ({
	root: { position: 'relative', display: 'block' },
	rootInline: { display: 'inline-block' },
	rootFullWidth: { width: '100%' },
	overlay: { position: 'absolute', top: '50%', left: '50%', marginTop: -12, marginLeft: -12 },
}))

export type OverlayButtonProps = ButtonProps & {
	buttonClassName?: string
	overlay?: React.ReactNode

	inline?: boolean
}

export const OverlayButton: FC<OverlayButtonProps> = ({
	className,
	buttonClassName,
	overlay,
	inline,
	disabled,
	...other
}) => {
	const classes = useStyles()

	const disabledComputed = disabled || !!overlay || false

	const rootClasses = clsx(className, classes.root, {
		[classes.rootInline]: inline,
		[classes.rootFullWidth]: other.fullWidth,
	})

	return (
		<div className={rootClasses}>
			<Button {...other} className={buttonClassName} disabled={disabledComputed} />
			{overlay && <div className={classes.overlay}>{overlay}</div>}
		</div>
	)
}
