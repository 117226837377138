import { formatRequests } from "pages/ConsultationRequests/redux/utils/formatRequests"
import { SagaIterator } from "redux-saga"
import { call, put, takeLatest } from "redux-saga/effects"
import { ActionType } from "typesafe-actions"
import { serverAxios } from "utils/api/serverAxios"
import { extractErrorMessage } from "utils/errors"
import { scheduledRequestsActions } from "./actions"

function* getScheduledRequests(action: ActionType<typeof scheduledRequestsActions.getScheduledRequests.request>): SagaIterator {
	const { limit, page } = action.payload
	try {
		const response = yield call(serverAxios.get, `/api/doctor/scheduled/consultations`, {
			headers: {
				'x-pagination-page': page || 1,
				'x-pagination-limit': limit || 10,
			}
		})

		yield put(scheduledRequestsActions.getScheduledRequests.success({ 
			data: formatRequests(response) || [],
			page: response.meta.page,
			total: response.meta.count,
			limit: response.meta.limit,
		}))
	} catch (error) {
		yield put(scheduledRequestsActions.getScheduledRequests.failure({ message: extractErrorMessage(error) }))
	}
}

function* getConsultationHistory(action: ActionType<typeof scheduledRequestsActions.getConsultationHistory.request>): SagaIterator {
	const { limit, page } = action.payload
	try {
		const response = yield call(serverAxios.get, `/api/doctor/history/consultations`, {
			headers: {
				'x-pagination-page': page || 1,
				'x-pagination-limit': limit || 10,
			}
		})

		yield put(scheduledRequestsActions.getConsultationHistory.success({ 
			data: response.data || [],
			page: response.meta.page,
			total: response.meta.count,
			limit: response.meta.limit,
		}))
	} catch (error) {
		yield put(scheduledRequestsActions.getConsultationHistory.failure({ message: extractErrorMessage(error) }))
	}
}

function* getConsultationData(action: ActionType<typeof scheduledRequestsActions.getConsultationData.request>): SagaIterator {
	const { id } = action.payload
	try {
		const response = yield call(serverAxios.get, `/api/doctor/history/consultation`, {
			headers: {
				'x-id': id
			}
		})

		yield put(scheduledRequestsActions.getConsultationData.success({ 
			data: response,
		}))
	} catch (error) {
		yield put(scheduledRequestsActions.getConsultationData.failure({ message: extractErrorMessage(error) }))
	}
}

export default [
  takeLatest(scheduledRequestsActions.getScheduledRequests.request, getScheduledRequests),
  takeLatest(scheduledRequestsActions.getConsultationHistory.request, getConsultationHistory),
  takeLatest(scheduledRequestsActions.getConsultationData.request, getConsultationData),
]
