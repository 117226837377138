import React, { FC, useCallback, useMemo } from 'react'
import GroupIcon from '@material-ui/icons/Group'
import EditIcon from '@material-ui/icons/Edit'
import { IconButton, makeStyles } from '@material-ui/core'
import { Colors } from 'utils/theme'
import DeleteIcon from '@material-ui/icons/Delete'
import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'

import { SpecializationItem } from 'pages/Profile/redux/model'

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		backgroundColor: theme.palette.background.default,
		borderRadius: '10px',
		padding: theme.spacing(2),
		marginBottom: theme.spacing(3),
		position: 'relative',
	},
	logo: {
		width: '50px',
		height: '50px',
		borderRadius: '50%',
		backgroundColor: theme.palette.primary.main,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		color: theme.palette.background.paper,
	},
	univer: {
		marginLeft: theme.spacing(2),
	},
	univerTitle: {
		fontWeight: 600,
		fontSize: '18px',
		marginBottom: theme.spacing(1),
	},
	faculty: {
		fontSize: '12px',
	},
	ml10: {
		marginLeft: theme.spacing(10),
	},
	rankWrapper: {
		width: 200,
		marginLeft: theme.spacing(10),
	},
	catergoryWrapper: {
		width: 110,
		marginLeft: theme.spacing(10),
	},
	title: {
		fontSize: '12px',
		color: Colors.grey[700],
		marginBottom: theme.spacing(1),
	},
	description: {
		fontWeight: 500,
		fontSize: '16px',
	},
	edit: {
		position: 'absolute',
		right: 0,
		top: 0,
		cursor: 'pointer',
	},
	delete: {
		position: 'absolute',
		right: 40,
		top: 0,
		cursor: 'pointer',
	},
	top: {
		paddingBottom: theme.spacing(2),
		display: 'flex',
	},
	bottom: {
		paddingTop: theme.spacing(2),
	},
}))

type SpecializationBannerProps = {
	item: SpecializationItem
	editItem: (itemId: string) => void
	deleteBanner?: (itemId: string) => void
}

export const SpecializationBanner: FC<SpecializationBannerProps> = ({
	item: { id, type, category, gradeLevel, start },
	editItem,
	deleteBanner,
}) => {
	const classes = useStyles()
	const { t } = useTranslation(['translation', 'specialty', 'degree', 'category'])
	const startDate = new Date(start)
	const date = format(startDate as Date, 'MM/dd/yyyy')
	const workExp = useMemo(
		() => +format(new Date() as Date, 'yyyy') - +format(startDate as Date, 'yyyy'),
		[startDate]
	)

	const specialtyOptions = t('specialty:SPECIALTY') as any[]
	const gradesOptions = t('degree:DEGREE') as any[]
	const categoryOptions = t('category:CATEGORY') as any[]
	const specialty = specialtyOptions.find(item => item.value === type).label
	const grade = gradesOptions.find(item => item.value === gradeLevel).label
	const doctorsCategory = categoryOptions.find(item => item.value === category).label

	const clickHandler = useCallback(() => {
		editItem(id)
	}, [editItem, id])

	const deleteItem = useCallback(() => {
		deleteBanner?.(id)
	}, [deleteBanner, id])

	return (
		<div className={classes.root}>
			<div className={classes.top}>
				<div className={classes.logo}>
					<GroupIcon fontSize="large" />
				</div>
				<div className={classes.univer}>
					<div className={classes.univerTitle}>
						{t('LEOMED_ADMIN_PROFILE_PAGE_SPECIALIZATION_LABEL')}
					</div>
					<div className={classes.faculty}>{specialty}</div>
				</div>
				<div className={classes.rankWrapper}>
					<div className={classes.title}>{t('LEOMED_ADMIN_PROFILE_PAGE_PICK_RANK_LABEL')}</div>
					<div className={classes.description}>{grade || '-'}</div>
				</div>
				<div className={classes.catergoryWrapper}>
					<div className={classes.title}>{t('LEOMED_ADMIN_PROFILE_PAGE_PICK_CATEGORY_LABEL')}</div>
					<div className={classes.description}>{doctorsCategory || '-'}</div>
				</div>
				<div className={classes.ml10}>
					<div className={classes.title}>{t('LEOMED_ADMIN_PROFILE_PAGE_START_PRACTICE_LABEL')}</div>
					<div className={classes.description}>{date}</div>
				</div>
				<div className={classes.ml10}>
					<div className={classes.title}>{t('LEOMED_ADMIN_PROFILE_PAGE_EXPERIENCE_LABEL')}</div>
					<div className={classes.description}>{`${workExp} ${t(
						'LEOMED_ADMIN_PROFILE_PAGE_YEARS_LABEL'
					)}`}</div>
				</div>
				<div className={classes.edit}>
					<IconButton onClick={clickHandler}>
						<EditIcon color="primary" />
					</IconButton>
				</div>
				{deleteBanner && (
					<div className={classes.delete}>
						<IconButton onClick={deleteItem}>
							<DeleteIcon color="primary" />
						</IconButton>
					</div>
				)}
			</div>
		</div>
	)
}
