import { getPatientInfo } from './../../Office/redux/selectors'
import { SagaIterator } from 'redux-saga'
import { call, put, takeLatest, select } from 'redux-saga/effects'
import { ActionType } from 'typesafe-actions'

import { callActions } from './actions'
import { serverAxios } from 'utils/api/serverAxios'
import { extractErrorMessage } from 'utils/errors'

function* diconnectFromCall(
	action: ActionType<typeof callActions.diconnectFromCall.request>
): SagaIterator {
	try {
		yield call(serverAxios.post, `/api/doctor/disconnect`)

		yield put(callActions.diconnectFromCall.success({}))
		if (action.payload.callback) {
			action.payload.callback()
		}
		window.location.reload()
	} catch (error) {
		yield put(callActions.diconnectFromCall.failure({ message: extractErrorMessage(error) }))
	}
}

function* leaveFromCall(
	action: ActionType<typeof callActions.leaveFromCall.request>
): SagaIterator {
	try {
		yield call(serverAxios.post, `/api/doctor/decline-session`)

		yield put(callActions.leaveFromCall.success({}))
		if (action.payload.callback) {
			action.payload.callback()
		}
	} catch (error) {
		yield put(callActions.leaveFromCall.failure({ message: extractErrorMessage(error) }))
	}
}

function* saveReport(action: ActionType<typeof callActions.saveReport.request>): SagaIterator {
	const { medicalReport } = action.payload

	try {
		yield call(serverAxios.put, `/api/doctor/medical-report`, { data: { medicalReport } })

		yield put(callActions.saveReport.success({ medicalReport }))
	} catch (error) {
		yield put(callActions.saveReport.failure({ message: extractErrorMessage(error) }))
	}
}

function* sendUserConnectedEvent(
	action: ActionType<typeof callActions.sendUserConnectedEvent.request>
): SagaIterator {
	try {
		const patientInfo = yield select(getPatientInfo)
		const response = yield call(serverAxios.put, `/api/statistic/user/0`, null, {
			headers: {
				'X-Session-ID': patientInfo.id,
			},
		})

		yield put(
			callActions.sendUserConnectedEvent.success({ callDuration: response.data.events[0].time })
		)
	} catch (error) {
		yield put(callActions.sendUserConnectedEvent.failure({ message: extractErrorMessage(error) }))
	}
}

function* sendUserDisconnectedEvent(
	action: ActionType<typeof callActions.sendUserDisconnectedEvent.request>
): SagaIterator {
	try {
		const patientInfo = yield select(getPatientInfo)
		yield call(serverAxios.put, `/api/statistic/user/1`, null, {
			headers: {
				'X-Session-ID': patientInfo.id,
			},
		})

		yield put(callActions.sendUserDisconnectedEvent.success({}))
	} catch (error) {
		yield put(callActions.sendUserDisconnectedEvent.failure({ message: extractErrorMessage(error) }))
	}
}

export default [
	takeLatest(callActions.diconnectFromCall.request, diconnectFromCall),
	takeLatest(callActions.leaveFromCall.request, leaveFromCall),
	takeLatest(callActions.sendUserConnectedEvent.request, sendUserConnectedEvent),
	takeLatest(callActions.sendUserDisconnectedEvent.request, sendUserDisconnectedEvent),
	takeLatest(callActions.saveReport.request, saveReport),
]
