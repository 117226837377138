import React, { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Dialog, makeStyles } from '@material-ui/core'
import { PatientInfoBanner } from './PatientInfoBanner'
import { ReportContainer } from 'pages/Call/containers/ReportContainer'
import { officeActions } from 'pages/Office/redux/actions'
import { getSendReportLoading } from 'pages/Office/redux/selectors'
import { isLoading } from 'utils/types/store'

const useStyles = makeStyles(theme => ({
	root: {
		padding: theme.spacing(4),
	},
	title: {
		fontSize: 24,
		fontWeight: 700,
	},
	description: {
		fontSize: 14,
		margin: theme.spacing(2, 0, 4, 0),
	},
	subtitle: {
		fontSize: 18,
		fontWeight: 700,
		marginBottom: theme.spacing(2),
	},
}))

export const EndReportDialog: FC = () => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const sendReportSubmitting = useSelector(getSendReportLoading)
	const isSendReportSubmitting = isLoading(sendReportSubmitting)

	const onSubmit = useCallback(
		values => {
			dispatch(
				officeActions.sendReport.request({
					medicalReport: {
						...values,
					},
				})
			)
		},
		[dispatch]
	)

	return (
		<Dialog open maxWidth="md" fullWidth>
			<div className={classes.root}>
				<div className={classes.title}>{t('LEOMED_ADMIN_CONSULTATION_FINISHED_TEXT')}</div>
				<div className={classes.description}>
					{t('LEOMED_ADMIN_CONSULTATION_FINISHED_DESCRIPTION')}
				</div>
				<div className={classes.subtitle}>{t('LEOMED_ADMIN_DIALOG_PATIENT_TEXT')}</div>
				<PatientInfoBanner />
				<ReportContainer
					onSubmit={onSubmit}
					submitting={isSendReportSubmitting}
					btnText={t('LEOMED_ADMIN_SEND_BUTTON_TEXT')}
				/>
			</div>
		</Dialog>
	)
}
