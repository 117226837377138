import React, { useRef, useEffect } from 'react'
import { IVideoTrack } from '../../types'
import { styled } from '@material-ui/core/styles'
import { Track } from 'twilio-video'
import useMediaStreamTrack from '../../hooks/useMediaStreamTrack/useMediaStreamTrack'

const Video = styled('video')({
	width: '100%',
	height: '100%',
	zIndex: 1,
})

interface VideoTrackProps {
	track: IVideoTrack
	isLocal?: boolean
	priority?: Track.Priority | null
}

export default function VideoTrack({ track, isLocal, priority }: VideoTrackProps) {
	const ref = useRef<HTMLVideoElement>(null!)
	const mediaStreamTrack = useMediaStreamTrack(track)

	useEffect(() => {
		const el = ref.current
		el.muted = true
		if (track.setPriority && priority) {
			track.setPriority(priority)
		}
		track.attach(el)
		return () => {
			track.detach(el)
			if (track.setPriority && priority) {
				// Passing `null` to setPriority will set the track's priority to that which it was published with.
				track.setPriority(null as any)
			}
		}
	}, [track, priority])

	// The local video track is mirrored if it is not facing the environment.
	const isFrontFacing = mediaStreamTrack?.getSettings().facingMode !== 'environment'
	const style = {
		transform: isLocal && isFrontFacing ? 'rotateY(180deg)' : '',
		objectFit: 'cover' as const,
	}

	return <Video ref={ref} style={style} />
}
