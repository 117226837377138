import { combineReducers } from 'redux'
import { StateType } from 'typesafe-actions'

import { authReducer } from 'pages/Auth/redux/reducers'
import { profileReducer } from 'pages/Profile/redux/reducers'
import { officeReducer } from 'pages/Office/redux/reducers'
import { callReducer } from 'pages/Call/redux/reducers'
import { consultationRequestsReducer } from 'pages/ConsultationRequests/redux/reducers'
import { scheduledRequestsReducer } from 'pages/MyCalendar/redux/reducers'
import { alertsReducer } from './alerts/reducer'

export const reducer = combineReducers({
	auth: authReducer,
	profile: profileReducer,
	office: officeReducer,
	consultationRequests: consultationRequestsReducer,
	scheduledRequests: scheduledRequestsReducer,
	call: callReducer,
	alerts: alertsReducer,
})

export type State = StateType<typeof reducer>
