import { Box, Button, FormHelperText, InputLabel, makeStyles, TextField } from '@material-ui/core'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { useDispatch, useSelector } from 'react-redux'
import { Controller, useForm } from 'react-hook-form'
import clsx from 'clsx'
import { Colors } from 'utils/theme'

import useUploadFile from 'hooks/useUploadFile'
import { ProfileHeader } from '../components/ProfileHeader'
import { profileActions } from '../redux/actions'
import { PersonalInfo, ProfileStep } from '../redux/model'
import ImagePicker from 'components/common/inputs/ImagePicker'
import { InputField } from 'components/common/inputs/InputField'
import { PhoneInputField } from 'components/common/inputs/PhoneInputField'
import { SelectField } from 'components/common/inputs/SelectField'
// import { useLocalization } from '../hooks/useLocalization'
import { getEditId, getPersonalInfo, getProfileStep } from '../redux/selectors'
import { InputDatePicker } from 'components/common/inputs/InputDatePicker'

const useStyles = makeStyles(theme => ({
	root: {
		padding: theme.spacing(3),
		borderBottom: `1px solid ${theme.palette.divider}`,
	},
	fieldsWrapper: {
		display: 'flex',
		justifyContent: 'space-between',
		marginBottom: theme.spacing(2),
	},
	field: {
		padding: theme.spacing(1),
		width: '32%',
	},
	labelWrapper: {
		marginBottom: theme.spacing(2),
	},
	label: {
		fontSize: '14px',
		color: theme.palette.primary.dark,
	},
	imageWrapper: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	imageText: {
		width: '75%',
	},
	subtitle: {
		fontWeight: 600,
		fontSize: '18px',
		paddingLeft: theme.spacing(1),
		margin: theme.spacing(5, 0, 2, 0),
	},
	terms: {
		padding: theme.spacing(3, 3, 0, 4),
	},
	termsLink: {
		color: theme.palette.primary.main,
		cursor: 'pointer',
		textDecoration: 'underline',
	},
	addBtnWrapper: {
		padding: theme.spacing(1),
	},
	addBtn: {
		backgroundColor: Colors.green[500],
		color: theme.palette.background.paper,
		'&:hover': {
			backgroundColor: Colors.green[500],
		},
	},
	saveBtn: {
		backgroundColor: theme.palette.primary.main,
		marginRight: theme.spacing(2),
		'&:hover': {
			backgroundColor: theme.palette.primary.main,
		},
	},
}))

export const PersonalContainer: FC = () => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const { t } = useTranslation(['translation', 'cities', 'countries', 'gender'])
	// const { changeLngHandler } = useLocalization()
	const editId = useSelector(getEditId)
	const personalInfo = useSelector(getPersonalInfo)
	const [isUkraine, setIsUkraine] = useState(personalInfo.country === 'ukraine')
	const [birthDate, setBirthDate] = useState<Date | null>(new Date('2001'))
	const { onFileChange, selectedFiles, handleDelete, formData, filesForFormData } = useUploadFile({
		name: 'avatar',
	})

	const { register, handleSubmit, errors, control, reset, setValue, clearErrors } = useForm<
		PersonalInfo
	>({
		mode: 'all',
		reValidateMode: 'onChange',
		defaultValues: {
			birthDate: new Date('2002'),
		},
	})

	const cityOptions = t('cities:CITIES') as any[]
	const countryOptions = t('countries:COUNTRIES') as any[]
	const genderOptions = t('gender:GENDER') as any[]
	const defaultCity = cityOptions.find(city => city.id === personalInfo.city)
	const profileStep = useSelector(getProfileStep)
	const isProfileFinished = profileStep > ProfileStep.almost_done

	const onSubmit = useCallback(
		values => {
			filesForFormData.forEach(item => {
				formData?.append('avatar', item, item.name)
			})

			dispatch(
				profileActions.updateProfile.request({
					profile: values,
					step: ProfileStep.education,
					attachments: formData,
				})
			)
		},
		[dispatch, filesForFormData, formData]
	)

	useEffect(() => {
		const { city, ...rest } = personalInfo
		reset(rest)
	}, [personalInfo, reset])

	useEffect(() => {
		setValue('city', '')
	}, [setValue, isUkraine])

	const addHandler = useCallback(
		values => {
			filesForFormData.forEach(item => {
				formData?.append('avatar', item, item.name)
			})

			dispatch(
				profileActions.updateProfile.request({
					profile: values,
					step: ProfileStep.almost_done,
					attachments: formData,
				})
			)
			if (editId) {
				dispatch(profileActions.editItemById({ itemId: null }))
				dispatch(profileActions.setProfileStep({ step: ProfileStep.almost_done }))
			}
		},
		[dispatch, editId, filesForFormData, formData]
	)

	const cancelHandler = useCallback(
		errors => {
			if (editId) {
				dispatch(profileActions.editItemById({ itemId: null }))
				dispatch(profileActions.setProfileStep({ step: ProfileStep.almost_done }))
			}
		},
		[dispatch, editId]
	)

	const onCityChange = useCallback(
		(event, newValue) => {
			if (newValue) {
				clearErrors('city')
			}
			setValue('city', newValue?.id || '')
		},
		[clearErrors, setValue]
	)

	const onCountryChange = useCallback(
		(newValue) => {
			if (newValue) {
				clearErrors('country')
			}
			if (newValue !== 'ukraine') {
				setIsUkraine(false)
			} else {
				setIsUkraine(true)
			}
			setValue('country', newValue || '')
			setValue('city', '')
		},
		[clearErrors, setValue]
	)

	const selectDate = useCallback(
		date => {
			setValue('birthDate', date)
			setBirthDate(date)
		},
		[setValue]
	)

	useEffect(() => {
		if (personalInfo.birthDate) {
			selectDate(personalInfo.birthDate)
		}
	}, [personalInfo.birthDate, selectDate])

	const addBtnStyles = clsx(classes.addBtn, {
		[classes.saveBtn]: editId,
	})

	return (
		<div>
			<ProfileHeader
				title={t('LEOMED_ADMIN_PROFILE_PAGE_PERSONAL_DATA_TITLE')}
				buttonText={t(
					isProfileFinished ? 'LEOMED_ADMIN_SAVE_BUTTON_TEXT' : 'LEOMED_ADMIN_NEXT_BUTTON_TEXT'
				)}
				onSubmit={handleSubmit(onSubmit)}
				disabled={!!editId}
				noBack={!!editId}
			/>
			<div className={classes.root}>
				<div className={classes.fieldsWrapper}>
					<div className={classes.field}>
						<InputField
							variant="outlined"
							name="lastName"
							label={t('LEOMED_ADMIN_PROFILE_PAGE_SURNAME_LABEL')}
							placeholder={t('LEOMED_ADMIN_PROFILE_PAGE_SURNAME_PLACEHOLDER')}
							inputRef={register({
								required: 'LEOMED_ADMIN_REQUIRED_FIELD_ERROR',
								validate: value => value.trim() !== '',
							})}
							error={!!errors.lastName}
							helperText={t(errors.lastName?.message as string)}
						/>
					</div>
					<div className={classes.field}>
						<InputField
							variant="outlined"
							name="firstName"
							label={t('LEOMED_ADMIN_PROFILE_PAGE_NAME_LABEL')}
							placeholder={t('LEOMED_ADMIN_PROFILE_PAGE_NAME_PLACEHOLDER')}
							inputRef={register({
								required: 'LEOMED_ADMIN_REQUIRED_FIELD_ERROR',
								validate: value => value.trim() !== '',
							})}
							error={!!errors.firstName}
							helperText={t(errors.firstName?.message as string)}
						/>
					</div>
					<div className={classes.field}>
						<InputField
							variant="outlined"
							name="middleName"
							label={t('LEOMED_ADMIN_PROFILE_PAGE_MIDDLE_NAME_LABEL')}
							placeholder={t('LEOMED_ADMIN_PROFILE_PAGE_MIDDLE_NAME_PLACEHOLDER')}
							inputRef={register({ validate: value => !value.length || value.trim() !== '' })}
							error={!!errors.middleName}
							helperText={t(errors.middleName?.message as string)}
						/>
					</div>
				</div>
				<div className={classes.fieldsWrapper}>
					<div className={classes.field}>
						<Controller
							control={control}
							name="birthDate"
							defaultValue=""
							render={props => (
								<InputDatePicker
									selectDate={selectDate}
									maxDate={new Date('2002-12-31')}
									startDate={birthDate}
									label={t('LEOMED_ADMIN_PROFILE_PAGE_DOB_LABEL')}
									{...props}
								/>
							)}
						/>
					</div>
					<div className={classes.field}>
						<Controller
							control={control}
							name="gender"
							defaultValue=""
							rules={{ required: 'LEOMED_ADMIN_REQUIRED_FIELD_ERROR' }}
							as={
								<SelectField
									label={t('LEOMED_ADMIN_PROFILE_PAGE_GENDER_LABEL')}
									options={genderOptions}
									error={!!errors.gender}
									errorMessage={t(errors.gender?.message as string)}
								/>
							}
						/>
					</div>

					<div className={classes.field}>
						<Box className={classes.labelWrapper}>
							<InputLabel className={classes.label}>
								{t('LEOMED_ADMIN_PROFILE_PAGE_ADD_PHOTO_LABEL')}
							</InputLabel>
							{/* {errorMessage && <ErrorTooltip text={t(errorMessage)} />} */}
						</Box>
						<div className={classes.imageWrapper}>
							{selectedFiles.map((item, index) => (
								<ImagePicker
									key={index}
									img={item.url}
									onChange={onFileChange}
									handleDelete={handleDelete}
									id={index + 1}
								/>
							))}
							{selectedFiles.length === 0 && (
								<ImagePicker onChange={onFileChange} id={6} count={selectedFiles.length} />
							)}
							<div className={classes.imageText}>{t('LEOMED_ADMIN_ADD_FILE_PERSONAL_PAGE')}</div>
						</div>
					</div>
				</div>
				<div className={classes.subtitle}>{t('LEOMED_ADMIN_PROFILE_PAGE_CONTACTS')}</div>
				<div className={classes.fieldsWrapper}>
					<div className={classes.field}>
						<Controller
							control={control}
							name="country"
							defaultValue=""
							rules={{ required: 'LEOMED_ADMIN_REQUIRED_FIELD_ERROR' }}
							as={
								<SelectField
									label={t('LEOMED_ADMIN_PROFILE_PAGE_COUNTRY_LABEL')}
									options={countryOptions}
									onChangeHandler={onCountryChange}
									error={!!errors.country}
									errorMessage={t(errors.country?.message as string)}
								/>
							}
						/>
					</div>
					<div className={classes.field}>
						{isUkraine ? (
							<Controller
								control={control}
								name="city"
								rules={{ required: 'LEOMED_ADMIN_REQUIRED_FIELD_ERROR' }}
								as={
									<>
										<Box className={classes.labelWrapper}>
											<InputLabel className={classes.label} htmlFor={'city'}>
												{t('LEOMED_ADMIN_PROFILE_PAGE_CITY_LABEL')}
											</InputLabel>
										</Box>
										<Autocomplete
											id="city"
											options={cityOptions}
											getOptionLabel={(option: any) => option.name}
											onChange={onCityChange}
											defaultValue={defaultCity}
											renderInput={params => void console.log(params) || (
												<TextField {...params} variant="outlined" error={!!errors?.city?.message} />
											)}
										/>
										{errors.city && (
											<FormHelperText error>{t(`${errors.city.message}`)}</FormHelperText>
										)}
									</>
								}
							/>
						) : (
							<InputField
								variant="outlined"
								name="city"
								label={t('LEOMED_ADMIN_PROFILE_PAGE_CITY_LABEL')}
								placeholder={t('LEOMED_ADMIN_PROFILE_PAGE_CITY_LABEL')}
								defaultValue={personalInfo.city}
								inputRef={register({
									validate: value => !value.length || value.trim() !== '',
									required: 'LEOMED_ADMIN_REQUIRED_FIELD_ERROR',
								})}
								error={!!errors.city}
								helperText={t(errors.city?.message as string)}
							/>
						)}
					</div>
					{/* <div className={classes.field}>
						<Controller
							control={control}
							name="language"
							defaultValue={localStorage.i18nextLng}
							rules={{ required: 'LEOMED_ADMIN_REQUIRED_FIELD_ERROR' }}
							as={
								<SelectField
									label={t('LEOMED_ADMIN_PROFILE_PAGE_LANGUAGE_LABEL')}
									options={lngOptions}
									onChangeHandler={changeLngHandler}
									error={!!errors.language}
									errorMessage={t(errors.language?.message as string)}
								/>
							}
						/>
					</div> */}
					<div className={classes.field}>
						<InputField
							variant="outlined"
							name="email"
							label={t('LEOMED_ADMIN_PROFILE_PAGE_EMAIL_LABEL')}
							placeholder="example@gmail.com"
							inputRef={register({
								required: 'LEOMED_ADMIN_REQUIRED_FIELD_ERROR',
								pattern: {
									value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
									message: 'LEOMED_ADMIN_REQUIRED_FIELD_EMAIL_ERROR',
								},
							})}
							error={!!errors.email}
							helperText={t(errors.email?.message as string)}
						/>
					</div>
				</div>
				<div className={classes.fieldsWrapper}>
					<div className={classes.field}>
						<PhoneInputField
							name="phone"
							placeholder={t('LEOMED_ADMIN_PROFILE_PAGE_PHONE_PLACEHOLDER')}
							label={t('LEOMED_ADMIN_PROFILE_PAGE_PHONE_LABEL')}
							code="UA"
							inputRef={register({
								required: 'LEOMED_ADMIN_REQUIRED_FIELD_ERROR',
								pattern: {
									value: /^\+380 \(\d\d\) \d\d\d-\d\d-\d\d$/,
									message: 'LEOMED_ADMIN_REQUIRED_FIELD_PHONE_ERROR',
								},
							})}
							error={!!errors.phone}
							errorMessage={t(errors.phone?.message as string)}
							dirty
						/>
					</div>
					<div className={classes.field} />
				</div>
				{editId && (
					<div className={classes.addBtnWrapper}>
						<Button className={addBtnStyles} variant="contained" onClick={handleSubmit(addHandler)}>
							{t('LEOMED_ADMIN_SAVE_BUTTON_TEXT')}
						</Button>
						{editId && (
							<Button variant="contained" onClick={cancelHandler}>
								{t('LEOMED_ADMIN_CANCEL_BUTTON_TEXT')}
							</Button>
						)}
					</div>
				)}
			</div>
			<div className={classes.terms}>
				{t('LEOMED_ADMIN_PROFILE_PAGE_AGREE_DESCRIPTION')}
				<span className={classes.termsLink}>
					{t('LEOMED_ADMIN_PROFILE_PAGE_AGREE_DESCRIPTION_TWO')}
				</span>
			</div>
		</div>
	)
}
