import React, { FC } from 'react'
import { Container } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Header } from './parts/Header'

const useStyles = makeStyles(theme => ({
	root: {
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
		minHeight: '100vh',
		backgroundImage: `url(${'https://ukrainianwall.com/images/2019/11/27/GWnm4mhV1llbo6prECM5iJ4FQnO8QWJyB1jiagb0.jpeg'})`,

		position: 'relative',
		'&::after': {
			content: '""',
			position: 'absolute',
			width: '100%',
			height: '100%',
			top: 0,
			left: 0,
			backgroundColor: 'rgba(46, 110, 193, 0.5)',
		},
	},
	container: {
		position: 'relative',
		zIndex: 1,
		display: 'flex',
		flexDirection: 'column',
		flexFlow: 'column',
		height: '100vh',
	},
	content: {
		position: 'relative',
		display: 'flex',
		flexFlow: 'column',
		height: '100%',
	},
}))

export const HomeLayout: FC = ({ children }) => {
	const classes = useStyles()

	return (
		<div className={classes.root}>
			<Container className={classes.container}>
				<Header />
				<main className={classes.content}>{children}</main>
			</Container>
		</div>
	)
}
