import React, { FC } from 'react'
import { Button, makeStyles, ButtonProps } from '@material-ui/core'
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
	root: {
		textDecoration: 'none',
		textTransform: 'capitalize',
	},
	generalBtn: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.primary.light,
		padding: theme.spacing(1, 12),
		borderRadius: '8px',
		fontWeight: 600,
		'&:hover': {
			backgroundColor: theme.palette.primary.main,
		},
	},
}))

type CommonBtnProps = { text: string } & ButtonProps

export const CommonBtn: FC<CommonBtnProps> = ({ text, className, ...rest }) => {
	const classes = useStyles()

	const btnStyles = clsx(className, classes.generalBtn)

	return (
		<Button variant="contained" className={btnStyles} {...rest}>
			{text}
		</Button>
	)
}
