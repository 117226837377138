import React, { FC } from 'react'
import { makeStyles, BoxProps, Box } from '@material-ui/core'
import clsx from 'clsx'
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat'
import { useTranslation } from 'react-i18next'
import { getProfileStep } from 'pages/Profile/redux/selectors'
import { Colors } from 'utils/theme'
import { useSelector } from 'react-redux'

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',

		backgroundColor: theme.palette.background.paper,
		marginBottom: theme.spacing(3),
	},
	stepRoot: {
		display: 'flex',
		paddingTop: theme.spacing(3),
		paddingBottom: theme.spacing(3),
		alignItems: 'center',
		height: '74px',
		fontWeight: 700,
		borderBottom: '3px solid transparent',
	},
	isActive: {
		color: theme.palette.primary.main,
		borderBottom: `3px solid ${theme.palette.primary.main}`,
	},
	lastStep: {
		color: Colors.green[500],
	},
	finished: {
		borderColor: Colors.green[500],
	},
}))

type StepProps = {
	text: string
	isActive?: boolean
	noArrow?: boolean
	step: number
} & BoxProps

const Step: FC<StepProps> = ({ text, isActive = false, noArrow = false, step, ...rest }) => {
	const classes = useStyles()
	const color = isActive ? 'primary' : 'disabled'
	const textStyles = clsx(classes.stepRoot, {
		[classes.isActive]: isActive,
		[classes.finished]: step === 4,
		[classes.lastStep]: noArrow && isActive,
	})

	return (
		<Box className={textStyles} {...rest}>
			{text} {!noArrow && <TrendingFlatIcon color={color} />}
		</Box>
	)
}

export const ProfileSteps: FC = () => {
	const classes = useStyles()
	const { t } = useTranslation()
	const step = useSelector(getProfileStep)

	return (
		<div className={classes.root}>
			<Step
				text={t('LEOMED_ADMIN_PROFILE_PAGE_PERSONAL_DATA')}
				isActive={step >= 0}
				step={step}
				justifyContent="space-between"
				width="20%"
				pl={3}
			/>
			<Step
				text={t('LEOMED_ADMIN_PROFILE_PAGE_EDUCATION')}
				justifyContent="space-between"
				width="20%"
				step={step}
				pl={8}
				isActive={step >= 1}
			/>
			<Step
				text={t('LEOMED_ADMIN_PROFILE_PAGE_SPECIALIZATION')}
				justifyContent="space-between"
				width="20%"
				step={step}
				pl={8}
				isActive={step >= 2}
			/>
			<Step
				text={t('LEOMED_ADMIN_PROFILE_PAGE_WORK_EXPERIENCE')}
				justifyContent="space-between"
				width="20%"
				pl={8}
				step={step}
				isActive={step >= 3}
			/>
			<Step
				text={t('LEOMED_ADMIN_PROFILE_PAGE_ALMOST_DONE')}
				noArrow
				justifyContent="flex-end"
				width="20%"
				step={step}
				pr={10}
				isActive={step >= 4}
			/>
		</div>
	)
}
