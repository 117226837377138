import React, { FC, useEffect } from 'react'
import { Container, makeStyles } from '@material-ui/core'
import useSocket from 'use-socket.io-client'
import { Redirect, useLocation } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import queryString from 'query-string'

import { OfficeHeader } from './parts/OfficeHeader'
import { Footer } from './parts/Footer'
import { NewConsultationDialog } from './parts/NewConsultationDialog'
import { officeActions } from 'pages/Office/redux/actions'
import { EndReportDialog } from './parts/EndReportDialog'
import { getDoctorWorking, getPatientInfo } from 'pages/Office/redux/selectors'
import { config } from 'utils/config'
import { getAccessToken } from 'pages/Auth/redux/selectors'
import { getSocketSettings } from '../utils/getSocketSettings'
import { useChangeTimeDialog } from 'hooks/scheduledReuqests/useChangeTimeDialog'
import { ChangeTimeModal } from 'pages/ConsultationRequests/components/ConsultationRequests/ChangeTimeModal'
import { alertsActions } from 'store/alerts/actions'
import { SocketNotificationTypes } from 'store/alerts/dtos'
import { NewRequestActionsDialog } from 'pages/ConsultationRequests/components/ConsultationRequests/NewRequestActionsDialog'
import { useNewRequestDialog } from 'hooks/scheduledReuqests/useNewRequestDialog'
import { useAudio } from 'hooks/useAudio'
import { DrawerLayout } from './parts/DrawerLayout'
import { useState } from 'react'
import { useCallback } from 'react'
import { useConsultationsRequests } from 'pages/ConsultationRequests/hooks/useConsultationsRequests'
import { OnlineSupportDialog } from './parts/Support/OnlineSupportDialog'

const useStyles = makeStyles(theme => ({
	root: {
		minHeight: '100vh',
		display: 'flex',
	},
	content: {
		position: 'relative',
		flexGrow: 1,
		backgroundColor: theme.palette.background.default,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
	},
}))

export enum PatientStatuses {
	accepted = 'client_accepted',
	onCall = 'doctor_on_conference',
	pending = 'pending',
}

export const OfficeLayout: FC = ({ children }) => {
	const classes = useStyles()
	const [isMenuOpen, setIsMenuOpen] = useState(true)
	const dispatch = useDispatch()
	const location = useLocation()
	const isDoctorWorking = useSelector(getDoctorWorking)
	const patientInfo = useSelector(getPatientInfo)
	const token = useSelector(getAccessToken)
	const { load, limit } = useConsultationsRequests()
	const { toggle } = useAudio(`${process.env.PUBLIC_URL}/notification.mp3`)
	const { isNewRequestDialogOpen, newConsultaionData } = useNewRequestDialog()
	const { isChangeTimeOpen, changeTimeData } = useChangeTimeDialog()
	const [socket] = useSocket(config?.server?.host, getSocketSettings(token))
	const search = queryString.parse(location.search)
  const page = +(search?.page || 1)

	const handleIsMenuOpenToggle = useCallback(() => {
		setIsMenuOpen(!isMenuOpen)
	}, [isMenuOpen])

	useEffect(() => {
		if (isDoctorWorking && !patientInfo) {
			dispatch(officeActions.getPatientInfo.request({}))
			let timerID = setInterval(() => dispatch(officeActions.getPatientInfo.request({})), 10000)
			return () => clearInterval(timerID)
		}
	}, [dispatch, isDoctorWorking, patientInfo])

	useEffect(() => {
		if (isDoctorWorking) {
			let timerID = setInterval(() => dispatch(officeActions.getDoctorStatus.request({})), 45000)
			return () => clearInterval(timerID)
		}
	}, [dispatch, isDoctorWorking])

	useEffect(() => {
		socket.connect()
		dispatch(officeActions.getNotifications.request({}))
		socket.on('doctor:clientSuggested', (e: any) => {})
		socket.on('doctor:new:scheduled:consultation', (data: any) => {
			load({
				page,
				limit,
				subStatus: 'CLIENT_CREATED'
			})
			dispatch(officeActions.getNotifications.request({}))
			dispatch(alertsActions.popupSocketNotification({ type: SocketNotificationTypes.NEW, data }))
			toggle()
		})
		socket.on('doctor:scheduled:consultation:rejected', (data: any) => {
			load({
				page,
				limit,
				subStatus: 'CLIENT_CREATED'
			})
			dispatch(officeActions.getNotifications.request({}))
			dispatch(alertsActions.popupSocketNotification({ type: SocketNotificationTypes.REJECTED, data }))
			toggle()
		})
		socket.on('doctor:scheduled:consultation:approved', (data: any) => {
			load({
				page,
				limit,
				subStatus: 'CLIENT_CREATED'
			})
			dispatch(officeActions.getNotifications.request({}))
			dispatch(alertsActions.popupSocketNotification({ type: SocketNotificationTypes.ACCEPTED, data }))
			toggle()
		})
		socket.on('doctor:scheduled:consultation:updated', (data: any) => {
			load({
				page,
				limit,
				subStatus: 'CLIENT_CREATED'
			})
			dispatch(officeActions.getNotifications.request({}))
			dispatch(alertsActions.popupSocketNotification({ type: SocketNotificationTypes.PENDING, data }))
			toggle()
		})
		
		return () => {
			socket.off('doctor:clientSuggested')
			socket.disconnect()
		}
		// eslint-disable-next-line
	}, [socket, dispatch])

	if (
		!patientInfo?.processed &&
		(patientInfo?.status === PatientStatuses.accepted ||
			patientInfo?.status === PatientStatuses.onCall)
	) {
		return <Redirect to={`/room/call`} />
	}

	return (
		<div className={classes.root}>
			<OfficeHeader handleIsMenuOpenToggle={handleIsMenuOpenToggle} />
			<DrawerLayout isMenuOpen={isMenuOpen} />
			<Container className={classes.content}>
				{patientInfo?.processed && <EndReportDialog />}
				{patientInfo && !patientInfo?.processed && <NewConsultationDialog />}
				{changeTimeData && <ChangeTimeModal isOpen={isChangeTimeOpen} requestData={changeTimeData} />}
				{newConsultaionData && <NewRequestActionsDialog isOpen={isNewRequestDialogOpen} requestData={newConsultaionData} />}
				<OnlineSupportDialog />
				<main>{children}</main>
				<Footer />
			</Container>
		</div>
	)
}
