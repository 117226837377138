import React from 'react'
import { useTranslation } from 'react-i18next'

import AudioInputList from './AudioInputList/AudioInputList'
import AudioOutputList from './AudioOutputList/AudioOutputList'
import {
	DialogContent,
	Typography,
	Divider,
	Dialog,
	DialogActions,
	Button,
	Theme,
	DialogTitle,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import VideoInputList from './VideoInputList/VideoInputList'

const useStyles = makeStyles((theme: Theme) => ({
	container: {
		width: '600px',
		minHeight: '400px',
		[theme.breakpoints.down('xs')]: {
			width: 'calc(100vw - 32px)',
		},
		'& .inputSelect': {
			width: 'calc(100% - 35px)',
		},
	},
	button: {
		float: 'right',
	},
	paper: {
		[theme.breakpoints.down('xs')]: {
			margin: '16px',
		},
	},
	headline: {
		marginBottom: '1.3em',
		fontSize: '1.1rem',
	},
	listSection: {
		margin: '2em 0 0.8em',
		'&:first-child': {
			margin: '1em 0 2em 0',
		},
	},
}))

export default function DeviceSelectionDialog({
	open,
	onClose,
}: {
	open: boolean
	onClose: () => void
}) {
	const classes = useStyles()
	const { t } = useTranslation()

	return (
		<Dialog open={open} onClose={onClose} classes={{ paper: classes.paper }}>
			<DialogTitle>{t('LEOMED_ADMIN_CALL_AUDIO_AND_VIDEO')}</DialogTitle>
			<Divider />
			<DialogContent className={classes.container}>
				<div className={classes.listSection}>
					<Typography variant="h6" className={classes.headline}>
						{t('LEOMED_ADMIN_CALL_VIDEO')}
					</Typography>
					<VideoInputList />
				</div>
				<Divider />
				<div className={classes.listSection}>
					<Typography variant="h6" className={classes.headline}>
						{t('LEOMED_ADMIN_CALL_AUDIO')}
					</Typography>
					<AudioInputList />
				</div>
				<div className={classes.listSection}>
					<AudioOutputList />
				</div>
			</DialogContent>
			<Divider />
			<DialogActions>
				<Button color="primary" variant="contained" className={classes.button} onClick={onClose}>
					{t('LEOMED_ADMIN_CALL_DONE')}
				</Button>
			</DialogActions>
		</Dialog>
	)
}
