import React, { FC, useCallback } from 'react'
import AddIcon from '@material-ui/icons/Add'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import { IconButton, makeStyles } from '@material-ui/core'
import { Colors } from 'utils/theme'
import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'

import { ExperienceItem } from 'pages/Profile/redux/model'

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		alignItems: 'center',
		backgroundColor: theme.palette.background.default,
		borderRadius: '10px',
		padding: theme.spacing(2),
		marginBottom: theme.spacing(3),
		position: 'relative',
	},
	logo: {
		width: '50px',
		height: '50px',
		borderRadius: '50%',
		backgroundColor: theme.palette.primary.main,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		color: theme.palette.background.paper,
	},
	univer: {
		marginLeft: theme.spacing(2),
		width: 150,
	},
	univerTitle: {
		fontWeight: 600,
		fontSize: '18px',
		marginBottom: theme.spacing(1),
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
	faculty: {
		fontSize: '12px',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
	ml10: {
		marginLeft: theme.spacing(10),
	},
	title: {
		fontSize: '12px',
		color: Colors.grey[700],
		marginBottom: theme.spacing(1),
	},
	description: {
		display: 'flex',
		alignItems: 'center',
		fontWeight: 500,
		fontSize: '16px',
	},
	edit: {
		position: 'absolute',
		right: 0,
		top: 0,
		cursor: 'pointer',
	},
	delete: {
		position: 'absolute',
		right: 40,
		top: 0,
		cursor: 'pointer',
	},
	icon: {
		color: Colors.green[500],
		marginRight: theme.spacing(1),
	},
}))

type ExperienceBannerProps = {
	item: ExperienceItem
	editItem: (itemId: string) => void
	deleteBanner?: (itemId: string) => void
}

export const ExperienceBanner: FC<ExperienceBannerProps> = ({
	item: { id, title, periodOfWork, workingNow, address },
	editItem,
	deleteBanner,
}) => {
	const classes = useStyles()
	const { t } = useTranslation()
	const from = `${t('LEOMED_ADMIN_PROFILE_PAGE_FROM_LABEL')} ${format(
		new Date(periodOfWork.from),
		'dd/MM/yyyy'
	)}`
	const to =
		periodOfWork.to && !workingNow
			? `${t('LEOMED_ADMIN_PROFILE_PAGE_TO_LABEL')} ${format(
					new Date(periodOfWork.to),
					'dd/MM/yyyy'
			  )}`
			: ''

	const workExp = `${from} ${to}`

	const clickHandler = useCallback(() => {
		editItem(id)
	}, [editItem, id])

	const deleteItem = useCallback(() => {
		deleteBanner?.(id)
	}, [deleteBanner, id])

	return (
		<div className={classes.root}>
			<div className={classes.logo}>
				<AddIcon fontSize="large" />
			</div>
			<div className={classes.univer}>
				<div className={classes.univerTitle}>{title}</div>
				<div className={classes.faculty}>{address}</div>
			</div>
			<div className={classes.ml10}>
				<div className={classes.title}>{t('LEOMED_ADMIN_PROFILE_PAGE_WORK_PERIOD_LABEL')}</div>
				<div className={classes.description}>{workExp}</div>
			</div>
			{workingNow && (
				<div className={classes.ml10}>
					<div className={classes.description}>
						<CheckCircleIcon className={classes.icon} />
						{t('LEOMED_ADMIN_PROFILE_PAGE_STILL_WORKS_BANNER_LABEL')}
					</div>
				</div>
			)}
			<div className={classes.edit}>
				<IconButton onClick={clickHandler}>
					<EditIcon color="primary" />
				</IconButton>
			</div>
			{deleteBanner && (
				<div className={classes.delete}>
					<IconButton onClick={deleteItem}>
						<DeleteIcon color="primary" />
					</IconButton>
				</div>
			)}
		</div>
	)
}
