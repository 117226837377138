import * as actionType from './const'

export const alertsMiddleware = (store: any)  => (next: any) => (action: any) => {
  if (action.type.includes('FAILURE')) {
    store.dispatch({
      type: actionType.POPUP_ALERT,
      payload: { message: action.payload.message, severity: "error" }
    })

    setTimeout(() => store.dispatch({ type: actionType.REMOVE_ALERT_POPUP }), 3000)
  }

  if (action.type.includes('SUCCESS') && action.payload.alertMessage) {
    store.dispatch({
      type: actionType.POPUP_ALERT,
      payload: { message: action.payload.alertMessage, severity: "success", color: action.payload.color || '' }
    })

    setTimeout(() => store.dispatch({ type: actionType.REMOVE_ALERT_POPUP }), 3000)
  }

  return next(action)
}