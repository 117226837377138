import fileSize from 'filesize'
import { ProfileAttachments } from 'pages/Profile/redux/model'
import { getProfileEntities } from 'pages/Profile/redux/selectors'
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

type MimesTypes = {
	[key: string]: string[]
}

export const Mimes: MimesTypes = {
	image: ['image/jpeg', 'image/png', 'image/tiff'],
	file: ['.csv'],
}

export const maxSizeMb = 8

export type SelectedFileState = { url: string; type?: string}
type FormDataState = FormData | undefined

export interface UseUploadFile {
	selectedFiles: SelectedFileState[]
	setSelectedFiles: Dispatch<SetStateAction<SelectedFileState[]>>
	setFilesForFormData: Dispatch<SetStateAction<File[]>>
	onFileChange: (files: FileList | null) => void
	handleDelete: (id: number) => void
	formData: FormDataState
	filesForFormData: File[]
}

const useUploadFile = ({ name, editId }: { name?: string; editId?: string }): UseUploadFile => {
	const session = useSelector(getProfileEntities)
	const { t } = useTranslation()
	const [selectedFiles, setSelectedFiles] = useState<SelectedFileState[]>([])
	const [filesForFormData, setFilesForFormData] = useState<File[]>([])
	const [formData, setFormData] = useState<FormDataState>(undefined)

	useEffect(() => {
		if (session.personalInfo.avatar && name === ProfileAttachments.avatar) {
			setSelectedFiles([{ url: session.personalInfo.avatar }])
		}
		if (session.educationItemsIds.length > 0 && editId && name === ProfileAttachments.education) {
			setFormData(undefined)
			setSelectedFiles(session.educationItems[editId].documents?.map((item: any) => ({ 
				url: item.url,
				type: item.mimetype,
			})) || [])
		}
		if (
			session.specializationItemsIds.length > 0 &&
			editId &&
			name === ProfileAttachments.speciality
		) {
			setFormData(undefined)
			setSelectedFiles(
				session.specializationItems[editId].documents?.map((item: any) => ({ 
					url: item.url,
					type: item.mimetype,
				})) || []
			)
		}
		if (
			session.experienceItemsIds.length > 0 &&
			editId &&
			name === ProfileAttachments.employmentHistory
		) {
			setFormData(undefined)
			setSelectedFiles(
				session.experienceItems[editId].documents?.map((item: any) => ({ 
					url: item.url,
					type: item.mimetype,
				})) || []
			)
		}
	}, [
		editId,
		name,
		session.educationItems,
		session.educationItemsIds.length,
		session.personalInfo.avatar,
		session.specializationItems,
		session.specializationItemsIds.length,
		session.experienceItems,
		session.experienceItemsIds.length,
	])

	const onFileChange = (files: FileList | null) => {
		if (!files) return
		const data = new FormData()
		setFilesForFormData([...filesForFormData, ...files])

		for (let i = 0; i < files.length; i++) {
			const [sizeInMb] = fileSize(files[i].size, { exponent: 2, output: 'array' })
			const parsedSizeInMb = Number.parseFloat(sizeInMb)
			const isWrongSize = !parsedSizeInMb || parsedSizeInMb > maxSizeMb
			const isAllowed = name === 'avatar' || name === 'onlineSupportFiles' ? Mimes['image'].includes(files[i].type) : true

			if (isAllowed) {
				if (isWrongSize) {
					window.alert(t('LEOMED_ADMIN_WRONG_FILE_SIZE'))
					return
				}
				const reader = new FileReader()
				reader.addEventListener('load', (event): void => {
					setSelectedFiles(prev => [...prev, { url: event?.target?.result } as SelectedFileState])
				})
				reader.readAsDataURL(files[i])

				setFormData(data)
			} else {
				window.alert(t('LEOMED_ADMIN_WRONG_FILE_TYPE'))
			}
		}
	}

	const handleDelete = useCallback(
		(id: number) => {
			const overflow = id + 1 - filesForFormData.length
			const newFiles = selectedFiles.filter((item, index) => index !== id - 1)
			const newFilesForFormData = filesForFormData.filter((item, index) => index !== id - overflow)
			setSelectedFiles(newFiles)
			setFilesForFormData(newFilesForFormData)
		},
		[filesForFormData, selectedFiles]
	)

	return {
		selectedFiles,
		setSelectedFiles,
		onFileChange,
		handleDelete,
		formData,
		filesForFormData,
		setFilesForFormData,
	}
}

export default useUploadFile
