import React, { FC, useState, useCallback } from 'react'
import { Box, InputLabel, makeStyles, TextField } from '@material-ui/core'
import { DesktopDatePicker } from '@material-ui/pickers/DatePicker'
import DateRangeIcon from '@material-ui/icons/DateRange'
import { Colors } from 'utils/theme'

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
	},
	label: {
		fontSize: '14px',
		color: theme.palette.primary.dark,
		marginBottom: theme.spacing(2),
	},
	input: { width: '100%' },
	icon: {
		color: theme.palette.primary.main,
	},
	errorMessage: {
		position: 'absolute',
		color: Colors.red[500]
	},
	datePicker: {
		position: 'relative'
	}
}))

type InputDatePickerProps = {
	name?: string
	label?: string
	startDate?: Date | null
	views?: ('date' | 'year' | 'month')[]
	selectDate: (date: string) => void
	maxDate?: any
	minDate?: any
	disableFuture?: boolean
	errorMessage?: string
}

export const InputDatePicker: FC<InputDatePickerProps> = ({
	name,
	label,
	selectDate,
	startDate,
	views,
	maxDate,
	minDate,
	disableFuture,
	errorMessage,
}) => {
	const classes = useStyles()
	const [selectedDate, setSelectedDate] = useState(new Date())

	const selectDateHandler = useCallback(
		date => {
			selectDate(date)
			setSelectedDate(date)
		},
		[selectDate]
	)

	return (
		<Box className={classes.root}>
			<InputLabel className={classes.label} htmlFor={name}>
				{label}
			</InputLabel>
			<Box>
				<DesktopDatePicker
					value={startDate || selectedDate}
					views={views}
					inputFormat="dd/MM/yyyy"
					openPickerIcon={<DateRangeIcon className={classes.icon} />}
					onChange={selectDateHandler}
					maxDate={maxDate}
					allowSameDateSelection
					minDate={minDate}
					disableFuture={disableFuture}
					renderInput={({ helperText, inputProps, ...rest }) => {
						return (
							<div className={classes.datePicker}>
								<TextField
									classes={{ root: classes.input }}
									variant="outlined"
									{...rest}
									value={inputProps?.value}
								/>
								{errorMessage && <div className={classes.errorMessage}>{errorMessage}</div>}
							</div>
						)
					}}
				/>
			</Box>
		</Box>
	)
}
