import { Alert } from "@material-ui/lab"
import { IconButton, makeStyles } from "@material-ui/core"
import CloseIcon from '@material-ui/icons/Close'
import { Trans } from 'react-i18next'
import React, { FC } from "react"
import { Colors } from "utils/theme"
import WarningIcon from '@material-ui/icons/Warning'

const useStyles = makeStyles(theme => ({
  alert: {
		color: '#000',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.orange[500],
    position: 'relative',
	},
	alertIcon: {
		color:  '#000',
	},
  closeIcon: {
    position: 'absolute',
    right: 20,
    top: '50%',
    color: '#000',
    marginTop: -15,
    width: 30,
    height: 30
  }
}))

type ConsultationSoonBannerProps = {
  date?: string
  time?: string
  removeClosestTime: () => void
}

export const ConsultationSoonBanner: FC<ConsultationSoonBannerProps> = ({ date, time, removeClosestTime }) => {
  const classes = useStyles()

  return (
    <Alert
      severity="warning"
      className={classes.alert}
      iconMapping={{
        warning: <WarningIcon className={classes.alertIcon} />,
      }}
    >
      <Trans i18nKey="LEOMED_ADMIN_OFFICE_SCHEDULED_CALL_SOON_TEXT" values={{ date, time }} />
      <IconButton className={classes.closeIcon} onClick={removeClosestTime}>
        <CloseIcon />
      </IconButton>
    </Alert>
  )
}
