import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CircularProgress, Dialog, makeStyles } from '@material-ui/core'
import { Colors } from 'utils/theme'
import clsx from 'clsx'
import { OverlayButton } from 'components/common/OverlayButton'
import { useCountdown } from 'hooks/useCountdown'
import { useDispatch, useSelector } from 'react-redux'
import { officeActions } from 'pages/Office/redux/actions'
import {
	getCreateRoomLoading,
	getDoctorWorking,
	getPatientInfo,
} from 'pages/Office/redux/selectors'
import { isLoading } from 'utils/types/store'
import { formatArrayToMap } from 'utils/formatArrayToMap'
import { useHistory } from 'react-router'
import { useAudio } from 'hooks/useAudio'

const useStyles = makeStyles(theme => ({
	root: {
		padding: theme.spacing(4),
		minWidth: 520,
	},
	title: {
		fontWeight: 700,
		display: 'flex',
		alignItems: 'center',
	},
	circle: {
		width: 28,
		height: 28,
		fontSize: '18px',
		backgroundColor: theme.palette.warning.main,
		marginRight: theme.spacing(2),
		borderRadius: '50%',
		position: 'relative',
	},
	innerCircle: {
		position: 'absolute',
		width: 14,
		height: 14,
		top: '50%',
		left: '50%',
		marginLeft: -7,
		marginTop: -7,
		backgroundColor: Colors.red[200],
		borderRadius: '50%',
	},
	descr: {
		fontSize: '14px',
		marginTop: theme.spacing(2),
	},
	patientWrapper: {
		padding: theme.spacing(2),
		marginTop: theme.spacing(2),
		border: `2px solid ${theme.palette.warning.main}`,
		borderRadius: '10px',
	},
	patientHeader: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	patient: {
		fontSize: '12px',
		color: Colors.grey[600],
	},
	date: {
		fontSize: '12px',
	},
	patientRow: {
		display: 'flex',
		alignItems: 'center',
		fontSize: '12px',
		marginTop: theme.spacing(2),
	},
	patientImg: {
		width: 32,
		height: 32,
		borderRadius: '50%',
		backgroundColor: theme.palette.primary.main,
		marginRight: theme.spacing(1),
	},
	patientName: {
		fontWeight: 700,
		marginRight: theme.spacing(2),
	},
	patientSymptoms: {
		fontSize: '12px',
		color: Colors.grey[600],
		marginRight: theme.spacing(2),
		width: '22%',
	},
	patientValue: {
		width: '78%',
	},
	remaining: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		margin: theme.spacing(4, 0),
	},
	remainingTime: {
		fontWeight: 700,
		fontSize: '24px',
	},
	buttonGroup: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	submit: {
		display: 'flex',
		width: '45%',
		justifyContent: 'center',
		alignItems: 'center',
	},
	buttonClassName: {
		backgroundColor: theme.palette.primary.dark,
		color: theme.palette.primary.light,
		textTransform: 'capitalize',
		width: '100%',
		fontSize: 16,
		padding: theme.spacing(2, 0),
		lineHeight: 1.2,
		borderRadius: 10,
	},
	cancelBtn: {
		backgroundColor: theme.palette.warning.main,
		'&:hover': {
			backgroundColor: theme.palette.warning.main,
		},
	},
	acceptBtn: {
		backgroundColor: Colors.green[500],
		'&:hover': {
			backgroundColor: Colors.green[500],
		},
	},
}))

type Option = { value: string; label: string }

export const NewConsultationDialog: FC = () => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const history = useHistory()
	const [isDisabled, setIsDisabled] = useState(false)
	const { t } = useTranslation([
		'translation',
		'conditions',
		'diseases',
		'allergies',
		'medications',
	])
	const { time } = useCountdown()
	const { toggle, disable } = useAudio(`${process.env.PUBLIC_URL}/bumer.mp3`)
	const patientInfo = useSelector(getPatientInfo)
	const isDoctorWorking = useSelector(getDoctorWorking)
	const submitting = useSelector(getCreateRoomLoading)
	const isSubmitting = isLoading(submitting)

	const symptoms = t('conditions:CONDITIONS') as Option[]
	const diseases = t('diseases:DISEASES') as Option[]
	const allergies = t('allergies:ALLERGIES') as Option[]
	const medications = t('medications:MEDICATIONS') as Option[]
	const symptomsMap = useMemo(() => formatArrayToMap(patientInfo?.symptoms), [patientInfo])
	const diseasesMap = useMemo(() => formatArrayToMap(patientInfo?.diseases), [patientInfo])
	const allergiesMap = useMemo(() => formatArrayToMap(patientInfo?.allergies), [patientInfo])
	const medicationsMap = useMemo(() => formatArrayToMap(patientInfo?.medications), [patientInfo])

	const cancelBtnStyles = clsx(classes.buttonClassName, classes.cancelBtn)
	const acceptBtnStyles = clsx(classes.buttonClassName, classes.acceptBtn)

	const acceptHandler = useCallback(() => {
		setIsDisabled(true)
		toggle()
		dispatch(
			officeActions.acceptPatient.request({
				callback: () => {
					history.push(`room/call`)
				},
			})
		)
	}, [dispatch, history, toggle])

	const declineHandler = useCallback(() => {
		dispatch(officeActions.declinePatient.request({}))
		disable()
	}, [dispatch, disable])

	useEffect(() => {
		if (time.trim() === '00:00') {
			disable()
			dispatch(officeActions.toggleDoctorStatus.request({}))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, time])

	const showNotification = () => {
		const notification = new Notification(
			`${t('LEOMED_ADMIN_DIALOG_PATIENT_NEEDS_CONSULTATION')}`,
			{
				icon: `${process.env.PUBLIC_URL}/logo.png`,
			}
		)

		notification.onclick = e => {
			window.focus()
		}
	}

	useEffect(() => {
		if (Notification.permission === 'granted') {
			if (patientInfo) {
				showNotification()
			}
		} else if (Notification.permission !== 'denied') {
			Notification.requestPermission().then(permission => {
				if (permission === 'granted') {
					showNotification()
				}
			})
		}
		toggle()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	if (!patientInfo) return null

	return (
		<Dialog open={patientInfo && isDoctorWorking}>
			<div className={classes.root}>
				<div className={classes.title}>
					<div className={classes.circle}>
						<div className={classes.innerCircle} />
					</div>
					{t('LEOMED_ADMIN_DIALOG_PATIENT_NEEDS_CONSULTATION')}
				</div>
				<div className={classes.descr}>
					{t('LEOMED_ADMIN_DIALOG_PATIENT_NEEDS_CONSULTATION_DESCR')}
				</div>
				<div className={classes.patientWrapper}>
					<div className={classes.patientHeader}>
						<div className={classes.patient}>{t('LEOMED_ADMIN_DIALOG_PATIENT_TEXT')}</div>
						<div className={classes.date}>{patientInfo.createdAt}</div>
					</div>
					<div className={classes.patientRow}>
						<div className={classes.patientImg} />
						<div className={classes.patientName}>{patientInfo.name}</div>
						{/* <div>Мужчина, 34 года </div> */}
						{patientInfo.ageCategory && (
							<div>{t(`LEOMED_ADMIN_${patientInfo.ageCategory}_TEXT`)}</div>
						)}
					</div>
					<div className={classes.patientRow}>
						<div className={classes.patientSymptoms}>{t('LEOMED_ADMIN_TEMPERATURE_TEXT')}</div>
						<div>{patientInfo.fever || ' - '}</div>
					</div>
					<div className={classes.patientRow}>
						<div className={classes.patientSymptoms}>{t('LEOMED_ADMIN_DIALOG_SYMPTOMS_TEXT')}</div>
						<div className={classes.patientValue}>
							{symptoms
								.filter((item: Option) => symptomsMap[item.value])
								.map((item: Option) => item.label)
								.join(', ')}
						</div>
					</div>
					<div className={classes.patientRow}>
						<div className={classes.patientSymptoms}>{t('LEOMED_ADMIN_PATIENT_ALLERGIES')}</div>
						<div className={classes.patientValue}>
							{allergies
								.filter((item: Option) => allergiesMap[item.value])
								.map((item: Option) => item.label)
								.join(', ')}
						</div>
					</div>
					<div className={classes.patientRow}>
						<div className={classes.patientSymptoms}>
							{t('LEOMED_ADMIN_PATIENT_CHRONIC_DISEASES')}
						</div>
						<div className={classes.patientValue}>
							{diseases
								.filter((item: Option) => diseasesMap[item.value])
								.map((item: Option) => item.label)
								.join(', ')}
						</div>
					</div>
					<div className={classes.patientRow}>
						<div className={classes.patientSymptoms}>
							{t('LEOMED_ADMIN_PATIENT_TAKING_MEDICATIONS')}
						</div>
						<div className={classes.patientValue}>
							{medications
								.filter((item: Option) => medicationsMap[item.value])
								.map((item: Option) => item.label)
								.join(', ')}
						</div>
					</div>
					<div className={classes.patientRow}>{patientInfo.comments}</div>
				</div>
				<div className={classes.remaining}>
					<div>{t('LEOMED_ADMIN_DIALOG_REMAINING_TEXT')}</div>
					<div className={classes.remainingTime}>{time}</div>
				</div>
				<div className={classes.buttonGroup}>
					<OverlayButton
						className={classes.submit}
						buttonClassName={cancelBtnStyles}
						type="submit"
						variant="contained"
						color="secondary"
						onClick={declineHandler}
						disabled={isSubmitting}
						overlay={false && <CircularProgress size={24} />}
					>
						{t('LEOMED_ADMIN_DIALOG_CANCEL_PATIENT_TEXT')}
					</OverlayButton>
					<OverlayButton
						className={classes.submit}
						buttonClassName={acceptBtnStyles}
						type="submit"
						variant="contained"
						color="primary"
						onClick={acceptHandler}
						disabled={isSubmitting || isDisabled}
						overlay={isSubmitting && <CircularProgress size={24} />}
					>
						{t('LEOMED_ADMIN_DIALOG_ACCEPT_PATIENT_TEXT')}
					</OverlayButton>
				</div>
			</div>
		</Dialog>
	)
}
