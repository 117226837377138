import * as yup from 'yup'

const schema = yup.object({
	version: yup.string(),
	auth0Domain: yup.string(),
	auth0ClientId: yup.string(),
	auth0RedirectURI: yup.string(),
	auth0Audience: yup.string(),

	server: yup.object({
		host: yup.string(),
	}),

	trueConf: yup.object({
		host: yup.string(),
	}),
	serverScope: yup.string(),
})

export type Config = yup.InferType<typeof schema>

export const config = schema.validateSync(
	{
		version: process.env.REACT_APP_VERSION,
		auth0Domain: process.env.REACT_APP_AUTH0_DOMAIN,
		auth0Audience: process.env.REACT_APP_AUTH0_AUDIENCE,
		auth0ClientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
		auth0RedirectURI: process.env.REACT_APP_AUTH0_REDIRECT_URI,
		server: { host: process.env.REACT_APP_SERVER_BASE_URL },
		serverScope: process.env.REACT_APP_SERVER_SCOPE,
		trueConf: {
			host: process.env.REACT_APP_TRUECONF_BASE_URL,
		},
	},
	{ stripUnknown: true }
)
