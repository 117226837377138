import React, { FC, useState, useCallback } from 'react'
import { Box, InputLabel, makeStyles, TextField } from '@material-ui/core'
import ScheduleIcon from '@material-ui/icons/Schedule'
import { TimePicker } from "@material-ui/pickers"
import { Colors } from 'utils/theme'

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
	},
	label: {
		fontSize: '14px',
		color: theme.palette.primary.dark,
		marginBottom: theme.spacing(1),
	},
	input: { width: '100%' },
	icon: {
		color: theme.palette.primary.main,
	},
	errorMessage: {
		position: 'absolute',
		color: Colors.red[500]
	},
	datePicker: {
		position: 'relative'
	}
}))

type InputTimePickerProps = {
	name?: string
	label?: string
	startTime?: Date | null
	selectDate: (date: string) => void
  ampm?: boolean
	errorMessage?: string
}

export const InputTimePicker: FC<InputTimePickerProps> = ({
	name,
	label,
	selectDate,
  ampm,
	startTime,
	errorMessage
}) => {
	const classes = useStyles()
	const [selectedDate, setSelectedDate] = useState(new Date())

	const selectDateHandler = useCallback(
		date => {
			selectDate(date)
			setSelectedDate(date)
		},
		[selectDate]
	)

	return (
		<Box className={classes.root}>
			<InputLabel className={classes.label} htmlFor={name}>
				{label}
			</InputLabel>
			<Box>
        <TimePicker
          showTodayButton
					openPickerIcon={<ScheduleIcon className={classes.icon} />}
          ampm={ampm}
					minTime={errorMessage && new Date()}
          value={startTime || selectedDate}
          minutesStep={5}
          onChange={selectDateHandler}
          renderInput={({ helperText, inputProps, ...rest }) => {
						return (
							<div className={classes.datePicker}>
								<TextField
									classes={{ root: classes.input }}
									variant="outlined"
									color="primary"
									{...rest}
									value={inputProps?.value}
								/>
								{errorMessage && <div className={classes.errorMessage}>{errorMessage}</div>}
							</div>
						)
					}}
        />
			</Box>
		</Box>
	)
}
