const getItemsByStatus = (data: any, status: string) => data?.data?.find((item: any) => item.group === status)?.items ?? []

export const formatRequests = (data: any) => {
    const approvedRequests = getItemsByStatus(data, 'APPROVED')
    const pendingRequests = getItemsByStatus(data, 'PENDING')
    const rejectedRequests = getItemsByStatus(data, 'REJECTED')
    const expiredRequests = getItemsByStatus(data, 'EXPIRED')

    return [
        ...approvedRequests,
        ...pendingRequests,
        ...rejectedRequests,
        ...expiredRequests,
    ]
}
