import { getChangeTimeDialogId } from './../../pages/ConsultationRequests/redux/selectors'
import { useSelector } from 'react-redux'
import { useCallback, useState, useEffect } from "react"

export const useChangeTimeDialog = () => {
  const [isChangeTimeOpen, setIsChangeTimeOpen] = useState(false)
  const changeTimeData = useSelector(getChangeTimeDialogId)

  const toggleChangeTimeOpen = useCallback(() => {
		setIsChangeTimeOpen(!isChangeTimeOpen)
	}, [isChangeTimeOpen])

	const handleConfirm = useCallback(() => {
		setIsChangeTimeOpen(false)
		
		// disptch here
	}, [setIsChangeTimeOpen])

  useEffect(() => {
    if (changeTimeData) {
      setIsChangeTimeOpen(true)
    } else {
      setIsChangeTimeOpen(false)
    }
  }, [changeTimeData])

  return {
    handleConfirm,
    isChangeTimeOpen,
    toggleChangeTimeOpen,
    changeTimeData,
  }
}
