import React, { useEffect } from 'react'
import { Switch, Redirect } from 'react-router-dom'

import ProtectedRoute from 'components/Router/ProtectedRoute'
import GuestRoute from 'components/Router/GuestRoute'
import { RoutePath } from './routes'
import { LogoutPage } from 'pages/Auth/LogoutPage'
import { HomePage } from 'pages/Home/HomePage'
import { OfficePage } from 'pages/Office/OfficePage'
import { HomeLayout } from 'layouts/HomeLayout'
import { OfficeLayout } from 'layouts/OfficeLayout'
import { ProfilePage } from 'pages/Profile/ProfilePage'
import { CallPage } from 'pages/Call/CallPage'
import { useDispatch, useSelector } from 'react-redux'
import { useAuthorization } from 'pages/Auth/hooks/useAuthorization'
import { officeActions } from 'pages/Office/redux/actions'
import { getAccessToken } from 'pages/Auth/redux/selectors'
import { ErrorBoundary } from 'layouts/parts/ErrorBoundary'
import { SocketNotifications } from 'layouts/parts/SocketNotifications/SocketNotifications'
import { ConsultationRequestsPage } from 'pages/ConsultationRequests/ConsultationRequestsPage'
import { MyCalendarPage } from 'pages/MyCalendar/MyCalendarPage'

export const defaultGuestUrl = RoutePath.home

export const defaultProtectedUrl = RoutePath.office

export const ClientRouter = () => {
	const dispatch = useDispatch()
	const token = useSelector(getAccessToken)
	const { storeTokenHandler, isAuthenticated } = useAuthorization()

	useEffect(() => {
		if (isAuthenticated) {
			storeTokenHandler()
		}
	}, [isAuthenticated, storeTokenHandler])

	useEffect(() => {
		if (token) {
			dispatch(officeActions.initialLoad({}))
		}
	}, [dispatch, token])

	return (
		<>
			<Switch>
				<GuestRoute path={RoutePath.home} exact layout={HomeLayout} component={HomePage} />
				<ProtectedRoute path={RoutePath.logout} exact component={LogoutPage} />
				<ProtectedRoute path={RoutePath.office} exact layout={OfficeLayout} component={OfficePage} />
				<ProtectedRoute
					path={RoutePath.profile}
					exact
					layout={OfficeLayout}
					component={ProfilePage}
				/>
				<ProtectedRoute path={RoutePath.requests} exact layout={OfficeLayout} component={ConsultationRequestsPage} />
				<ProtectedRoute path={RoutePath.calendar} exact layout={OfficeLayout} component={MyCalendarPage} />
				<ProtectedRoute path={RoutePath.call} exact component={CallPage} />

				<Redirect to={defaultProtectedUrl} />
			</Switch>
			<ErrorBoundary />
			<SocketNotifications />
		</>
	)
}
