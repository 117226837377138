import { makeStyles, Paper } from '@material-ui/core'
import React, { FC, useEffect } from 'react'

import { ConsultationHistoryList } from 'pages/MyCalendar/components/ConsultationHistory/ConsultationHistoryList'
import { useConsultationHistory } from 'pages/MyCalendar/hooks/useConsultationHistory'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(4),
    paddingTop: theme.spacing(2),
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 2)
  },
  headerTitle: {
    color: theme.palette.text.primary,
    fontWeight: 700,
  }
}))

type ConsultationHistoryContainerProps = {
  handlePageChange: (value: number) => void,
  page: number
}

export const ConsultationHistoryContainer: FC<ConsultationHistoryContainerProps> = ({ handlePageChange, page }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { load, loading, items, limit } = useConsultationHistory()

  useEffect(() => {
    load({
      page,
      limit,
    })
  }, [dispatch, page, limit, load])

  return (
    <Paper className={classes.root}>
      {!loading && (
        <div className={classes.header}>
          <div className={classes.headerTitle}>{t('LEOMED_ADMIN_OFFICE_MY_CALENDAR_LATEST_CONSULTATIONS_TEXT')}</div>
        </div>
      )}
      <ConsultationHistoryList
        history={items}
        loading={loading}
        handlePageChange={handlePageChange}
      />
    </Paper>
  )
}
