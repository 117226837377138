import { AxiosError } from 'axios'

import type { ServerErrorResponse } from './api/serverAxios'
import { Errors } from './constants'
import SentryClient from './sentry'

export const isInternalError = (error: Error): boolean =>
  [EvalError, RangeError, ReferenceError, SyntaxError, TypeError, URIError].some(
    (item) => error instanceof item
  )

export const isAxiosError = <T extends ServerErrorResponse>(error: Error): error is AxiosError<T> =>
  (error as AxiosError<T>).isAxiosError

export const extractErrorMessage = <R extends {}>(
  error: Error | AxiosError<R>,
  inputDefaultMessage?: string
): string => {
  const defaultMessage = inputDefaultMessage || Errors.DEFAULT

  if (process.env.NODE_ENV !== 'production') {
    console.error(error)
  }

  if (isInternalError(error)) {
    SentryClient.captureException(error)
    return defaultMessage
  }

  if (isAxiosError(error)) {
    return error?.response?.data?.message ?? error.message ?? defaultMessage
  }

  return error.message || defaultMessage
}
